import { ReactNode } from "react";
import Paginate from "react-paginate";
import styles from "./styles.module.scss";
import useUrlState from "@ahooksjs/use-url-state";
import { PaginationSelect } from "@/models/orders";
import { useTranslation } from "react-i18next";

type TPaginatedTable = {
  swrData: any;
  children: ReactNode;
};

export const PaginatedTable = ({ swrData, children }: TPaginatedTable) => {
  const [t] = useTranslation("global");
  const { data } = swrData;
  const [{ page }, setPage] = useUrlState({ page: "0" });

  const handlePageChange = (selectedObject: PaginationSelect) => {
    setPage({ page: selectedObject.selected });
  };

  return (
    <>
      {data?.content?.length > 0 ? (
        <div className="tableScroll">{children}</div>
      ) : (
        <>
          <div className="spacer" />
          <p className="bold textCenter">{t("NO_DATA")}</p>
        </>
      )}
      {Number(data?.totalPages ?? 0) > 1 && (
        <div className={styles.paginatorContainer}>
          <Paginate
            forcePage={Number(page)}
            pageCount={data.totalPages}
            previousLabel={"←"}
            nextLabel={"→"}
            marginPagesDisplayed={3}
            onPageChange={handlePageChange}
            containerClassName={styles.pagination}
            previousLinkClassName={styles.previous}
            nextLinkClassName={styles.next}
            disabledClassName={styles.disabled}
            activeClassName={styles.selected}
            breakClassName={styles.break}
          />
        </div>
      )}
    </>
  );
};
