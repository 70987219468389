import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import {
  createSupplierAddress,
  TSellerAddress,
  TSupplierAddress,
  updateSupplier,
  updateSupplierAddress,
} from "@/http";
import { useEffect, useState } from "react";
import { useStates, useCities, useCountries } from "@/hooks/useCountry";
import { useAuth } from "@/store/auth";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { useAddresses } from "@/store/profile";
import { FaEdit, FaPlus } from "react-icons/fa";
import Tooltip from "@/components/shared/Tooltip/Tooltip";
import { Modal } from "@/components/shared/Modal/Modal";
import { Select } from "@/components/shared/Input/Select";
import { InputV2 } from "@/components/shared/Input/InputV2";
import { Checkbox } from "@/components/shared/Input/Checkbox";
import { isValidEmail, isValidPhone } from "@/utils/validations/email";
import { validateOnlyNumber } from "@/utils/keyboard";
import { Autocomplete } from "@/components/shared/Autocomplete/Autocomplete";
import clsx from "clsx";
import { isUSA } from "@/constants/core";

type TAddressDetail = {
  id?: string;
  address?: Partial<TSupplierAddress> | Partial<TSellerAddress>;
  action?: (data: Partial<TSupplierAddress>) => void;
  text?: boolean;
  supplierAddress?: boolean;
};

const AddressDetail = ({
  address,
  action = () => {
    return;
  },
  text,
  supplierAddress = false,
}: TAddressDetail) => {
  const defaultValues = address
    ? { ...address, countryName: address.country }
    : {
        name: "",
        addressLine1: "",
        addressLine2: "",
        isMain: false,
        country: "",
        state: "",
        city: "",
        latitude: "",
        longitude: "",
        postalCode: "",
        phone: "",
        contactName: "",
        countryName: "",
      };
  const methods = useForm<Partial<TSupplierAddress>>({
    defaultValues,
  });
  const { handleSubmit, reset, watch } = methods;
  const countries = useCountries();
  const [country, setCountry] = useState<any>(null);
  const { states, statesIsLoading } = useStates(country?.id);
  const { cities, citiesIsLoading } = useCities(states, watch("state") ?? "");
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { getAddresses, currentPage } = useAddresses();
  const [t] = useTranslation("global");
  const { user } = useAuth();

  const onCloseModal = () => {
    reset(defaultValues);
    setIsOpen(false);
  };

  const onSubmit = async (values: any) => {
    try {
      setIsSubmitting(true);
      const payload = {
        ...values,
        countryCode: country?.prefix,
        country: country?.name,
      };
      if (supplierAddress) {
        await updateSupplier(user.id, { address: payload });
        showToastSuccess(t("ACCOUNT.ADDRESSES.SUCCESSFUL_CONTACT_DATA"));
        action({});
        reset(defaultValues);
        onCloseModal();
      } else {
        if (address?.id) {
          const addressUpdated = await updateSupplierAddress(
            user.id,
            address.id,
            payload
          );
          getAddresses(user.id, currentPage);
          showToastSuccess(t("ACCOUNT.ADDRESSES.SUCCESSFUL_EDITION"));
          reset(defaultValues);
          action(addressUpdated);
          onCloseModal();
        } else {
          const newAddress = await createSupplierAddress(user.id, payload);
          getAddresses(user.id, currentPage);
          showToastSuccess(t("ACCOUNT.ADDRESSES.SUCCESSFUL_CREATION"));
          reset(defaultValues);
          action(newAddress);
          onCloseModal();
        }
      }
    } catch (error) {
      showToastError({ message: t("ERROR_SOME_BAD"), error });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    reset(defaultValues);
  }, [address]);

  useEffect(() => {
    if (address) {
      const country = countries?.find(c => c.name === address.country);
      if (country) {
        setCountry(country);
      }
    }
  }, [countries]);

  return (
    <>
      {Object.keys(address ?? {}).length > 0 ? (
        <Tooltip
          position="Left"
          title={
            supplierAddress
              ? t("ACCOUNT.ADDRESSES.EDIT_CONTACT")
              : t("ACCOUNT.ADDRESSES.EDIT")
          }
        >
          <button
            onClick={e => {
              setIsOpen(true);
              e.stopPropagation();
            }}
            className="primary"
          >
            <FaEdit size={15} />
          </button>
        </Tooltip>
      ) : (
        <div className="rigthAlign">
          <button
            onClick={e => {
              e.preventDefault();
              setIsOpen(true);
            }}
            className={clsx("flexGap center", {
              ["primary"]: text,
              ["lg"]: !supplierAddress,
              ["purpleOutlined"]: supplierAddress,
            })}
          >
            <FaPlus />
            {supplierAddress
              ? t("ACCOUNT.ADDRESSES.ADD_CONTACT")
              : t("ACCOUNT.ADDRESSES.ADD")}
          </button>
        </div>
      )}

      <Modal
        isOpen={isOpen}
        onClose={() => onCloseModal()}
        title={
          <>
            {supplierAddress
              ? t("ACCOUNT.PERSONAL_DATA.CONTACT")
              : address?.id
              ? t("ACCOUNT.ADDRESSES.EDIT")
              : t("ACCOUNT.ADDRESSES.CREATE")}
          </>
        }
        footer={
          <>
            <button
              onClick={e => {
                e.stopPropagation();
                onCloseModal();
              }}
              disabled={isSubmitting}
            >
              {t("ACCOUNT.ADDRESSES.CANCEL")}
            </button>
            <button
              className="primary lg"
              onClick={handleSubmit(onSubmit)}
              disabled={isSubmitting}
            >
              {t("ACCOUNT.ADDRESSES.SAVE")}
            </button>
          </>
        }
      >
        <FormProvider {...methods}>
          {!supplierAddress && (
            <div className="row flexGap">
              <div className="col-12 noPadding">
                <InputV2
                  name="name"
                  type="text"
                  label={t("ACCOUNT.ADDRESSES.NAME")}
                  required
                  maxLength={80}
                />
              </div>
            </div>
          )}
          <div className="row flexGap">
            <div className="col-12 noPadding">
              <InputV2
                name="contactName"
                type="text"
                label={t("ACCOUNT.ADDRESSES.CONTACT_NAME")}
                required
                maxLength={80}
              />
            </div>
          </div>
          <div className="row flexGap">
            <div className="col-6 noPadding">
              <Autocomplete
                inputName="countryName"
                inputLabel={t("COUNTRY")}
                inputPlaceholder=""
                required
                choices={
                  countries?.map(country => ({
                    ...country,
                    id: "" + country.id,
                  })) ?? []
                }
                action={country => {
                  methods.setValue("countryName", country.name);
                  setCountry(country);
                }}
              />
            </div>
            <div className="col-6 noPadding">
              <Select
                name="state"
                label={t("ACCOUNT.ADDRESSES.STATE")}
                register={methods.register}
                error={methods.formState.errors.state}
                required
                disabled={statesIsLoading}
                choices={states?.map(state => ({
                  value: state.name,
                  label: state.name,
                }))}
              />
            </div>
          </div>
          <div className="row flexGap">
            <div className="col-6 noPadding">
              <Select
                name="city"
                label={t("ACCOUNT.ADDRESSES.CITY")}
                register={methods.register}
                error={methods.formState.errors.city}
                required
                disabled={citiesIsLoading}
                choices={cities?.map(city => ({
                  value: city.name,
                  label: city.name,
                }))}
              />
            </div>
          </div>
          <div className="row flexGap">
            <div className="col-12 noPadding">
              <InputV2
                name="addressLine1"
                type="text"
                label={t("ACCOUNT.ADDRESSES.ADDRESS_LINE_1")}
                placeholder={t("ACCOUNT.ADDRESSES.ADDRESS_LINE_1_PLACEHOLDER")}
                required
                maxLength={170}
              />
            </div>
          </div>
          <div className="row flexGap">
            <div className="col-12 noPadding">
              <InputV2
                name="addressLine2"
                type="text"
                label={t("ACCOUNT.ADDRESSES.ADDRESS_LINE_2")}
                placeholder={t("ACCOUNT.ADDRESSES.ADDRESS_LINE_2_PLACEHOLDER")}
                maxLength={170}
              />
            </div>
          </div>
          <div className="row flexGap">
            <div className="col-6 noPadding">
              <InputV2
                name="postalCode"
                type="text"
                label={t("ACCOUNT.ADDRESSES.POSTAL_CODE")}
                required={!isUSA}
                maxLength={10}
                onKeyPress={validateOnlyNumber}
              />
            </div>
            <div className="col-6 noPadding">
              <InputV2
                name="phone"
                label={t("COMMERCIALS.PHONE")}
                required={!isUSA}
                placeholder={t("COMMERCIALS.TEN_DIGITS")}
                validate={isValidPhone}
                onKeyPress={validateOnlyNumber}
                validMessage={t("LOGIN.PHONE_VALIDATION")}
                maxLength={10}
              />
            </div>
          </div>
          {supplierAddress && (
            <div className="row flexGap">
              <div className="col-6 noPadding">
                <InputV2
                  name="email"
                  validate={isValidEmail}
                  label={t("CLIENTS.EMAIL")}
                  placeholder={t("LOGIN.EMAIL_PLACEHOLDER")}
                />
              </div>
              <div className="col-6 noPadding" />
            </div>
          )}

          {!supplierAddress && (
            <div className="row flexGap">
              <div className="col-6 noPadding">
                <Checkbox name="isMain" label={t("MAIN_ADDRESS")} />
              </div>
              <div className="col-6 noPadding">
                <Checkbox name="overStock" label={t("OVERSTOCK")} />
              </div>
            </div>
          )}
        </FormProvider>
      </Modal>
    </>
  );
};

export default AddressDetail;
