import { create } from "zustand";
import { getSupplierBoxes } from "@/http";
import { TSupplierBox } from "@/http/types/supplier-box";

type TUseBoxes = {
  getBoxes: (...args: any) => void;
  boxes: TSupplierBox[];
  isLoading: boolean;
  totalPages: number;
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
};
export const useBoxes = create<TUseBoxes>((set, get) => ({
  getBoxes: async (userId: string) => {
    set(() => ({ isLoading: true }));
    try {
      const { currentPage } = get();
      if (userId) {
        const boxes = await getSupplierBoxes(userId, {
          page: currentPage,
          size: 10,
        });

        set(() => ({
          boxes: boxes.content,
          isLoading: false,
          totalPages: boxes.totalPages,
        }));
      }
    } catch (error) {
      set(() => ({ tieredDiscounts: [], isLoading: false }));
    }
  },
  boxes: [],
  isLoading: false,
  totalPages: 0,
  currentPage: 0,
  setCurrentPage: currentPage => set(() => ({ currentPage })),
}));
