import { CoinsBalance } from "@/components/profile/coins/CoinsBalance";
import { CoinsList } from "@/components/profile/coins/CoinsList";
import { Card } from "@/components/shared/Card/Card";
import { SellerResume } from "@/components/shared/Sellers/SellerResume/SellerResume";
import { getSellerById } from "@/http";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import useSWR from "swr";

export const CoinsVaultPage = () => {
  const { setTitle } = useHeaderTitle();
  const { id } = useParams();
  const [t] = useTranslation("global");
  const { data: seller } = useSWR(
    id ? [id, "seller-by-id"] : null,
    getSellerById
  );

  useEffect(() => {
    setTitle(t("SIDEBAR.COINS_VAULT"), "", "");
  }, [t]);

  if (!id) {
    return null;
  }

  return (
    <>
      <div className="bolder">
        <SellerResume seller={seller} hideEdit />
      </div>
      <CoinsBalance sellerId={id} />
      <div className="spacer" />
      <Card className="noPadding">
        <CoinsList sellerId={id} />
      </Card>
    </>
  );
};
