import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";
import { TInvoice } from "../invoiceData";
import { invoiceTotalsStyles, invoiceTotalsStylesPOS } from "./Styles";
import { formatCurrency } from "@/utils/currency";

type Props = {
  invoice: TInvoice;
  translate: any;
  forPOS?: boolean;
};

const styles = StyleSheet.create(invoiceTotalsStyles);
const stylesPOS = StyleSheet.create(invoiceTotalsStylesPOS);

const InvoiceTotalsTable = ({ translate, invoice, forPOS }: Props) => {
  return (
    <>
      {invoice?.total !== invoice?.subTotal && (
        <View style={forPOS ? stylesPOS.container : styles.container}>
          <View style={styles.item}>
            <Text>{translate("SUB_TOTAL")}: </Text>
          </View>
          <View style={styles.value}>
            <Text>{formatCurrency(invoice?.subTotal || 0)}</Text>
          </View>
        </View>
      )}
      {invoice?.discount && (
        <View style={forPOS ? stylesPOS.container : styles.container}>
          <View style={styles.item}>
            <Text>{translate("DISCOUNT")}: </Text>
          </View>
          <View style={styles.value}>
            <Text>{formatCurrency(invoice?.discount || 0)}</Text>
          </View>
        </View>
      )}
      {invoice?.coins && (
        <View style={forPOS ? stylesPOS.container : styles.container}>
          <View style={styles.item}>
            <Text>{translate("COINS_DISCOUNT")}: </Text>
          </View>
          <View style={styles.value}>
            <Text>{formatCurrency(invoice?.coins || 0)}</Text>
          </View>
        </View>
      )}
      {invoice?.shipping && (
        <View style={forPOS ? stylesPOS.container : styles.container}>
          <View style={styles.item}>
            <Text>{translate("SHIPPING")}: </Text>
          </View>
          <View style={styles.value}>
            <Text>{formatCurrency(invoice?.shipping || 0)}</Text>
          </View>
        </View>
      )}
      <View style={forPOS ? stylesPOS.container : styles.container}>
        <View style={styles.item}>
          <Text>{translate("TOTAL")}: </Text>
        </View>
        <View style={styles.value}>
          <Text>{formatCurrency(invoice?.total || 0)}</Text>
        </View>
      </View>
    </>
  );
};

export default InvoiceTotalsTable;
