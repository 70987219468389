import styles from "./styles.module.scss";
import Paginate from "react-paginate";
import { useOffers } from "@/store/offers";
import { PaginationSelect } from "@/models/orders";
import { useAuth } from "@/store/auth";
import { useEffect } from "react";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { CreateOfferButton } from "../create/CreateOfferButton";
import { FilterTabs } from "@/components/app/ButtonFilterTabs/ButtonFilterTabs";
import { OfferRow } from "./OfferRow";
import { useTranslation } from "react-i18next";

export const OffersList = () => {
  const {
    currentPage,
    setCurrentPage,
    getOffers,
    offers,
    isLoadingOffers,
    setCurrentFilter,
    currentFilter,
  } = useOffers();
  const { user } = useAuth();
  const [t] = useTranslation("global");

  useEffect(() => {
    getOffers(user.id);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(0);
    getOffers(user.id);
  }, [currentFilter]);

  if (isLoadingOffers) {
    return <Spinner />;
  }

  return (
    <>
      <CreateOfferButton />

      <FilterTabs
        options={[{ text: t("OFFERS.ALL"), value: "" }]}
        currentFilter={currentFilter}
        action={setCurrentFilter}
      />

      <div className={styles.container}>
        <div className="tableScroll">
          <table className={styles.table}>
            <thead>
              <tr className={styles.row}>
                <th>{t("OFFERS.CREATION")}</th>
                <th>{t("OFFERS.NAME")}</th>
                <th>{t("OFFERS.OFFER_TYPE")}</th>
                <th>{t("OFFERS.MIN_VALUE")}</th>
                <th>{t("OFFERS.VALID")}</th>
                <th>{t("OFFERS.STATUS")}</th>
                <th>{t("ACTIONS")}</th>
              </tr>
            </thead>
            <tbody>
              {offers.map(offer => (
                <OfferRow rowOffer={offer} key={offer.id} />
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <OffersPagination />
    </>
  );
};

const OffersPagination = () => {
  const { totalPages, currentPage, setCurrentPage } = useOffers();

  const handlePageChange = (selectedObject: PaginationSelect) => {
    setCurrentPage(selectedObject.selected);
  };

  if (totalPages < 2) {
    return null;
  }

  return (
    <div className={styles.paginate}>
      <Paginate
        forcePage={currentPage}
        pageCount={totalPages}
        previousLabel={"←"}
        nextLabel={"→"}
        marginPagesDisplayed={3}
        onPageChange={handlePageChange}
        containerClassName={styles.pagination}
        previousLinkClassName={styles.previous}
        nextLinkClassName={styles.next}
        disabledClassName={styles.disabled}
        activeClassName={styles.selected}
        breakClassName={styles.break}
      />
    </div>
  );
};
