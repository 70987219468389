import { CommercialResume } from "@/components/shared/Commercials/CommercialResume/CommercialResume";
import { CommercialSellerSearchInput } from "@/components/shared/Commercials/CommercialSellerInput/CommercialSellerSearchInput";
import { useCommercialById } from "@/hooks/useSupplier";
import { showToastError } from "@/hooks/useToast";
import { updateSellerCommercial } from "@/http";
import { useAuth } from "@/store/auth";
import { getSupplierSellerFromSuppliers } from "@/utils/sellers";
import clsx from "clsx";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import styles from "./styles.module.scss";

type TClientCommercial = {
  suppliers: any[];
  onDone?: (value?: any) => void;
  clientId: string;
};
export const ClientCommercial = ({
  suppliers,
  onDone,
  clientId,
}: TClientCommercial) => {
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [t] = useTranslation("global");
  const { user } = useAuth();
  const supplierSeller = getSupplierSellerFromSuppliers(
    suppliers || [],
    user.id
  );
  const defaultValues = { commercialId: supplierSeller?.commercialId ?? "" };
  const methods = useForm({ defaultValues });

  const onSubmit = async (values: any) => {
    try {
      setLoading(true);

      await updateSellerCommercial(clientId, user.id, {
        commercialId: values?.commercial?.id,
        referralCode: values?.commercial?.referralCode,
      } as any);
      onDone?.();
      setLoading(false);
      setOpen(false);
    } catch (error: any) {
      setLoading(false);
      setOpen(false);
      showToastError({ message: t("ERROR_SOME_BAD"), error });
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        {!isOpen ? (
          <>
            {methods.watch("commercialId") ? (
              <CommercialName
                id={methods.watch("commercialId")}
                editAction={() => setOpen(true)}
                deleteAction={() => onSubmit({})}
              />
            ) : (
              <div className="flexGap">
                {t("NO_DATA_COMMERCIAL")}
                <button
                  className={clsx("pointer", "purple", styles.close)}
                  onClick={e => {
                    e.preventDefault();
                    setOpen(true);
                  }}
                >
                  <div className="pointer circleButton">{t("ADD")}</div>
                </button>
              </div>
            )}
          </>
        ) : (
          <div className="col-6 col-sm-12 noPadding">
            <CommercialSellerSearchInput disabled={isLoading} />
            <div className="rigthAlign">
              <button
                onClick={() => {
                  methods.setValue(
                    "commercialId",
                    supplierSeller?.commercialId ?? ""
                  );
                  setOpen(false);
                }}
                disabled={isLoading}
              >
                {t("CANCEL")}
              </button>
              <button
                className="md primary"
                onClick={methods.handleSubmit(onSubmit)}
                disabled={isLoading}
              >
                {t("SAVE")}
              </button>
            </div>
          </div>
        )}
      </FormProvider>
    </>
  );
};

const CommercialName = ({
  id,
  editAction,
  deleteAction,
}: {
  id: string;
  editAction: (...args: any[]) => void;
  deleteAction: (...args: any[]) => void;
}) => {
  const commercial = useCommercialById(id);

  return (
    <div className="flexGap centerVAlign">
      <CommercialResume commercial={commercial} />

      <button
        className={clsx("ml1", "pointer", "circle", "noPadding", styles.close)}
        onClick={e => {
          e.preventDefault();
          editAction();
        }}
      >
        <div className="pointer circleButton">
          <MdOutlineEdit />
        </div>
      </button>
      <button
        className={clsx("pointer", "circle", "noPadding", styles.close)}
        onClick={e => {
          e.preventDefault();
          deleteAction();
        }}
      >
        <div className="pointer circleButton">
          <MdOutlineDelete />
        </div>
      </button>
    </div>
  );
};
