import { InputV2 } from "@/components/shared/Input/InputV2";
import { Select } from "@/components/shared/Input/Select";
import { Modal } from "@/components/shared/Modal/Modal";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { useAuth } from "@/store/auth";
import { usePurchase } from "@/store/purchase";
import { isFinishedPurchase } from "@/utils/actions-disabled";
import {
  addPurchaseComment,
  cancelPurchase,
  getPurchaseCancellationReasons,
  TPurchase,
} from "@/http";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export type TCancelPurchase = {
  purchase: TPurchase;
};
export const CancelPurchase = ({ purchase }: TCancelPurchase) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reasons, setReasons] = useState<[]>([]);
  const { user } = useAuth();
  const { getPurchase } = usePurchase();
  const [t] = useTranslation("purchases");
  const [tGlobal] = useTranslation("global");
  const methods = useForm({ defaultValues: { reason: "", actionComment: "" } });

  const hasRemission = purchase?.subPurchases?.some(
    ({ supplierStatus }: any) => supplierStatus === "REMISSION_GENERATED"
  );

  const hasPurchaseId = !!purchase?.id;
  const isCancelEnabled = !!purchase?.cancelEnabled;
  const isPaymentPending = purchase?.paymentStatus === "PENDING";
  const isDeliveryNotFinal = !["REJECTED", "CANCELLED"].includes(
    purchase?.deliveryStatus
  );

  const disabledConditions =
    "DELIVERED" === purchase?.deliveryStatus
      ? false
      : !(
          hasPurchaseId &&
          isCancelEnabled &&
          isPaymentPending &&
          isDeliveryNotFinal
        ) || hasRemission;

  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    try {
      const { reason, actionComment: comment } = values;
      await cancelPurchase(purchase.id, {
        reason,
      });

      await addPurchaseComment(purchase.id, {
        createdBy: user.id,
        comment,
        fullName: user.name,
      });

      showToastSuccess(t("PURCHASE_CANCELLED"));
    } catch (error) {
      showToastError({ message: tGlobal("ERROR_SOME_BAD"), error });
    } finally {
      await getPurchase(purchase.id);
      setIsLoading(false);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    getPurchaseCancellationReasons("supplier").then((data: any) => {
      setReasons(data);
    });
  }, []);

  if (isFinishedPurchase(purchase)) {
    return null;
  }

  return (
    <>
      <div className="row rigthAlign">
        <button
          className="outlined"
          onClick={() => setIsOpen(true)}
          disabled={disabledConditions}
        >
          {t("CANCEL_PURCHASE")}
        </button>
      </div>
      {createPortal(
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="sm">
          <div className="spacer" />
          <h6 className="bold">
            {t("WANNA_CANCEL")} {purchase.purchaseNumber}?
          </h6>
          <div className="divider" />
          <div className="spacer" />
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)}>
              <Select
                name="reason"
                label={t("CANCELATION_REASON")}
                register={methods.register}
                required
                choices={reasons.map(reason => ({
                  value: reason,
                  label: reason,
                }))}
              />
              <InputV2 name="actionComment" label={t("COMMENT")} required />
              <div className="divider" />
              <div className="spacer" />
              <div className="rigthAlign">
                <button
                  onClick={e => {
                    e.preventDefault();
                    setIsOpen(false);
                  }}
                  disabled={isLoading}
                >
                  {t("CLOSE")}
                </button>
                <button
                  disabled={isLoading}
                  className="primary lg"
                  type="submit"
                >
                  {t("CANCEL_PURCHASE")}
                </button>
              </div>
            </form>
            <div className="spacer" />
          </FormProvider>
        </Modal>,
        document.body
      )}
    </>
  );
};
