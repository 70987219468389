import { THeaderTitleStatus, TSideBarStatus } from "@/models/ui";
import { create } from "zustand";

export const useSideBarStatus = create<TSideBarStatus>(set => ({
  isSideBarOpen: false,
  setSideBarOpen: () => set(state => ({ isSideBarOpen: !state.isSideBarOpen })),
  isSideBarCollapsed: true,
  setSideBarCollapsed: (isSideBarCollapsed: boolean) =>
    set(() => ({ isSideBarCollapsed })),
}));

export const useHeaderTitle = create<THeaderTitleStatus>(set => ({
  title: "",
  goBack: "",
  videoId: "",
  action: null,
  specificUrl: false,
  setTitle: (
    title,
    goBack = "",
    videoId = "",
    action = null,
    specificUrl = false
  ) => set(() => ({ title, goBack, videoId, action, specificUrl })),
}));
