import "@/components/orders/RangeDatePicker/styles.scss";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { format } from "date-fns";
import {
  DATE_MONT_YEAR_SLASH,
  USA_DATE_MONT_YEAR_SLASH,
} from "@/constants/date";
import { BiCalendarEvent } from "react-icons/bi";
import { isUSA } from "@/constants/core";
import { FC, useState } from "react";

type Props = Omit<ReactDatePickerProps, "onChange"> & {
  startDate: Date;
  endDate: Date;
  onChange: (dates: [Date, Date]) => void;
};

export const RangeDatePicker: FC<Props> = ({
  startDate,
  endDate,
  onChange,
  ...props
}) => {
  const [t] = useTranslation("orders-page");
  const [start, setStart] = useState<Date | null>(startDate);
  const [end, setEnd] = useState<Date | null>(endDate);

  const handleChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStart(start);
    setEnd(end);
    if (start && end) onChange([start, end]);
  };

  return (
    <div className={styles.dateInput}>
      <DatePicker
        selected={start}
        onChange={handleChange}
        startDate={start}
        endDate={end}
        selectsRange
        dateFormat="dd/MM/yyyy"
        customInput={
          <div className={styles.inputGroup}>
            <BiCalendarEvent className={styles.icon} />
            <input
              type="text"
              value={`${format(
                startDate,
                isUSA ? USA_DATE_MONT_YEAR_SLASH : DATE_MONT_YEAR_SLASH
              )} - ${format(
                endDate,
                isUSA ? USA_DATE_MONT_YEAR_SLASH : DATE_MONT_YEAR_SLASH
              )}`}
              readOnly
              placeholder={t("FILTER.SINCE_TO")}
            />
          </div>
        }
        {...props}
      />
    </div>
  );
};
