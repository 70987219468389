import { create } from "zustand";
import { getCommercialsVisits } from "@/http";

type TUseCommercialsVisit = {
  commercial: any;
  setCommercial: (...args: any) => void;
  getCommercialsVisits: (...args: any) => void;
  commercialsVisits: any[];
  isLoading: boolean;
  totalPages: number;
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
};
export const useCommercialsVisits = create<TUseCommercialsVisit>(
  (set, get) => ({
    commercial: null,
    setCommercial: commercial => set(() => ({ commercial })),
    getCommercialsVisits: async () => {
      set(() => ({ isLoading: true }));
      try {
        const { currentPage, commercial } = get();
        const commercialsVisits = await getCommercialsVisits({
          page: currentPage,
          size: 10,
          search: commercial?.id ? `commercialId:${commercial?.id}` : "",
        });

        set(() => ({
          commercialsVisits: commercialsVisits.content,
          isLoading: false,
          totalPages: commercialsVisits.totalPages,
        }));
      } catch (error) {
        set(() => ({ commercialsVisits: [], isLoading: false }));
      }
    },
    commercialsVisits: [],
    isLoading: false,
    totalPages: 0,
    currentPage: 0,
    setCurrentPage: currentPage => set(() => ({ currentPage })),
  })
);
