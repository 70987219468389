import { TPurchaseProduct } from "./purchase";

export type TTicketModelType = "RETURN" | "REFUND";

export enum TTicketRefundType {
  NONE = "NONE",
  MANUAL = "MANUAL",
  COINS = "COINS",
  REPLENISH_PRODUCTS = "REPLENISH_PRODUCTS",
  NOT_APPLICABLE_FOR_SUPPLIER = "NOT_APPLICABLE_FOR_SUPPLIER",
}

export type TTicketType = {
  code: TTicketModelType;
  name: string;
};

export type TTicketReasonCode =
  | "ORDER_WITHOUT_ARRIVING"
  | "INCOMPLETE_ORDER"
  | "DEFECTIVE_PRODUCTS"
  | "WRONG_PRODUCTS";

export type TTicketReason = {
  id: string;
  code: TTicketReasonCode;
  name: string;
  enabled: true;
};

export type TTicketRefundField = {
  field: "refundType";
  name: string;
  defaultValue: TTicketRefundType;
  service: null;
  type: "string";
  optionText: null;
  choices: null;
};

export type TTicketPickupField = {
  field: "requestPickup";
  name: string;
  defaultValue: boolean;
  service: null;
  type: "boolean";
  optionText: null;
  choices: null;
};

export type TTicketCommentField = {
  field: "comment";
  name: string;
  defaultValue: "";
  service: null;
  type: "string";
  optionText: null;
  choices: null;
};

export type TTicketEvidencesField = {
  field: "evidences";
  name: string;
  defaultValue: [];
  service: null;
  type: "evidence-array";
  optionText: null;
  choices: null;
};

export type TTicketProductsField = {
  field: "products";
  name: string;
  defaultValue: [];
  service: null;
  type: "product-array";
  optionText: null;
  choices: {
    id: string;
    name: string;
    quantity: number;
    pvp: number;
    images: {
      src: string;
    }[];
  }[];
};

export type TTicketField =
  | TTicketProductsField
  | TTicketEvidencesField
  | TTicketCommentField
  | TTicketPickupField
  | TTicketRefundField;

export type TTicketForm = {
  form: {
    id: TTicketReasonCode;
    type: string | null;
    name: string;
    code: string | null;
    fields: TTicketField[];
  };
};

export type TTicketPayload = {
  id?: string;
  evidenceLink: string;
  reason: string;
  type: string;
  productReturned: Record<string, number>;
};

export type TTicketEvidence = {
  id?: string;
  src: string;
  type: string;
};

export type TTicketTimeline = {
  id: string;
  name: string;
  sequence: number;
};

export type TPurchaseTicket = {
  id: string;
  number: string;
  type: TTicketType;
  status: TicketStatusEnum;
  createdAt: string;
  purchaseId: string;
  purchaseNumber: string;
  reason: {
    code: TTicketReasonCode;
    name: string;
  };
  reply?: string;
};

export type TListedTicket = {
  id: string;
  reason: {
    code: TTicketReasonCode;
    name: string;
  };
  type: "string";
  status: TicketStatusEnum;
  comment: string;
  userId: string;
  createdAt: string;
  number: string;
  evidences: TTicketEvidence[];
  timelines: {
    id: string;
    name: string;
    date: string;
    sequence: number;
  }[];
  purchaseId: string;
  purchaseNumber: string;
  products: TPurchaseProduct[];
  requestPickup: boolean;
  forSupplier: boolean;
  refundType: TTicketRefundType;
  reply?: string;
  supplierStatus: boolean;
};

export type TTicketResponse = {
  type: TTicketModelType;
  refund: boolean;
  requestPickup: boolean;
  reply: string;
  status: TicketStatusEnum;
};

export type TTicketProduct = {
  id: string;
  quantity: number;
};

export type TTicketModification = {
  id: string;
  ticketId: string;
  status: string;
  comment: string;
  createdAt: string;
  number: string;
  purchaseId: string;
  purchaseNumber: string;
  requestPickup: boolean;
  refundType: string;
  forSupplier: boolean;
};

export declare type TTicketDetail = {
  evidenceLink: string;
  id: string;
  reason: string;
  type: string;
  status: TicketStatusEnum;
  comment: string;
  userId: string;
  createdAt: string;
  number: string;
  evidences: TTicketEvidence[];
  timelines: {
    id: string;
    name: string;
    date: string;
    sequence: number;
  }[];
  purchaseId: string;
  purchaseNumber: string;
  products: TPurchaseProduct[];
  requestPickup: boolean;
  forSupplier: boolean;
  refundType: TTicketRefundType;
  reply?: string;
  modifications?: TTicketModification[];
  supplierStatus: boolean;
};

export enum TicketStatusEnum {
  PENDING = "PENDING",
  IN_SUPPLIER_REVIEW = "IN_SUPPLIER_REVIEW",
  IN_PROCESS = "IN_PROCESS",
  IN_REVIEW = "IN_REVIEW",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export type TRefundType = {
  value: string;
  name: string;
};
