import { BulkImportMode } from "./types";

export const apiModes: Record<BulkImportMode, { method: string; path: string }> = {
    ASSIGN: {
      method: "POST",
      path: "/api/seeri-excel/supplier-products/bulk-assign",
    },
    UPDATE: {
      method: "POST",
      path: "/api/seeri-excel/supplier-products/bulk-update",
    },
    CREATE: {
      method: "POST",
      path: "/api/seeri-excel/product-groups",
    },
  };