import { CURRENT_LANG, ES } from "@/constants/core";
import { deliveryStatus, paymentStatus } from "@/constants/purchases";
import { TPaymentMethodV2 } from "@/http";

export const getEventCopy = (modification: any, paymentMethods: any, t: any) => {
  const isES = CURRENT_LANG() === ES;
  const logCoinTransactionType: { [key: string]: () => string } = {
    CREATE: () => (isES ? "Orden creada" : "Order created"),
    QUOTED: () => (isES ? "Cotización creada" : "Quote created"),
    SENT_TO_DELIVERY: () =>
      isES ? "Pedido enviado a integracion" : "Order sent to integration",
    PURCHASE_CANCEL_ORDER: () =>
      isES
        ? `Orden cancelada con razon: ${modification?.data?.reason}`
        : `Order canceled with reason: ${modification?.data?.reason}`,
    PURCHASE_UPDATE_CANCEL_ENABLED: () =>
      isES ? "Orden habilitada para cancelar" : "Order enabled to cancel",
    PURCHASE_UPDATE_PRODUCT_LIST: () =>
      isES ? "Productos actualizados" : "Updated products",
    PAYMENT_UPDATE: () =>
      isES
        ? `Se actualizo el estado de pago de ${getPaymentStatusByCode(
            modification?.data?.oldPaymentStatus
          )} a ${getPaymentStatusByCode(modification?.data?.newPaymentStatus)}`
        : `The payment status was updated from ${getPaymentStatusByCode(
            modification?.data?.oldPaymentStatus
          )} to ${getPaymentStatusByCode(
            modification?.data?.newPaymentStatus
          )}`,
    SUB_PURCHASE_CREATED: () =>
      isES
        ? `Se creo el subpedido: ${modification?.data?.subPurchaseNumber}`
        : `The suborder ${modification?.data?.subPurchaseNumber} was created`,
    SUB_PURCHASE_DELETED: () =>
      isES ? "Se ha eliminado un subpedido" : "A suborder has been deleted",
    DELIVERY_STATUS_UPDATE: () =>
      isES
        ? `Se actualizo el estado de envio de ${getDeliveryStatusByCode(
            modification?.data?.oldDeliveryStatus
          )} a ${getDeliveryStatusByCode(
            modification?.data?.newDeliveryStatus
          )}`
        : `The shipping status was updated from${getDeliveryStatusByCode(
            modification?.data?.oldDeliveryStatus
          )} to ${getDeliveryStatusByCode(
            modification?.data?.newDeliveryStatus
          )}`,
    CLIENT_LOCATION_UPDATE: () =>
      isES ? "Direccion del cliente actualizada" : "Customer address updated",
    UPDATE_PRODUCTS: () =>
      isES ? "Productos actualizados" : "Updated products",
    PAYMENT_METHOD_UPDATE: () =>
      isES
        ? `Metodo de pago actualizado de ${getPaymentMethodByCode(
            paymentMethods,
            modification?.data?.oldPaymentMethod,
            t
          )} a ${getPaymentMethodByCode(
            paymentMethods,
            modification?.data?.newPaymentMethod,
            t
          )}`
        : `Payment method updated from ${getPaymentMethodByCode(
            paymentMethods,
            modification?.data?.oldPaymentMethod,
            t
          )} to ${getPaymentMethodByCode(
            paymentMethods,
            modification?.data?.newPaymentMethod,
            t
          )}`,
    MANUAL_UPDATE_PRIORITY: () =>
      isES
        ? `Prioridad actalizada de ${modification?.data?.oldPriority} a ${modification?.data?.newPriority}`
        : `Priority updated from ${modification?.data?.oldPriority} to ${modification?.data?.newPriority}`,
    DEFAULT: () =>
      isES ? `Otro: ${modification?.event}` : `Other: ${modification?.event}`,
  };
  return (
    logCoinTransactionType[modification.event]?.() ||
    logCoinTransactionType["DEFAULT"]()
  );
};

const getPaymentStatusByCode = (text: string) => {
  return paymentStatus.find(s => s.code === text)?.name || "";
};

const getDeliveryStatusByCode = (text: string) => {
  return deliveryStatus.find(s => s.code === text)?.name || "";
};

const getPaymentMethodByCode = (
  paymentMethods: TPaymentMethodV2[],
  text: string,
  t: any
) => {
  return (
    t(`PAYMENT_METHOD.${paymentMethods.find((s: TPaymentMethodV2) => s.code === text)?.code}`) || ""
  );
};
