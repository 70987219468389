import { useTranslation } from "react-i18next";
import { useHeaderTitle } from "@/store/ui";
import { useEffect, useState } from "react";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { getSupplierDashboard } from "@/http";
import { useAuth } from "@/store/auth";
import { useSubscription } from "@/hooks/useSubscription";
import { FEAT_ADVANCED_DASHBOARD } from "@/constants/features";
import { LockedFeaturePlaceholder } from "@/components/subscription/LockedFeaturePlaceholder";

export const PowerBIReport = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");
  const { hasFeature } = useSubscription();

  useEffect(() => {
    setTitle(t("SIDEBAR.METRICS"));
  }, [t]);

  if (!hasFeature(FEAT_ADVANCED_DASHBOARD)) {
    return <LockedFeaturePlaceholder />;
  }

  return <PowerBIContent />;
};

const PowerBIContent = () => {
  const { user } = useAuth();

  const [reportConfig, setReportConfig] =
    useState<models.IReportEmbedConfiguration>({
      type: "report",
      embedUrl: undefined,
      accessToken: undefined,
      id: undefined,
      tokenType: models.TokenType.Embed,
      settings: {
        panes: {
          filters: {
            expanded: false,
            visible: true,
          },
        },
        background: models.BackgroundType.Transparent,
      },
    });
  useEffect(() => {
    getSupplierDashboard({
      datasets: [
        {
          id: "13f8bf78-43fa-42d0-8bb9-ac60051f3c7d",
        },
      ],
      reports: [
        {
          id: "ba749cea-5eea-4a46-a762-558422cff6c6",
        },
      ],
    }).then((response: any) => {
      setReportConfig({
        ...reportConfig,
        embedUrl: response?.value?.[0].embedUrl,
        accessToken: response.token,
        id: response?.value?.[0]?.id,
        filters: [
          {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: "purchases",
              column: "supplier_id",
            },
            operator: "In",
            values: [user.id],
            filterType: models.FilterType.Basic,
            requireSingleSelection: true,
          },
          {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: "sellers",
              column: "supplier_id",
            },
            operator: "In",
            values: [user.id],
            filterType: models.FilterType.Basic,
            requireSingleSelection: true,
          },
          {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: "products",
              column: "supplier_id",
            },
            operator: "In",
            values: [user.id],
            filterType: models.FilterType.Basic,
            requireSingleSelection: true,
          },
        ],
        settings: {
          panes: {
            filters: {
              expanded: false,
              visible: false,
            },
          },
          background: models.BackgroundType.Transparent,
        },
      });
    });
  }, []);

  return (
    <>
      <div>
        <PowerBIEmbed embedConfig={reportConfig} cssClassName="vh85" />
      </div>
    </>
  );
};
