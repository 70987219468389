import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { TOrderDetailsPanel } from "@/models/order-details";
import {
  getSupplierBoxById,
  quoteDeliverySupplierOrder,
  updateSupplierOrder,
  updateTrackingCodeDeliveryProvider,
  updateTrackingCodeSupplierOrder,
} from "@/http";
import { useOrderDetails } from "@/store/order-detail";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { uploadFile } from "@/utils/file-upload";
import { buckets } from "@/core/buckets";
import { nanoid } from "nanoid";
import { Modal } from "../../shared/Modal/Modal";
import { useAddresses } from "@/store/profile";
import { useAuth } from "@/store/auth";
import { get } from "lodash-es";
import { formatCurrency } from "@/utils/currency";
import { SeeriLogisticDispatchForm } from "./SeeriLogisticDispatchForm";
import { OwnLogisticDispatchForm } from "./OwnLogisticDispatchForm";
import { LogisticOptionsForm } from "./LogisticOptionsForm";
import { ProviderLogisticDispatchForm } from "./ProviderLogisticDispatchForm";

const ConfirmOrderModal = ({
  orderDetail,
  userId,
  purchase,
}: TOrderDetailsPanel) => {
  const methods = useForm<any>({
    defaultValues: {
      dispatchValue: "SUPPLIER",
      warehouseId: orderDetail?.warehouseId ?? "",
      useSameBox: true,
      boxes: 1,
    },
  });
  const [open, setOpen] = useState(false);
  const [usedTrackingCode, setUsedTrackingCode] = useState(false);
  const [openShipping, setOpenShipping] = useState(false);
  const [quotation, setQuotation] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<any>([]);
  const { getAddresses, loadingOrders, addresses } = useAddresses();
  const { setOrderDetail } = useOrderDetails();
  const [t] = useTranslation("orders-page");
  const [tGlobal] = useTranslation("global");
  const { user } = useAuth();

  const uploadGuide = async (trackingFile: any) => {
    const url = await uploadFile(
      buckets.purchases,
      "SupplierGuide",
      nanoid(),
      trackingFile
    );

    return url;
  };

  const confirmOrder = async (values: any) => {
    const {
      boxes = 0,
      trackingCode = "",
      dispatchValue = "",
      estimatedDeliveryDate = "",
      observations = "",
    } = values;

    if (orderDetail?.id && userId) {
      setIsLoading(true);
      if (dispatchValue === "SEERI") {
        if (boxes < 1) {
          showToastError(t("ERROR_BOXES"));
          setIsLoading(false);
          return;
        }
        try {
          const address = user.addresses?.find(
            a => a.id === values.warehouseId
          );
          const box = await getSupplierBoxById(user.id, values.singleBoxSize);

          const payload = {
            destino: get(purchase, "client.location.city", ""),
            departamentoDestino: get(purchase, "client.location.state", ""),
            valorDeclarado: Number(values?.declaredValue ?? "0"),
            origen: address?.city,
            departamentoOrigen: address?.state,
            alto: box.height,
            ancho: box.width,
            largo: box.length,
            peso: Number(values.singleWeight),
            unidades: Number(values.boxes),
          };
          const quote = await quoteDeliverySupplierOrder(payload);
          setQuotation(quote);
          setOpenShipping(true);
        } catch (e) {
          await confirmOrderSeeri(values);
        } finally {
          setIsLoading(false);
        }
      } else if (dispatchValue === "SUPPLIER") {
        try {
          if (usedTrackingCode) {
            setIsLoading(false);
            return;
          }

          let url;
          if (files.length) {
            url = await uploadGuide(files[0]);
          }
          let dateString;
          if (estimatedDeliveryDate) {
            const date = new Date(estimatedDeliveryDate);
            date.setHours(23, 59, 59, 999);
            dateString = date.toISOString();
          }
          const supplierOrder = (await updateTrackingCodeSupplierOrder(
            orderDetail.id,
            trackingCode ? trackingCode : orderDetail.subPurchaseNumber,
            url,
            dateString,
            observations
          )) as any;
          showToastSuccess(t("ORDER_APPROVED"));
          setOrderDetail(supplierOrder);
          setOpen(false);
        } catch (error: any) {
          showToastError({ message: tGlobal("ERROR_SOME_BAD"), error });
        } finally {
          setIsLoading(false);
        }
      } else {
        if (!files?.length) {
          showToastError(t("GUIDE_REQUIRED"));
          setIsLoading(false);
          return;
        }
        if (usedTrackingCode) {
          setIsLoading(false);
          return;
        }
        const { providerDeliveryStatus = "" } = values;
        const url = await uploadGuide(files[0]);

        const supplierOrder = (await updateTrackingCodeDeliveryProvider(
          orderDetail.id,
          trackingCode ? trackingCode : orderDetail.subPurchaseNumber,
          url,
          dispatchValue,
          providerDeliveryStatus
        )) as any;
        showToastSuccess(t("ORDER_APPROVED"));
        setOrderDetail(supplierOrder);
        setOpen(false);
      }
    }
  };

  const confirmOrderSeeri = async (values: any) => {
    try {
      const supplierOrder = (await updateSupplierOrder(
        orderDetail?.id ?? "",
        "approve",
        {
          amountBoxes: values?.boxes,
          warehouseId: values?.warehouseId,
          boxId: values?.singleBoxSize,
          weight: values?.singleWeight,
        }
      )) as any;
      showToastSuccess(t("ORDER_APPROVED"));
      setOrderDetail(supplierOrder);
      setIsLoading(false);
      setOpen(false);
    } catch (error: any) {
      setIsLoading(false);
      showToastError({ message: tGlobal("ERROR_SOME_BAD"), error });
    }
  };

  useEffect(() => {
    getAddresses(userId ?? "", 0);
  }, []);

  return (
    <>
      <button
        onClick={() => setOpen(true)}
        className="primary"
        disabled={loadingOrders || !addresses.content}
      >
        {t("DETAILS.GENERATE_GUIDE")}
      </button>

      <Modal
        isOpen={open}
        onClose={() => {
          methods.reset();
          setOpen(false);
        }}
        size="sm"
        title={t("DETAILS.CONFIRM_DISPATCH")}
        footer={
          <>
            <button onClick={() => setOpen(false)} disabled={isLoading}>
              {t("DETAILS.CLOSE")}
            </button>
            <button
              className="primary lg"
              onClick={methods.handleSubmit(confirmOrder)}
              disabled={isLoading}
            >
              {t("DETAILS.DISPATCH")}
            </button>
          </>
        }
      >
        <p className="bold">{`${t("DETAILS.ORDER_ID")} ${
          orderDetail?.subPurchaseNumber
        }`}</p>
        <div className="spacer" />
        <p>{t("DETAILS.CONFIRMATION_DETAIL")}</p>
        <div className="spacer" />
        <FormProvider {...methods}>
          <LogisticOptionsForm />

          <div className="spacer" />

          {methods.watch("dispatchValue") === "SEERI" && (
            <SeeriLogisticDispatchForm />
          )}

          {methods.watch("dispatchValue") === "SUPPLIER" && (
            <OwnLogisticDispatchForm
              setIsLoading={setIsLoading}
              setUsedTrackingCode={setUsedTrackingCode}
              usedTrackingCode={usedTrackingCode}
              files={files}
              setFiles={setFiles}
            />
          )}

          {!!methods.watch("dispatchValue") &&
            !["SEERI", "SUPPLIER"].includes(methods.watch("dispatchValue")) && (
              <ProviderLogisticDispatchForm
                setIsLoading={setIsLoading}
                setUsedTrackingCode={setUsedTrackingCode}
                usedTrackingCode={usedTrackingCode}
                files={files}
                setFiles={setFiles}
              />
            )}
        </FormProvider>
      </Modal>

      <Modal
        title={t("DISPATCH_QUOTATION")}
        size="sm"
        isOpen={openShipping}
        onClose={() => {
          setOpenShipping(false);
        }}
        footer={
          <>
            <button
              onClick={() => {
                setOpenShipping(false);
                methods.setValue("dispatchValue", "SUPPLIER");
              }}
            >
              {t("USE_OWN_LOGISTIC")}
            </button>
            <button
              className="primary"
              onClick={methods.handleSubmit(confirmOrderSeeri)}
            >
              {t("DETAILS.DISPATCH")}
            </button>
          </>
        }
      >
        <>
          <p>
            <span className="bold">{t("DELIVERY_DAYS") + ": "}</span>
            <span>{quotation?.diasEntrega}</span>
          </p>
          <div className="spacer" />
          <p>
            <span className="bold">{t("FIXED_SHIPPING") + ": "}</span>
            <span>
              {quotation?.fleteFijo ? formatCurrency(quotation?.fleteFijo) : 0}
            </span>
          </p>
          <div className="spacer" />
          <p>
            <span className="bold">{t("VARIABLE_SHIPPING") + ": "}</span>
            <span>
              {quotation?.fleteVariable
                ? formatCurrency(quotation?.fleteVariable)
                : 0}
            </span>
          </p>
          <div className="spacer" />
          <p>
            <span className="bold">{t("TOTAL_SHIPPING") + ": "}</span>
            <span>
              {quotation?.fleteTotal
                ? formatCurrency(quotation?.fleteTotal)
                : 0}
            </span>
          </p>
          <div className="spacer" />
        </>
      </Modal>
    </>
  );
};

export default ConfirmOrderModal;
