import { useState } from "react";

export const ImageWithFallback = ({
  fallbackSrc,
  ...props
}: React.ImgHTMLAttributes<HTMLImageElement> & { fallbackSrc: string }) => {
  const [error, setError] = useState(false);
  return (
    <img
      {...props}
      src={!error ? props.src : fallbackSrc}
      onError={() => setError(true)}
    />
  );
};
