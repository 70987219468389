import { useTicket } from "@/store/ticket";
import { Collapsible } from "@/components/shared/Collapsible/Collapsible";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const TicketEvidences = () => {
  const { selectedTicket } = useTicket();
  const [t] = useTranslation("global");

  return selectedTicket?.evidenceLink ? (
    <Collapsible
      open
      enabled={false}
      className={styles.collapsible}
      title={t("EVIDENCE")}
    >
      <div className={`row ${styles.containerEvidences}`}>
        {selectedTicket?.comment && (
          <div className="col-sm-12 col-5">
            <span>{selectedTicket.comment}</span>
          </div>
        )}
        {selectedTicket?.evidenceLink && (
          <div className="col-sm-12 col-7 flexGap">
            <Link to={selectedTicket?.evidenceLink} target="_blank">
              <img
                src={selectedTicket?.evidenceLink}
                className={styles.imgEvidences}
              />
            </Link>
          </div>
        )}
      </div>
    </Collapsible>
  ) : null;
};
