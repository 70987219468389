import clsx from "clsx";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineCheck } from "react-icons/ai";
import styles from "./styles.module.scss";

type TMultipleMenu = {
  label: ReactNode;
  values: {
    onSelect: (value: any) => void;
    options: { value: any; label: any }[];
    title: string;
    selectedValue: any;
  }[];
  hideOnSelect?: boolean;
  align?: "left" | "rigth";
  position?: "top" | "bottom";
  width?: string;
  className?: string;
};
export const MultipleMenu = ({
  label,
  values,
  position,
  hideOnSelect,
  align,
  width,
  className,
}: TMultipleMenu) => {
  const [showOptions, setShowOptions] = useState(false);
  const optionsRef = useRef<HTMLDivElement>(null);
  const [t] = useTranslation("global");

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target as Node)
      ) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        className={clsx(styles.containerMultipleOptions, className)}
        ref={optionsRef}
      >
        <div
          className="pointer flexGap center"
          onClick={() => {
            setShowOptions(!showOptions);
          }}
        >
          {label}
        </div>
        {showOptions && (
          <div className={styles.options} style={{ width }}>
            {values.map((value, index) => (
              <div key={`${index}_${value.title}`}>
                <p className={`bold ${styles.title}`}>{value?.title}</p>
                <ul
                  className={clsx({
                    [styles.top]: position === "top",
                    [styles.rigth]: align === "rigth",
                  })}
                >
                  {value?.options?.map((option, idx) => (
                    <li
                      key={`${idx}_${option.label}`}
                      onClick={() => {
                        value?.onSelect(option.value);
                        if (hideOnSelect) {
                          setShowOptions(false);
                        }
                      }}
                    >
                      {option.value === value.selectedValue && (
                        <span>
                          <AiOutlineCheck />
                        </span>
                      )}
                      <div className={styles.description}>{option.label}</div>
                    </li>
                  ))}
                  {!value?.options?.length && (
                    <li>{t("NO_AVAILABLE_OPTIONS")}</li>
                  )}
                </ul>
                {index !== values.length - 1 && <div className="divider" />}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
