import { TBrand } from "@/http";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/store/auth";
import { InputV2 } from "@/components/shared/Input/InputV2";
import styles from "./styles.module.scss";
import { useFormContext } from "react-hook-form";
import { useSupplierBrands } from "@/hooks/useSupplier";
import { BrandNameChip } from "./BrandNameChip";

type TBrandInput = {
  name: string;
  brands: string[];
  setBrands: (products: string[]) => void;
  onDelete: (idx: number) => void;
};

export const BrandInput = ({
  name,
  brands,
  setBrands,
  onDelete,
}: TBrandInput) => {
  const [options, setOptions] = useState<TBrand[]>([]);
  const [showOptions, setShowOptions] = useState(false);
  const debounceRefProduct = useRef<any>();
  const optionsRef = useRef<HTMLDivElement>(null);
  const [t] = useTranslation("global");
  const { user } = useAuth();
  const { setValue } = useFormContext();
  const allBrands = useSupplierBrands(user.id);

  const onProductNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (debounceRefProduct.current) {
      clearTimeout(debounceRefProduct.current);
    }

    debounceRefProduct.current = setTimeout(() => {
      getFilterProducts(event.target.value);
    }, 250);
  };

  const getFilterProducts = async (name: string) => {
    const content = allBrands.filter(brand =>
      brand.name.toLowerCase().includes(name.toLowerCase())
    );
    setOptions(content ?? []);
    setShowOptions(true);
  };

  const setProduct = ({ id }: TBrand) => {
    setShowOptions(false);
    const parsedValuesSet = new Set([...brands, id]);
    setBrands(Array.from(parsedValuesSet));
    setValue(name, "");
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target as Node)
      ) {
        setShowOptions(false);
        setValue(name, "");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className={styles.containerInput}>
        <InputV2
          name={`${name}`}
          label={""}
          type="text"
          placeholder={t("OFFERS.BRAND_PLACEHOLDER")}
          onChange={onProductNameChange}
        />
        {showOptions && (
          <div ref={optionsRef}>
            <ul>
              {options.map(option => (
                <li
                  key={option.id}
                  onClick={() => {
                    if (option.status) {
                      setProduct(option);
                    }
                  }}
                  className={!option.status ? styles.disabledProduct : ""}
                >
                  <div className={styles.description}>{option.name}</div>
                </li>
              ))}
              {!options?.length && <li>{t("NO_RESULTS")}</li>}
            </ul>
          </div>
        )}
      </div>
      {brands?.length > 0 && (
        <div>
          {brands.map((product, idx) => (
            <BrandNameChip
              key={product}
              id={product}
              onDelete={() => onDelete(idx)}
            />
          ))}
        </div>
      )}
    </>
  );
};
