import ProductImage from "@/components/layout/ProductImage";
import { PaginationSelect } from "@/models/orders";
import { useProductsCreation } from "@/store/products-creation";
import { formatCurrency } from "@/utils/currency";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import Paginate from "react-paginate";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const VariantsTable = () => {
  const { products, setLoading } = useProductsCreation();
  const [t] = useTranslation("products-page");

  return (
    <>
      <div className={styles.purpleContainer}>
        {t("NO_PRODUCT_FOUND")}
        <span className={styles.action}>
          <Link to="/products/create/new">{t("CREATE_PRODUCT")}</Link>
        </span>
      </div>
      <div className="spacer" />
      <div className="spacer" />

      {products?.length > 0 && (
        <>
          <div className="tableScroll">
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>{t("IMAGE")}</th>
                  <th>{t("PRODUCT_NAME")}</th>
                  <th>{t("PVSP")}</th>
                  <th>{t("BAR_CODE")}</th>
                  <th>{t("ACTIONS_NAME")}</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product: any, index: number) => (
                  <tr className={styles.row} key={index}>
                    <td>
                      <ProductImage images={product.images ?? []} />
                    </td>
                    <td>{product.name}</td>
                    <td>
                      {product?.retailPrice > 0
                        ? formatCurrency(product.retailPrice)
                        : "-"}
                    </td>
                    <td>{product.barCode}</td>
                    <td>
                      <span className={`${styles.actionTable} textLine`}>
                        <Link
                          to={`/products/create/relate/${product.id}`}
                          onClick={() => setLoading(true)}
                        >
                          {t("ADD_PRODUCT")}
                        </Link>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <VariantsTablePagination />
        </>
      )}
    </>
  );
};

const VariantsTablePagination = () => {
  const { totalPages, currentPage, setCurrentPage, getProducts } =
    useProductsCreation();

  const handlePageChange = (selectedObject: PaginationSelect) => {
    setCurrentPage(selectedObject.selected);
  };

  useEffect(() => {
    getProducts();
  }, [currentPage]);

  if (totalPages < 2) {
    return null;
  }

  return (
    <div className={styles.paginate}>
      <Paginate
        forcePage={currentPage}
        pageCount={totalPages}
        previousLabel={"←"}
        nextLabel={"→"}
        marginPagesDisplayed={3}
        onPageChange={handlePageChange}
        containerClassName={styles.pagination}
        previousLinkClassName={styles.previous}
        nextLinkClassName={styles.next}
        disabledClassName={styles.disabled}
        activeClassName={styles.selected}
        breakClassName={styles.break}
      />
    </div>
  );
};
