import { Spinner } from "@/components/shared/Spinner/Spinner";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import clsx from "clsx";
import {
  TCreditTransaction,
  getCredit,
  getCreditTransactions,
} from "@/http/resources/sellers/credits";
import { getSellerById } from "@/http";
import { formatCurrency } from "@/utils/currency";
import { SellerResume } from "../shared/Sellers/SellerResume/SellerResume";
import { format } from "date-fns";
import { isUSA } from "@/constants/core";
import { DATE_HOUR, USA_DATE_HOUR } from "@/constants/date";
import { Link } from "react-router-dom";
import { ConfirmReversion } from "./ConfirmReversion";
import { CreditResume } from "../shared/Sellers/CreditResume/CreditResume";
import { CreatePayment } from "./CreatePayment";
import { PaymentDetail } from "./PaymentDetail";
import useUrlState from "@ahooksjs/use-url-state";
import { PaginatedTable } from "../shared/PaginatedTable/PaginatedTable";
import { Card } from "../shared/Card/Card";

type TCreditTransactionListParams = {
  creditId: number;
};

export const CreditTransactionsList = ({
  creditId,
}: TCreditTransactionListParams) => {
  const [t] = useTranslation("global");
  const { data: credit, mutate } = useSWR(String(creditId), () =>
    getCredit(creditId)
  );

  const [{ page }] = useUrlState({ page: "0" });
  const params: any = {};
  params.size = 10;
  params.page = page;
  const swrData = useSWR(
    [creditId, params, "getCreditTransactions"],
    getCreditTransactions
  );
  const { data: creditTransactions, error, mutate: mutateT } = swrData;
  const sellerId = credit?.sellerId || "";
  const { data: seller } = useSWR(sellerId, () => getSellerById(sellerId));

  const setDone = () => {
    mutate();
    mutateT();
  };

  if (error) {
    return <p>Error</p>;
  }

  if (!creditTransactions) {
    return <Spinner />;
  }

  const buildTransactionDetail = (transaction: TCreditTransaction) => {
    let detail;

    switch (transaction.reference) {
      case "PURCHASE":
        detail = (
          <Link
            to={`/purchases/${transaction?.referenceId}/show`}
            className="bold"
          >
            {transaction.referenceDetail}
          </Link>
        );
        break;

      case "PAYMENT":
        detail = (
          <div className={styles.transactionDetail}>
            <div>{transaction.referenceDetail}</div>
            <div>
              <PaymentDetail
                creditId={creditId}
                paymentId={transaction?.referenceId}
                setDone={setDone}
              />
            </div>
          </div>
        );
        break;

      default:
        detail = transaction.referenceDetail;
        break;
    }

    return detail;
  };

  return (
    <div className={styles.container}>
      <div className="spacer" />
      <div className={clsx(styles.main, "flexGap flexSmColumn")}>
        <div>
          <SellerResume seller={seller} hideEdit />
        </div>
        <div className="dividerVertical" />
        <div>
          <CreditResume credit={credit} />
        </div>
        <div className="dividerVertical" />
        <div className="flexGap rigthAlignFlex">
          <Link
            to={`/credits/${creditId}/transactions/extract`}
            target="_blank"
          >
            <button className="purpleOutlined">{t("DOWNLOAD_PDF")}</button>
          </Link>
          <CreatePayment creditId={creditId} setDone={setDone} />
        </div>
      </div>
      <div className="spacer" />
      <Card className="noPadding">
        <PaginatedTable swrData={swrData}>
          <table className={styles.table}>
            <thead>
              <tr className={styles.purpleHead}>
                <th>{t("DATE")}</th>
                <th>{t("CREDITS.TRANSACTIONS.TABLE.STATUS")}</th>
                <th>{t("CREDITS.TRANSACTIONS.TABLE.DETAIL")}</th>
                <th>{t("CREDITS.TRANSACTIONS.TABLE.AMOUNT")}</th>
                <th>{t("CREDITS.TRANSACTIONS.TABLE.CONDITIONS")}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {creditTransactions.content.map(transaction => (
                <tr key={transaction.id} className={styles.row}>
                  <td className="textLine">
                    {format(
                      new Date(transaction?.createdAt),
                      isUSA ? USA_DATE_HOUR : DATE_HOUR
                    )}
                  </td>
                  <td>
                    {t(
                      `CREDITS.TRANSACTIONS.${transaction?.status?.toUpperCase()}`
                    )}
                  </td>
                  <td>{buildTransactionDetail(transaction)}</td>
                  <td
                    className={transaction.amount > 0 ? "redText" : "greenText"}
                  >
                    {formatCurrency(transaction.amount || 0)}
                  </td>
                  <td>
                    {transaction.reference === "PURCHASE" ? (
                      <div className={styles.transactionConditions}>
                        <div>
                          {t("CREDITS.TRANSACTIONS.TERM")}:{" "}
                          {transaction.dueDays} {t("CREDITS.TRANSACTIONS.DAYS")}
                        </div>
                        <div>
                          {t("CREDITS.TRANSACTIONS.INTEREST")}:{" "}
                          {transaction.interest}%
                        </div>
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {transaction.reference === "PURCHASE" &&
                      transaction.status === "PAID" && (
                        <ConfirmReversion
                          creditId={creditId}
                          transactionId={transaction.id}
                          setDone={setDone}
                        />
                      )}{" "}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </PaginatedTable>
      </Card>
      {!creditTransactions.content.length && (
        <h6 className="bold">{t("NO_AVAILABLE_INFO")}</h6>
      )}
    </div>
  );
};
