import { Checkbox } from "@/components/shared/Input/Checkbox";
import { InputV2 } from "@/components/shared/Input/InputV2";
import { useTranslation } from "react-i18next";

export const DatesInput = () => {
  const minDate = new Date().toISOString().split("T")[0];
  const [t] = useTranslation("global");

  return (
    <>
      <Checkbox name={"enabled"} label={t("OFFERS.ENABLE_OFFER")} />
      <div className="row">
        <div className="col-6 col-sm-12 noHPaddingSm">
          <InputV2
            type="date"
            label={t("OFFERS.END_DATE")}
            name="endsAtDate"
            min={minDate}
            required
          />
        </div>
        <div className="col-6 col-sm-12 noHPaddingSm">
          <InputV2
            type="time"
            label={t("OFFERS.END_TIME")}
            name="endsAtTime"
            required
          />
        </div>
      </div>
    </>
  );
};
