import { Spinner } from "@/components/shared/Spinner/Spinner";
import { useAuth } from "@/store/auth";
import { useAddresses } from "@/store/profile";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AddressDetail from "./AddressDetail";
import AddressesList from "./AddressesList";
import styles from "./styles.module.scss";

const Addresses = () => {
  const { getAddresses, loadingOrders } = useAddresses();
  const [t] = useTranslation("global");
  const { user } = useAuth();

  useEffect(() => {
    getAddresses(user.id, 0);
  }, []);

  if (loadingOrders) {
    return <Spinner />;
  }

  return (
    <div>
      <div className={styles.addressesContainer}>
        <h6>{t("ACCOUNT.ADDRESSES.MY_ADDRESSES")}</h6>
        <AddressesList />
        <AddressDetail />
      </div>
    </div>
  );
};

export default Addresses;
