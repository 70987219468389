import { useEffect } from "react";
import { useTicket } from "@/store/ticket";
import { TicketInformation } from "./TicketInformation";
import { TicketModifications } from "./TicketModification";
import ProductsDetails from "@/components/order-details/ProductsDetails/ProductsDetails";
import { TicketEvidences } from "./TicketEvidences";
import { TicketUpdateResponse } from "./TicketUpdateResponse";
import { useTranslation } from "react-i18next";

export const TicketDetailModal = () => {
  const [t] = useTranslation("global");
  const [selectedTicket, getDetailTicket] = useTicket(state => [
    state.selectedTicket,
    state.getDetailTicket,
  ]);

  useEffect(() => {
    if (selectedTicket?.id) {
      getDetailTicket(selectedTicket?.id);
    }
  }, []);

  return (
    <div>
      <h6 className="bold">{t("TICKETS.MANAGE")}</h6>
      <div className="divider" />
      <div className="spacer" />
      <TicketInformation />
      {selectedTicket?.modifications &&
        selectedTicket?.modifications?.length > 0 && (
          <>
            <div className="spacer" />
            <div className="spacer" />
            <TicketModifications />
          </>
        )}
      {selectedTicket?.products && selectedTicket?.products!.length > 0 && (
        <>
          <div className="spacer" />
          <div className="spacer" />
          <ProductsDetails
            isProductsTicket
            orderDetail={selectedTicket as any}
          />
        </>
      )}
      {selectedTicket?.evidenceLink && (
        <>
          <div className="spacer" />
          <div className="spacer" />
          <TicketEvidences />
        </>
      )}
      <div className="spacer" />
      <TicketUpdateResponse />
    </div>
  );
};

export default TicketDetailModal;
