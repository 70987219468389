import { PurchaseList } from "@/components/purchases/PurchaseList/PurchaseList";
import { PurchaseListActions } from "@/components/purchases/PurchaseList/PurchaseListActions/PurchaseListActions";
import { LockedFeaturePlaceholder } from "@/components/subscription/LockedFeaturePlaceholder";
import { FEAT_SELL_IN } from "@/constants/features";
import { useSubscription } from "@/hooks/useSubscription";
import { QUOTED } from "@/http";
import { usePurchase } from "@/store/purchase";
import { useHeaderTitle } from "@/store/ui";
import { debounce } from "lodash-es";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const AcquisitionsListPage = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");
  const { statusFilters, setStatusFilters } = usePurchase();
  const isQuotes = statusFilters["status"] === QUOTED;
  const { setIsProcurement, isProcurementFilter } = usePurchase();
  const { hasFeature } = useSubscription();

  useEffect(() => {
    setTitle(t("ACQUISITIONS.LIST_TITLE"));
  }, [t]);

  const debouncedEffectFunction = debounce(() => {
    setIsProcurement(true);
    if (isQuotes) {
      setStatusFilters({ key: "status", value: "PENDING" });
    }
  }, 500);

  useEffect(() => {
    debouncedEffectFunction();
  }, []);

  if (!hasFeature(FEAT_SELL_IN)) {
    return <LockedFeaturePlaceholder />;
  }

  return (
    <>
      <PurchaseListActions />
      {!isQuotes && isProcurementFilter && <PurchaseList />}
    </>
  );
};
