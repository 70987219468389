export const deleteDuplicatedObjects = (array: any[], key: string): any[] => {
  const unique = new Set();
  return array.filter(o => {
    if (!unique.has(o[key])) {
      unique.add(o[key]);
      return true;
    }
    return false;
  });
};
