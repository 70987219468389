import { TProduct, searchProducts } from "@/http";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/store/auth";
import ProductImage from "@/components/layout/ProductImage";
import { formatCurrency } from "@/utils/currency";
import { AiOutlineDelete } from "react-icons/ai";
import styles from "./Products.module.scss";
import { BrandName } from "@/components/shared/Details";
import { InputV2 } from "@/components/shared/Input/InputV2";
import { getSupplierProduct, ProductSupplierName } from "./ProductSupplierName";
import { hasPermission } from "@/utils/validations/permissions";
import { NumericInput } from "@/components/shared/Input/NumericInput";
import { getProductSupplierFromProduct } from "@/utils/products";
import { createPortal } from "react-dom";

type TProductInput = {
  index: number;
  canDelete: boolean;
  hideQuantity?: boolean;
  onDelete: (...params: any[]) => void;
  source: string;
  supplierId?: string;
  isQuote?: boolean;
  modifyPrice?: boolean;
  reservedQuantity?: number;
};
export const ProductInput = ({
  index,
  canDelete,
  onDelete,
  hideQuantity = false,
  source,
  supplierId,
  isQuote,
  modifyPrice = false,
  reservedQuantity = 0,
}: TProductInput) => {
  const { setValue, watch } = useFormContext();
  const [options, setOptions] = useState<TProduct[]>([]);
  const locked = watch(`products[${index}].locked`) ?? null;
  const supplierPrice = watch(`products[${index}].supplierPrice`) ?? null;
  const unitRate = watch(`products[${index}].unitRate`) ?? 1;
  const availableStock =
    Math.floor((watch(`products[${index}].availableStock`) ?? 0) / unitRate) +
    reservedQuantity;
  const [showOptions, setShowOptions] = useState(false);
  const debounceRefProduct = useRef<any>();
  const optionsRef = useRef<HTMLDivElement>(null);
  const [t] = useTranslation("purchases");
  const { user, isCommercial, role } = useAuth();
  const unitPrice = watch(`products[${index}].unitPrice`);
  const debounceRefQuantity = useRef<any>();
  const debounceRefDiscount = useRef<any>();
  const fixedDivRef = useRef<HTMLDivElement>(null);

  const isProductLoaded = (id: string) => {
    const product = watch("products")?.find(
      (product: any, idx: number) => product?.id === id && index !== idx
    );
    return !!product;
  };

  const onProductNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (debounceRefProduct.current) {
      clearTimeout(debounceRefProduct.current);
    }

    debounceRefProduct.current = setTimeout(() => {
      getFilterProducts(event.target.value);
    }, 250);
  };

  const getFilterProducts = async (name: string) => {
    const { content } = (await searchProducts({
      data: {
        text: name,
        ...(source ? { status: true } : {}),
        supplierId: supplierId ?? user.id,
        action: source,
      },
      params: {
        sort: "name,asc",
        page: 0,
        size: 10,
      },
    })) as any;
    setOptions(content ?? []);
    setShowOptions(true);
  };

  const setProduct = ({ id, name, suppliers }: TProduct, index: number) => {
    const supplierProduct = getSupplierProduct({ suppliers } as any, user);
    setValue(`products[${index}]`, {
      id,
      name: `${name} | ${formatCurrency(
        supplierProduct?.wholesalePrice ?? 0
      )} x ${t("UNIT")}`,
      supplierId: supplierProduct.supplierId,
      availableStock: supplierProduct.availableStock,
      supplierPrice:
        supplierProduct?.wholesalePrice ?? supplierProduct?.supplierPrice,
    });

    setShowOptions(false);
  };

  const onQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
    const quantityValue = event.target.value;

    if (debounceRefQuantity.current) {
      clearTimeout(debounceRefQuantity.current);
    }

    debounceRefQuantity.current = setTimeout(() => {
      setValue(`products[${index}].quantity`, quantityValue);
    }, 600);
  };

  const onDiscountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const discountValue = event.target.value;

    if (debounceRefDiscount.current) {
      clearTimeout(debounceRefDiscount.current);
    }

    debounceRefDiscount.current = setTimeout(() => {
      setValue(`products[${index}].unitPrice`, discountValue);
      if (supplierPrice) {
        setValue(
          `products[${index}].mandatoryDiscount`,
          (
            ((Number(supplierPrice) - Number(discountValue)) /
              Number(supplierPrice)) *
            100
          ).toFixed(10)
        );
      }
    }, 600);
  };

  useEffect(() => {
    if (unitPrice && supplierPrice) {
      setValue(
        `products[${index}].mandatoryDiscount`,
        (
          ((Number(supplierPrice) - Number(unitPrice)) /
            Number(supplierPrice)) *
          100
        ).toFixed(10)
      );
    } else {
      setValue(`products[${index}].mandatoryDiscount`, 0);
    }
  }, [supplierPrice]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target as Node)
      ) {
        setValue(`products.${index}`, {
          id: crypto.randomUUID(),
          name: "",
        });

        setShowOptions(false);
      }
    };

    const handleOutsideScroll = (event: Event) => {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target as Node)
      ) {
        setValue(`products.${index}`, { id: crypto.randomUUID(), name: "" });
        setShowOptions(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    document.addEventListener("wheel", handleOutsideScroll);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("wheel", handleOutsideScroll);
    };
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={`row flexGap ${styles.containerInputs}`}>
          <div className={`col-12 col-sm-12 noPadding`} ref={fixedDivRef}>
            <InputV2
              name={`products[${index}].name`}
              label={
                <p className={styles.productInputLabel}>
                  {`${t("PRODUCT")} ${index + 1}`}
                  {canDelete && (
                    <AiOutlineDelete
                      className="pointer"
                      onClick={() => {
                        if (onDelete) {
                          onDelete(index);
                        }
                      }}
                    />
                  )}
                </p>
              }
              type="text"
              placeholder={t("SELECT_PRODUCTS")}
              required
              onChange={onProductNameChange}
              disabled={locked}
            />
          </div>
        </div>
        {!hideQuantity && (
          <div className="col-12 col-sm-12 noPadding flexGap">
            <div className="col-4 noPadding">
              <NumericInput
                name={`quantities[${index}].quantity`}
                label={t("PRODUCT_QUANTITY")}
                placeholder={t("SELECT_QUANTITY")}
                hideControls
                required
                decimals
                max={isQuote ? null : availableStock}
                min={0.01}
                onChange={onQuantityChange}
              />
            </div>
            {hasPermission(isCommercial, role, ["action.purchase.discount"]) &&
              (!supplierId || modifyPrice) && (
                <div className="col-4 noPadding">
                  <NumericInput
                    name={`discounts[${index}].unitPrice`}
                    label={
                      <span className="textLine">{t("DISCOUNT_PRICE")}</span>
                    }
                    placeholder={t("DISCOUNT_PRICE_PLACEHOLDER")}
                    hideControls
                    decimals
                    min={0}
                    onChange={onDiscountChange}
                  />
                </div>
              )}

            {isQuote ? null : (
              <div>
                <div>
                  {t("UNIT_TYPE")}:{" "}
                  <span className="bold">
                    {watch(`products[${index}].unitType`)}
                  </span>
                </div>
                <div>
                  {t("AVAILABLE_STOCK")}:{" "}
                  <span className="bold">
                    {availableStock && availableStock > 0 ? availableStock : 0}
                  </span>
                </div>
              </div>
            )}
          </div>
        )}

        {showOptions &&
          createPortal(
            <div
              ref={optionsRef}
              style={{
                top: fixedDivRef.current
                  ? `${
                      fixedDivRef.current.getBoundingClientRect().top +
                      80 +
                      (window.pageYOffset || document.documentElement.scrollTop)
                    }px`
                  : "0",
                width: fixedDivRef.current
                  ? `${fixedDivRef.current.getBoundingClientRect().width}px`
                  : "100%",
                left: fixedDivRef.current
                  ? `${fixedDivRef.current.getBoundingClientRect().left}px`
                  : "100%",
              }}
              className={styles.list}
            >
              <ul>
                {options.map((option: any) => {
                  const productSupplier = getProductSupplierFromProduct(
                    option,
                    supplierId ?? user.id
                  );

                  return (
                    <li
                      key={option?.id}
                      onClick={() => {
                        if (
                          isQuote ||
                          (productSupplier?.status &&
                            !isProductLoaded(option?.id) &&
                            productSupplier?.availableStock > 0)
                        ) {
                          setProduct(option, index);
                        }
                      }}
                      className={
                        isQuote
                          ? ""
                          : !productSupplier?.status ||
                            isProductLoaded(option?.id) ||
                            productSupplier?.availableStock <= 0
                          ? styles.disabledProduct
                          : ""
                      }
                    >
                      <div>
                        <ProductImage images={option.images} />
                      </div>
                      <div className={styles.description}>
                        <div>
                          {option?.name}{" "}
                          {!productSupplier?.status && !isQuote && (
                            <span className={styles.sku}>
                              * {t("PRODUCT_DISABLED")}
                              <br />
                            </span>
                          )}
                          {productSupplier?.availableStock <= 0 && !isQuote && (
                            <span className={styles.sku}>
                              * {t("PRODUCT_NOT_AVAILABLE_SALE")}
                              <br />
                            </span>
                          )}
                        </div>

                        {option?.suppliers?.[0]?.supplierSku && (
                          <span className={styles.sku}>
                            <span className="bold">{"SKU: "}</span>
                            {option?.suppliers?.[0]?.supplierSku}
                            <br />
                          </span>
                        )}
                        {option?.brand?.name ? (
                          <span className={`${styles.sku} bold`}>{`${t(
                            "BRAND"
                          )}: ${option.brand.name} | `}</span>
                        ) : (
                          option?.brandId && (
                            <span className={styles.sku}>
                              <span className="bold">{`${t("BRAND")}: `}</span>
                              <BrandName id={option.brandId} />
                              {" | "}
                            </span>
                          )
                        )}
                        {source === "purchase" && (
                          <>
                            <span className={styles.sku}>
                              <span className="bold">{`${t(
                                "SUPPLIER"
                              )}: `}</span>
                              <ProductSupplierName product={option} />
                              <br />
                            </span>
                          </>
                        )}
                      </div>
                      <div className="rigthAlign">
                        <div className="bold">
                          {formatCurrency(
                            option?.suppliers?.[0]?.wholesalePrice ?? 0
                          )}
                        </div>
                        {!isQuote && (
                          <div className={`${styles.mediumSize} textLine`}>
                            <span className="bold">{`${t(
                              "AVAILABLE"
                            )}: `}</span>
                            <span className="greenText">
                              {productSupplier?.availableStock ?? 0}
                            </span>
                            <br />
                          </div>
                        )}
                      </div>
                    </li>
                  );
                })}
                {!options?.length && <li>{t("NO_OPTIONS")}</li>}
              </ul>
            </div>,
            document.body
          )}
      </div>
    </>
  );
};
