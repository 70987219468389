import { useOnboarding } from "./useOnboarding";
import { BiHelpCircle } from "react-icons/bi";

export const OnboardingButton = () => {
  const { startOnboarding } = useOnboarding();

  return (
    <div className="flexGap center pointer">
      <BiHelpCircle onClick={startOnboarding} size={"1.5rem"} />
    </div>
  );
};
