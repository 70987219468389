import { useTranslation } from "react-i18next";
import { InputProps } from "./Input";
import styles from "./styles.module.scss";

export const Select = ({
  name,
  label,
  register,
  error,
  required = false,
  choices,
  disabled = false,
}: InputProps) => {
  const [t] = useTranslation("global");

  return (
    <div className={`${styles.field} ${error ? styles.required : ""}`}>
      <label>
        <span>{label}</span>
        <select
          className={`${styles.select} ${error ? styles.required : ""}`}
          {...register(name, {
            required: required ? t("LOGIN.FIELD_REQUIRED") : false,
          })}
          disabled={disabled}
        >
          <option disabled hidden value="">
            {t("SELECT_OPTION")}
          </option>
          {choices?.map(choice => (
            <option key={choice.value} value={choice.value}>
              {choice.label}
            </option>
          ))}
        </select>
      </label>

      {error && error.type === "required" && (
        <span className={styles.errorsLabel}>{t("LOGIN.FIELD_REQUIRED")}</span>
      )}
    </div>
  );
};
