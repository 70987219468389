import { Page, Document, StyleSheet, View } from "@react-pdf/renderer";
import BillTo from "./BillTo";
import InvoiceNo from "./InvoiceNo";
import InvoiceItemsTable from "./InvoiceItemsTable";
import SupplierInfo from "./SupplierInfo";
import { TInvoice } from "../invoiceData";
import ShipTo from "./ShipTo";
import { invoiceStyles } from "./Styles";
import InvoiceTotalsTable from "./InvoiceTotalsTable";
import PoweredLabel from "./PoweredLabel";
import { ObservationsInfo } from "./ObservationsInfo";
import { DeliverySign } from "./DeliverySign";

type Props = {
  invoice: TInvoice;
  type: string;
  translate: any;
};

const styles = StyleSheet.create(invoiceStyles);

const Invoice = ({ translate, type, invoice }: Props) => {
  return (
    <Document title={`${translate(type)}: ${invoice.number}`}>
      <Page size="LETTER" style={styles.page}>
        <View style={styles.headerContainer}>
          <SupplierInfo supplier={invoice.supplier} />
          <InvoiceNo translate={translate} type={type} invoice={invoice} />
        </View>
        {invoice.showClientInfo && (
          <View style={styles.shippingContainer}>
            {invoice.billing && (
              <BillTo translate={translate} billing={invoice.billing} />
            )}
            {invoice.client && (
              <ShipTo translate={translate} client={invoice.client} />
            )}
          </View>
        )}
        {invoice?.observations && (
          <ObservationsInfo
            translate={translate}
            observations={invoice.observations}
          />
        )}

        <InvoiceItemsTable translate={translate} invoice={invoice} />
        {invoice.showPrices && (
          <View style={styles.totals}>
            <InvoiceTotalsTable translate={translate} invoice={invoice} />
          </View>
        )}
        {type !== "INVOICE" && <DeliverySign translate={translate} />}
        <PoweredLabel />
      </Page>
    </Document>
  );
};

export default Invoice;
