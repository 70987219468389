import { DetailedHTMLProps, InputHTMLAttributes, ReactNode } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { get } from "lodash-es";
import styles from "./styles.module.scss";

type InputProps = {
  name: string;
  label: string | ReactNode;
  type?:
    | "text"
    | "email"
    | "password"
    | "number"
    | "date"
    | "time"
    | "datetime-local";
  placeholder?: string;
  required?: boolean;
  onChange?: (...params: any[]) => void;
  min?: number | string;
  max?: number | string;
  choices?: { value: string; label: string }[];
  validate?: any;
  disabled?: boolean;
  maxLength?: number;
  minLength?: number;
  onKeyPress?: any;
  validMessage?: string;
  infoLabel?: string | ReactNode;
  onFocus?: (...params: any[]) => void;
  onBlur?: (...params: any[]) => void;
  inputBG?: "transparent" | "white";
  inputProps?: DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
};

export const InputV2 = ({
  name,
  label,
  type,
  placeholder,
  required = false,
  onChange,
  min,
  max,
  validate,
  maxLength,
  minLength,
  onKeyPress,
  disabled,
  validMessage,
  infoLabel,
  onFocus,
  onBlur,
  inputBG = "transparent",
  inputProps,
}: InputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const [t] = useTranslation("global");
  const typeError = get(errors, `${name}.type`);

  return (
    <div className={`${styles.field} ${typeError ? styles.required : ""}`}>
      <label htmlFor={name}>{label}</label>
      <input
        type={type}
        id={name}
        placeholder={placeholder}
        {...register(name, {
          required: required ? t("LOGIN.FIELD_REQUIRED") : false,
          onChange: onChange,
          validate,
          shouldUnregister: true,
          minLength,
        })}
        disabled={disabled}
        min={min}
        max={max}
        minLength={minLength}
        maxLength={maxLength}
        onKeyPress={onKeyPress}
        onFocus={onFocus}
        onBlur={onBlur}
        autoComplete="off"
        style={disabled ? {} : { backgroundColor: inputBG }}
        {...inputProps}
      />
      {!!infoLabel && <span className={styles.infoLabel}>{infoLabel}</span>}
      {typeof typeError === "string" && typeError === "required" && (
        <span className={styles.errorsLabel}>{t("LOGIN.FIELD_REQUIRED")}</span>
      )}
      {typeof typeError === "string" && typeError === "validate" && (
        <span className={styles.errorsLabel}>
          {validMessage ||
            get(errors as any, `${name}.message`) ||
            t("LOGIN.INVALID_FIELD")}
        </span>
      )}
    </div>
  );
};
