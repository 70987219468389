import { TSubPurchase } from "@/http";
import { useTranslation } from "react-i18next";
import styles from "./SubPurchases.module.scss";
import format from "date-fns/format";
import { DATE_HOUR, USA_DATE_HOUR } from "@/constants/date";
import { paymentStatus } from "@/constants/purchases";
import { Link } from "react-router-dom";
import ProductsDetails from "@/components/order-details/ProductsDetails/ProductsDetails";
import { useAuth } from "@/store/auth";
import { isUSA } from "@/constants/core";
import { DeleteDispatchOrder } from "@/components/orders/TableOrders/DeleteOrder";
import { AiOutlineDelete } from "react-icons/ai";
import { usePurchase } from "@/store/purchase";

type TSubPurchases = {
  subpurchases: TSubPurchase[];
};
export const SubPurchases = ({ subpurchases }: TSubPurchases) => {
  const [t] = useTranslation("purchases");
  const [globalT] = useTranslation("global");
  const { user } = useAuth();
  const { purchase, getPurchase } = usePurchase();

  return (
    <>
      {subpurchases.map((subpurchase, idx) => (
        <div key={subpurchase.id}>
          <div className="flexGap">
            <Link to={`/orders/${subpurchase.id}`} className={"bold"}>
              <span className={styles.linkTitle}>{`${t("SUBPURCHASE_ID")}: ${
                subpurchase?.subPurchaseNumber ?? "-"
              }`}</span>
            </Link>
            {}
            {user.id === subpurchase?.supplierId ? (
              <>
                <DeleteDispatchOrder
                  button={<AiOutlineDelete className="pointer mtsm" />}
                  orderNumber={subpurchase.subPurchaseNumber}
                  orderId={subpurchase.id}
                  onDone={() => getPurchase(purchase.id)}
                />
              </>
            ) : (
              <span className="statusChip purple">
                {t("OTHER_PROVIDER_SUPPLIES")}
              </span>
            )}
          </div>
          <div className="spacer" />
          <div className="row">
            <div className="col-6 noPadding">
              <div className={styles.subpurchaseContainer}>
                <div className={styles.field}>
                  <span className={styles.title}>{t("CREATED_AT")}:</span>
                  <span>
                    {subpurchase?.createdAt
                      ? format(
                          new Date(subpurchase?.createdAt),
                          isUSA ? USA_DATE_HOUR : DATE_HOUR
                        )
                      : "-"}
                  </span>
                </div>

                {subpurchase?.supplierStatus !== "CANCELLED" && (
                  <div className={styles.field}>
                    <span className={styles.title}>{t("GUIDE")}:</span>

                    {subpurchase.trackingLink ? (
                      <a
                        target={"_blank"}
                        rel={"noreferrer"}
                        className={styles.link}
                        href={subpurchase.trackingLink}
                      >
                        {subpurchase.trackingCode || t("OPEN_LINK")}
                      </a>
                    ) : (
                      <span>{subpurchase.trackingCode || "-"}</span>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-6 noPadding">
              <div className={styles.subpurchaseContainer}>
                <div className={styles.field}>
                  <span className={styles.title}>
                    {t("PURCHASE_PAYMENT_STATUS")}:
                  </span>
                  <span>
                    {
                      paymentStatus.find(
                        s => s.code === subpurchase.paymentStatus
                      )?.name
                    }
                  </span>
                </div>

                <div className={styles.field}>
                  <span className={styles.title}>
                    {t("PURCHASE_PAYMENT_METHOD")}:
                  </span>
                  <span>
                    {globalT(`PAYMENT_METHOD.${purchase?.paymentMethod}`)}
                  </span>
                </div>

                <div className={styles.field}>
                  <span className={styles.title}>
                    {t("PURCHASE_DELIVERY_STATUS")}:
                  </span>
                  <span>
                    {subpurchase?.createdAt
                      ? format(
                          new Date(subpurchase?.createdAt),
                          isUSA ? USA_DATE_HOUR : DATE_HOUR
                        )
                      : "-"}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 noPadding">
              <ProductsDetails
                isSubpurchase
                orderDetail={subpurchase as any}
                lightStyle
              />
            </div>

            {idx + 1 !== subpurchases?.length && (
              <>
                <div className="col-12 noPadding spacer" />
                <div className="col-12 noPadding spacer" />
              </>
            )}
          </div>
        </div>
      ))}
    </>
  );
};
