import { create } from "zustand";

import {
  getProductById,
  getSupplierProductGroupByIdV2,
  searchProducts,
} from "@/http";
import { showToastError } from "@/hooks/useToast";
import { CURRENT_LANG, ES } from "@/constants/core";

type TUseProductsCreation = {
  brandId: string;
  setBrandId: (brandId: string) => void;
  brandName: string;
  setBrandName: (brandName: string) => void;
  productName: string;
  setProductName: (productName: string) => void;
  products: any[];
  totalPages: number;
  getProducts: () => void;
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
  productRelate: any;
  getProductRelate: (id: string) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

export const useProductsCreation = create<TUseProductsCreation>((set, get) => ({
  brandId: "",
  setBrandId: brandId => set({ brandId }),
  brandName: "",
  setBrandName: brandName => set({ brandName }),
  productName: "",
  setProductName: productName => set({ productName }),
  products: [],
  getProducts: async () => {
    const { brandId, productName, currentPage } = get();
    const payload: any = {};
    if (brandId) {
      payload.brandId = brandId;
    }
    if (productName) {
      payload.text = productName;
    }
    const { content, totalPages } = (await searchProducts({
      data: payload,
      params: {
        sort: "name,asc",
        page: currentPage,
        size: 10,
      },
    })) as any;
    set({ products: content, totalPages, loading: false });
  },
  totalPages: 0,
  currentPage: 0,
  setCurrentPage: currentPage => set({ currentPage }),
  productRelate: null,
  getProductRelate: async (id: string) => {
    try {
      const variant = await getProductById(id);
      const group = await getSupplierProductGroupByIdV2(variant.groupProductId);
      set({ productRelate: { variant, group }, loading: false });
    } catch (e) {
      set({ productRelate: null });
      showToastError({
        message:
          CURRENT_LANG() === ES
            ? "Hubo un error, intenta mas tarde."
            : "An error occurred, please try again later.",
      });
    }
  },
  loading: false,
  setLoading: loading => set({ loading }),
}));
