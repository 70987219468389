import { FaRegClock } from "react-icons/fa";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Modal } from "@/components/shared/Modal/Modal";
import { PurchaseTimeline } from "../PurchaseGeneralInfo/PurchaseTimeline";

type TShowTimeline = {
  purchaseId: string;
};
export const ShowTimeline = ({ purchaseId }: TShowTimeline) => {
  const [t] = useTranslation("global");
  const [open, setIsOpen] = useState(false);

  return (
    <>
      <ToolTip title={t("SHOW_TIMELINE")} position="Left">
        <span className="pointer circleButton" onClick={() => setIsOpen(true)}>
          <FaRegClock />
        </span>
      </ToolTip>
      <Modal
        isOpen={open}
        onClose={() => setIsOpen(false)}
        title={t("PURCHASE_TIMELINE")}
      >
        <PurchaseTimeline id={purchaseId} />
      </Modal>
    </>
  );
};
