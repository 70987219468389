import { useAuth } from "@/store/auth";
import { formatCurrency } from "@/utils/currency";
import { getClientReport, TSeller } from "@/http";
import {
  TClientReportRequest,
  TSupplierReportSales,
} from "@/http/types/reports";
import { ReactNode, useState } from "react";
import { Chart } from "react-google-charts";
import { endOfDay, format, startOfDay, subMonths } from "date-fns";
import { DEFAULT_FORMAT } from "@/constants/date";
import { useTranslation } from "react-i18next";
import useSWRImmutable from "swr/immutable";
import { RangeDatePicker } from "./RangeDatePicker";
import { Spinner } from "../../shared/Spinner/Spinner";
import styles from "./styles.module.scss";
import { useRecordContext } from "../ui/RecordContext";

const defaultFrom = () => startOfDay(subMonths(new Date(), 1));
const defaultTo = () => endOfDay(new Date());

export const ClientReportsGrid = () => {
  const [t] = useTranslation("global");
  const { user } = useAuth();
  const client = useRecordContext<TSeller>();
  const [from, setFrom] = useState(defaultFrom());
  const [to, setTo] = useState(defaultTo());

  const reqParams: TClientReportRequest = {
    supplierId: user.supplierId ?? user.id,
    clientId: client.id,
    fromDate: startOfDay(from).toISOString(),
    toDate: endOfDay(to).toISOString(),
  };

  const { data: report, isValidating: isLoading } = useSWRImmutable(
    Object.values(reqParams).every(Boolean) ? reqParams : null,
    getClientReport
  );

  const getProductsUnits = (units: TSupplierReportSales[]) => {
    const unitsSort = units.sort((a, b) => b.total - a.total);
    const unitsFirst = unitsSort
      .slice(0, 10)
      .map(unit => [unit.name, unit.total, null, unit.name]);
    const total = Math.round(
      units.reduce((acumulador, actual) => acumulador + actual.total, 0)
    );

    return { unitsFirst, total };
  };

  return (
    <>
      <div className="flexGap center flexSmColumn">
        <div>
          <b>{t("DASHBOARD.REPORTS")}</b>
        </div>
        <div className="col-sm-12 noPadding">
          <RangeDatePicker
            startDate={from}
            endDate={to}
            onChange={([start, end]) => {
              setFrom(start);
              setTo(end);
            }}
            maxDate={endOfDay(new Date())}
          />
        </div>
        <div>
          {report && (
            <div className="textCenter">
              {t("DASHBOARD.SHOWING_RESULTS")}{" "}
              {format(from, DEFAULT_FORMAT).split("-").reverse().join("/")}{" "}
              {t("DASHBOARD.SHOWING_RESULTS_TO")}{" "}
              {format(to, DEFAULT_FORMAT).split("-").reverse().join("/")}
            </div>
          )}
        </div>
      </div>
      <div className="spacer" />
      <div className="spacer" />
      {isLoading && <Spinner />}

      {!isLoading && report && report.totalOrders > 0 ? (
        <>
          <div className={`row flexGap ${styles.row}`}>
            <Card
              title={t("DASHBOARD.ORDERS_TOTAL")}
              content={
                <span className={styles.bigDesc}>
                  {report.totalOrders || 0}
                </span>
              }
            />
            <Card
              title={t("DASHBOARD.SALES_TOTAL")}
              content={
                <span className={styles.bigDesc}>
                  {formatCurrency(report.totalOrdersValue || 0)}
                </span>
              }
            />
          </div>
          <div className="spacer" />
          <div className={`row flexGap ${styles.row}`}>
            <Card
              title={t("DASHBOARD.AVG_ORDERS_VALUE")}
              content={
                <span className={styles.bigDesc}>
                  {formatCurrency(report.averageOrderValue || 0)}
                </span>
              }
            />
            <Card
              title={t("DASHBOARD.PROFIT")}
              content={
                <span className={styles.bigDesc}>
                  {formatCurrency(report.profit || 0)}
                </span>
              }
            />
          </div>
          <div className="spacer" />
          <div className={`row flexGap ${styles.row}`}>
            <Card
              title={t("DASHBOARD.TOP_PRODUCTS")}
              content={
                <>
                  <div>
                    <span className={styles.bigDesc}>
                      {getProductsUnits(report.productsBySoldUnits || []).total}
                    </span>
                    <span>{` ${t("DASHBOARD.SALE_UNITS")}`}</span>
                  </div>
                  {report.productsBySoldUnits?.length > 0 && (
                    <Chart
                      chartType="BarChart"
                      width="100%"
                      data={[
                        [
                          t("DASHBOARD.PRODUCT"),
                          t("DASHBOARD.QUANTITY"),
                          { role: "style" },
                          { role: "annotation" },
                        ],
                        ...getProductsUnits(report.productsBySoldUnits)
                          .unitsFirst,
                      ]}
                      options={{
                        chartArea: { width: "100%", top: 18, bottom: 0 },
                        colors: ["#9189FF"],
                        legend: "none",
                        height: 320,
                        bar: { groupWidth: "75%" },
                      }}
                    />
                  )}
                </>
              }
            />
          </div>
          <div className="spacer" />
        </>
      ) : (
        <h6 className={styles.noData}>{t("DASHBOARD.NO_SALES")}</h6>
      )}
    </>
  );
};

type TCard = {
  title: ReactNode;
  content: ReactNode;
};
const Card = ({ title, content }: TCard) => {
  return (
    <div className={`col-12 col-sm-12 noPadding ${styles.card}`}>
      <div className={styles.purpleBorder} />
      <div className={styles.content}>
        <span className={styles.title}>{title}</span>
        <div className="spacer" />
        <div>{content}</div>
      </div>
    </div>
  );
};
