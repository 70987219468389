import React, { useCallback, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { get } from "lodash-es";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

type SmallImageInputProps = {
  name: string;
  label: string;
  required?: boolean;
  imageUrl?: string | null;
  disabled?: boolean;
  buttonW?: string;
  buttonH?: string;
};

export const SmallImageInput: React.FC<SmallImageInputProps> = ({
  name,
  label,
  required = false,
  imageUrl,
  disabled,
  buttonW,
  buttonH,
}) => {
  const { control, formState, setValue, trigger } = useFormContext();
  const { errors } = formState;
  const [selectedImage, setSelectedImage] = useState<string | null>(
    imageUrl || null
  );
  const [t] = useTranslation("global");

  const handleFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = event => {
          setSelectedImage(event?.target?.result as string);
          setValue(name, file);
          trigger(name);
        };
        reader.readAsDataURL(file);
      } else {
        setValue(name, "");
        setSelectedImage(null);
        trigger(name);
      }
    },
    [setSelectedImage]
  );

  return (
    <div className={styles.smallImageInput}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className={styles.imageContainer}>
        {selectedImage && (
          <>
            <img
              src={selectedImage}
              alt="Preview"
              className={styles.image}
              style={{
                width: buttonW,
                maxWidth: buttonW,
                height: buttonH,
                maxHeight: buttonH,
                display: buttonH || buttonW ? "block" : "",
                objectFit: buttonH || buttonW ? "cover" : undefined,
              }}
            />
            {!disabled && (
              <button
                type="button"
                onClick={() => {
                  setSelectedImage(null);
                  setValue(name, "");
                  trigger(name);
                }}
                className={styles.removeButton}
              >
                x
              </button>
            )}
          </>
        )}
        {!selectedImage && (
          <input
            type="file"
            accept="image/png,image/jpeg"
            id={name}
            name={name}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        )}
        {!selectedImage && (
          <label
            htmlFor={name}
            className={styles.uploadButton}
            style={{
              width: buttonW,
              height: buttonH,
              display: buttonH || buttonW ? "block" : "",
            }}
          >
            {t("SELECT")}
          </label>
        )}
      </div>

      {!!get(errors, name) && (
        <span className={styles.errorText}>
          {get(errors as any, name).message}
        </span>
      )}
      <Controller
        name={name}
        control={control}
        rules={{
          required: required ? t("IMAGE_REQUIRED") : undefined,
        }}
        render={({ field }) => (
          <input
            type="hidden"
            name={field.name}
            value={field.value}
            ref={field.ref}
          />
        )}
      />
    </div>
  );
};
