import { DATE_MONT_YEAR, USA_DATE_MONT_YEAR } from "@/constants/date";
import { TOrderDetailsPanel } from "@/models/order-details";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { ModalComment } from "../ModalComment/ModalComment";
import { usePurchaseClient } from "@/hooks/usePurchaseProducts";
import styles from "./styles.module.scss";
import { UpdateDeliveryStatus } from "./UpdateDeliveryStatus";
import { BoxDetailField } from "./BoxDetailField";
import { CURRENT_COUNTRY, getCurrentCountry, isUSA } from "@/constants/core";
import { ShippingInfo } from "./ShippingInfo";
import { isProd } from "@/constants/env";
import { UpdateProviderDeliveryStatus } from "./UpdateProviderDeliveryStatus";

const OrderDetailsPanel = ({ orderDetail }: TOrderDetailsPanel) => {
  return (
    <div className={styles.panelContainer}>
      <OrderDetails orderDetail={orderDetail} />
    </div>
  );
};

const OrderDetails = ({ orderDetail }: TOrderDetailsPanel) => {
  const [t] = useTranslation("orders-page");
  const [globalT] = useTranslation("global");
  const purchaseClient = usePurchaseClient(orderDetail?.purchaseId ?? "");

  const getColor = (status: string | undefined) => {
    let color = "";

    switch (status) {
      case "pending":
      case "rejected":
      case "cancelled":
        color = styles.red;
        break;
      case "approved":
      case "in_process":
        color = styles.green;
        break;
      case "remission_generated":
        color = styles.purple;
        break;
    }

    return color;
  };

  return (
    <>
      <div className={styles.item}>
        <span className={styles.title}>{t("DETAILS.STATUS")}</span>
        <span
          className={`${styles.description} ${getColor(
            orderDetail?.supplierStatus?.toLowerCase()
          )}`}
        >
          {orderDetail?.supplierStatus
            ? t(`STATUS.${orderDetail.supplierStatus}`)
            : "-"}
        </span>
      </div>
      {orderDetail?.deliveryProvider === "SUPPLIER" && <UpdateDeliveryStatus />}
      {orderDetail?.deliveryProvider &&
        !["SUPPLIER", "SEERI"].includes(orderDetail?.deliveryProvider) && (
          <UpdateProviderDeliveryStatus />
        )}
      <div className={styles.item}>
        <span className={styles.title}>{t("ORDERS.CREATED_AT")}</span>
        <span className={styles.description}>
          {orderDetail?.createdAt
            ? format(
                new Date(orderDetail.createdAt),
                isUSA ? USA_DATE_MONT_YEAR : DATE_MONT_YEAR
              )
            : "-"}
        </span>
      </div>
      {orderDetail?.paymentMethod && (
        <div className={styles.item}>
          <span className={styles.title}>{t("PAYMENT_METHOD")}</span>
          <span className={styles.description}>
            {globalT(`PAYMENT_METHOD.${orderDetail?.paymentMethod}`) ?? "-"}
          </span>
        </div>
      )}

      <ShippingInfo purchaseId={orderDetail?.purchaseId ?? ""} />

      {orderDetail?.approvedAt && (
        <div className={styles.item}>
          <span className={styles.title}>{t("ORDERS.APPROVED_AT")}</span>
          <span className={styles.description}>
            {orderDetail?.approvedAt
              ? format(
                  new Date(orderDetail.approvedAt),
                  isUSA ? USA_DATE_MONT_YEAR : DATE_MONT_YEAR
                )
              : "-"}
          </span>
        </div>
      )}
      <div className={styles.item}>
        {!["REJECTED", "CANCELLED", "REMISSION_GENERATED"].includes(
          orderDetail?.supplierStatus || ""
        ) ? (
          orderDetail?.trackingCode && orderDetail?.estimatedDeliveryDate ? (
            <>
              <span className={styles.title}>{t("DETAILS.GUIDE_ID")}</span>
              <a
                href={`https://${
                  isProd ? "seeri.co" : "seeri-app.dev.seeri.co"
                }${getCurrentCountry() === "mx" ? "/es-MX" : ""}/tracking/${
                  orderDetail?.trackingCode
                }`}
                target="_blank"
                rel="noreferrer"
                className={styles.description}
              >
                {orderDetail?.trackingCode}
              </a>
            </>
          ) : ["mx", "us"].includes(CURRENT_COUNTRY) ? (
            <>
              <span className={styles.title}>{t("DETAILS.GUIDE_ID")}</span>
              {(orderDetail?.trackingCodes?.length || 0) > 0 ? (
                <>
                  {orderDetail?.trackingCodes.map((tc: any, index: number) => (
                    <div key={index} className="flexGap">
                      <span className="bold">
                        {t("BOX")} {index + 1}:{" "}
                      </span>
                      <a
                        href={tc?.trackingLink}
                        target="_blank"
                        rel="noreferrer"
                        className={styles.description}
                      >
                        {orderDetail?.trackingCode}
                      </a>
                    </div>
                  ))}
                </>
              ) : (
                <span className={styles.description}>
                  {t("DETAILS.GENERATING_GUIDE")}
                </span>
              )}
            </>
          ) : (
            orderDetail?.trackingCode && (
              <>
                <span className={styles.title}>{t("DETAILS.GUIDE_ID")}</span>
                {orderDetail?.trackingFile ? (
                  <a
                    href={orderDetail?.trackingFile}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.description}
                  >
                    {orderDetail?.trackingCode}
                  </a>
                ) : !orderDetail?.trackingCode &&
                  orderDetail?.supplierStatus === "APPROVED" ? (
                  <span className={styles.description}>
                    {t("DETAILS.GENERATING_GUIDE")}
                  </span>
                ) : (
                  <span className={styles.description}>
                    {orderDetail?.trackingCode}
                  </span>
                )}
              </>
            )
          )
        ) : (
          orderDetail?.reasonCancellation && (
            <>
              <span className={styles.title}>{t("DETAILS.REJECT_REASON")}</span>
              <span className={styles.description}>
                {orderDetail?.reasonCancellation}
                {orderDetail?.messageCancellation && (
                  <ModalComment comment={orderDetail.messageCancellation} />
                )}
              </span>
            </>
          )
        )}
      </div>
      <div className={styles.item}>
        <span className={styles.title}>{t("DETAILS.CITY")}</span>
        <span className={styles.description}>
          {purchaseClient?.location?.city || "-"}
        </span>
      </div>
      <div className={styles.item}>
        <span className={styles.title}>{t("DETAILS.PRODUCT_QUANTITY")}</span>
        <span className={styles.description}>
          {orderDetail?.products?.reduce(
            (p: number, c: any) => p + c?.quantity ?? 0,
            0
          ) || 0}
        </span>
      </div>
      {!["PENDING", "CANCELLED", "REJECTED"].includes(
        orderDetail?.status || ""
      ) && (
        <>
          {orderDetail?.amountBoxes && (
            <div className={styles.item}>
              <span className={styles.title}>{t("DETAILS.AMOUNT_BOXES")}</span>
              <span className={styles.description}>
                {orderDetail?.amountBoxes || 0}
              </span>
            </div>
          )}
          {orderDetail?.weight && (
            <div className={styles.item}>
              <span className={styles.title}>{t("WEIGHT_BOX")}</span>
              <span className={styles.description}>
                {orderDetail?.weight || 0} Kg
              </span>
            </div>
          )}
          <BoxDetailField boxId={orderDetail?.boxId ?? ""} />
        </>
      )}
    </>
  );
};

export default OrderDetailsPanel;
