import { getSeeriApi } from "@/http";
import { TCustomAttribute } from "@/http/types/custom-attribute";
import { useAuth } from "@/store/auth";
import { useState } from "react";
import useSWR from "swr";

type CustomAttributeDTO = Omit<TCustomAttribute, "id" | "supplierId">;

export const useCustomAttributes = (
  appliesTo?: TCustomAttribute["appliesTo"]
) => {
  const supplier = useAuth(s => s.user);
  const [saving, setSaving] = useState(false);

  const {
    data: customAttributes,
    isValidating: loading,
    mutate,
  } = useSWR(["custom-fields", appliesTo], () =>
    getSeeriApi()
      .get<TCustomAttribute[]>(
        `/api/custom-attributes/supplier/${supplier.id}`,
        { params: { appliesTo } }
      )
      .then(res => res.data)
  );

  const createCustomAttribute = async (payload: CustomAttributeDTO) => {
    try {
      setSaving(true);
      await getSeeriApi().post("/api/custom-attributes/", {
        supplierId: supplier.id,
        ...payload,
      });
      await mutate();
    } finally {
      setSaving(false);
    }
  };

  const updateCustomAttribute = async (
    id: number,
    payload: CustomAttributeDTO
  ) => {
    try {
      setSaving(true);
      await getSeeriApi().put(`/api/custom-attributes/${id}`, {
        supplierId: supplier.id,
        ...payload,
      });
      await mutate();
    } finally {
      setSaving(false);
    }
  };

  const deleteCustomAttribute = async (id: number) => {
    try {
      setSaving(true);
      await getSeeriApi().delete(`/api/custom-attributes/${id}`);
      await mutate();
    } finally {
      setSaving(false);
    }
  };

  return {
    customAttributes,
    loadingCustomAttributes: loading,
    createCustomAttribute,
    updateCustomAttribute,
    deleteCustomAttribute,
    savingCustomAttribute: saving,
  };
};
