import {
  FC,
  createContext,
  useContext,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import { get } from "lodash-es";
import styles from "./styles.module.scss";

type TableProps = {
  records: Record<string, any>[];
  children: ReactNode;
};

type TableContextValue = {
  records: Record<string, any>[];
};

const TableContext = createContext<TableContextValue>({
  records: [],
});

type TableCellContextValue = {
  record: Record<string, any>;
  rowIndex: number;
};

const TableCellContext = createContext<TableCellContextValue>({
  record: {},
  rowIndex: 0,
});

type TableHeadersContextValue = {
  headers: Set<string | ReactNode>;
  setHeaders: Dispatch<SetStateAction<Set<string | ReactNode>>>;
};

const TableHeadersContext = createContext<TableHeadersContextValue>({
  headers: new Set(),
  setHeaders: () => undefined,
});

export const useTableContext = () => useContext(TableContext);
export const useTableCellContext = () => useContext(TableCellContext);
const useTableHeadersContext = () => useContext(TableHeadersContext);

export const Table: FC<TableProps> = ({ records, children }) => {
  const [headers, setHeaders] = useState<Set<string | ReactNode>>(new Set());

  return (
    <TableHeadersContext.Provider value={{ headers, setHeaders }}>
      <TableContext.Provider value={{ records }}>
        <table className={styles.table}>
          <thead>
            <tr className={styles.row}>
              {[...headers].map((header, i) => (
                <th key={i}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {records.map((record, rowIndex) => (
              <TableCellContext.Provider
                value={{ record, rowIndex }}
                key={record.id}
              >
                <tr className={styles.row}>{children}</tr>
              </TableCellContext.Provider>
            ))}
          </tbody>
        </table>
      </TableContext.Provider>
    </TableHeadersContext.Provider>
  );
};

type TableCellProps = {
  label: string | ReactNode;
  source?: string;
  render?: (record: any, value?: any) => ReactNode;
};

export const TableCell: FC<TableCellProps> = ({ label, source, render }) => {
  const { setHeaders } = useTableHeadersContext();
  const { record, rowIndex } = useTableCellContext();

  useEffect(() => {
    if (rowIndex === 0) {
      setHeaders(prev => new Set([...prev, label]));
    }
  }, []);

  const value = source ? get(record, source, "-") : null;
  return <td>{render ? render(record, value) : value}</td>;
};
