import { TRestListData } from "../../types/rest";
import { getInstance } from "../../core/axios-gateway";
import {
  TRestListParamsSuppliers,
  TSupplierPendingProduct,
  TSupplierProduct,
} from "../../types/supplier-product";
import { TBrand } from "../../types/brand";

const BASE_PATH = "/api/products/supplier";
const SUBPURCHASE_PATH = "/api/sub-purchases/product-debts";

export const getSupplierProductGroupsV3 = async (
  supplierId: string,
  params: TRestListParamsSuppliers
) => {
  return getInstance()
    .get<TRestListData<TSupplierProduct>>(`${BASE_PATH}/${supplierId}/groups`, {
      params,
    })
    .then(res => res.data);
};

export const getSupplieVariantsV3 = async (
  supplierId: string,
  params: TRestListParamsSuppliers
) => {
  return getInstance()
    .get<TRestListData<TSupplierProduct>>(`${BASE_PATH}/${supplierId}`, {
      params,
    })
    .then(res => res.data);
};

export const getSupplierBrandsV3 = async (supplierId: string) => {
  return getInstance()
    .get<TBrand[]>(`${BASE_PATH}/${supplierId}/brands`)
    .then(res => res.data);
};

export const getSupplierPendingProducts = async (supplierId: string) => {
  return getInstance()
    .get<TSupplierPendingProduct[]>(`${SUBPURCHASE_PATH}/${supplierId}`)
    .then(res => res.data);
};
