import { logError } from "@/utils/errors";
import * as Sentry from "@sentry/react";
import packageJson from "../../package.json";

export const SENTRY_DNS = import.meta.env.VITE_APP_SENTRY_DNS;

const SentryConfig = {
  dsn: import.meta.env.VITE_APP_SENTRY_DNS,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 0.1,
  tracePropagationTargets: ["localhost"],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,
  release: `${packageJson.name}@${packageJson.version}`,
  environment: import.meta.env.VITE_APP_ENV,
};

export const initializeSentry = () => {
  if (SENTRY_DNS) {
    try {
      Sentry.init(SentryConfig);
    } catch (e) {
      logError(e);
    }
  }
};
