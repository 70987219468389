import { InvoiceShow } from "@/components/invoices/InvoiceShow";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { QUOTED } from "@/http";
import { useAuth } from "@/store/auth";
import { usePurchase } from "@/store/purchase";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

export const InvoicePage = () => {
  const { setTitle } = useHeaderTitle();
  const { id = "" } = useParams();
  const [t] = useTranslation("global");
  const [tInvoice] = useTranslation("invoice");
  const { purchase, getPurchase } = usePurchase();
  const { user } = useAuth();

  useEffect(() => {
    if (id) {
      getPurchase(id);
    }
  }, [id]);

  useEffect(() => {
    const timerId = setInterval(() => {
      if (id) {
        getPurchase(id);
      }
    }, 10000);

    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    setTitle(
      `${t(
        purchase?.status === QUOTED
          ? "QUOTES.DETAIL.TITLE"
          : purchase?.supplierId === user.id
          ? "PURCHASES.DETAIL.TITLE"
          : "PURCHASES.ORDERS.DETAIL_TITLE"
      )} » ${purchase?.purchaseNumber ?? "-"} » ${tInvoice("INVOICE")}`,
      `/${
        purchase?.status === QUOTED
          ? "quotes"
          : purchase?.supplierId === user.id
          ? "purchases"
          : "acquisitions"
      }/${id}/show`,
      "",
      null,
      true
    );
  }, [t, purchase]);

  if (!Object.keys(purchase).length) return <Spinner />;

  return (
    <>
      <InvoiceShow purchase={purchase} />
    </>
  );
};
