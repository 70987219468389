import { TicketStatusEnum } from "@/http";
import { CURRENT_LANG, ES } from "./core";

export const ticketsFilters = [
  { name: CURRENT_LANG() === ES ? "Aprobados" : "Approved", code: "APPROVED" },
  { name: CURRENT_LANG() === ES ? "Rechazados" : "Rejected", code: "REJECTED" },
];

export const ticketStatus: Readonly<
  Record<string, { name: string; code: TicketStatusEnum; style?: string }>
> = {
  [TicketStatusEnum.PENDING]: {
    code: TicketStatusEnum.PENDING,
    name: CURRENT_LANG() === ES ? "Pendiente" : "Pending",
    style: "",
  },
  [TicketStatusEnum.IN_SUPPLIER_REVIEW]: {
    code: TicketStatusEnum.IN_SUPPLIER_REVIEW,
    name: CURRENT_LANG() === ES ? "Por revisar" : "To review",
    style: "yellow",
  },
  [TicketStatusEnum.IN_PROCESS]: {
    code: TicketStatusEnum.IN_PROCESS,
    name: CURRENT_LANG() === ES ? "En Progreso" : "In progress",
    style: "yellow",
  },
  [TicketStatusEnum.IN_REVIEW]: {
    code: TicketStatusEnum.IN_REVIEW,
    name: CURRENT_LANG() === ES ? "En Revisión" : "In review",
    style: "yellow",
  },
  [TicketStatusEnum.APPROVED]: {
    code: TicketStatusEnum.APPROVED,
    name: CURRENT_LANG() === ES ? "Aprobado" : "Approved",
    style: "green",
  },
  [TicketStatusEnum.REJECTED]: {
    code: TicketStatusEnum.REJECTED,
    name: CURRENT_LANG() === ES ? "Rechazado" : "Rejected",
    style: "red",
  },
};

export const ticketStatusResponse: { label: string; value: string }[] = [
  {
    value: TicketStatusEnum.APPROVED,
    label: CURRENT_LANG() === ES ? "Aprobado" : "Approved",
  },
  {
    value: TicketStatusEnum.REJECTED,
    label: CURRENT_LANG() === ES ? "Rechazado" : "Rejected",
  },
];
