import { AxiosRequestConfig } from "axios";
import { getInstance } from "../../core/axios-gateway";
import { TRestListData, TRestListParams } from "../../types/rest";
import { TSupplier } from "../../types/supplier";

export * from "./orders";
export * from "./addresses";
export * from "./products";
export * from "./productsV2";
export * from "./productsV3";
export * from "./global-offers";
export * from "./offers";
export * from "./limits";
export * from "./reports";
export * from "./boxes";
export * from "./payments";
export * from "./commercials";
export * from "./auth";

const BASE_PATH = "/api/suppliers";

export const getSuppliersByIds = async (parentSupplierId: string) => {
  return getInstance()
    .post<TSupplier[]>(`${BASE_PATH}/by-ids`, { parentSupplierId })
    .then(res => res.data);
};

export const getSuppliers = async (params: TRestListParams) => {
  return getInstance()
    .get<TRestListData<TSupplier>>(`${BASE_PATH}/list`, { params })
    .then(res => res.data);
};

export const getSupplierById = async (
  supplierId: string,
  config?: AxiosRequestConfig
) => {
  return getInstance()
    .get<TSupplier>(`${BASE_PATH}/${supplierId}`, config)
    .then(res => res.data);
};

export const createSupplier = async (data: TSupplier) => {
  const { id: supplierId } = data;
  return getInstance()
    .post<TSupplier>(`${BASE_PATH}/${supplierId}`, data)
    .then(res => res.data);
};

export const createSupplierWithoutId = async (data: Partial<TSupplier>) => {
  return getInstance()
    .post<TSupplier>(BASE_PATH, data)
    .then(res => res.data);
};

export const updateSupplier = async (
  supplierId: string,
  data: Partial<TSupplier>
) => {
  return getInstance()
    .put<TSupplier>(`${BASE_PATH}/id/${supplierId}`, data)
    .then(res => res.data);
};

export const getSupplierDashboard = async (data: any) => {
  return getInstance()
    .post(`${BASE_PATH}/dashboard`, data)
    .then(res => res.data);
};
