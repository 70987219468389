import { Spinner } from "@/components/shared/Spinner/Spinner";
import { isUSA } from "@/constants/core";
import { DATE_HOUR, USA_DATE_HOUR } from "@/constants/date";
import { offersType } from "@/constants/offers";
import { getOfferById } from "@/http";
import { formatCurrency } from "@/utils/currency";
import { format } from "date-fns";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import useSWR from "swr";
import { BrandNameChip } from "../_form/BrandNameChip";
import { ClientNameChip } from "../_form/ClientNameChip";
import { ProductNameChip } from "../_form/ProductNameChip";
import { ProductGifTChip } from "./ProductGiftChip";
import styles from "./styles.module.scss";

export const DetailOffer = () => {
  const [t] = useTranslation("global");
  const { id } = useParams();
  const { data, mutate, isValidating } = useSWR(
    [id, "offerById"],
    getOfferById
  );

  useEffect(() => {
    mutate();
  }, [id]);

  if (isValidating) {
    return <Spinner />;
  }

  if (!data) {
    return <h6 className="bold">{t("ERROR_HAPPEN")}</h6>;
  }

  const isPayXTakeY = data.type === "PAY_X_TAKE_Y";
  const isDiscountNC = data.type === "DISCOUNT_PERCENTAGE_NON_CUMULATIVE";

  return (
    <>
      <div className={styles.detailContainer}>
        <p>
          {t("OFFERS.NAME")}: <span>{data?.name}</span>
        </p>
        <p>
          {t("OFFERS.TYPE")}:
          <span>
            {offersType.find(({ id }: any) => id === data?.type)?.name ?? "-"}
          </span>
        </p>
        <p>
          {t("OFFERS.STATUS")}:{" "}
          <span>{data?.enabled ? "Activo" : "Inactivo"}</span>
        </p>
        <p>
          {t("OFFERS.CREATED")}:{" "}
          <span>
            {format(
              new Date(data?.createdAt),
              isUSA ? USA_DATE_HOUR : DATE_HOUR
            )}
          </span>
        </p>
        <p>
          {t("OFFERS.ENDS")}:{" "}
          <span>
            {format(new Date(data?.endsAt), isUSA ? USA_DATE_HOUR : DATE_HOUR)}
          </span>
        </p>

        <p>
          {t("OFFERS.MIN_VALUE")}:
          <span>{formatCurrency(data.minValue ?? 0)}</span>
        </p>

        {isPayXTakeY && (
          <p>
            {t("OFFERS.PRODUCTS_BUY")}:<span>{data.productsToPay}</span>
          </p>
        )}

        {data?.targetProductsIds?.length > 0 && (
          <div className={styles.chips}>
            <h6>{t("OFFERS.PRODUCTS_OFFER")}</h6>
            {data.targetProductsIds.map(product => (
              <ProductNameChip key={product} id={product} hideDelete />
            ))}
          </div>
        )}

        {data?.targetBrandsProducts?.length > 0 && (
          <div className={styles.chips}>
            <h6>{t("OFFERS.BRANDS_OFFER")}</h6>
            {data.targetBrandsProducts.map(brand => (
              <BrandNameChip key={brand} id={brand} hideDelete />
            ))}
          </div>
        )}

        {isPayXTakeY && data?.gifts?.length > 0 && (
          <div className={styles.chips}>
            <h6>{t("OFFERS.GIFTS_OFFER")}</h6>
            <div className="flexGap flexWrap">
              {data.gifts.map(gift => (
                <ProductGifTChip
                  key={gift.id}
                  id={gift.productId}
                  quantity={gift.quantity}
                  invoiceIncluded={gift.invoiceIncluded}
                />
              ))}
            </div>
          </div>
        )}

        {isDiscountNC && (
          <>
            <p>
              {t("OFFERS.DISCOUNT_RATE")}:<span>{data.value ?? 0}%</span>
            </p>
            <p>
              {t("OFFERS.MAX_VALUE_TO_OFFER")}:
              <span>{formatCurrency(data.maxValueToOffer ?? 0)}</span>
            </p>
          </>
        )}

        {data?.targetSellersIds?.length > 0 && (
          <div className={styles.chips}>
            <h6>{t("OFFERS.USERS_OFFER")}</h6>
            {data.targetSellersIds.map(brand => (
              <ClientNameChip key={brand} id={brand} hideDelete />
            ))}
          </div>
        )}

        {data?.targetCouponCodes?.length > 0 && (
          <div className={styles.chips}>
            <h6>{t("OFFERS.COUPON_CODES")}</h6>
            <div className="flexGap flexWrap">
              {data.targetCouponCodes.map((code, index) => (
                <span key={index} className={styles.productGiftChip}>
                  {code}
                </span>
              ))}
            </div>
          </div>
        )}

        {data?.targetReferralCodes?.length > 0 && (
          <div className={styles.chips}>
            <h6>{t("OFFERS.REFERRAL_CODES")}</h6>
            <div className="flexGap flexWrap">
              {data.targetReferralCodes.map((code, index) => (
                <span key={index} className={styles.productGiftChip}>
                  {code}
                </span>
              ))}
            </div>
          </div>
        )}

        <h6 className="bolder">
          <u>{t("OFFERS.LIMITATIONS")}</u>
        </h6>

        <p>
          {t("OFFERS.FIRST_SALE_ONLY")}:{" "}
          <span>{data?.firstSale ? t("YES") : t("NO")}</span>
        </p>
        <p>
          {t("OFFERS.FOR_VIP_ONLY")}:{" "}
          <span>{data?.forVip ? t("YES") : t("NO")}</span>
        </p>
        <p>
          {t("OFFERS.MAXIMUM_USES")}: <span>{data?.maximumUses ?? "-"}</span>
        </p>
        <p>
          {t("OFFERS.MAXIMUM_USES_USER")}:{" "}
          <span>{data?.maximumUsesByUser ?? "-"}</span>
        </p>
      </div>
    </>
  );
};
