import { TSupplierVariantPP } from "@/models/order-details";
import { useProducts } from "@/store/products";
import { FormEditVariant } from "./FormDetailVariant";
import { useProductGroup } from "@/hooks/useProducts";

type TEditVariantModal = {
  onDone?: (variant: TSupplierVariantPP) => void;
};

export const EditVariantModal = ({ onDone }: TEditVariantModal) => {
  const { selectedVariantEdit } = useProducts();
  const productGroup = useProductGroup(selectedVariantEdit?.groupProductId);

  if (Object.keys(productGroup)?.length <= 0) {
    return null;
  }

  return (
    <FormEditVariant
      onDone={onDone}
      isSupplierBrand
      productGroup={productGroup}
    />
  );
};
