import { useTicket } from "@/store/ticket";
import { TicketModificationRow } from "./TicketModificationRow";
import { Collapsible } from "@/components/shared/Collapsible/Collapsible";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

export const TicketModifications = () => {
  const [selectedTicket] = useTicket(state => [state.selectedTicket]);
  const [t] = useTranslation("global");
  const modifications = selectedTicket?.modifications?.reverse() ?? [];

  return modifications!.length > 0 ? (
    <Collapsible
      open
      className={styles.collapsible}
      title={t("TICKETS.HISTORY")}
    >
      <div className="tableScroll">
        <div className={`row ${styles.containerItems}`}>
          {modifications.map(modification => (
            <TicketModificationRow
              key={modification.id}
              modification={modification}
            />
          ))}
        </div>
      </div>
    </Collapsible>
  ) : (
    <></>
  );
};
