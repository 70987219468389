import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { TClient } from "@/http";
import { buildCountryLine, buildLineAddress } from "../utils";
import { shipComponent, shipComponentPOS } from "./Styles";

type Props = {
  client: TClient;
  translate: any;
  forPOS?: boolean;
};

const styles = StyleSheet.create(shipComponent);
const stylesPOS = StyleSheet.create(shipComponentPOS);

const ShipTo = ({ translate, client, forPOS }: Props) => (
  <View style={forPOS ? stylesPOS.headerContainer : styles.headerContainer}>
    <Text style={styles.billTo}>{translate("SHIP_TO")}:</Text>
    <View style={styles.infoContainer}>
      <Text>{client.legalName}</Text>
      <Text>
        {client.firstName} {client.lastName}
      </Text>
      {client?.documentId && (
        <Text>
          {client.documentType} {client.documentId}
        </Text>
      )}
      <Text>
        {client.phonePrefix} {client.phone}
      </Text>
      <Text>{buildLineAddress(client.location)}</Text>
      <Text>{buildCountryLine(client?.location)}</Text>
      <Text>{client?.email}</Text>
    </View>
  </View>
);

export default ShipTo;
