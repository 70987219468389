import { CommercialVisitsList } from "@/components/commercials/visits/CommercialVisitsList";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const CommercialTracking = () => {
  const [t] = useTranslation("global");
  const { setTitle } = useHeaderTitle();

  useEffect(() => {
    setTitle(t("COMMERCIAL_TRACKING.TITLE"));
  }, [t]);

  return (
    <>
      <CommercialVisitsList />
    </>
  );
};
