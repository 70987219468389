import { getProductById } from "@/http";
import useSWR from "swr";

type TProductSKU = {
  id: string;
  userId: string;
};
const ProductSKU = ({ id, userId }: TProductSKU) => {
  const { data: productDetail } = useSWR(id, getProductById);

  const sku = productDetail?.suppliers?.find(
    (supplier: any) => supplier?.supplierId === userId
  )?.supplierSku;

  return <>{sku || "-"}</>;
};

export default ProductSKU;
