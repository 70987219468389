import { useSubscriptionResources } from "@/lib/subscriptions/usePackageAndFeatures";
import { useCountry } from "./useCountry";
import useSWR from "swr";
import { getSeeriApi } from "@/http";
import { useAuth } from "@/store/auth";
import { IPackage } from "@/lib/subscriptions/types";

export interface ISubscription {
  currentPackage: IPackage;
  start: string;
  finish: string;
}

export interface ISubscriptionValidations {
  [key: string]:
    | {
        create: boolean;
        current: number;
        maximum: number;
      }
    | undefined;
}

export const useSubscription = () => {
  const user = useAuth(s => s.user);
  const country = useCountry();

  const { data: resources } = useSubscriptionResources(
    country.prefix.toLowerCase()
  );

  const features = resources?.features ?? [];
  const packages = resources?.packages ?? [];
  const addons = resources?.addons ?? [];

  const { data: subscription, mutate: refreshSubscription } = useSWR(
    "my-subscription",
    () =>
      getSeeriApi()
        .get<ISubscription>(`/api/subscriptions/${user.id}`)
        .then(res => res.data)
  );

  const { data: subscriptionValidations, mutate: refreshValidations } = useSWR(
    "my-subscription-validations",
    () =>
      getSeeriApi()
        .get<ISubscriptionValidations>(
          `/api/subscriptions/${user.id}/validations`
        )
        .then(res => res.data)
  );

  const currentPackage = subscription?.currentPackage;

  const hasFeature = (featCode: string) =>
    currentPackage?.features[featCode]?.has;

  const basicPack = resources?.packages.find(p => p.purchasable && !p.monthly);

  const isBasicFeature = (featCode: string) =>
    basicPack?.features[featCode]?.has;

  const reloadSubscription = async () => {
    return Promise.all([refreshSubscription(), refreshValidations()]);
  };

  return {
    subscription,
    subscriptionValidations,
    currentPackage,
    features,
    packages,
    addons,
    hasFeature,
    isBasicFeature,
    reloadSubscription,
  };
};
