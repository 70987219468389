import { TPurchase } from "@/http";

export const isFinishedPurchase = (purchase: TPurchase) => {
  if (!purchase) return true;
  return ["DONE", "CANCELLED"].includes(purchase.status);
};

export const isCanceledPurchase = (purchase: TPurchase) => {
  if (!purchase) return true;
  return ["CANCELLED"].includes(purchase.status);
};
