import ReactDOM from "react-dom/client";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import App from "./App";
import { I18nConfig } from "@/core/i18n";
import "@/core/zod";
import { ToastContainer } from "react-toastify";
import "@fontsource/quicksand/400.css";
import "react-toastify/dist/ReactToastify.css";
import { Portal } from "@headlessui/react";
import { initializeSentry } from "@/core/sentry";

i18next.init(I18nConfig);

initializeSentry();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <I18nextProvider i18n={i18next}>
    <App />
    <Portal>
      <ToastContainer theme="colored" />
    </Portal>
  </I18nextProvider>
);
