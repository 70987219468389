import { TPaymentProvider } from "@/http/types/payment-methods";
import { getInstance } from "../../core/axios-gateway";

export const getPaymentProviders = async () => {
  return getInstance()
    .get<TPaymentProvider[]>("/api/suppliers/payment-providers")
    .then(res => res.data);
};

export const savePaymentProvider = async (
  paymentProvider: TPaymentProvider
) => {
  return getInstance()
    .post<TPaymentProvider>("/api/suppliers/payment-providers", paymentProvider)
    .then(res => res.data);
};

export const updatePaymentProvider = async (
  id: string,
  paymentProvider: TPaymentProvider
) => {
  return getInstance()
    .put<TPaymentProvider>(
      `/api/suppliers/payment-providers/${id}`,
      paymentProvider
    )
    .then(res => res.data);
};

export const deletePaymentProvider = async (id: string) => {
  return getInstance()
    .delete<TPaymentProvider>(`/api/suppliers/payment-providers/${id}`)
    .then(res => res.data);
};
