import {
  createSupplierProcurementV2,
  getProductV2,
  supplierProcurementMovement,
  TSupplierAddress,
} from "@/http";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { useAuth } from "@/store/auth";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { createPortal } from "react-dom";
import { Modal } from "@/components/shared/Modal/Modal";
import AddressDetail from "@/components/profile/addresses/AddressDetail";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { useAddresses } from "@/store/profile";
import { hasPermission } from "@/utils/validations/permissions";
import { MdOutlineEdit } from "react-icons/md";
import { WarehouseName } from "@/components/shared/WarehouseName";
import { RadioGroupV2 } from "@/components/shared/RadioGroup/RadioGroupV2";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { NumericInput } from "@/components/shared/Input/NumericInput";
import { addressToString } from "@/utils/address";

type TCreateProcurement = {
  variant: any;
  type: string;
  onDone?: (variant: any) => void;
};

export const CreateProcurement = ({
  variant,
  type,
  onDone,
}: TCreateProcurement) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [addressId, setAddressId] = useState("");
  const [destinyWarehouses, setDestinyWarehouses] = useState<any[]>([]);
  const [tGlobal] = useTranslation("gloabl");
  const [t] = useTranslation("products-page");
  const { getAddresses, loadingOrders, addresses } = useAddresses();
  const methods = useForm<any>({
    defaultValues: {
      quantity: null,
      warehouseId: variant.warehouseId ?? "",
      action: "",
    },
  });
  const { user, isCommercial, role } = useAuth();
  const userAction = methods.watch("action");

  const onCreateAddress = (address: Partial<TSupplierAddress>) => {
    getAddresses(user.id, 0);
    setAddressId(address.id ?? "");
  };

  const onSubmit = async (values: any) => {
    setIsLoading(true);
    try {
      const { quantity, warehouseId, action, origin: originWarehouse } = values;

      if (quantity <= 0) {
        showToastError(t("ERROR_STOCK_MIN"));
      }

      const payload: any = {
        type: "SUPPLY",
        createdBy: user.id,
        warehouseId,
        amount: Number(quantity),
      };

      if (action === "add") {
        payload.destiny = "AVAILABLE";
        await createSupplierProcurementV2(variant?.id, payload);
      } else if (action === "sustract") {
        payload.source = "AVAILABLE";
        if (quantity > variant?.availableStock) {
          showToastError(t("ERROR_STOCK_REQUIRED"));
        }
        await createSupplierProcurementV2(variant?.id, payload);
      } else {
        await supplierProcurementMovement(variant?.id, {
          warehouseFrom: originWarehouse,
          warehouseTo: warehouseId,
          amount: Number(quantity),
        });
      }

      const newVariant = await getProductV2(variant?.productId ?? "");

      onSuccessCreate(newVariant);
    } catch (error) {
      showToastError({ message: tGlobal("ERROR_SOME_BAD"), error });
    } finally {
      setIsLoading(false);
    }
  };

  const onSuccessCreate = (newVariant: any): void => {
    setIsLoading(false);
    setOpen(false);
    onDone?.(newVariant);
    showToastSuccess(t("PRODUCT_UPDATED"));
    resetFields();
  };

  const resetFields = () => {
    methods.reset({ quantity: 0, warehouseId: "" });
  };

  const validateMaximunQuantity = () => {
    if (userAction === "add") {
      return { disabled: false, quantity: undefined };
    }
    if (["sustract", "movement"].includes(userAction)) {
      const warehouse = methods.watch(
        userAction === "sustract" ? "warehouseId" : "origin"
      );
      const originWarehouse = variant?.warehouses?.find(
        (w: any) => w?.warehouseId === warehouse
      );

      return {
        disabled: !originWarehouse,
        quantity: originWarehouse?.availableStock || 0,
      };
    }

    return { disabled: true, quantity: undefined };
  };

  useEffect(() => {
    methods.setValue("warehouseId", addressId);
  }, [addressId, addresses]);

  useEffect(() => {
    switch (userAction) {
      case "add":
      case "movement":
        setDestinyWarehouses(
          addresses?.content?.map(address => ({
            value: address?.id ?? "",
            label: address?.name || addressToString(address),
          }))
        );
        break;
      case "sustract":
        setDestinyWarehouses(
          variant?.warehouses?.map((w: any) => ({
            value: w?.warehouseId,
            label: <WarehouseName id={w?.warehouseId} useOnlyName />,
          }))
        );
        break;
      default:
        setDestinyWarehouses([]);
    }
  }, [userAction, addresses]);

  useEffect(() => {
    methods.setValue("warehouseId", "");
  }, [userAction]);

  if (!hasPermission(isCommercial, role, ["action.products.stock"])) {
    return null;
  }

  return (
    <>
      <ToolTip title={t("MODIFY_STOCK")} position="Top">
        <div
          onClick={() => {
            setOpen(true);
            getAddresses(user.id, 0);
          }}
          className={`${styles.stockButton} pointer`}
        >
          <MdOutlineEdit />
        </div>
      </ToolTip>
      {createPortal(
        <Modal
          isOpen={isOpen}
          onClose={() => {
            resetFields();
            setOpen(false);
          }}
          size={"sm"}
        >
          <div className={styles.modalEditVariant}>
            <h6 className="bold">{`${t("MANAGE_PROCUREMENT")}: ${type}`}</h6>
            <div className="divider" />

            <FormProvider {...methods}>
              <form
                autoComplete="off"
                onSubmit={methods.handleSubmit(onSubmit)}
              >
                <div className={styles.containerProcurement}>
                  <div className={`${styles.available} contentInline`}>
                    <div className={styles.availableText}>
                      {t("STOCK_AVAILABLE")}
                    </div>
                  </div>
                  <div className="spacer" />
                  <SupplierWarehouseStockTable
                    warehouses={variant?.warehouses ?? []}
                  />

                  <div className="contentInline">
                    <span></span>
                  </div>
                  <div className={styles.divider} />
                  <div className={styles.available}>
                    <h6>*{t("SELECT_OPTION")}</h6>
                  </div>

                  <div className={`contentInline`}>
                    <div className="col-12 noPadding">
                      <RadioGroupV2
                        name="action"
                        options={[
                          { label: t("ADD"), value: "add" },
                          ...(variant?.availableStock > 0
                            ? [
                                { label: t("REMOVE"), value: "sustract" },
                                { label: t("MOVEMENT"), value: "movement" },
                              ]
                            : []),
                        ]}
                        required
                        inline
                        color="purple"
                      />
                    </div>
                  </div>

                  <div className="spacer" />

                  {userAction === "movement" && (
                    <div className={styles.warehouseInput}>
                      <SelectV2
                        label={t("ORIGIN_WAREHOUSE")}
                        required
                        name="origin"
                        choices={variant?.warehouses?.map((w: any) => ({
                          value: w?.warehouseId,
                          label: (
                            <WarehouseName id={w?.warehouseId} useOnlyName />
                          ),
                        }))}
                      />
                    </div>
                  )}

                  {loadingOrders ? (
                    <Spinner />
                  ) : (
                    <div className={styles.warehouseInput}>
                      <SelectV2
                        required
                        disabled={!userAction || !destinyWarehouses?.length}
                        name="warehouseId"
                        label={
                          methods.watch("action") === "movement"
                            ? t("DESTINY_WAREHOUSE")
                            : t("WAREHOUSE")
                        }
                        choices={destinyWarehouses ?? []}
                        infoLabel={
                          userAction !== "sustract" ? (
                            <AddressDetail text action={onCreateAddress} />
                          ) : null
                        }
                      />
                    </div>
                  )}
                  <div className={styles.warehouseInput}>
                    <NumericInput
                      label={t("QUANTITY")}
                      required
                      name="quantity"
                      min={0.1}
                      max={validateMaximunQuantity().quantity}
                      hideControls
                      decimals
                      disabled={validateMaximunQuantity().disabled}
                    />
                  </div>
                  <div className="spacer" />
                </div>
                <div className="rigthAlign">
                  <button
                    disabled={isLoading}
                    onClick={() => {
                      resetFields();
                      setOpen(false);
                    }}
                  >
                    {t("CANCEL")}
                  </button>
                  <button className="primary lg" disabled={isLoading}>
                    {t("SAVE_CHANGES")}
                  </button>
                </div>
              </form>
            </FormProvider>
          </div>
        </Modal>,
        document.body
      )}
    </>
  );
};

export const SupplierWarehouseStockTable = ({
  warehouses,
  includeTitle = false,
  unitRate = 1,
  overStock = false,
}: {
  warehouses: any[];
  includeTitle?: boolean;
  unitRate?: number;
  overStock?: boolean;
}) => {
  const [t] = useTranslation("products-page");

  return (
    <>
      {(warehouses ?? []).length > 0 ? (
        <div className={styles.warehousesContainer}>
          {includeTitle && (
            <div className={`${styles.available} contentInline bold mbmd`}>
              <span className={styles.availableText}>{t("WAREHOUSE")}</span>
              <span className={styles.availableQuantity}>Stock</span>
            </div>
          )}
          {warehouses.map((warehouse: any, idx: number) => (
            <div key={idx}>
              <div className={`${styles.available} contentInline`}>
                <span className={styles.availableText}>
                  <WarehouseName id={warehouse?.warehouseId} useOnlyName />
                </span>
                <span className={styles.availableQuantity}>
                  {(overStock
                    ? warehouse?.overStock
                      ? warehouse?.availableStock ??
                        0 + warehouse?.overStockAmount
                      : warehouse?.availableStock ?? 0
                    : warehouse?.availableStock ?? 0) / unitRate}
                </span>
              </div>
              {includeTitle && idx !== warehouses.length - 1 && (
                <div className="spacer" />
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.available}>{t("NO_WAREHOUSES")}</div>
      )}
    </>
  );
};
