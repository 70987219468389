import { Spinner } from "@/components/shared/Spinner/Spinner";
import { isUSA } from "@/constants/core";
import { useAuth } from "@/store/auth";
import { usePurchase } from "@/store/purchase";
import { hasPermission } from "@/utils/validations/permissions";
import { debounce } from "lodash-es";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PurchaseListPagination } from "./PurchaseListPagination";
import { PurchaseListRow } from "./PurchaseListRow";
import styles from "./styles.module.scss";

export const PurchaseListTable = ({ quotes }: { quotes?: boolean }) => {
  const [t] = useTranslation("purchases");
  const {
    purchaseNumberFilter,
    supplierIdFilter,
    setCurrentPage,
    getPurchases,
    purchases,
    currentPage,
    isLoadingPurchase,
    sortBy,
    sortDirection,
    isProcurementFilter,
    statusFilters,
  } = usePurchase();

  const { user, isCommercial, commercial, role } = useAuth();
  const isSale = !isProcurementFilter && !quotes;

  const hasMultiplePermissions = hasPermission(isCommercial, role, [
    "action.purchase.show-all-purchases",
  ]);

  useEffect(() => {
    getPurchases(user.id, isCommercial, commercial?.id, hasMultiplePermissions);
  }, [currentPage]);

  useEffect(() => {
    const purchasesDebounce = debounce(() => {
      setCurrentPage(0);
      getPurchases(
        user.id,
        isCommercial,
        commercial?.id,
        hasMultiplePermissions
      );
    }, 500);
    purchasesDebounce();
  }, [
    purchaseNumberFilter,
    supplierIdFilter,
    sortBy,
    sortDirection,
    isProcurementFilter,
    statusFilters,
    isCommercial,
    commercial,
  ]);

  if (isLoadingPurchase) {
    return <Spinner />;
  }

  return (
    <>
      {purchases?.length > 0 ? (
        <div className={styles.container}>
          <div className="tableScroll">
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>{t("CREATED_AT")}</th>
                  {quotes &&
                    hasPermission(isCommercial, role, [
                      "action.quotes.show-commercial-creation",
                    ]) && <th>{t("CREATED_BY")}</th>}
                  <th>{t(!quotes ? "PURCHASE_NUMBER" : "QUOTE_NUMBER")}</th>
                  <th className={isSale ? "leftAlign" : ""}>
                    {t("PURCHASE_PAYMENT_METHOD")}
                  </th>
                  <th>{t("TOTAL")}</th>
                  {!isProcurementFilter && (
                    <>
                      <th className="leftAlign">
                        {t(isUSA ? "LEGAL_NAME" : "CLIENT")}
                      </th>
                      <th>{t("COMMERCIAL")}</th>
                    </>
                  )}
                  {!quotes && (
                    <>
                      <th className="leftAlign">{t("PURCHASE_STATUS")}</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {purchases.map(purchase => (
                  <PurchaseListRow key={purchase.id} purchase={purchase} />
                ))}
              </tbody>
            </table>
          </div>
          <PurchaseListPagination />
        </div>
      ) : (
        <h2 className={styles.noData}>{t("NO_DATA")}</h2>
      )}
    </>
  );
};
