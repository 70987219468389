import { AxiosRequestConfig } from "axios";
import { AxiosInstance, setSeeriApi } from "@/http";
import { app as firebaseApp, firebaseLogout } from "./firebase";
import { getAuth } from "firebase/auth";

import { isProd } from "@/constants/env";
import packageJson from "../../package.json";
import { esCO, esMX, enUS } from "@/utils/locale";
import { EN, ES } from "@/constants/core";

type SeeriApiParams = {
  baseURL: string;
  accessToken: string;
  country: string;
};

const configDev = {
  apiKey: "AIzaSyAXIpYruBPO5YLoGD4ShUn1RsbDletlJcA",
};

const configProd = {
  apiKey: "AIzaSyCeq4IEkSGoU-WJWLlgZlweMS2cSNIiB6c",
};

const configEnv = isProd ? configProd : configDev;

export const appHeaders = {
  "x-api-key": configEnv.apiKey,
  "x-origin-name": "suppliers",
  "x-origin-platform-version": packageJson.version,
  "x-origin-agent-version": "unknown",
  "x-origin-agent-name": "suppliers",
  "x-origin-language": "es-CO",
};

export const configureSeeriApi = ({
  country,
  baseURL,
  accessToken,
}: SeeriApiParams) => {
  const config: AxiosRequestConfig = {
    baseURL,
    headers: appHeaders,
  };

  if (accessToken && config.headers) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }

  if (country && config.headers) {
    const localLang = localStorage.getItem("lang") ?? ES;
    const lang = localLang === EN ? enUS : country === "co" ? esCO : esMX;
    config.headers["x-user-language"] = lang;
    config.headers["x-user-country"] = country;
    config.headers["Accept-Language"] = lang.slice(0, 2)
  }

  const instance = setSeeriApi(config);
  setSeeriApiInterceptors(instance);
};

export const setSeeriApiInterceptors = async (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    async config => {
      if (firebaseApp) {
        const accessToken = await getAuth(
          firebaseApp
        ).currentUser?.getIdToken();

        if (accessToken && config.headers) {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
      }

      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (401 === error?.response?.status) {
        firebaseLogout();
      } else {
        return Promise.reject(error);
      }
    }
  );

  return instance;
};
