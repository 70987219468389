import { Modal } from "@/components/shared/Modal/Modal";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { deleteSupplierLimit, updateSupplier } from "@/http";
import { useAuth } from "@/store/auth";
import { formatCurrency } from "@/utils/currency";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete } from "react-icons/ai";
import styles from "./styles.module.scss";
import { TSupplierLimit } from "@/http/types/supplier-limit";
import { useTieredDiscounts } from "@/store/limits";

type TDeleteTieredDiscount = { index: number; discount: TSupplierLimit };
export const DeleteTieredDiscount = ({ discount }: TDeleteTieredDiscount) => {
  const [t] = useTranslation("tiered-discounts");
  const [tGlobal] = useTranslation("global");
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const { getTieredDiscounts } = useTieredDiscounts();

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await deleteSupplierLimit(discount?.id ?? "");
      getTieredDiscounts(user.id);
      setIsLoading(false);
      setModalOpen(false);
      showToastSuccess(t("DISCOUNT_DELETED"));
    } catch (error) {
      setIsLoading(false);
      showToastError({ message: tGlobal("ERROR_SOME_BAD"), error });
    }
  };

  return (
    <>
      <ToolTip title={t("DELETE_DISCOUNT")} position="Left">
        <AiOutlineDelete cursor="pointer" onClick={() => setModalOpen(true)} />
      </ToolTip>
      <Modal isOpen={isModalOpen} size="sm" onClose={() => setModalOpen(false)}>
        <div className={styles.modal}>
          <h6>{t("DELETE_DISCOUNT")}</h6>
          <div className={"divider"} />
          <div className={styles.content}>
            <p>{t("YOU_WANT_DELETE_DISCOUNT")}</p>
            <br />

            <p className={styles.item}>
              <span className="bold">{`${t("MIN_VALUE")}: `}</span>
              {formatCurrency(discount?.minValue ?? 0)}
            </p>
            <p className={styles.item}>
              <span className="bold">{`${t("DISCOUNT_PERCENTAGE")}: `}</span>
              {discount?.discountPercentage}%
            </p>
          </div>
          <div className={"divider"} />
          <div className="rigthAlign">
            <button className="primary" disabled={isLoading} onClick={onSubmit}>
              {t("DELETE_DISCOUNT_ACTION")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
