import styles from "./styles.module.scss";
import { getSeeriApi } from "@/http";
import { useState } from "react";
import { useAuth } from "@/store/auth";
import { toast } from "react-toastify";
import { saveFile } from "@/utils/save-file";
import { useTranslation } from "react-i18next";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { logError } from "@/utils/errors";

export const ClientsBulkProcessDownload = ({
  mode,
}: {
  mode: "create" | "update";
}) => {
  const [t] = useTranslation("products-page");

  const modes = {
    create: {
      download: "/api/seeri-excel/supplier-clients/bulk-create/download",
    },
    update: {
      download: "/api/seeri-excel/supplier-clients/bulk-update/download",
    },
  };

  const supplier = useAuth(s => s.user);
  const [loading, setLoading] = useState(false);

  const downloadTemplate = async () => {
    setLoading(true);

    try {
      const res = await getSeeriApi().get(modes[mode].download, {
        params: { supplierId: supplier.id },
        responseType: "blob",
      });

      await saveFile(res.data, `clients-bulk-${mode}.xlsx`);
    } catch (error) {
      logError(error);
      toast.error(t("BULK_CHARGE.DOWNLOAD.TEMPLATE_ERROR").toString());
    }

    setLoading(false);
  };

  return (
    <div className={styles.spacing}>
      <div className="flexGap">
        <button
          onClick={downloadTemplate}
          className="outlined"
          disabled={loading}
        >
          {t("BULK_CHARGE.DOWNLOAD.DOWNLOAD")}
        </button>
        {loading ? <Spinner /> : <span />}
      </div>
    </div>
  );
};
