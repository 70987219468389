import ProductImage from "@/components/layout/ProductImage";
import { TProduct } from "@/http";
import { formatCurrency } from "@/utils/currency";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { BrandNameChip } from "./BrandNameChip";
import styles from "./ProductSelectOption.module.scss";

export const ProductSelectOption = ({ product }: { product: TProduct }) => {
  const [t] = useTranslation("purchases");

  const productSupplier = product.suppliers.find(s => s.supplierId);

  if (!productSupplier) return null;

  return (
    <div
      className={clsx(
        styles.productOption,
        productSupplier.status === false && styles.disabled
      )}
    >
      <ProductImage images={product.images} />
      <div>
        <div className={styles.description}>
          {product.name}
          <br />
          {!productSupplier?.status && (
            <span className={styles.sku}>
              {t("PRODUCT_DISABLED")}
              <br />
            </span>
          )}
          {product?.suppliers?.[0]?.supplierSku && (
            <span className={styles.sku}>
              SKU: {product?.suppliers?.[0]?.supplierSku}
              <br />
            </span>
          )}
          {product?.brandId && (
            <span className={styles.sku}>
              {t("BRAND")} <BrandNameChip id={product.brandId} />
              <br />
            </span>
          )}
        </div>
        <div className="bold">
          {formatCurrency(product?.suppliers[0]?.wholesalePrice ?? 0)}
        </div>
      </div>
    </div>
  );
};
