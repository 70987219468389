import { TRestListData, TSellerBillingAddress } from "@/http";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { DeleteBillingAddress } from "./DeleteBillingAddress";
import { Table, TableCell } from "./ui/Table/Table";
import tableStyles from "./ui/Table/styles.module.scss";

type BillingAddressTableProps = {
  list: TRestListData<TSellerBillingAddress>;
  page: number;
  onPageChange: (page: number) => void;
  refresh: () => void;
};

export const BillingAddressTable: FC<BillingAddressTableProps> = ({
  list,
  page,
  onPageChange,
  refresh,
}) => {
  const [t] = useTranslation("global");

  return (
    <>
      <Table records={list.content}>
        <TableCell
          label={t("CLIENTS.NAME")}
          render={(address: TSellerBillingAddress) => (
            <>{`${address.firstName} ${address.lastName}`}</>
          )}
        />
        <TableCell
          label={t("CLIENTS.DOC")}
          render={(address: TSellerBillingAddress) => (
            <>{`${address.documentType} - ${address.documentId}`}</>
          )}
        />
        <TableCell
          label={t("CLIENTS.ADDRESS")}
          render={(address: TSellerBillingAddress) => (
            <>{`${address.addressLine1}, ${address.addressLine2 ?? ""}`}</>
          )}
        />
        <TableCell label={t("CLIENTS.COUNTRY")} source="country" />
        <TableCell label={t("CLIENTS.STATE")} source="state" />
        <TableCell label={t("CLIENTS.CITY")} source="city" />
        <TableCell
          label={t("ACTIONS")}
          render={(address: TSellerBillingAddress) => (
            <DeleteBillingAddress address={address} onDone={() => refresh()} />
          )}
        />
      </Table>
      <div className={tableStyles.paginate}>
        <ReactPaginate
          forcePage={page}
          onPageChange={p => onPageChange(p.selected)}
          pageCount={list.totalPages}
          previousLabel="←"
          nextLabel="→"
          marginPagesDisplayed={3}
          containerClassName={tableStyles.pagination}
          previousLinkClassName={tableStyles.previous}
          nextLinkClassName={tableStyles.next}
          disabledClassName={tableStyles.disabled}
          activeClassName={tableStyles.selected}
          breakClassName={tableStyles.break}
        />
      </div>
    </>
  );
};
