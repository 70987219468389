import {
  TDeliveryProvider,
  TDeliveryStatusProvider,
} from "@/http/types/delivery-provider";
import { getInstance } from "../../core/axios-gateway";

const BASE_PATH = "/api/suppliers/delivery-provider";

export const createSupplierDeliveryProvider = async (
  data: Partial<TDeliveryProvider>
) => {
  return getInstance()
    .post<TDeliveryProvider>(BASE_PATH, data)
    .then(res => res.data);
};

export const updateSupplierDeliveryProvider = async (
  supplierDeliverProviderId: string,
  data: Partial<TDeliveryProvider>
) => {
  return getInstance()
    .put<TDeliveryProvider>(`${BASE_PATH}/${supplierDeliverProviderId}`, data)
    .then(res => res.data);
};

export const deleteSupplierDeliveryProvider = async (
  supplierDeliverProviderId: string
) => {
  return getInstance()
    .delete(`${BASE_PATH}/${supplierDeliverProviderId}`)
    .then(res => res.data);
};

export const getSupplierDeliveryProviderById = async (
  supplierDeliveryProviderId: string
) => {
  return getInstance()
    .get<TDeliveryProvider>(`${BASE_PATH}/${supplierDeliveryProviderId}`)
    .then(res => res.data);
};

export const getSupplierDeliveryProviderByCode = async (code: string) => {
  return getInstance()
    .get<TDeliveryStatusProvider[]>(`${BASE_PATH}/${code}/code`)
    .then(res => res.data);
};

export const getSupplierDeliveryProviders = async () => {
  return getInstance()
    .get<TDeliveryProvider[]>(BASE_PATH)
    .then(res => res.data);
};

export const updateSupplierDeliveryStatusProvider = async (
  deliveryProviderId: string,
  deliveryStatusId: string,
  data: Partial<TDeliveryStatusProvider>
) => {
  return getInstance()
    .put<TDeliveryStatusProvider>(
      `${BASE_PATH}/${deliveryProviderId}/delivery-status/${deliveryStatusId}`,
      data
    )
    .then(res => res.data);
};

export const createSupplierDeliveryStatusProvider = async (
  deliveryProviderId: string,
  data: Partial<TDeliveryStatusProvider>
) => {
  return getInstance()
    .post<TDeliveryStatusProvider>(
      `${BASE_PATH}/${deliveryProviderId}/delivery-status`,
      data
    )
    .then(res => res.data);
};

export const deleteSupplierDeliveryStatusProvider = async (
  deliveryProviderId: string,
  deliveryStatusId: string
) => {
  return getInstance()
    .delete(
      `${BASE_PATH}/${deliveryProviderId}/delivery-status/${deliveryStatusId}`
    )
    .then(res => res.data);
};
