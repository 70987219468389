import { Modal } from "@/components/shared/Modal/Modal";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { TGlobalOffer } from "@/http/types/global-offers";
import { useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { AiOutlineEdit } from "react-icons/ai";
import { FormOffer } from "../_form/FormOffer";

type TEditOffer = {
  offer: TGlobalOffer;
  isButton?: boolean;
  onDone?: (offer: TGlobalOffer) => void;
};
export const EditOffer = ({ offer, isButton, onDone }: TEditOffer) => {
  const [isOpen, setOpen] = useState(false);
  const [t] = useTranslation("global");

  return (
    <>
      {isButton ? (
        <>
          <button
            className="primary lg"
            onClick={e => {
              e.stopPropagation();
              setOpen(true);
            }}
          >
            {t("EDIT")}
          </button>
        </>
      ) : (
        <ToolTip title={t("EDIT")} position="Left">
          <AiOutlineEdit
            cursor="pointer"
            onClick={e => {
              e.stopPropagation();
              setOpen(true);
            }}
          />
        </ToolTip>
      )}
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setOpen(false);
        }}
        size="xl"
      >
        <FormOffer
          offer={offer}
          onDone={offer => {
            setOpen(false);
            onDone?.(offer);
          }}
        />
      </Modal>
    </>
  );
};
