import { isES } from "@/constants/core";

export type TOrderFilterStatus = {
  filter: string;
  setFilter: (filter: string) => void;
  idFilter: string;
  setIdFilter: (idFilter: string) => void;
  startDateFilter: Date | null;
  endDateFilter: Date | null;
  setDatesFilter: (
    startDateFilter: Date | null,
    endDateFilter: Date | null
  ) => void;
  sortBy: string;
  sortDirection: string | string[];
  setSorting: (sortBy: string, sortDirection: string | string[]) => void;
};

export type TTableRange = {
  slice: Order[];
  range: Order[];
};

export type TOrdersTable = {
  tableRange: number[];
  slice: Order[];
  page: number;
  setTableRange: (data: Order[], page: number, rowsPerPage: number) => void;
  setPage: (page: number) => void;
};

export type FilterType =
  | "all"
  | "pending"
  | "attended"
  | "rejected"
  | "is_invoiced";

export enum Filter {
  ALL = "all",
  PENDING = "pending",
  ATTENDED = "attended",
  REJECTED = "rejected",
  CANCELLED = "cancelled",
  IN_PROCESS = "in_process",
  REMISSION_GENERATED = "remission_generated",
  IS_INVOICED = "is_invoiced",
}

export const OrderFilters = [
  {
    text: "PENDING",
    value: "pending",
    show: isES ? "pendientes" : "pending",
  },
  {
    text: "ATTENDED",
    value: "attended",
    show: isES ? "aprobadas" : "attended",
  },
  {
    text: "REJECTED",
    value: "rejected",
    show: isES ? "rechazadas" : "rejected",
  },
  {
    text: "CANCELLED",
    value: "cancelled",
    show: isES ? "canceladas" : "cancelled",
  },
  {
    text: "IN_PROCESS",
    value: "in_process",
    show: isES ? "En proceso" : "in process",
  },
  {
    text: "ALL",
    value: "all",
    show: "",
  },
  {
    text: "IS_INVOICED",
    value: "is_invoiced",
    show: isES ? "pendientes de facturar" : "pending invoice",
  },
];

export type Order = {
  id: number;
  date: string;
  ammount: number;
  status: FilterType;
};

export type TPaginatorTableOrdersProps = {
  getOrders: (page: number) => void;
};

export type PaginationSelect = {
  selected: number;
};
