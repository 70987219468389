import { getCoinsBalanceBySellerId } from "@/http";
import { formatCurrency } from "@/utils/currency";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

export const CoinsBalance = ({
  sellerId,
  isSlim = false,
}: {
  sellerId: string;
  isSlim?: boolean;
}) => {
  const [t] = useTranslation("global");
  const { data } = useSWR(
    [sellerId, "coins-balance"],
    getCoinsBalanceBySellerId
  );

  return (
    <>
      <p className={clsx({ ["bold"]: !isSlim })}>
        <span>{t("AVAILABLE_AMOUNT_COINS")}</span>
        <span className={clsx("ml1", { ["xlFont"]: !isSlim })}>
          {formatCurrency(data?.balance ?? 0)}
        </span>
      </p>
    </>
  );
};
