import { TOTAL_ORDERS_PER_PAGE } from "@/constants/export-orders";
import { getTicketById, getTicketsBySupplierId, TTicketDetail } from "@/http";
import { TUseTicket } from "@/models/tickets";
import { create } from "zustand";

export const useTicket = create<TUseTicket>((set, get) => ({
  setCurrentFilter: (currentFilter: string) => set(() => ({ currentFilter })),
  currentFilter: "",
  getTickets: async (supplierId: string) => {
    try {
      set({ isLoading: true });
      const { currentPage, currentFilter, sortBy, sortDirection } = get();
      const data = await getTicketsBySupplierId({
        search: `supplierId:${supplierId}${
          currentFilter ? `,status:${currentFilter}` : ""
        }`,
        size: TOTAL_ORDERS_PER_PAGE,
        page: currentPage,
        sort: `${sortBy},${sortDirection}`,
      });
      set({
        tickets: data.content,
        totalPages: data.totalPages,
        isLoading: false,
      });
    } catch (e) {
      set({
        tickets: [],
        totalPages: 0,
        isLoading: false,
      });
    }
  },

  isLoading: false,
  tickets: [],
  currentPage: 0,
  totalPages: 0,
  setCurrentPage: currentPage => set(() => ({ currentPage })),
  getDetailTicket: async id => {
    try {
      const response = await getTicketById(id);
      set({ selectedTicket: response });
    } catch (e) {
      set({ selectedTicket: {} as TTicketDetail });
    }
  },
  sortBy: "createdAt",
  sortDirection: "DESC",
  setSorting: (sortBy, sortDirection) => set(() => ({ sortBy, sortDirection })),
  isOpenModal: false,
  setOpenModal: (isOpenModal: boolean) => set({ isOpenModal }),
  selectedTicket: undefined,
  setSelectedTicket: (selectedTicket?: TTicketDetail) =>
    set({ selectedTicket }),
}));
