import confetti from "canvas-confetti";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Modal } from "../shared/Modal/Modal";
import styles from "./styles.module.scss";

export const SubscriptionResult = () => {
  const [t] = useTranslation("global");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("success") === "true") {
      const duration = 3 * 1000;
      const end = Date.now() + duration;

      (function frame() {
        confetti({
          disableForReducedMotion: true,
          particleCount: 7,
          angle: 60,
          spread: 200,
          origin: { x: 0 },
          startVelocity: 80,
        });
        confetti({
          disableForReducedMotion: true,
          particleCount: 7,
          angle: 120,
          spread: 200,
          origin: { x: 1 },
          startVelocity: 80,
        });
        if (Date.now() < end) {
          requestAnimationFrame(frame);
        }
      })();
    }
  }, []);

  if (!searchParams.has("success")) {
    return null;
  }

  const onClose = () => {
    searchParams.delete("success");
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <Modal isOpen onClose={onClose}>
      <div className={styles.result}>
        {searchParams.get("success") === "true" && (
          <p className={styles.true}>{t("SUBSCRIPTION.SUCCESS_TRUE")}</p>
        )}

        {searchParams.get("success") === "false" && (
          <p className={styles.false}>{t("SUBSCRIPTION.SUCCESS_FALSE")}</p>
        )}
        <br />
        <div>
          <button className="primary" onClick={onClose}>
            OK
          </button>
        </div>
      </div>
    </Modal>
  );
};
