import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { useAuth } from "@/store/auth";
import { CommercialModal } from "@/components/commercials/CommercialModal";
import { useCommercials } from "@/store/commercials";
import { CommercialsTable } from "@/components/commercials/CommercialsTable";
import { FilterTabs } from "@/components/app/ButtonFilterTabs/ButtonFilterTabs";
import { useSearchParams } from "react-router-dom";
import { RolesList } from "@/components/commercials/roles/RolesList";
import { RoleModal } from "@/components/commercials/roles/RoleModal";
import { useTranslation } from "react-i18next";
import { useSubscription } from "@/hooks/useSubscription";

export const COMMERCIALS_TABS = { USERS: "users", PROFILES: "profiles" };

export const CommercialsList = () => {
  const [t] = useTranslation("global");
  const { setTitle } = useHeaderTitle();
  const [search, setSearch] = useSearchParams("tab=users");
  const { user } = useAuth();
  const { getCommercials, commercials, isLoading, currentPage } =
    useCommercials();
  const tabName = search.get("tab") ?? "users";

  const onTabChange = (tab: string) => {
    const newSearch = new URLSearchParams(search);
    newSearch.set("tab", tab);
    setSearch(newSearch);
  };

  useEffect(() => {
    setTitle(t("SIDEBAR.COMMERCIALS"));
  }, [t]);

  useEffect(() => {
    getCommercials(user.id);
  }, [currentPage]);

  return (
    <>
      <FilterTabs
        options={[
          { text: t("COMMERCIALS.COMMERCIALS_TAB"), value: "users" },
          { text: t("COMMERCIALS.ROLES_TAB"), value: "profiles" },
        ]}
        currentFilter={tabName}
        action={onTabChange}
      />
      <div className="spacer" />
      {COMMERCIALS_TABS.USERS === tabName && (
        <div>
          <div className="contentInline smContentColumn">
            <StaffProgress />
            <CommercialModal />
          </div>
          {isLoading ? (
            <Spinner />
          ) : (
            <div>
              <div className="spacer" />
              <div className="spacer" />
              {commercials.length > 0 ? (
                <div className="tableScroll">
                  <CommercialsTable />
                </div>
              ) : (
                <h6 className="bolder">{t("NO_AVAILABLE_INFO")}</h6>
              )}
            </div>
          )}
        </div>
      )}
      {COMMERCIALS_TABS.PROFILES === tabName && (
        <div>
          <div className="contentInline smContentColumn">
            <div />
            <RoleModal />
          </div>
          {isLoading ? (
            <Spinner />
          ) : (
            <div>
              <div className="spacer" />
              <div className="spacer" />
              <RolesList />
            </div>
          )}
        </div>
      )}
    </>
  );
};

const StaffProgress = () => {
  const [t] = useTranslation("global");
  const { subscriptionValidations } = useSubscription();
  const validation = subscriptionValidations?.USERS;
  const isUnlimited = validation && !validation.maximum && validation.create;

  return (
    <div>
      {t("SUBSCRIPTION.CREATED_USERS")}:{" "}
      <span className="bold">
        {validation?.current ?? 0}{" "}
        {!isUnlimited && <>/ {validation?.maximum ?? 0}</>}
      </span>
    </div>
  );
};
