import { useAuth } from "@/store/auth";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ProductCreationForm } from "@/components/products/CreationV2/ProductCreationForm";

export const CreateProductVariantPage = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");
  const { refreshUserData } = useAuth();
  const methods = useForm({
    defaultValues: {
      images: [],
    } as any,
  });

  useEffect(() => {
    setTitle(t("CREATE_PRODUCT"), "/products/search");
  }, [t]);

  useEffect(() => {
    refreshUserData();
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        <ProductCreationForm />
      </FormProvider>
    </>
  );
};
