import { TSupplierRole } from "@/http";

export const hasPermission = (
  isCommercial: boolean,
  role: TSupplierRole | null,
  requiredPermissions: string[]
) => {
  if (!isCommercial) {
    return true;
  }

  if (!requiredPermissions.length) {
    return true;
  }

  return requiredPermissions.every(permission =>
    role?.permissions?.includes(permission)
  );
};
