import { getCurrentCountry, isCO } from "@/constants/core";
import { useProductsForSale } from "@/hooks/useProducts";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { createPurchase } from "@/http";
import { useAuth } from "@/store/auth";
import { useProducts } from "@/store/products";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DeliveryInfoSale } from "./DeliveryInfo/DeliveryInfoSale";
import { SaleProducts } from "./SaleProducts/SaleProducts";
import { SaleResume } from "./SaleResume/SaleResume";
import styles from "./styles.module.scss";
import { DisabledZone } from "@/components/shared/DisabledZone/DisabledZone";
import { WarehouseInfo } from "./WarehouseInfo/WarehouseInfo";
import { SaleProductsResult } from "./SaleProducts/SaleProductsResult";

export const SaleCreation = ({
  type = "SALE",
  disabled = false,
}: {
  type?: string;
  disabled?: boolean;
}) => {
  const [t] = useTranslation("purchases");
  const [tGlobal] = useTranslation("global");
  const { selectedProducts, resetSelectedProducts } = useProducts();
  const [search] = useSearchParams("from=orders");
  const [prepurchase, setPrepurchase] = useState<any>({});
  const [isLoading, setLoading] = useState(false);
  const { user, commercial, isCommercial } = useAuth();
  const navigate = useNavigate();
  const defaultValues: any = {
    sellerSearchType: "firstName",
    supplierId: user.id,
    useSameDirection: true,
    purchaseType: type,
    warehouseId: isCommercial ? commercial?.warehouseId ?? "" : "",
  };

  const methods = useForm({ defaultValues });
  const origin = search.get("from");
  const { data } = useProductsForSale(origin === "products", selectedProducts);
  const isQuotation = type === "QUOTE";

  const getProductStock = (
    productSupplier: any,
    warehouseId: string,
    unitRate: any
  ) => {
    const unitRateNumber = Number(unitRate);
    const warehouse = (productSupplier?.warehouses ?? []).find(
      (w: any) => w.warehouseId === warehouseId
    );

    return unitRate
      ? Number(productSupplier?.availableStock ?? 0) / unitRateNumber +
          (warehouse?.overStock
            ? Number(warehouse?.overStockAmount ?? 1000000) / unitRateNumber
            : 0)
      : Number(productSupplier?.availableStock ?? 0) +
          (warehouse?.overStock
            ? Number(warehouse?.overStockAmount ?? 1000000)
            : 0);
  };

  const onSubmit = async (values: any) => {
    try {
      setLoading(true);
      const isSale = values.purchaseType === "SALE";
      const dispatchWarehouse = values?.warehouseId ?? "";

      const productsMapped = (values?.products ?? [])?.map((p: any) => ({
        id: p?.id,
        supplierId: p?.productSupplier?.supplierId,
        quantity: p?.quantity,
        availableStock: getProductStock(
          p?.productSupplier,
          p?.dispatchWarehouse,
          p?.unitRate
        ),
        status: p?.productSupplier?.status,
        warehouseId: p?.dispatchWarehouse,
        supplierPrice: p?.productSupplier?.wholesalePrice,
        mandatoryDiscount: p?.mandatoryDiscount
          ? (
              ((Number(p?.productSupplier?.wholesalePrice) -
                Number(p?.mandatoryDiscount)) /
                Number(p?.productSupplier?.wholesalePrice)) *
              100
            ).toFixed(10)
          : undefined,
        unitPrice: p?.mandatoryDiscount,
        unitRate: p?.unitRate,
        unitType: p?.unitType,
      }));

      const products = isSale
        ? productsMapped?.filter((p: any) => {
            const unitRateNumber = p?.unitRate ? Number(p?.unitRate) : 1;
            if (dispatchWarehouse) {
              return (
                !!p?.id &&
                p.availableStock / unitRateNumber > 0 &&
                p.status &&
                p.warehouseId
              );
            } else {
              return (
                !!p?.id && p.availableStock / unitRateNumber > 0 && p.status
              );
            }
          })
        : productsMapped;

      if (!products?.length) {
        showToastError({ message: t("ADD_PRODUCTS") });
        return;
      }
      if (!values?.paymentMethod && !values?.coins) {
        showToastError({ message: t("ERROR_SOME_BAD") });
        return;
      }

      const payload = {
        ...values,
        products,
        sellerId: values?.seller?.id,
        supplierId: user.id,
        isForSeller: true,
        clientTarget: "own",
        userType: "SELLER",
        countryCode: getCurrentCountry(),
        shipping: values?.shippingPrice,
        voucherUrl: values?.voucherUrl,
        couponCode: values?.coupon,
      };

      payload.paymentStatus = values.isPaid === true ? "PAID" : null;
      payload.deliveryStatus = values.isDelivered === true ? "DELIVERED" : null;

      if (isCO) {
        payload.useSameDirection = true;
      }

      if (!isSale) {
        payload.isQuotation = true;
      }

      const purchase = await createPurchase(payload);
      resetSelectedProducts();
      showToastSuccess(t(isSale ? "PURCHASE_CREATED" : "QUOTE_CREATED"));
      navigate(`/purchases/${purchase.id}/show`);
    } catch (error) {
      showToastError({ message: tGlobal("ERROR_SOME_BAD"), error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    methods.setValue("products", data ?? []);
  }, [data]);

  return (
    <DisabledZone when={disabled}>
      <FormProvider {...methods}>
        <DeliveryInfoSale isQuote={isQuotation} />
        <div className="spacer" />
        <div className="spacer" />
        <SaleProducts />
        <div className="spacer" />
        <SaleProductsResult />
        <div className="spacer" />
        <div className="flexGap flexWrap flex1 flexSmColumn">
          <WarehouseInfo type={type} prepurchase={prepurchase} />
          <SaleResume
            setPrepurchase={setPrepurchase}
            prepurchase={prepurchase}
            setLoading={setLoading}
          />
        </div>
        {createPortal(
          <div className={styles.footer}>
            <div className="spacerVertical" />
            <button
              className="outlined md"
              onClick={() => navigate(-1)}
              disabled={isLoading}
            >
              {t("CANCEL")}
            </button>
            <button
              className="primary md"
              onClick={async () => {
                const isValid = await methods.trigger();
                if (isValid) {
                  onSubmit(methods.watch());
                }
              }}
              disabled={isLoading || disabled}
            >
              {isQuotation ? t("CREATE_QUOTE") : t("CREATE")}
            </button>
          </div>,
          document.body
        )}
      </FormProvider>
    </DisabledZone>
  );
};
