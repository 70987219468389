import { getSellerById } from "@/http";
import clsx from "clsx";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { Chip } from "./Chip";

type TClientNameChip = {
  id: string;
  onDelete?: () => void;
  hideDelete?: boolean;
  isBold?: boolean;
  isUnderline?: boolean;
};
export const ClientNameChip = ({
  id,
  onDelete,
  hideDelete = false,
}: TClientNameChip) => {
  const { data } = useSWR([id, "ProductIdChip"], getSellerById);

  if (!data) {
    return null;
  }
  return (
    <Chip>
      {`${data.firstName} ${data.lastName}`}
      {!hideDelete && <span onClick={() => onDelete?.()}>x</span>}
    </Chip>
  );
};

export const ClientName = ({ id, isBold, isUnderline }: TClientNameChip) => {
  const { data } = useSWR(id ? [id, "ClientName"] : null, getSellerById);

  if (!data) {
    return null;
  }
  return (
    <Link
      to={`/clients/${data.id}/show`}
      className={clsx({ ["bold"]: isBold, ["textUnderline"]: isUnderline })}
    >{`${data.firstName} ${data.lastName}`}</Link>
  );
};
