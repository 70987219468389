import { searchProducts, TProduct } from "@/http";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import { Controller, useFieldArray, useFormState } from "react-hook-form";
import clsx from "clsx";
import { AiOutlineDelete } from "react-icons/ai";
import { useAuth } from "@/store/auth";
import { Checkbox } from "@/components/shared/Input/Checkbox";
import { InputV2 } from "@/components/shared/Input/InputV2";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import localStyles from "./ProductGiftArrayInput.module.scss";
import { validateDecimalNumber } from "@/utils/keyboard";
import { ProductSelectOption } from "./ProductSelectOption";
import { TGlobalOfferGift } from "@/http/types/global-offers";
import { singleSelectStyles } from "@/constants/input";

export const ProductGiftArrayInput = () => {
  const [t] = useTranslation("global");
  const { user } = useAuth();
  const { errors } = useFormState<{ gifts: TGlobalOfferGift[] }>();
  const { fields, append, remove } = useFieldArray({
    name: "gifts",
    keyName: "id",
    rules: {
      minLength: { value: 1, message: t("LOGIN.FIELD_REQUIRED") },
      required: { value: true, message: t("LOGIN.FIELD_REQUIRED") },
    },
    shouldUnregister: true,
  });

  return (
    <>
      {fields.map((item, index) => (
        <div key={item.id} className={localStyles.inputs}>
          <div className={localStyles.productSelectContainer}>
            <p>{t("OFFERS.PRODUCT")}</p>
            <Controller
              name={`gifts.${index}.productId`}
              rules={{
                required: { value: true, message: t("LOGIN.FIELD_REQUIRED") },
              }}
              render={({ field }) => (
                <AsyncSelect
                  {...field}
                  placeholder={t("SELECT") + "..."}
                  cacheOptions
                  defaultOptions
                  loadOptions={text =>
                    searchProducts({
                      data: { text, supplierId: user.id },
                      params: {
                        sort: "name,asc",
                        page: 0,
                        size: 10,
                      },
                    }).then(data => data.content)
                  }
                  getOptionValue={product => product.id}
                  formatOptionLabel={(product, { context }) =>
                    context === "menu" ? (
                      <ProductSelectOption product={product} />
                    ) : (
                      product.name
                    )
                  }
                  isOptionDisabled={(product: TProduct) =>
                    product?.suppliers?.find(s => s.supplierId)?.status ===
                    false
                  }
                  styles={singleSelectStyles}
                />
              )}
            />
            <p className={localStyles.errorsLabel}>
              {errors.gifts?.[index]?.productId?.message?.toString()}
            </p>
          </div>
          <div>
            <InputV2
              name={`gifts.${index}.quantity`}
              label={t("OFFERS.QUANTITY")}
              onKeyPress={validateDecimalNumber}
              required
            />
          </div>
          <div>
            <Checkbox
              name={`gifts.${index}.invoiceIncluded`}
              label={t("OFFERS.INVOICE_INCLUDED")}
              direction="column"
            />
          </div>
          <div className="rigthAlign">
            <div className="spacer"></div>
            <ToolTip
              title={t("OFFERS.DELETE_OFFER")}
              position="Left"
              containerClass={clsx("statusColorHelper red", localStyles.remove)}
            >
              <AiOutlineDelete
                cursor="pointer"
                size={17}
                onClick={() => {
                  remove(index);
                }}
              />
            </ToolTip>
          </div>
        </div>
      ))}
      <div>
        <button
          type="button"
          className="secondary"
          onClick={() =>
            append({ productId: "", quantity: 0, invoiceIncluded: false })
          }
        >
          + {t("ADD")}
        </button>
        <p className={localStyles.errorsLabel}>
          {errors.gifts?.root?.message?.toString()}
        </p>
      </div>
    </>
  );
};
