import { FormOffer } from "@/components/offers/_form/FormOffer";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const CreateOfferPage = (): JSX.Element => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");

  useEffect(() => setTitle(t("OFFERS.CREATE_TITLE"), "/offers"), []);

  return (
    <div>
      <FormOffer />
    </div>
  );
};
