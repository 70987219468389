import { Modal } from "@/components/shared/Modal/Modal";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { getSeeriApi } from "@/http";
import { useAuth } from "@/store/auth";
import { useProducts } from "@/store/products";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const BulkActivation = () => {
  const [t] = useTranslation("products-page");
  const [tGlobal] = useTranslation("global");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAuth();
  const { getVariants } = useProducts();

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await getSeeriApi()
        .put(`/api/products/${user.id}/massive-update-status`, {
          status: true,
        })
        .then(() => {
          showToastSuccess(t("PRODUCTS_UPDATED"));
          getVariants(user.id);
          setIsOpen(false);
        });
    } catch (error) {
      showToastError({ message: tGlobal("ERROR_SOME_BAD"), error });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <button onClick={() => setIsOpen(true)} className="outlined">
        {t("BULK_CHARGE.ACTIVATION")}
      </button>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="sm">
        <h6 className="bolder">{t("BULK_CHARGE.ACTIVATION")}</h6>
        <div className="spacer" />
        <p>{t("BULK_ACTIVATION_CONFIRM")}</p>
        <div className="spacer" />
        <div className="spacer" />
        <div className="rigthAlign">
          <button disabled={isLoading} onClick={() => setIsOpen(false)}>
            {t("CANCEL")}
          </button>
          <button
            className="primary lg"
            disabled={isLoading}
            onClick={onSubmit}
          >
            {t("IM_SURE")}
          </button>
        </div>
      </Modal>
    </>
  );
};
