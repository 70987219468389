import { FC } from "react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";

import styles from "./rupee-title.module.scss";
import { VideoPlayer } from "./VideoPlayer";

type Props = {
  title: string;
  goBack?: string;
  videoId?: string;
  specificURL?: boolean;
};

const RupeeTitle: FC<Props> = ({ title, goBack, videoId, specificURL }) => {
  const navigate = useNavigate();

  return (
    <div className={`${styles.container}`}>
      <h1 className={`heading ${styles.heading}`}>
        {!!goBack &&
          (specificURL ? (
            <Link to={goBack} className="centerVAlign">
              <HiOutlineArrowNarrowLeft size={30} />
            </Link>
          ) : (
            <div onClick={() => navigate(-1)} className="centerVAlign">
              <HiOutlineArrowNarrowLeft size={30} />
            </div>
          ))}
        {title}
        {!!videoId && <VideoPlayer id={videoId} />}
      </h1>
    </div>
  );
};

export default RupeeTitle;
