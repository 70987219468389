import { useCustomAttributes } from "@/hooks/useCustomAttributes";
import {
  TCustomAttribute,
  TRecordCustomAttribute,
} from "@/http/types/custom-attribute";
import { validateOnlyNumber } from "@/utils/keyboard";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

type Props = {
  appliesTo: TCustomAttribute["appliesTo"];
  values: TRecordCustomAttribute[];
  onChange: (values: TRecordCustomAttribute[]) => void;
  disabled?: boolean;
};

export const RecordCustomAttributesTableInputs = ({
  values,
  onChange,
  appliesTo,
  disabled,
}: Props) => {
  const { customAttributes } = useCustomAttributes(appliesTo);
  const [t] = useTranslation("global");

  const getById = (id: number) => values.find(v => v.customAttributeId === id);

  if (!customAttributes) return null;

  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>{t("CUSTOM_ATTRIBUTES.MODEL.NAME")}</th>
            <th>{t("CUSTOM_ATTRIBUTES.MODEL.DATA_TYPE")}</th>
            <th>{t("CUSTOM_ATTRIBUTES.MODEL.MANDATORY")}</th>
            <th>{t("CUSTOM_ATTRIBUTES.MODEL.VISIBILITY")}</th>
            <th>{t("CUSTOM_ATTRIBUTES.MODEL.VISIBILE_IN")}</th>
            <th>{t("CUSTOM_ATTRIBUTES.TABLE.VALUE")}</th>
          </tr>
        </thead>
        <tbody>
          {customAttributes.map(attr => (
            <tr key={attr.id}>
              <td>{attr.name}</td>
              <td>{t(`CUSTOM_ATTRIBUTES.DATA_TYPES.${attr.dataType}`)}</td>
              <td>{attr.mandatory ? t("YES") : t("NO")}</td>
              <td>{attr.visible ? t("PUBLIC") : t("PRIVATE")}</td>
              <td>
                {attr.visible && attr?.visibleWhen
                  ? t(
                      `CUSTOM_ATTRIBUTES.VISIBILITY_${attr.visibleWhen.toUpperCase()}`
                    )
                  : "-"}
              </td>
              <td>
                {attr.dataType === "BOOLEAN" && (
                  <input
                    type="checkbox"
                    checked={getById(attr.id)?.value === "true"}
                    onChange={() =>
                      onChange(
                        customAttributes.map(cf => ({
                          customAttributeId: cf.id,
                          name: cf.name,
                          dataType: cf.dataType,
                          value:
                            cf.id === attr.id
                              ? getById(cf.id)?.value === "true"
                                ? "false"
                                : "true"
                              : getById(cf.id)?.value ?? "",
                          mandatory: cf.mandatory,
                        }))
                      )
                    }
                    disabled={disabled}
                  />
                )}

                {attr.dataType !== "BOOLEAN" && (
                  <input
                    type={
                      attr.dataType === "DATE"
                        ? "date"
                        : attr.dataType === "INTEGER" ||
                          attr.dataType === "DOUBLE"
                        ? "number"
                        : "text"
                    }
                    value={getById(attr.id)?.value ?? ""}
                    onChange={event =>
                      onChange(
                        customAttributes.map(cf => ({
                          customAttributeId: cf.id,
                          name: cf.name,
                          dataType: cf.dataType,
                          value:
                            cf.id === attr.id
                              ? event.target.value
                              : getById(cf.id)?.value ?? "",
                          mandatory: cf.mandatory,
                        }))
                      )
                    }
                    onKeyDown={
                      attr.dataType === "INTEGER"
                        ? validateOnlyNumber
                        : undefined
                    }
                    disabled={disabled}
                    required={attr.mandatory}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
