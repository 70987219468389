import { getInstance } from "../../core/axios-gateway";
import { TClientReportRequest, TClientReportResponse, TSupplierReport, TSupplierReportRequest } from "../../types/reports";

const BASE_PATH = "/api/reports";

export const getSupplierReports = async (params: Partial<TSupplierReportRequest>) => {
  return getInstance()
    .post<TSupplierReport>(BASE_PATH, params)
    .then((res) => res.data);
};

export const getClientReport = async (params: TClientReportRequest) => {
  return getInstance()
    .post<TClientReportResponse>(`${BASE_PATH}/client`, params)
    .then((res) => res.data);
};