import { usePayments } from "@/store/payments";
import styles from "./styles.module.scss";
import { get } from "lodash-es";
import { formatCurrency } from "@/utils/currency";
import {
  renderingPaymentReference,
  renderingPaymentStatus,
  renderingPaymentType,
} from "@/constants/payments";
import { ProductName } from "@/components/shared/Details";
import { Link } from "react-router-dom";
import { downloadSupplierPaymentById } from "@/http";
import { format } from "date-fns";
import { DATE_HOUR_NO_SPACES, USA_DATE_HOUR_NO_SPACES } from "@/constants/date";
import { showToastError } from "@/hooks/useToast";
import { useTranslation } from "react-i18next";
import { isUSA } from "@/constants/core";

export const PaymentDetail = () => {
  const { payment } = usePayments();
  const keys = Object.keys(payment?.transactions ?? {});
  const [t] = useTranslation("global");

  const downloadPayment = async () => {
    try {
      const response = await downloadSupplierPaymentById(payment.id);

      if (response) {
        const url = window.URL.createObjectURL(new Blob([response]));
        const a = document.createElement("a");
        a.href = url;
        a.download = `${t("PAYMENTS.PAYMENT")}_${
          payment?.paymentNumber
        }_${format(
          new Date(),
          isUSA ? USA_DATE_HOUR_NO_SPACES : DATE_HOUR_NO_SPACES
        )}.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        showToastError({ message: t("PAYMENTS.ERROR_DOWNLOAD_FILE") });
      }
    } catch (error) {
      showToastError({
        message: t("PAYMENTS.ERROR_DOWNLOAD_FILE"),
        error,
      });
    }
  };

  if (!payment) {
    return null;
  }

  return (
    <>
      <div className="contentInline">
        <span
          className={`${styles.textStatus} ${
            payment.status !== "PAID" ? styles.red : ""
          }`}
        >
          {t("PAYMENTS.STATUS")}:{" "}
          {get(renderingPaymentStatus, payment?.status, payment?.status)}
        </span>
        <button className="primary" onClick={downloadPayment}>
          {t("DOWNLOAD")}
        </button>
      </div>
      <div className="spacer" />
      <p className="bold">
        {t("PAYMENTS.TOTAL_TO_PAY")}: {formatCurrency(payment.total)}
      </p>
      <div className="spacer" />
      <div className="spacer" />
      {keys.map((key, index) => (
        <div key={index}>
          <p className="bold">
            {get(payment, `transactions.${key}[0].subPurchaseId`, "") ? (
              <Link
                to={`/orders/${get(
                  payment,
                  `transactions.${key}[0].subPurchaseId`,
                  ""
                )}`}
                className="textUnderline"
              >
                {t("PAYMENTS.DISPATCH")} {key}
              </Link>
            ) : (
              <>
                {t("PAYMENTS.DISPATCH")} {key}
              </>
            )}
          </p>
          <div className="scrollX">
            <table className={styles.table}>
              <thead>
                <tr className={`bold ${styles.thead} ${styles.row}`}>
                  <th>{t("PAYMENTS.TYPE")}</th>
                  <th>{t("PAYMENTS.REFERENCE")}</th>
                  <th>{t("PAYMENTS.SKU")}</th>
                  <th>{t("PAYMENTS.SELL_PRICE")}</th>
                  <th>{t("PAYMENTS.QUANTITY")}</th>
                  <th>{t("PAYMENTS.SUBTOTAL")}</th>
                </tr>
              </thead>
              <tbody>
                {get(payment, `transactions.${key}`, []).map(
                  (transaction: any, idx: number) => (
                    <tr key={idx} className={` ${styles.row}`}>
                      <td>{get(renderingPaymentType, transaction.type, "")}</td>
                      <td>
                        {transaction.type === "PRODUCT_SELL" ? (
                          <ProductName id={transaction.reference} />
                        ) : (
                          get(
                            renderingPaymentReference,
                            transaction.reference,
                            transaction.reference
                          )
                        )}
                      </td>
                      <td>{get(transaction, "data.sku", "")}</td>
                      <td>
                        {get(transaction, "data.sellPrice", "")
                          ? formatCurrency(get(transaction, "data.sellPrice"))
                          : ""}
                      </td>
                      <td>{get(transaction, "data.quantity", "")}</td>
                      <td>
                        {formatCurrency(get(transaction, "data.subTotal", 0))}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
          <div className="spacer" />
          <div className="spacer" />
        </div>
      ))}
    </>
  );
};
