import { useTicket } from "@/store/ticket";
import { TextArea } from "@/components/shared/Input/TextArea";
import styles from "./styles.module.scss";
import { FormProvider, useForm } from "react-hook-form";
import { Select } from "@/components/shared/Input/Select";
import { useState } from "react";
import { TTicketDetail, TicketStatusEnum, updateTicket } from "@/http";
import { ticketStatusResponse } from "@/constants/tickets";
import { useAuth } from "@/store/auth";
import { useTranslation } from "react-i18next";
import { showToastError } from "@/hooks/useToast";

export const TicketUpdateResponse = () => {
  const { setOpenModal, selectedTicket, getTickets } = useTicket();
  const { user } = useAuth();
  const [isLoading, setLoading] = useState(false);
  const methods = useForm<any>({
    defaultValues: {
      comment: selectedTicket?.comment,
      status: selectedTicket?.status,
    },
  });
  const { handleSubmit, watch } = methods;
  const status = watch("status");
  const [t] = useTranslation("global");

  const enableEditing =
    selectedTicket!.status === TicketStatusEnum.PENDING &&
    selectedTicket?.userId !== user.id;

  const showRefundType = status === TicketStatusEnum.APPROVED;

  const onSubmit = async (values: any) => {
    try {
      setLoading(true);
      const data: Partial<TTicketDetail> & { approvedReturned: any } = {
        status: values.status,
        comment: values.comment,
        approvedReturned: {},
      };

      await updateTicket(selectedTicket!.id, data);
      getTickets(user.id);
      setLoading(false);
      setOpenModal(false);
    } catch (error) {
      showToastError({ message: t("ERROR_SOME_BAD"), error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="false">
        <div className={`row ${styles.containerEvidences}`}>
          <div className="col-sm-12 col-5">
            <Select
              disabled={!enableEditing}
              register={methods.register}
              name="status"
              label={t("TICKETS.SUPPLIER_RESPONSE")}
              choices={ticketStatusResponse}
              required
              error={methods.formState.errors.customerArticles}
            />
          </div>
          <div className="col-sm-12 col-7">
            <TextArea
              name="comment"
              label=""
              required={!showRefundType}
              disabled={!enableEditing}
              placeholder={t("TICKETS.WRITE_COMMENT")}
              rows={6}
            />
          </div>
          {enableEditing ? (
            <div className="col-12 rigthAlign">
              <button
                disabled={isLoading}
                onClick={() => {
                  methods.reset();
                  setOpenModal(false);
                }}
              >
                {t("TICKETS.CANCEL")}
              </button>
              <button className="primary lg" disabled={isLoading}>
                {t("TICKETS.SAVE_CHANGES")}
              </button>
              <div className="spacer" />
            </div>
          ) : null}
        </div>
      </form>
    </FormProvider>
  );
};
