import { invoiceNoStyles } from "@/components/invoices/components/Styles";
import { isUSA } from "@/constants/core";
import { addressToString } from "@/utils/address";
import { formatCurrency } from "@/utils/currency";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { Fragment } from "react";

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 0,
    width: "50%",
    display: "flex",
    flexDirection: "row",
  },
  billTo: {
    marginTop: 0,
    paddingBottom: 3,
    fontFamily: "Helvetica-Oblique",
  },
  supplierInfoContainer: {
    width: "100%",
  },

  detailItem: {
    backgroundColor: "#edf2f7",
    padding: "5px 3px",
  },
  mdFont: {
    fontSize: "11px",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  font: {
    fontSize: "11px",
    fontWeight: "bold",
  },
  lgFont: {
    fontSize: "15px",
  },
  bold: {
    fontWeight: "heavy",
  },
});

const stylesDetail = StyleSheet.create(invoiceNoStyles);

export const SellerInfo = ({
  seller,
  translate: t,
  addresses,
}: {
  seller: any;
  translate: any;
  addresses: any;
}) => {
  const mainAddress =
    addresses?.content?.find((address: any) => address?.isMain) ||
    addresses?.[0];
  return (
    <>
      <View style={styles.headerContainer}>
        <div style={styles.supplierInfoContainer}>
          <Text style={styles.mdFont}>{t("CREDIT_EXTRACT")}</Text>
          <Text style={styles.lgFont}>
            {isUSA
              ? `${seller?.legalName ?? ""} - ${seller?.firstName ?? ""}`
              : `${seller?.firstName ?? ""} ${seller?.lastName ?? ""}`}
          </Text>

          <Text>
            <Text style={styles.bold}>{t("EMAIL") + ": "}</Text>
            {seller?.email}
          </Text>
          <Text>
            <Text style={styles.bold}>{t("ADDRESS") + ": "}</Text>
            {addressToString(mainAddress)}
          </Text>
        </div>
      </View>
    </>
  );
};

export const ExtractInfoPDF = ({
  translate,
  credit,
}: {
  translate: any;
  credit: any;
}) => {
  return (
    <Fragment>
      <View style={stylesDetail.invoiceHeaderContainer}>
        <View style={stylesDetail.invoiceDateContainer}>
          <Text style={stylesDetail.label}>
            <Text>{translate("EXPEDITION_DATE") + ": "}</Text>
            {new Date().toLocaleDateString?.()}
          </Text>
        </View>
        <View>
          <View style={styles.detailItem}>
            <Text style={styles.font}>{translate("CREDITS.TABLE.AMOUNT")}</Text>
            <Text style={styles.mdFont}>
              {formatCurrency(credit.amount || 0)}
            </Text>
            <Text> </Text>
            <Text style={styles.font}>
              {translate("CREDITS.TABLE.BALANCE")}
            </Text>
            <Text>{formatCurrency(credit.balance || 0)}</Text>
            <Text> </Text>
            <Text style={styles.font}>
              {translate("CREDITS.TABLE.REMAINING")}
            </Text>
            <Text>
              {formatCurrency((credit?.amount || 0) - (credit?.balance || 0))}
            </Text>
          </View>
        </View>
      </View>
    </Fragment>
  );
};
