import { OffersList } from "@/components/offers/list/OffersList";
import { useSubscription } from "@/hooks/useSubscription";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LockedFeaturePlaceholder } from "../../components/subscription/LockedFeaturePlaceholder";
import { FEAT_OFFERS } from "@/constants/features";

export const OffersPage = (): JSX.Element => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");
  const { hasFeature } = useSubscription();

  useEffect(() => setTitle(t("OFFERS.TITLE")), []);

  if (!hasFeature(FEAT_OFFERS)) {
    return <LockedFeaturePlaceholder />;
  }

  return (
    <div>
      <OffersList />
    </div>
  );
};
