import { BannersList } from "@/components/banners/BannersList";
import { Card } from "@/components/shared/Card/Card";
import { LockedFeaturePlaceholder } from "@/components/subscription/LockedFeaturePlaceholder";
import { FEAT_CATALOG } from "@/constants/features";
import { useSubscription } from "@/hooks/useSubscription";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const CustomizePage = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");
  const { hasFeature } = useSubscription();

  useEffect(() => {
    setTitle(t("SIDEBAR.CUSTOMIZE"), "", "");
  }, [t]);

  if (!hasFeature(FEAT_CATALOG)) {
    return <LockedFeaturePlaceholder />;
  }

  return (
    <Card borderTop className="noPadding">
      <div className="pw2 ph1">
        <BannersList />
      </div>
    </Card>
  );
};
