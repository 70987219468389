import { Modal } from "@/components/shared/Modal/Modal";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { useAvailablePaymentMethods } from "@/hooks/usePurchaseProducts";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import {
  TRequestPurchasePaymentMethod,
  updatePurchasePaymentMethod,
} from "@/http";
import { useAuth } from "@/store/auth";
import { usePurchase } from "@/store/purchase";
import { isFinishedPurchase } from "@/utils/actions-disabled";
import { hasPermission } from "@/utils/validations/permissions";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MdOutlineEdit } from "react-icons/md";
import { TPurchaseShow } from "../PurchaseShow";
import { PaymentMethodsGroup } from "../../PurchaseCreateForm/PaymentMethodsGroup/PaymentMethodsGroup";

export const PurchaseUpdatePaymentMethod = ({ purchase }: TPurchaseShow) => {
  const methods = useForm<TRequestPurchasePaymentMethod>({});
  const [isLoading, setLoading] = useState(false);
  const paymentMethods = useAvailablePaymentMethods(purchase.id);
  const [isOpen, setOpen] = useState(false);
  const [t] = useTranslation("purchases");
  const [tGlobal] = useTranslation("global");
  const { getPurchase } = usePurchase();
  const purchaseId = purchase?.id;
  const paid = purchase?.paymentStatus === "PAID";
  const { isCommercial, role } = useAuth();

  const onSubmit = async (values: TRequestPurchasePaymentMethod) => {
    try {
      setLoading(true);
      await updatePurchasePaymentMethod(purchaseId as string, values);
      showToastSuccess(t("PAYMENT_METHOD_UPDATED"));
      getPurchase(purchaseId);
      setLoading(false);
      setOpen(false);
    } catch (error: any) {
      setLoading(false);
      setOpen(false);
      showToastError({ message: tGlobal("ERROR_SOME_BAD"), error });
    }
  };

  if (
    isFinishedPurchase(purchase) ||
    paid ||
    !hasPermission(isCommercial, role, ["action.purchase.payment-method"])
  ) {
    return null;
  }
  return (
    <>
      <ToolTip title={t("UPDATE_PAYMENT_METHOD")} position="Left">
        <span className="pointer circleButton" onClick={() => setOpen(true)}>
          <MdOutlineEdit />
        </span>
      </ToolTip>
      <Modal
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        size={"sm"}
        title={`${t("UPDATE_PAYMENT_METHOD")}`}
        footer={
          <button
            className="primary lg"
            disabled={isLoading}
            onClick={methods.handleSubmit(onSubmit)}
          >
            {t("UPDATE")}
          </button>
        }
      >
        <FormProvider {...methods}>
          {paymentMethods && (
            <PaymentMethodsGroup
              paymentMethods={paymentMethods}
              isEdit={true}
              paymentMethod={purchase.paymentMethod}
              paymentProvider={purchase?.paymentProvider}
            />
          )}
        </FormProvider>
      </Modal>
    </>
  );
};
