import { SaleCreation } from "@/components/purchases/SaleCreation/SaleCreation";
import { PremiumFeatureIndicator } from "@/components/subscription/PremiumFeatureIndicator";
import { FEAT_PURCHASES } from "@/constants/features";
import { useSubscription } from "@/hooks/useSubscription";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const PurchasesCreatePage = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");
  const { subscriptionValidations } = useSubscription();
  const validation = subscriptionValidations?.PURCHASES;

  useEffect(() => {
    setTitle(t("PURCHASES.CREATE.TITLE"), "/purchases");
  }, [t]);

  return (
    <>
      <PurchasesProgress />
      <SaleCreation disabled={validation?.create === false} />
    </>
  );
};

const PurchasesProgress = () => {
  const [t] = useTranslation("global");
  const { subscriptionValidations } = useSubscription();
  const validation = subscriptionValidations?.PURCHASES;
  const isUnlimited = validation && !validation.maximum && validation.create;

  return (
    <div className="flexGap mbmd">
      <span>{t("SUBSCRIPTION.PURCHASES_PROGRESS")}:</span>
      <span className="purpleText bold">
        {validation?.current ?? 0}{" "}
        {!isUnlimited && <>/ {validation?.maximum ?? 0}</>}
      </span>
      <PremiumFeatureIndicator feature={FEAT_PURCHASES} />
    </div>
  );
};
