const PENDING = "PENDING";
const SUPPLIER_PENDING = "SUPPLIER_PENDING";
const IN_TRANSIT = "IN_TRANSIT";
const DONE = "DONE";
const CANCELLED = "CANCELLED";
const EXPIRED = "EXPIRED";
const REJECTED = "REJECTED";
const FAILED = "FAILED";
const PREPARATION = "PREPARATION";
const DELIVERED = "DELIVERED";
const FAILED_TO_SEND = "FAILED_TO_SEND";
const REVIEW = "REVIEW";
const PAID = "PAID";
const PICKUP_FROM_SUPPLIER = "PICKUP_FROM_SUPPLIER";
const SHARED = "SHARED";
const OPENED = "OPENED";
const NO_LINK = "NO_LINK";
export const QUOTED = "QUOTED";
export const Status = {
  PENDING,
  IN_TRANSIT,
  DONE,
  CANCELLED,
  EXPIRED,
  REJECTED,
  FAILED,
  QUOTED,
};
export type TPurchaseStatus = keyof typeof Status;

export const DeliveryStatus = {
  PENDING,
  PREPARATION,
  IN_TRANSIT,
  DELIVERED,
  REJECTED,
  CANCELLED,
  FAILED_TO_SEND,
  SUPPLIER_PENDING,
};
export type TPurchaseDeliveryStatus = keyof typeof DeliveryStatus;

export const PaymentStatus = {
  PENDING,
  REVIEW,
  PAID,
  FAILED,
  EXPIRED,
  CANCELLED,
  PICKUP_FROM_SUPPLIER,
};
export type TPurchasePaymentStatus = keyof typeof PaymentStatus;

export const LinkStatus = {
  PENDING,
  SHARED,
  OPENED,
  EXPIRED,
  NO_LINK,
};
export type TPurchaseLinkStatus = keyof typeof LinkStatus;
