import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import InvoiceTableHeader from "./InvoiceTableHeader";
import InvoiceTableRow from "./InvoiceTableRow";
import { TInvoice } from "../invoiceData";
import { invoiceItemsStyle } from "./Styles";

type Props = {
  invoice: TInvoice;
  translate: any;
  forPOS?: boolean;
};

const styles = StyleSheet.create(invoiceItemsStyle);

const InvoiceItemsTable = ({ translate, invoice, forPOS }: Props) => {
  const showUnitTypes = (invoice?.items ?? []).some(i => !!i.unitType);
  return (
    <View style={styles.tableContainer}>
      <InvoiceTableHeader
        translate={translate}
        hasDiscount={!!invoice.discount}
        showPrices={invoice.showPrices}
        forPOS={forPOS}
        showUnitTypes={showUnitTypes}
      />
      <InvoiceTableRow
        items={invoice.items}
        hasDiscount={!!invoice.discount}
        showPrices={invoice.showPrices}
        forPOS={forPOS}
        showUnitTypes={showUnitTypes}
      />
    </View>
  );
};

export default InvoiceItemsTable;
