import { View, StyleSheet, Text } from "@react-pdf/renderer";
import { deliverySign } from "./Styles";

const styles = StyleSheet.create(deliverySign);

export const DeliverySign = ({ translate }: { translate: any }) => {
  return (
    <View style={styles.signContainer}>
      <View style={styles.container}>
        <Text>{translate("DELIVERED_BY")}: _____________________________</Text>
      </View>
      <View style={styles.container}>
        <Text>{translate("RECEIVED_BY")}: _____________________________</Text>
      </View>
    </View>
  );
};
