export const ExportOrdersHeaders = [
  {
    id: "subPurchaseNumber",
    displayName: "ID",
  },
  {
    id: "createdAt",
    displayName: "Date",
  },
  {
    id: "supplierStatus",
    displayName: "Status",
  },
];

export const TOTAL_ORDERS_PER_PAGE = 10;
export const MAX_ORDERS_PER_PAGE = 9999;
