import { CreditExtratPDF } from "@/components/creditTransactions/extractPDF/CreditExtractPDF";
import { LockedFeaturePlaceholder } from "@/components/subscription/LockedFeaturePlaceholder";
import { FEAT_CREDIT } from "@/constants/features";
import { useSubscription } from "@/hooks/useSubscription";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

export const CreditExtractPage = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");
  const { hasFeature } = useSubscription();
  const { id } = useParams();

  useEffect(() => {
    setTitle(
      t("CREDITS.TITLE_EXTRACT"),
      `/credits/${id}/transactions`,
      "",
      null,
      true
    );
  }, [t]);

  if (!hasFeature(FEAT_CREDIT)) {
    return <LockedFeaturePlaceholder />;
  }

  return (
    <>
      <CreditExtratPDF />
    </>
  );
};
