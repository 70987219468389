import { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { Modal } from "@/components/shared/Modal/Modal";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import styles from "./styles.module.scss";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { TClient, TSeller, unlinkSupplierSeller } from "@/http";
import { useAuth } from "@/store/auth";
import { useTranslation } from "react-i18next";

type TClientDelete = {
  client: TClient | TSeller;
  onDone: () => void;
};
export const ClientDelete = ({ client, onDone }: TClientDelete) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const [t] = useTranslation("global");

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await unlinkSupplierSeller(client.id, user.id);
      setIsLoading(false);
      setModalOpen(false);
      onDone();
      showToastSuccess(t("CLIENTS.CLIENT_DELETED"));
    } catch (error) {
      showToastError({ message: t("ERROR_SOME_BAD"), error });
    }
  };

  return (
    <>
      <ToolTip title={t("CLIENTS.DELETE_CLIENT")} position="Left">
        <AiOutlineDelete
          cursor="pointer"
          onClick={() => setModalOpen(true)}
          size={"1.3rem"}
        />
      </ToolTip>
      <Modal isOpen={isModalOpen} size="sm" onClose={() => setModalOpen(false)}>
        <div className={styles.modal}>
          <h6 className="bold">{t("CLIENTS.DELETE_CLIENT")}</h6>
          <div className={"divider"} />
          <div className={styles.content}>
            <p>{`${t("CLIENTS.SURE_DELETE_CLIENT")} ${client.firstName} ${
              client.lastName
            }?`}</p>
            <div className={"spacer"} />
            <div className={"spacer"} />
          </div>
          <div className={"divider"} />
          <div className="rigthAlign">
            <button
              className="primary lg"
              disabled={isLoading}
              onClick={onSubmit}
            >
              {t("IM_SURE")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
