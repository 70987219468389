import { InputV2 } from "@/components/shared/Input/InputV2";
import { TextArea } from "@/components/shared/Input/TextArea";
import { Modal } from "@/components/shared/Modal/Modal";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { showToastError } from "@/hooks/useToast";
import {
  savePaymentProvider,
  TPaymentProvider,
  updatePaymentProvider,
} from "@/http";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiOutlineEdit, AiOutlineFileAdd } from "react-icons/ai";

type Props = {
  paymentProvider?: TPaymentProvider;
  iconButton?: boolean;
  onDone?: (d: TPaymentProvider) => void;
};

export const PaymentProviderForm = ({
  paymentProvider,
  iconButton,
  onDone,
}: Props) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm<TPaymentProvider>({ defaultValues: paymentProvider });
  const onSuccess = (r: TPaymentProvider) => {
    methods.reset();
    setIsLoading(false);
    setIsOpen(false);
    onDone?.(r);
  };
  const onSubmit = (values: any) => {
    setIsLoading(true);
    if (paymentProvider) {
      updatePaymentProvider(paymentProvider.id, values)
        .then(onSuccess)
        .catch(showToastError)
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      savePaymentProvider(values)
        .then(onSuccess)
        .catch(showToastError)
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);

    if (isOpen) {
      document.body.classList.add("noScroll");
    } else {
      document.body.classList.remove("noScroll");
    }

    return () => {
      document.body.classList.remove("noScroll");
      window.removeEventListener("resize", handleResize);
    };
  }, [open]);
  return (
    <>
      <button
        onClick={() => {
          setIsOpen(true);
        }}
        className={"primary"}
      >
        {!iconButton && !isSmallScreen && (
          <span>+ {t("PAYMENT_PROVIDERS.CREATE_PROVIDER")}</span>
        )}
        {(iconButton || isSmallScreen) && (
          <span>
            {paymentProvider ? (
              <AiOutlineEdit size={"1.0rem"} />
            ) : (
              <AiOutlineFileAdd size={"1.1rem"} />
            )}
          </span>
        )}
      </button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          methods.reset();
        }}
        title={t(
          paymentProvider
            ? "PAYMENT_PROVIDERS.EDIT_PROVIDER"
            : "PAYMENT_PROVIDERS.CREATE_PROVIDER"
        )}
        footer={
          <button
            type="button"
            onClick={methods.handleSubmit(onSubmit)}
            disabled={isLoading}
            className="primary lg"
          >
            {!isLoading && t("SAVE")} {isLoading && <Spinner />}
          </button>
        }
      >
        <FormProvider {...methods}>
          <InputV2
            name="code"
            label={t("PAYMENT_PROVIDERS.CODE")}
            required
            infoLabel={t("PAYMENT_PROVIDERS.CODE_SUGGESTION")}
          />
          <TextArea
            infoLabel={t("PAYMENT_PROVIDERS.DESCRIPTION_SUGGESTION")}
            maxLength={250}
            name="description"
            label={t("PAYMENT_PROVIDERS.DESCRIPTION")}
            rows={3}
          />
        </FormProvider>
      </Modal>
    </>
  );
};
