import styles from "./styles.module.scss";
import { FormProvider, useForm } from "react-hook-form";
import { TextArea } from "@/components/shared/Input/TextArea";
import { useAuth } from "@/store/auth";
import { useOrderDetails } from "@/store/order-detail";
import { TSubPurchaseComment } from "@/http";
import { useTranslation } from "react-i18next";

export const CreateComment = ({ orderId }: { orderId: string }) => {
  const [t] = useTranslation("orders-page");
  const methods = useForm<any>();
  const { user } = useAuth();
  const { createComment, isLoadingComment } = useOrderDetails();

  const { handleSubmit, reset } = methods;

  const onSubmit = (data: any) => {
    const newComment: TSubPurchaseComment = {
      ...data,
      fullName: user.name,
    };

    createComment(orderId, newComment);
    reset();
  };

  return (
    <div className="col-4 col-sm-12 noPadding">
      <div className={`${styles.header}`}>
        <span className="bold">{t("ADD_COMMENT")}</span>
      </div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="false">
          <TextArea
            name="message"
            label={t("WRITE_COMMENT")}
            placeholder=""
            required
            rows={6}
          />
          <div className="rigthAlign">
            <button
              type="submit"
              className="primary"
              disabled={isLoadingComment}
            >
              {t("SAVE_COMMENT")}
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
