import { TUnitType } from "@/http";
import { useTranslation } from "react-i18next";
import { BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";
import { DeleteUnitType } from "./DeleteUnitType";
import styles from "./styles.module.scss";
import { UnitTypeModal } from "./UnitTypeModal";

type TUnitTypesTable = {
  unitTypes: TUnitType[];
};
export const UnitTypesTable = ({ unitTypes }: TUnitTypesTable) => {
  const [t] = useTranslation("global");
  return (
    <div className="tableScroll">
      <table className={styles.table}>
        <thead>
          <tr className={styles.thead}>
            <th>{t("NAME")}</th>
            <th>{t("RATE")}</th>
            <th>{t("DEFAULT")}</th>
            <th>{t("ACTIONS")}</th>
          </tr>
        </thead>
        <tbody>
          {unitTypes.map(u => (
            <tr key={u.id} className={styles.row}>
              <td>{u.name}</td>
              <td>{u.rate}</td>
              <td>
                {u.isDefault ? (
                  <span className="greenText">
                    <BsFillCheckCircleFill />
                  </span>
                ) : (
                  <span className="redText">
                    <BsFillXCircleFill />
                  </span>
                )}
              </td>
              <td>
                <div className="flexGap centerAlign">
                  <UnitTypeModal unitType={u} />
                  {!u.isDefault && <DeleteUnitType unitType={u} />}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
