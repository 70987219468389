import {
  getAddressListBySellerId,
  getCredit,
  getCreditTransactions,
  getSellerById,
} from "@/http";
import {
  Document,
  Page,
  View,
  Text,
  PDFViewer,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { useParams } from "react-router";
import useSWR from "swr";
import {
  invoiceItemsStyle,
  invoiceStyles,
} from "../../invoices/components/Styles";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { isUSA } from "@/constants/core";
import {
  DATE_MONT_YEAR_SLASH,
  USA_DATE_MONT_YEAR_SLASH,
} from "@/constants/date";
import { formatCurrency } from "@/utils/currency";
import { ExtractInfoPDF, SellerInfo } from "./SellerInfoPDF";
import { Spinner } from "@/components/shared/Spinner/Spinner";

const styles = StyleSheet.create(invoiceItemsStyle);
const stylesPDF = StyleSheet.create(invoiceStyles);

export const CreditExtratPDF = () => {
  const [t] = useTranslation("global");
  const { id: creditId } = useParams();
  const params: any = {
    size: 100,
    page: 0,
  };

  const { data } = useSWR(
    [creditId, params, "getCreditTransactions"],
    getCreditTransactions
  );

  const { data: credit } = useSWR([creditId, "getCreditById"], getCredit);
  const { data: seller } = useSWR(
    [credit?.sellerId, "getSellerById"],
    getSellerById
  );

  const { data: addresses } = useSWR(
    [credit?.sellerId, {}, "getAddressesSellerById"],
    getAddressListBySellerId
  );

  if (!seller || !credit || !data || !addresses) {
    return <Spinner />;
  }

  return (
    <>
      <div className={`col-sm-6`}>
        <PDFDownloadLink
          document={
            <DocumentPDF
              data={data}
              translate={t}
              seller={seller}
              addresses={addresses}
              credit={credit}
            />
          }
        >
          <button className="primary lg">{t("DOWNLOAD")}</button>
        </PDFDownloadLink>
      </div>
      <PDFViewer width="100%" height="700">
        <DocumentPDF
          data={data}
          translate={t}
          seller={seller}
          addresses={addresses}
          credit={credit}
        />
      </PDFViewer>
    </>
  );
};

const DocumentPDF = ({
  data,
  translate: t,
  seller,
  addresses,
  credit,
}: {
  data: any;
  translate: any;
  seller: any;
  addresses: any;
  credit: any;
}) => {
  return (
    <>
      <Document title={`${t("CREDIT_EXTRACT")} - ${credit.id}`}>
        <Page size="LETTER" style={stylesPDF.page}>
          <View style={stylesPDF.headerContainer}>
            <SellerInfo seller={seller} translate={t} addresses={addresses} />
            <ExtractInfoPDF translate={t} credit={credit} />
          </View>
          <View style={styles.tableContainerExtract}>
            <View style={styles.container}>
              <Text style={styles.extractMovement}>{t("DATE")}</Text>
              <Text style={styles.extractMovement}>{t("STATUS")}</Text>
              <Text style={styles.extractMovement}>{t("AMOUNT")}</Text>
            </View>

            {data?.content?.map((item: any) => (
              <View style={styles.row} key={item.id}>
                <Text style={styles.extractMovement}>
                  {format(
                    new Date(item.createdAt),
                    isUSA ? USA_DATE_MONT_YEAR_SLASH : DATE_MONT_YEAR_SLASH
                  )}
                </Text>
                <Text style={styles.extractMovement}>{item.status}</Text>
                <Text style={styles.extractMovement}>
                  {formatCurrency(item.amount)}
                </Text>
              </View>
            ))}
          </View>
        </Page>
      </Document>
    </>
  );
};
