import { RadioGroupV2 } from "@/components/shared/RadioGroup/RadioGroupV2";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { formatCurrency } from "@/utils/currency";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RiErrorWarningLine } from "react-icons/ri";
import { TPaymentMethod, TPaymentProvider } from "@/http";
import { ErrorsTooltip } from "./ErrorsTooltip";
import { PaymentProvidersGroup } from "./PaymentProvidersGroup";
import { CreditCreation } from "./CreditCreation";
import { Checkbox } from "@/components/shared/Input/Checkbox";
import { UploadVoucher } from "./UploadVoucher";
import { hasPermission } from "@/utils/validations/permissions";
import { useAuth } from "@/store/auth";
import { NumericInput } from "@/components/shared/Input/NumericInput";
type TPaymentMethodsGroup = {
  paymentMethods: TPaymentMethod[];
  isEdit?: boolean;
  paymentMethod?: string;
  paymentProvider?: string;
  coinsBalance?: number;
  maxCoins?: number;
};

export const PaymentMethodsGroup = ({
  paymentMethods: paymentMethodList,
  isEdit,
  paymentProvider,
  coinsBalance = 0,
  maxCoins = 0,
}: TPaymentMethodsGroup) => {
  const { isCommercial, role } = useAuth();
  const { setValue, watch } = useFormContext();

  const [t] = useTranslation("purchases");
  const [globalT] = useTranslation("global");
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [paymentProviders, setPaymentProviders] = useState<any[]>([]);
  const paymentMethodWatch = watch("paymentMethod");
  const paymentProviderWatch = watch("paymentProvider");
  const purchaseType = watch("purchaseType");

  const buildPaymentProviders = () => {
    const paymentProvidersFilter =
      paymentMethods?.find(p => p.value === paymentMethodWatch)?.providers ||
      [];
    const choisesProviders = paymentProvidersFilter?.map(
      (pp: TPaymentProvider) => ({
        label: pp?.code,
        value: pp?.code,
        disabled: paymentProvidersFilter.length === 1 || !pp?.isValid,
        isValid: pp?.isValid,
        errors: pp?.errors,
      })
    );
    setPaymentProviders(choisesProviders);
    if (choisesProviders.length === 1) {
      setValue("paymentProvider", choisesProviders[0].value);
    } else if (
      !paymentProvidersFilter
        .map((p: TPaymentProvider) => p.code)
        .includes(paymentProviderWatch)
    ) {
      setValue("paymentProvider", null);
    }
  };

  useEffect(() => {
    const choices =
      paymentMethodList
        ?.filter(p => p.code !== "PARTIAL_PAYMENT")
        ?.map(paymentMethod => ({
          label: (
            <>
              {`${globalT(`PAYMENT_METHOD.${paymentMethod.code}`)}${
                Number(paymentMethod?.availableAmount) > 0 &&
                paymentMethod.providers.length == 1
                  ? ` (${t("AVAILABLE_AMOUNT")}: ${formatCurrency(
                      Number(paymentMethod?.availableAmount)
                    )})`
                  : ""
              }`}
              {paymentMethod.isValid ? null : (
                <ErrorsTooltip errors={paymentMethod?.errors} />
              )}
              {!isEdit &&
                paymentMethod.code === "CREDIT" &&
                !paymentMethod.isValid && (
                  <CreditCreation errors={paymentMethod?.errors ?? []} />
                )}
            </>
          ),
          value: paymentMethod.code,
          disabled: !paymentMethod.isValid,
          providers: paymentMethod?.providers || [],
        })) || [];
    choices.sort((x, y) =>
      x.disabled === y.disabled ? 0 : x.disabled ? 1 : -1
    );
    setPaymentMethods(choices);
  }, [paymentMethodList]);

  useEffect(() => {
    if (paymentMethods?.length === 1 && !paymentMethods?.[0]?.disabled) {
      setValue("paymentMethod", paymentMethods[0]?.value);
    }
    buildPaymentProviders();
  }, [paymentMethods]);

  useEffect(() => {
    buildPaymentProviders();
  }, [paymentMethodWatch]);

  const showPaidButton =
    paymentMethodList?.find(pm => pm.code === paymentMethodWatch)
      ?.paymentType == "postpaid" &&
    paymentProviderWatch !== "SUPPLIER_CREDIT" &&
    !isEdit;

  return (
    <>
      <div className="contentInline">
        {!isEdit && (
          <div className="flexGap center">
            {<span className="bold">{t("PAYMENT_METHOD")}</span>}
            {paymentMethods?.length > 0 && (
              <ToolTip
                title={t("PAYMENT_LABEL_REMINDER")}
                position="Right"
                width="15rem"
                theme="orange"
              >
                <span>
                  <RiErrorWarningLine className="orangeText mtmd" />
                </span>
              </ToolTip>
            )}
          </div>
        )}

        {showPaidButton && (
          <div className="flexGap center">
            {hasPermission(isCommercial, role, ["action.purchase.paid"]) && (
              <Checkbox name="isPaid" singleInput={true} label={t("PAID")} />
            )}
            {showPaidButton && <UploadVoucher />}
          </div>
        )}
      </div>

      {paymentMethods?.length > 0 && (
        <>
          <RadioGroupV2
            name="paymentMethod"
            color="purple"
            options={paymentMethods.map(pm => ({
              ...pm,
              label: (
                <>
                  <div className="flexGap center">
                    {pm.label}
                    {paymentMethodWatch === pm.value && (
                      <>
                        {paymentProviders.length > 0 && (
                          <PaymentProvidersGroup
                            providers={pm.providers}
                            defaultProvider={paymentProvider}
                          />
                        )}
                      </>
                    )}
                  </div>
                </>
              ),
            }))}
            required
          />
        </>
      )}

      {purchaseType === "SALE" && coinsBalance > 0 && (
        <>
          <div className="spacer" />
          <div className="spacer" />
          <div className="flexGap">
            <NumericInput
              name="coins"
              label={t("COINS_PARTIAL_PAYMENT")}
              decimals
              max={maxCoins}
              infoLabel={
                t("COINS_AVAILABLE") + ": " + formatCurrency(coinsBalance)
              }
            />
          </div>
        </>
      )}
    </>
  );
};
