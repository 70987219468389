import { TSeller } from "@/http/types/seller";
import { getInstance } from "../../core/axios-gateway";

export type TOtpProps = {
  phone: string;
  prefix: string;
  email: string;
  otp: string;
};

export type TOtpVerifyProps = {
  phone: string;
  prefix: string;
  code: string;
  otp: string;
};
export type TOtpSupplierVerifyProps = {
  phone: string;
  otp: string;
  email: string;
};

const BASE_PATH = "/api/sellers/otp";
const USERS_PATH = "/api/users";

export const sendOtpToPhone = async (data: Partial<TOtpProps>) => {
  return getInstance()
    .post(`${BASE_PATH}`, data)
    .then(res => res.data);
};

export const sendOtpSignUp = async (data: Partial<TOtpProps>) => {
  return getInstance()
    .post(`${USERS_PATH}/otp`, data)
    .then(res => res.data);
};
export const verifyOtpCodeSignUp = async (data: Partial<TOtpVerifyProps>) => {
  return getInstance()
    .post(`${USERS_PATH}/otp/verify`, data)
    .then(res => res.data);
};

export const verifyOtpCode = async (data: Partial<TOtpVerifyProps>) => {
  return getInstance()
    .post(`${BASE_PATH}/validate`, data)
    .then(res => res.data);
};

export const sendSupplierOtpToPhone = async (data: Partial<TOtpProps>) => {
  return getInstance()
    .post(`${USERS_PATH}/otp`, data)
    .then(res => res.data);
};

export const verifySupplierOtpCode = async (
  data: Partial<TOtpSupplierVerifyProps>
) => {
  return getInstance()
    .post(`${USERS_PATH}/otp/verify`, data)
    .then(res => res.data);
};

export const getUserByPhone = async (phone: string) => {
  return getInstance()
    .get<TSeller>(`${USERS_PATH}/email-or-phone/${phone}`)
    .then(res => res.data);
};
