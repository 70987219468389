import { DragAndDropFile } from "@/components/shared/DragAndDropFIle/DragAndDropFIle";
import { InputV2 } from "@/components/shared/Input/InputV2";
import { TextArea } from "@/components/shared/Input/TextArea";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { getSubpurchaseByTrackingCode } from "@/http";
import { debounce } from "lodash-es";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IoMdRemoveCircle } from "react-icons/io";

type TOwnLogisticDispatchForm = {
  setIsLoading: (value: boolean) => void;
  setUsedTrackingCode: (value: boolean) => void;
  usedTrackingCode: boolean;
  files: any[];
  setFiles: (files: any[]) => void;
};

export const OwnLogisticDispatchForm = ({
  setIsLoading,
  setUsedTrackingCode,
  usedTrackingCode,
  files,
  setFiles,
}: TOwnLogisticDispatchForm) => {
  const minDate = new Date().toLocaleDateString();
  const [t] = useTranslation("orders-page");
  const methods = useFormContext();

  const validateTrackingCode = debounce(async (trackingCode: string) => {
    setIsLoading(true);
    const { content = [] } = await getSubpurchaseByTrackingCode(trackingCode);
    const mappedContent = content.filter(
      order => order.trackingCode === trackingCode
    );
    setUsedTrackingCode(!!mappedContent?.length);
    methods.trigger("trackingCode");
    setIsLoading(false);
  }, 500);

  return (
    <>
      <InputV2
        name="trackingCode"
        label={t("TRACKING_CODE")}
        onChange={e => validateTrackingCode(e.target.value)}
        infoLabel={
          usedTrackingCode &&
          methods.watch("trackingCode") && (
            <p className="redText">*{t("USED_GUIDE")}</p>
          )
        }
      />
      <p> {t("TRACKING_GUIDE")}</p>
      <div>
        {!files.length && (
          <DragAndDropFile
            accept="image/png,image/jpeg,application/pdf"
            files={files}
            setFiles={setFiles}
            minifiedStyle
          />
        )}
        {files.length > 0 && (
          <div className="flexGap center mtmd">
            <ToolTip title={t("DELETE_GUIDE")} position="Right">
              <IoMdRemoveCircle
                className="redText mtmd"
                cursor="pointer"
                onClick={() => setFiles([])}
              />
            </ToolTip>
            {files[0]?.name ?? "-"}
          </div>
        )}
      </div>
      <div className="spacer" />
      <InputV2
        name="estimatedDeliveryDate"
        label={t("ESTIMATED_DELIVERY_DATE")}
        type="date"
        min={minDate}
      />
      <TextArea name="observations" label={t("OBSERVATIONS")} rows={5} />
    </>
  );
};
