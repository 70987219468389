import styles from "../styles.module.scss";
import { TBrand, getBrands, getSeeriApi } from "@/http";
import { useState } from "react";
import { AutocompleteMultiple } from "@/components/shared/AutocompleteMultiple/AutocompleteMultiple";
import { useAuth } from "@/store/auth";
import { toast } from "react-toastify";
import { saveFile } from "@/utils/save-file";
import { BulkImportMode } from "./types";
import { apiModes } from "./modes";
import { useTranslation } from "react-i18next";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { logError } from "@/utils/errors";

export const BulkDownloadTemplate = ({ mode }: { mode: BulkImportMode }) => {
  const [t] = useTranslation("products-page");

  const apiMode = apiModes[mode];
  const supplier = useAuth(s => s.user);
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState<TBrand[]>([]);

  const downloadTemplate = async () => {
    if (mode === "ASSIGN" && brands.length === 0) {
      return toast.warn(
        t("BULK_CHARGE.DOWNLOAD.CHOOSE_AT_LEAST_ONE_BRAND").toString()
      );
    }

    setLoading(true);

    try {
      const params: Record<string, string> = {
        supplierId: supplier.id,
      };

      if (mode === "ASSIGN") {
        params.brandIds = brands.map(c => c.id).join(",");
      }

      const res = await getSeeriApi().request({
        url: apiMode.path + "/download",
        params,
        responseType: "blob",
      });
      await saveFile(res.data, `bulk-${mode.toLowerCase()}.xlsx`);
    } catch (error) {
      logError(error);
      toast.error(t("BULK_CHARGE.DOWNLOAD.TEMPLATE_ERROR").toString());
    }

    setLoading(false);
  };

  return (
    <div className={styles.spacing}>
      {mode === "ASSIGN" && (
        <div>
          <AutocompleteMultiple
            values={brands}
            onChange={setBrands}
            searchFn={q =>
              getBrands({ search: `name:${q}`, size: 5 }).then(d => d.content)
            }
          />
        </div>
      )}

      <div className="contentInline">
        {loading ? <Spinner /> : <span />}
        <button
          onClick={downloadTemplate}
          className="outlined"
          disabled={loading}
        >
          {t("BULK_CHARGE.DOWNLOAD.DOWNLOAD")}
        </button>
      </div>
    </div>
  );
};
