import { CreditTransactionsList } from "@/components/creditTransactions/CreditTransactionsList";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

const TransactionsPage = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");
  const { id } = useParams();

  useEffect(() => {
    setTitle(t("CREDITS.TRANSACTIONS.TITLE"), t("CREDITS.TITLE"));
  }, [t]);

  return (id ? <CreditTransactionsList creditId={ Number(id) } /> : null);
};

export default TransactionsPage;
