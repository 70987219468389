import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { TOrderDetailsPanel } from "@/models/order-details";
import { getPurchaseCancellationReasons, updateSupplierOrder } from "@/http";

import { useOrderDetails } from "@/store/order-detail";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Modal } from "../shared/Modal/Modal";
import { Select } from "../shared/Input/Select";
import { TextArea } from "../shared/Input/TextArea";

const RejectOrderModal = ({ orderDetail, userId }: TOrderDetailsPanel) => {
  const methods = useForm();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { setOrderDetail } = useOrderDetails();
  const [t] = useTranslation("orders-page");
  const [tGlobal] = useTranslation("global");
  const [reasons, setReasons] = useState<[]>([]);

  const rejectOrder = async ({ comment, rejectReason }: any) => {
    if (orderDetail?.id && userId) {
      setIsLoading(true);
      try {
        const supplierOrder = (await updateSupplierOrder(
          orderDetail?.id,
          "reject",
          { message: comment, reason: rejectReason }
        )) as any;
        setOrderDetail(supplierOrder);
        showToastSuccess(t("ORDER_REJECTED"));
        setOpen(false);
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        showToastError({ message: tGlobal("ERROR_SOME_BAD"), error });
      }
    }
  };

  useEffect(() => {
    getPurchaseCancellationReasons("supplier").then((data: any) => {
      setReasons(data);
    });
  }, []);

  return (
    <>
      <button onClick={() => setOpen(true)}> {t("DETAILS.REJECT")}</button>

      <Modal isOpen={open} onClose={() => setOpen(false)} size="sm">
        <h6 className="bold">
          {t("DETAILS.CONFIRM_REJECT")}
          <div className="divider" />
        </h6>
        <p className="bold">{`${t("DETAILS.ORDER_ID")} ${
          orderDetail?.subPurchaseNumber
        }`}</p>
        <div className="spacer" />
        <FormProvider {...methods}>
          <Select
            register={methods.register}
            label={t("DETAILS.REJECT_REASON")}
            name="rejectReason"
            required
            choices={reasons.map(reason => ({
              value: reason,
              label: reason,
            }))}
          />
          <TextArea
            maxLength={250}
            name="variantLongDescription"
            required
            label={t("DETAILS.REJECT_COMMENT")}
            rows={7}
          />
        </FormProvider>
        <div className="divider" />
        <div className="spacer" />
        <div className="row rigthAlign">
          <button onClick={() => setOpen(false)} disabled={isLoading}>
            {t("CLOSE")}
          </button>
          <button
            className="primary lg"
            onClick={methods.handleSubmit(rejectOrder)}
            disabled={isLoading}
          >
            {t("REJECT")}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default RejectOrderModal;
