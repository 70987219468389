import ProductImage from "@/components/layout/ProductImage";
import { usePurchaseProducts } from "@/hooks/usePurchaseProducts";
import { TOrderDetailsPanel } from "@/models/order-details";
import { useAuth } from "@/store/auth";
import { formatCurrency } from "@/utils/currency";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import { TPurchaseProduct } from "@/http";
import { ProductResume } from "./ProductResume";
import clsx from "clsx";

const ProductsDetails = ({
  orderDetail,
  lightStyle = false,
  isProductsTicket,
  isSubpurchase,
}: TOrderDetailsPanel) => {
  const [t] = useTranslation("orders-page");
  const { user } = useAuth();
  const orderProducts = usePurchaseProducts(
    orderDetail?.products ?? [],
    orderDetail?.purchaseId ?? ""
  );

  if (!orderProducts.length) {
    return null;
  }

  const sum = orderProducts.reduce(
    (previousValue: number, currentValue: TPurchaseProduct) =>
      previousValue + (currentValue?.subTotalWithDiscount ?? 0),
    0
  );
  const totalReturnedAmount = isSubpurchase
    ? orderProducts.reduce((amount, p) => amount + (p.returnedAmount ?? 0), 0)
    : 0;

  const price = useCallback(
    (product: any) => {
      return (
        product.suppliers?.find(
          (supplier: any) => supplier?.supplierId === user.id
        )?.wholesalePrice ?? 0
      );
    },
    [user.id]
  );

  const getDiscount = useCallback(
    (product: any) => {
      return (
        (product?.wholesalePrice || 0) * (product?.quantity || 0) -
        (product?.couponType === "DISCOUNT_PERCENTAGE"
          ? (product.subTotalWithDiscount || 0) -
            (product.discountByCoupon || 0)
          : product.subTotalWithDiscount || 0)
      );
    },
    [user.id]
  );

  const getFinalPrice = useCallback(
    (product: any) => {
      return product?.couponType === "DISCOUNT_PERCENTAGE"
        ? (product?.subTotalWithDiscount || 0) -
            (product?.discountByCoupon || 0)
        : product?.subTotalWithDiscount || 0;
    },
    [user.id]
  );

  const getDiscountPercentage = useCallback(
    (product: any) => {
      const discountTotal = getDiscount(product);
      const discountPercentage =
        discountTotal /
        ((product?.wholesalePrice || 0) * (product?.quantity || 0));

      const finalDiscount = (discountPercentage || 0) * 100;

      return Math.floor(finalDiscount * 100) / 100;
    },
    [user.id]
  );

  return (
    <>
      <div className={styles.productsDetails}>
        {!isProductsTicket && (
          <p className={styles.productsTitle}>{t("PRODUCTS_DETAILS.TITLE")}</p>
        )}

        <div
          className={clsx(styles.productsTable, {
            [styles.lightStyle]: lightStyle,
          })}
        >
          <table id="products-details-table">
            <thead>
              <tr className={styles.productsTableHead}>
                <th>{t("PRODUCTS_DETAILS.IMAGE")}</th>
                <th>{t("PRODUCTS_DETAILS.PRODUCT")}</th>
                <th>{t("PRODUCTS_DETAILS.QUANTITY")}</th>
                <th>{t("PRODUCTS_DETAILS.UNIT_TYPE")}</th>
                {user?.showPricingInfoOnInvoice && !isProductsTicket && (
                  <>
                    <th>{t("UNIT_PRICE")}</th>
                    <th>{t("DISCOUNT")} (%)</th>
                    <th>{t("FINAL_DISCOUNT")}</th>
                    <th>{t("PRODUCT_FINAL_PRICE")}</th>
                  </>
                )}
                {isProductsTicket && (
                  <>
                    <th>{t("UNIT_PRICE")}</th>
                    <th>{t("TOTAL")}</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {orderProducts?.map((product: any, index: number) => (
                <tr
                  key={`${product.sku}_${index}`}
                  className={styles.productsTableRow}
                >
                  <td>
                    <Link to={`/products/${product.id}`}>
                      <ProductImage images={product.images} />
                    </Link>
                  </td>
                  <td className="bold">
                    <ProductResume product={product} />
                  </td>
                  <td>{product.quantity}</td>
                  <td>{product?.unitType ?? "-"}</td>
                  {user?.showPricingInfoOnInvoice && !isProductsTicket && (
                    <>
                      <td>{formatCurrency(product?.wholesalePrice || 0)}</td>
                      <td>{getDiscountPercentage(product)} %</td>
                      <td>{formatCurrency(getDiscount(product))}</td>
                      <td>{formatCurrency(getFinalPrice(product))}</td>
                    </>
                  )}
                  {isProductsTicket && (
                    <>
                      <td>{formatCurrency(price(product))}</td>
                      <td>
                        {formatCurrency(price(product) * product?.quantity)}
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          {isSubpurchase && (
            <div className="mr1">
              <div className="col-12 noPadding rigthAlign">
                <p>
                  {orderDetail?.total && (
                    <>
                      <b>{t("TOTAL_SUBPURCHASE") + ": "}</b>
                      {formatCurrency(orderDetail?.total)}
                    </>
                  )}
                </p>
                {Boolean(orderDetail?.shipping) && (
                  <>
                    <p>
                      <b>{t("SHIPPING") + ": "}</b>
                      {formatCurrency(orderDetail?.shipping ?? 0)}
                    </p>
                    <p>
                      <b>{t("TOTAL_SUBPURCHASE_SHIPPING") + ": "}</b>
                      {formatCurrency(sum + (orderDetail?.shipping ?? 0))}
                    </p>
                  </>
                )}
                {Boolean(orderDetail?.partialMethodAmount) && (
                  <p>
                    <b>{t("PARTIAL_PAYMENT") + ": "}</b>
                    {formatCurrency(orderDetail?.partialMethodAmount ?? 0)}
                  </p>
                )}
                {Boolean(totalReturnedAmount) && (
                  <p>
                    <b>{t("RETURNED_AMOUNT") + ": "}</b>
                    {formatCurrency(totalReturnedAmount)}
                  </p>
                )}
                {Boolean(orderDetail?.partialMethodAmount) && (
                  <p>
                    <b>{t("TOTAL_PAY") + ": "}</b>
                    {formatCurrency(
                      sum -
                        (orderDetail?.partialMethodAmount ?? 0) +
                        (orderDetail?.shipping ?? 0)
                    )}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductsDetails;
