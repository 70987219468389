import {
  UseFormRegister,
  FieldValues,
  FieldError,
  Merge,
  FieldErrorsImpl,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import styles from "./RadioGroup.module.scss";

type TRadioGroup = {
  name: string;
  options: { label: string; value: string }[];
  register: UseFormRegister<FieldValues | any>;
  required?: boolean;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  label?: string;
  labelBold?: boolean;
};

function RadioGroup({
  name,
  options,
  register,
  required = false,
  error,
  label,
  labelBold,
}: TRadioGroup) {
  const [t] = useTranslation("global");

  return (
    <div>
      {label && (
        <h6
          className={`${styles.labelGroup} ${
            error ? styles.labelRequired : ""
          } ${labelBold ? "bold" : ""}`}
        >
          {label}
        </h6>
      )}
      {options.map((option, index) => (
        <div
          key={index}
          className={`${styles.radioContainer} ${error ? styles.required : ""}`}
        >
          <label
            htmlFor={`${name}-${option.value}`}
            className={styles.radioLabel}
          >
            <input
              type="radio"
              id={`${name}-${option.value}`}
              value={option.value}
              {...register(name, {
                required: required ? t("LOGIN.FIELD_REQUIRED") : false,
              })}
              className={styles.radioInput}
            />
            <span className={styles.customRadio} />
            <span className={styles.customText}>{option.label}</span>
          </label>
        </div>
      ))}
      {error && error?.type === "required" && (
        <span className={`${error ? styles.errorsLabel : ""}`}>
          {t("LOGIN.FIELD_REQUIRED")}
        </span>
      )}
    </div>
  );
}

export default RadioGroup;
