import { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { TInvoice } from "../invoiceData";
import { invoiceNoStyles } from "./Styles";

type Props = {
  invoice: TInvoice;
  type: string;
  translate: any;
};

const styles = StyleSheet.create(invoiceNoStyles);
const InvoiceNo = ({ translate, type, invoice }: Props) => {
  const showCommercialName =
    localStorage.getItem("showCommercialOnInvoice") === "true";

  return (
    <Fragment>
      <View style={styles.invoiceHeaderContainer}>
        <View style={styles.invoiceNoContainer}>
          <Text style={styles.label}>{translate(type)}: </Text>
          <Text>{invoice.number}</Text>
        </View>
        {type == "POS" && invoice.invoiceName && (
          <View style={styles.invoiceNoContainer}>
            <Text style={styles.label}>{translate("INVOICE")}: </Text>
            <Text>{invoice.invoiceName}</Text>
          </View>
        )}
        {invoice.createdAt && (
          <View style={styles.invoiceDateContainer}>
            <Text style={styles.label}>{translate("DATE")}: </Text>
            <Text>{invoice.createdAt.toLocaleDateString()}</Text>
          </View>
        )}
        {invoice.expiresAt && (
          <View style={styles.invoiceDateContainer}>
            <Text style={styles.label}>{translate("EXPIRES_AT")}: </Text>
            <Text>{invoice.expiresAt.toLocaleDateString()}</Text>
          </View>
        )}
        {invoice.commercialName && showCommercialName && (
          <View style={styles.invoiceDateContainer}>
            <Text style={styles.label}>
              {translate("COMMERCIAL")}
              {": "}
            </Text>
            <Text>{invoice.commercialName}</Text>
          </View>
        )}
      </View>
    </Fragment>
  );
};

export default InvoiceNo;
