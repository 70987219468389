import { SVGAttributes } from "react";

export const EnterpriseIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.6893 9.00842L10.1817 8.81804L8 3L5.81804 8.81804L5.31074 9.00842L0 11L5.81804 13.1817L8 19L10.1817 13.1817L16 11L10.6893 9.00842Z" />
      <path
        d="M10.6893 9.00842L10.1817 8.81804L8 3L5.81804 8.81804L5.31074 9.00842L0 11L5.81804 13.1817L8 19L10.1817 13.1817L16 11L10.6893 9.00842Z"
        fillOpacity="0.2"
      />
      <path d="M16.3446 3.00421L16.0909 2.90902L15 0L13.909 2.90902L13.6554 3.00421L11 4L13.909 5.09087L15 8L16.0909 5.09087L19 4L16.3446 3.00421Z" />
      <path
        d="M16.3446 3.00421L16.0909 2.90902L15 0L13.909 2.90902L13.6554 3.00421L11 4L13.909 5.09087L15 8L16.0909 5.09087L19 4L16.3446 3.00421Z"
        fillOpacity="0.2"
      />
    </svg>
  );
};
