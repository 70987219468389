import { NumericInput } from "@/components/shared/Input/NumericInput";
import { TextArea } from "@/components/shared/Input/TextArea";
import { Modal } from "@/components/shared/Modal/Modal";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { addPurchaseComment, TPurchase, updatePurchaseShipping } from "@/http";
import { useAuth } from "@/store/auth";
import { usePurchase } from "@/store/purchase";
import { isFinishedPurchase } from "@/utils/actions-disabled";
import { useState } from "react";
import { createPortal } from "react-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export type TShippingPurchase = {
  purchase: TPurchase;
};

export const ShippingPurchase = ({ purchase }: TShippingPurchase) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [t] = useTranslation("purchases");
  const [tGlobal] = useTranslation("global");
  const methods = useForm({
    defaultValues: { shipping: purchase.shipping, actionComment: "" },
  });
  const { getPurchase } = usePurchase();
  const { user } = useAuth();

  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    try {
      const { shipping, actionComment: comment } = values;
      await updatePurchaseShipping(purchase.id, {
        shipping,
      });

      await addPurchaseComment(purchase.id, {
        createdBy: user.id,
        comment,
        fullName: user.name,
      });

      showToastSuccess(t("SUCCESS_UPDATE"));
    } catch (error) {
      showToastError({ message: tGlobal("ERROR_SOME_BAD"), error });
    } finally {
      await getPurchase(purchase.id);
      setIsLoading(false);
      setIsOpen(false);
    }
  };

  if (isFinishedPurchase(purchase) || user.id !== purchase?.supplierId) {
    return null;
  }

  return (
    <>
      <div className="row rigthAlign">
        <button
          className="outlined bold"
          onClick={() => setIsOpen(true)}
          disabled={isFinishedPurchase(purchase)}
        >
          {t("UPDATE_SHIPPING_PRICE")}
        </button>
      </div>
      {createPortal(
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="sm">
          <div className="spacer" />
          <h6 className="bold">{t("UPDATE_SHIPPING_PRICE")}</h6>
          <div className="divider" />
          <div className="spacer" />
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)}>
              <NumericInput
                name="shipping"
                label={t("SHIPPING")}
                required
                decimals
              />
              <TextArea
                name="actionComment"
                label={t("COMMENT")}
                rows={4}
                required
              />
              <div className="divider" />
              <div className="spacer" />
              <div className="rigthAlign">
                <button
                  onClick={e => {
                    e.preventDefault();
                    setIsOpen(false);
                  }}
                  disabled={isLoading}
                >
                  {t("CLOSE")}
                </button>
                <button
                  disabled={isLoading}
                  className="primary lg"
                  type="submit"
                >
                  {t("UPDATE")}
                </button>
              </div>
            </form>
            <div className="spacer" />
          </FormProvider>
        </Modal>,
        document.body
      )}
    </>
  );
};
