import { useTranslation } from "react-i18next";
import { SingleMenu } from "@/components/shared/Menu/SingleMenu";
import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { CURRENT_LANG, EN, ES } from "@/constants/core";
import { TbWorld } from "react-icons/tb";
import clsx from "clsx";

export const I18nSelector = () => {
  const [t, i18n] = useTranslation("global");
  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("lang") ?? ES
  );
  const LANGUAGES = [ES, EN];

  const setLanguage = (lang: string) => {
    const langMapped = lang.toLowerCase();
    const currentLang = localStorage.getItem("lang");
    if (langMapped !== currentLang) {
      location.reload();
      i18n.changeLanguage(langMapped);
      localStorage.setItem("lang", langMapped);
      setCurrentLang(langMapped);
    }
  };

  useEffect(() => {
    setLanguage(CURRENT_LANG());
  }, []);

  return (
    <div className={styles.langSelector}>
      <SingleMenu
        onSelect={setLanguage}
        hideOnSelect
        align="rigth"
        width={110}
        label={
          <div className={clsx("flexGap center", styles.langText)}>
            <TbWorld size={24} />
            <span className="bold mdFont">
              {t(`LANGUAGE.${currentLang.toUpperCase()}`)}
            </span>
          </div>
        }
        options={LANGUAGES.map(lang => ({
          value: lang,
          label: <>{t(`LANGUAGE.${lang.toUpperCase()}`)}</>,
        }))}
      />
    </div>
  );
};
