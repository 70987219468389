import { Page, Document, StyleSheet, View } from "@react-pdf/renderer";
import BillTo from "./BillTo";
import InvoiceNo from "./InvoiceNo";
import InvoiceItemsTable from "./InvoiceItemsTable";
import SupplierInfo from "./SupplierInfo";
import { TInvoice } from "../invoiceData";
import ShipTo from "./ShipTo";
import { invoicePOSStyles } from "./Styles";
import InvoiceTotalsTable from "./InvoiceTotalsTable";
import PoweredLabel from "./PoweredLabel";
import { ObservationsInfo } from "./ObservationsInfo";

type Props = {
  invoice: TInvoice;
  type: string;
  translate: any;
};

const mmToPoints = (mm: number) => mm * 2.83465;

const styles = StyleSheet.create(invoicePOSStyles);

export const InvoicePOS = ({ translate, type, invoice }: Props) => {
  const customWidth = mmToPoints(70);

  return (
    <Document title={`${translate(type)}: ${invoice.number}`}>
      <Page style={styles.page} size={{ width: customWidth }}>
        <View style={styles.headerContainer}>
          <InvoiceNo translate={translate} type={type} invoice={invoice} />
        </View>
        <View style={styles.headerContainer}>
          <SupplierInfo supplier={invoice.supplier} forPOS />
        </View>
        {invoice.showClientInfo && (
          <>
            <View style={styles.shippingContainer}>
              {invoice.billing && (
                <BillTo
                  translate={translate}
                  billing={invoice.billing}
                  forPOS
                />
              )}
            </View>
            <View style={styles.shippingContainer}>
              {invoice.client && (
                <ShipTo translate={translate} client={invoice.client} forPOS />
              )}
            </View>
          </>
        )}
        {invoice?.observations && (
          <ObservationsInfo
            translate={translate}
            observations={invoice.observations}
          />
        )}

        <InvoiceItemsTable translate={translate} invoice={invoice} forPOS />
        {invoice?.showPrices && (
          <View style={styles.totals}>
            <InvoiceTotalsTable
              translate={translate}
              invoice={invoice}
              forPOS
            />
          </View>
        )}
        <PoweredLabel />
      </Page>
    </Document>
  );
};
