import { getBaseURL } from "@/config/remote";
import { locale } from "@/config/locale";
import { configureSeeriApi } from "@/core/seeri-api";
import { getToken } from "@/core/firebase";

export const refreshSeeriApiConfig = async (countryCode?: string) => {
  const baseURL = await getBaseURL(locale);
  const country = countryCode
    ? countryCode
    : localStorage.getItem("country") ?? "co";

  const accessToken = (await getToken()) || "";
  configureSeeriApi({ country, baseURL, accessToken });
};
