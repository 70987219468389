import { useSubscription } from "@/hooks/useSubscription";
import { BiLock } from "react-icons/bi";
import styles from "./styles.module.scss";

export const LockedFeatureIndicator = ({
  feature: featureCode,
  size = 12,
}: {
  feature: string;
  size?: number;
}) => {
  const { hasFeature } = useSubscription();

  if (hasFeature(featureCode)) {
    return null;
  }

  return <BiLock size={size} className={styles.locked} />;
};
