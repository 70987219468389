import { Modal } from "@/components/shared/Modal/Modal";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { TNewPayment, createPayment } from "@/http";
import { useAuth } from "@/store/auth";
import { useProducts } from "@/store/products";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { InputV2 } from "../shared/Input/InputV2";
import { DragAndDropFile } from "../shared/DragAndDropFIle/DragAndDropFIle";
import ToolTip from "../shared/Tooltip/Tooltip";
import { IoMdRemoveCircle } from "react-icons/io";
import { RED_ALERT } from "@/constants/colors";
import { uploadImagePaymentObject } from "@/utils/image-upload";
import { validateDecimalNumber } from "@/utils/keyboard";

type TCreatePayment = {
  creditId: number;
  setDone: () => void;
};

export const CreatePayment = ({ creditId, setDone }: TCreatePayment) => {
  const methods = useForm<TNewPayment>({});
  const [t] = useTranslation("global");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAuth();
  const { getVariants } = useProducts();
  const [files, setFiles] = useState<any>([]);

  const onSubmit = async (values: TNewPayment) => {
    setIsLoading(true);
    values.reference = "VOUCHER";
    values.referenceDetail = t("CREDITS.PAYMENTS.VOUCHER");
    values.status = "APPROVED";
    if (!files.length) {
      showToastError(t("IMAGE_REQUIRED"));
    }
    values.referenceUrl = (await uploadImagePaymentObject(files[0])).src;
    try {
      await createPayment(creditId, values).then(() => {
        showToastSuccess(t("CREDITS.TRANSACTIONS.REVERSION.SUCCESS"));
        getVariants(user.id);
        setIsOpen(false);
        setDone();
      });
      setFiles([]);
    } catch (error) {
      showToastError({ message: t("ERROR_SOME_BAD"), error });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="rigthAlign">
        <button
          onClick={() => {
            setIsOpen(true);
          }}
          className={"primary"}
        >
          <span>{"+ "}</span>
          <span className={styles.responsive}>
            {t("CREDITS.PAYMENTS.CREATE")}
          </span>
        </button>
      </div>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="sm">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="spacer" />
            <InputV2
              name="amount"
              label={t("CREDITS.PAYMENTS.AMOUNT")}
              required
              onKeyPress={validateDecimalNumber}
            />
            {files.length == 0 && (
              <DragAndDropFile
                minifiedStyle
                accept="image/png,image/jpeg"
                files={files}
                setFiles={setFiles}
              />
            )}
            {files.length > 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  marginTop: "0.7rem",
                }}
              >
                <ToolTip title={t("DELETE_GUIDE")}>
                  <IoMdRemoveCircle
                    color={RED_ALERT}
                    cursor="pointer"
                    onClick={() => setFiles([])}
                  />
                </ToolTip>
                {files[0].name}
              </div>
            )}
            <div className="divider" />
            <div className="spacer" />
            <div className="rigthAlign">
              <button className="primary lg" disabled={isLoading}>
                {t("SAVE")}
              </button>
            </div>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};
