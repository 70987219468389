import { getInstance } from "@/http/core/axios-gateway";

const BASE_PATH = "/api/subscriptions";

export const startSubscription = async (
  supplierId: string,
  data: { code: string; period: string; addons: Record<string, number> }
) => {
  return getInstance()
    .post<{ url?: string }>(`${BASE_PATH}/${supplierId}`, data)
    .then(res => res.data);
};
