import { Modal } from "@/components/shared/Modal/Modal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { ClientsBulkProcessDownload } from "./ClientsBulkProcessDownload";
import { ClientsBulkProcessUpload } from "./ClientsBulkProcessUpload";
import { PremiumFeatureIndicator } from "@/components/subscription/PremiumFeatureIndicator";
import { FEAT_BULK } from "@/constants/features";
import { DisabledZone } from "@/components/shared/DisabledZone/DisabledZone";
import { useSubscription } from "@/hooks/useSubscription";

export const ClientsBulkAction = ({
  mode,
  refresh,
}: {
  mode: "create" | "update";
  refresh: () => void;
}) => {
  const [t] = useTranslation("products-page");
  const [open, setOpen] = useState(false);
  const { hasFeature } = useSubscription();

  const modes = {
    create: {
      title: t("BULK_CHARGE.CREATE"),
    },
    update: {
      title: t("BULK_CHARGE.UPDATE"),
    },
  };

  return (
    <>
      <button onClick={() => setOpen(true)} className="outlined md">
        {modes[mode].title} <PremiumFeatureIndicator feature={FEAT_BULK} />
      </button>

      <Modal isOpen={open} onClose={() => setOpen(false)}>
        <div className={styles.modalBulkUpload}>
          <h2>
            <strong>{modes[mode].title}</strong>{" "}
            <PremiumFeatureIndicator feature={FEAT_BULK} />
          </h2>

          <DisabledZone when={!hasFeature(FEAT_BULK)}>
            <ol>
              <li>
                <ClientsBulkProcessDownload mode={mode} />
              </li>
              <li>
                <ClientsBulkProcessUpload mode={mode} refresh={refresh} />
              </li>
            </ol>
          </DisabledZone>
        </div>
      </Modal>
    </>
  );
};
