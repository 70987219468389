import { showToastError } from "@/hooks/useToast";
import { getProductOptions } from "@/http";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BiEdit } from "react-icons/bi";
import useSWR from "swr";
import { GroupOptionsForm } from "./GroupOptionsForm";
import styles from "./styles.module.scss";

export const GroupOptions = () => {
  const methods = useFormContext();
  const [t] = useTranslation("products-page");
  const hideForm = !!methods.watch("hideVariantForm");
  const { data } = useSWR(
    [
      {
        page: 0,
        size: 1000,
        sort: "name,asc",
        search: "enabled:true",
      },
    ],
    getProductOptions
  );
  const productOptions = data?.content ?? [];

  const getValuesName = (values: string) => {
    const parsedValues = values
      .trim()
      .split(",")
      .map((value: string) => value.trim())
      .filter((value: string) => !!value);
    const parsedValuesSet = new Set(parsedValues);

    return Array.from(parsedValuesSet);
  };

  const validatePartialForm = async () => {
    const options = methods.watch("options") ?? [];
    const optionName = await Promise.all(
      options.map((_: any, index: number) =>
        methods.trigger(`options[${index}].name`)
      )
    );
    const optionValues = await Promise.all(
      options.map((_: any, index: number) =>
        methods.trigger(`options[${index}].aditionalOptionValues`)
      )
    );
    const validOptionName = !optionName.some(name => name === false);
    const validOptionValues = !optionValues.some(value => value === false);

    if (validOptionName && validOptionValues) {
      const variantFormValues = options.map((option: any) => {
        const defaultValues = Object.keys(option?.optionsSelected).filter(
          key => option?.optionsSelected[key] === true
        );
        const newValues = getValuesName(option?.aditionalOptionValues ?? "");

        const value: any = {};
        value[option.name] = [...defaultValues, ...newValues];
        return value;
      });
      const mappedVariantFormValues = variantFormValues.reduce(
        (prev: any, next: any) => {
          const llave = Object.keys(next)[0];
          prev[llave] = next[llave];
          return prev;
        },
        {}
      );

      methods.setValue("variantFormValues", mappedVariantFormValues);
      methods.setValue("hideVariantForm", "hide");
      return;
    }
    showToastError(t("VERIFY_VARIANTS_FIELDS"));
  };

  const addOption = () => {
    methods.setValue("options", [
      ...methods.watch("options"),
      { name: "", value: "", optionValues: [], optionsSelected: {} },
    ]);
  };

  if (!data) {
    return null;
  }

  return (
    <>
      <h6 className="bold flexGap centerVAlign">
        {`${t("VARIANTS")} `}
        {hideForm && (
          <BiEdit
            size={16}
            className="pointer"
            onClick={() => {
              methods.setValue("hideVariantForm", "");
              methods.setValue("variantFormValues", []);
            }}
          />
        )}
      </h6>
      <div className="spacer" />
      {!hideForm && (
        <>
          {(methods.watch("options") ?? []).map((_: any, index: number) => (
            <GroupOptionsForm
              key={index}
              index={index}
              options={productOptions}
            />
          ))}
          <div className="spacer" />
          <button type="button" className="outlined" onClick={addOption}>
            {t("ADD_OTHER_OPTION")}
          </button>
          <button
            type="button"
            className="outlined"
            onClick={validatePartialForm}
          >
            {t("READY")}
          </button>
        </>
      )}
      {hideForm && (
        <div className={styles.optionsChips}>
          {Object.keys(methods.watch("variantFormValues") ?? {}).map(
            (key: string, idx: number) => (
              <div key={idx}>
                <div className="flexGap centerVAlign flexWrap">
                  <span className="flexGap centerVAlign bold mdFont">
                    {key}
                  </span>
                  <div className="spacerVertical" />
                  {(methods.watch(`variantFormValues[${key}]`) ?? []).map(
                    (value: any, index: number) => (
                      <span key={index} className="statusChip purple bold">
                        {value}
                      </span>
                    )
                  )}
                </div>
                <div className="spacer" />
                <div className="spacer" />
              </div>
            )
          )}
        </div>
      )}
    </>
  );
};
