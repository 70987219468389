import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

type Category = {
  id: string;
  name: string;
  subCategories: Category[];
};

type TCategoryInput = {
  categories: Category[];
  index: number;
};
export const CategoryInput = ({ categories, index }: TCategoryInput) => {
  const [subCategories, setSubCategories] = useState<Category[]>([]);
  const [t] = useTranslation("products-page");
  const { setValue } = useFormContext();

  const handleSelectedCategory = (id: string) => {
    const currentCategory = categories.find(category => category.id === id);
    setSubCategories(currentCategory?.subCategories ?? []);
    setValue("categoryId", id);

    for (let i = index + 1; i < 4; i++) {
      setValue(`temporalCategory[${i}]`, "");
    }
  };

  return (
    <>
      <SelectV2
        name={`temporalCategory[${index}]`}
        label={`${t("PRODUCT.CATEGORY")} ${index + 1}`}
        required={index === 0}
        choices={[
          ...categories.map(category => ({
            value: category.id,
            label: category.name,
          })),
        ]}
        onChange={event => handleSelectedCategory(event.target.value)}
      />
      {subCategories?.length > 0 && (
        <CategoryInput index={index + 1} categories={subCategories} />
      )}
    </>
  );
};
