import { isUSA } from "@/constants/core";
import { DATE_HOUR_SLASH, USA_DATE_HOUR_SLASH } from "@/constants/date";
import { renderingPaymentStatus } from "@/constants/payments";
import { PaginationSelect } from "@/models/orders";
import { useAuth } from "@/store/auth";
import { usePayments } from "@/store/payments";
import { formatCurrency } from "@/utils/currency";
import { validateTimezoneDate } from "@/utils/date";
import { format } from "date-fns";
import { get } from "lodash-es";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Paginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";

export const PaymentsList = () => {
  const { getPayments, payments, isLoadingPayments } = usePayments();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [t] = useTranslation("global");

  const showPayment = (id: string) => {
    navigate(`/payments/${id}`);
  };

  useEffect(() => {
    getPayments(user.id);
  }, []);

  if (!payments.length && !isLoadingPayments) {
    return <h6 className="bolder">{t("PAYMENTS.NO_PAYMENTS")}</h6>;
  }

  return (
    <>
      <div>
        <table className={styles.table}>
          <thead>
            <tr className={`bold ${styles.thead} ${styles.row}`}>
              <td>{t("PAYMENTS.PAYMENT_NUMBER")}</td>
              <td>{t("PAYMENTS.CREATED")}</td>
              <td>{t("PAYMENTS.STATUS")}</td>
              <td>{t("PAYMENTS.TOTAL")}</td>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment, index: number) => (
              <tr
                key={index}
                className={`${styles.row} pointer`}
                onClick={() => showPayment(payment.id ?? "")}
              >
                <td>{payment.paymentNumber}</td>
                <td>
                  {payment?.createdAt
                    ? format(
                        new Date(validateTimezoneDate(payment.createdAt)),
                        isUSA ? USA_DATE_HOUR_SLASH : DATE_HOUR_SLASH
                      )
                    : "-"}
                </td>
                <td
                  className={payment.status === "PAID" ? "bold greenText" : ""}
                >
                  {get(renderingPaymentStatus, payment.status, "-")}
                </td>
                <td>{formatCurrency(Number(payment?.total ?? 0))}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <PaymentsPagination />
    </>
  );
};

const PaymentsPagination = () => {
  const { setCurrentPage, currentPage, totalPages } = usePayments();

  const handlePageChange = (selectedObject: PaginationSelect) => {
    setCurrentPage(selectedObject.selected);
  };

  if (totalPages < 2) {
    return null;
  }

  return (
    <div className={styles.paginate}>
      <Paginate
        forcePage={currentPage}
        pageCount={totalPages}
        previousLabel={"←"}
        nextLabel={"→"}
        marginPagesDisplayed={3}
        onPageChange={handlePageChange}
        containerClassName={styles.pagination}
        previousLinkClassName={styles.previous}
        nextLinkClassName={styles.next}
        disabledClassName={styles.disabled}
        activeClassName={styles.selected}
        breakClassName={styles.break}
      />
    </div>
  );
};
