import { FilterTabs } from "@/components/app/ButtonFilterTabs/ButtonFilterTabs";
import { quotesRenderFilter } from "@/constants/purchases";
import { usePurchase } from "@/store/purchase";
import { PurchaseListTable } from "./PurchaseListTable/PurchaseListTable";
import { PurchaseListFilters } from "./PurchaseListTable/PurchaseListFilters";
import { usePurchaseListFilters } from "@/hooks/usePurchaseProducts";
import { useTranslation } from "react-i18next";

export const PurchaseList = ({ quotes }: { quotes?: boolean }) => {
  const [t] = useTranslation("orders-page");

  const { statusFilters, setStatusFilters } = usePurchase();
  const filters = usePurchaseListFilters() ?? {};
  const statusFilter = filters["status"] ?? [];

  return (
    <>
      <FilterTabs
        options={
          quotes
            ? quotesRenderFilter
            : [
                ...statusFilter
                  .filter((s: string) => s !== "QUOTED")
                  .map((status: string) => ({
                    text: t(`FILTER.${status}`),
                    value: status,
                  })),
                { text: "FILTERS.ALL", value: "" },
              ]
        }
        currentFilter={statusFilters["status"] ?? ""}
        action={(value: string) => setStatusFilters({ key: "status", value })}
      />
      <PurchaseListFilters />
      <div className="spacer" />
      <PurchaseListTable quotes={quotes} />
    </>
  );
};
