import { FormEventHandler } from "react";
import { useAuth } from "@/store/auth";
import {
  AxiosError,
  TIntegration,
  createIntegrationCredentials,
  deleteIntegrationCredential,
  updateIntegrationCredentials,
} from "@/http";
import clsx from "clsx";
import { toast } from "react-toastify";
import { useSWRConfig } from "swr";
import { SWR_INTEGRATIONS } from "./constants";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { logError } from "@/utils/errors";

type IntegrationFormProps = {
  integration: TIntegration;
  credentialId?: string;
};

export function IntegrationCredentialForm({
  integration,
  credentialId,
}: IntegrationFormProps) {
  const [t] = useTranslation("global");
  const { user } = useAuth();
  const { mutate: refresh } = useSWRConfig();

  const onSubmit: FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault();
    const form = event.currentTarget;
    const values = Object.fromEntries(new FormData(form));
    const toastId = toast.loading(
      (credentialId
        ? t("INTEGRATIONS.UPDATING")
        : t("INTEGRATIONS.SYNC")
      ).toString()
    );
    try {
      if (credentialId) {
        await updateIntegrationCredentials(credentialId, {
          credentials: values,
        });
      } else {
        await createIntegrationCredentials({
          supplierId: user.id,
          integrationId: integration.id,
          credentials: values,
        });
      }
      form.reset();
      toast.update(toastId, {
        render: (credentialId
          ? t("INTEGRATIONS.UPDATED")
          : t("INTEGRATIONS.SUCCESS_CONNECTION")
        ).toString(),
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      refresh([user.id, SWR_INTEGRATIONS]);
    } catch (error) {
      logError(error);
      toast.update(toastId, {
        render:
          error instanceof AxiosError && error.status === 422
            ? error.response?.data.message
            : t("INTEGRATIONS.ERROR"),
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <form onSubmit={onSubmit} className={styles.dialogForm}>
        {integration.credentials
          .filter(field => field.visible)
          .map(field => (
            <div key={field.id} className={styles.field}>
              <label className={styles.label}>{field.name}</label>
              <div>
                <input
                  placeholder={
                    credentialId ? "•".repeat(field.hint.length) : field.hint
                  }
                  className={clsx(credentialId && styles.placeholder)}
                  name={field.key}
                  required
                />
              </div>
              <p className={styles.description}>{field.description}</p>
            </div>
          ))}
        <div className={styles.actions}>
          {!!credentialId && (
            <DeleteCredentialButton credentialId={credentialId} />
          )}
          {!integration?.authUrl && (
            <button type="submit" className={clsx("primary", styles.save)}>
              {t("SAVE")}
            </button>
          )}
          {integration.authUrl && (
            <Link
              className={clsx("primary", styles.save, styles.actionBtn)}
              target="_blank"
              to={integration.authUrl}
            >
              {t("CONNECT")}
            </Link>
          )}
        </div>
      </form>
    </>
  );
}

const DeleteCredentialButton = ({ credentialId }: { credentialId: string }) => {
  const [t] = useTranslation("global");
  const { user } = useAuth();
  const { mutate: refresh } = useSWRConfig();
  return (
    <button
      type="button"
      className={styles.deleteBtn}
      onClick={() => {
        toast.promise(
          deleteIntegrationCredential(credentialId).then(() =>
            refresh([user.id, SWR_INTEGRATIONS])
          ),
          {
            pending: t("INTEGRATIONS.UNLINKING"),
            success: t("INTEGRATIONS.UNLINKED_INTEGRATION") as any,
            error: t("INTEGRATIONS.ERROR_UNLINK"),
          },
          {
            style: {
              "--toastify-color-success": "var(--toastify-color-info)",
            },
          }
        );
      }}
    >
      {t("INTEGRATIONS.UNLINK")}
    </button>
  );
};
