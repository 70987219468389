import { getInstance } from "../../core/axios-gateway";
import { TRestListData, TRestListParams } from "../../types/rest";
import { TSellerBillingAddress } from "../../types/seller";

const BASE_PATH = "/api/sellers/invoice-third-party";

export const getSellerBillingAddresses = async (params: TRestListParams) => {
    return getInstance()
      .get<TRestListData<TSellerBillingAddress>>(BASE_PATH, { params })
      .then((res) => res.data);
  };

export const getSellerBillingAddressById = async (id: string) => {
  return getInstance()
    .get<TSellerBillingAddress>(`${BASE_PATH}/${id}`)
    .then((res) => res.data);
};

export const createSellerBillingAddress = async (data: Partial<TSellerBillingAddress>) => {
  return getInstance()
    .post<TSellerBillingAddress>(BASE_PATH, data)
    .then((res) => res.data)
}

export const deleteSellerBillingAddress = async (id: string) => {
  return getInstance().delete(`${BASE_PATH}/${id}`);
};