import { showToastError } from "@/hooks/useToast";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isValidEmail, isValidPhone } from "@/utils/validations/email";
import styles from "./styles.module.scss";
import { Modal } from "@/components/shared/Modal/Modal";
import { InputV2 } from "../shared/Input/InputV2";
import {
  sendSupplierOtpToPhone,
  verifySupplierOtpCode,
} from "@/http/resources/sellers/otp";
import { refreshSeeriApiConfig } from "@/config/seeri-api";
import { PinInput } from "../shared/Input/PinInput";
import { useAuth } from "@/store/auth";
import RadioGroup from "../shared/RadioGroup/RadioGroup";
import Select from "react-select";
import useSWR from "swr";
import { getCountries } from "@/http";
import { singleSelectStyles } from "@/constants/input";

type TQuickLoginForm = {
  phone: string;
  type: string;
  email: string;
  country: string;
};

export const OTPLogin = () => {
  const { loginOtp } = useAuth();
  const methods = useForm<TQuickLoginForm>();
  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const initialTime = 2 * 60;
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const [isActiveTimer, setIsActiveTimer] = useState(false);
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const [otpSent, setOtpSent] = useState(false);
  const [phoneOtp, setPhoneOtp] = useState("");
  const [emailOtp, setEmailOtp] = useState("");
  const [typeOtp, setTypeOtp] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [t] = useTranslation("global");
  const typeMethodWatch = methods.watch("type");
  const { data: countries = [], mutate } = useSWR("countries", getCountries);

  const sendNewOTP = async () => {
    try {
      setIsLoading(true);
      await sendSupplierOtpToPhone(
        typeOtp === "email" ? { email: emailOtp } : { phone: phoneOtp }
      );
      handleResetTimer();
      setIsLoading(false);
    } catch (e) {
      showToastError({
        message: t("LOGIN.OTP_ERROR_SEND"),
        e,
      });
      setIsLoading(false);
    }
  };

  const onSendOTP = async (values: TQuickLoginForm) => {
    try {
      setIsLoading(true);
      const { phone: partialPhone, type, email, country } = values;
      const phone = country + partialPhone;
      await sendSupplierOtpToPhone(type === "email" ? { email } : { phone });
      handleStartTimer();
      setOtpSent(true);
      setPhoneOtp(phone);
      setEmailOtp(email);
      setTypeOtp(type);
      setValue("phone", "");
      setValue("email", "");
      setValue("type", "");
    } catch (error) {
      setPhoneOtp("");
      setValue("phone", "");
      setValue("email", "");
      setValue("type", "");
      showToastError({
        message: t("ERROR_SOME_BAD"),
        error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const validateOTP = async (otp: string) => {
    try {
      const { token, isValid } = await verifySupplierOtpCode(
        typeOtp === "email"
          ? { email: emailOtp, otp }
          : {
              phone: phoneOtp,
              otp,
            }
      );
      if (isValid) {
        loginOtp(token);
      } else {
        showToastError("Invalid user");
      }
    } catch (e) {
      showToastError({ e, message: t("LOGIN.OTP_NO_VALID") });
    }
  };

  const onCloseModal = () => {
    setOtpSent(false);
    setValue("phone", "");
    setIsOpen(false);
    setPhoneOtp("");
  };

  const handleStartTimer = () => {
    setIsActiveTimer(true);
  };

  const handleResetTimer = () => {
    setTimeLeft(initialTime);
    setIsActiveTimer(true);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isActiveTimer) {
      timer = setInterval(() => {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1);
        } else {
          clearInterval(timer);
          setIsActiveTimer(false);
        }
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isActiveTimer, timeLeft]);

  const initialize = async () => {
    await refreshSeeriApiConfig();
  };

  useEffect(() => {
    initialize().then(() => mutate());
  }, []);

  return (
    <>
      <button className={styles.buttonLogin} onClick={() => setIsOpen(true)}>
        {t("LOGIN.WHATSAPP_LOGIN_MESSAGE")}
      </button>
      <Modal size={"sm"} isOpen={isOpen} onClose={() => onCloseModal()}>
        <div className={styles.modalContent}>
          <h6 className={styles.title}> {t("LOGIN.WHATSAPP_LOGIN_MESSAGE")}</h6>
          <div className="divider" />
          <div className={styles.content}>
            {otpSent ? (
              <div className={styles.emailSent}>
                <p>
                  {t(
                    typeOtp === "email" ? "LOGIN.CODE_IN_MAIL" : "LOGIN.CODE_IN"
                  )}
                </p>
                <div className="spacer" />
                <PinInput numInputs={6} onComplete={validateOTP} />

                <div className="spacer" />
                <span className="mdFont">
                  {t("LOGIN.LEFT_TIME")}: {minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </span>
                {!isActiveTimer && (
                  <>
                    <div className="spacer" />
                    <div className="spacer" />
                    <button
                      className="primary lg"
                      disabled={isLoading}
                      onClick={sendNewOTP}
                    >
                      {t("RESEND")}
                    </button>
                  </>
                )}
                <div className="spacer" />
                <div className="spacer" />
                <div className="spacer" />
              </div>
            ) : (
              <>
                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(onSendOTP)}>
                    <RadioGroup
                      name="type"
                      required
                      label={t("LOGIN.QUICK_TYPE")}
                      options={[
                        { label: t("LOGIN.WHATSAPP"), value: "whatsapp" },
                        { label: t("LOGIN.EMAIL"), value: "email" },
                      ]}
                      register={methods.register}
                      error={methods.formState.errors.type}
                    />
                    <div className="spacer" />
                    {typeMethodWatch === "whatsapp" && (
                      <>
                        <p>{t("COUNTRY")}</p>
                        <Select
                          isSearchable
                          name="color"
                          placeholder={t("SELECT_OPTION")}
                          required
                          options={countries?.map(c => ({
                            label: `${c.emoji} ${c.name}`,
                            value: `+${c.phoneCode}`,
                          }))}
                          styles={singleSelectStyles}
                          isDisabled={!countries?.length}
                          onChange={({ value = "" }: any) =>
                            setValue("country", value)
                          }
                        />
                        <InputV2
                          name="phone"
                          label={t("LOGIN.PHONE")}
                          placeholder={t("LOGIN.PHONE_PLACEHOLDER")}
                          required
                          validate={isValidPhone}
                          validMessage={t("LOGIN.PHONE_VALIDATION")}
                        />
                      </>
                    )}

                    {typeMethodWatch === "email" && (
                      <InputV2
                        name="email"
                        label={t("LOGIN.EMAIL")}
                        placeholder={t("LOGIN.EMAIL_PLACEHOLDER")}
                        required
                        validate={isValidEmail}
                      />
                    )}

                    {!otpSent && (
                      <div className={styles.footer}>
                        <div className={styles.buttons}>
                          <button
                            className="primary lg"
                            type="submit"
                            disabled={isSubmitting || isLoading}
                          >
                            {t("CONTINUE")}
                          </button>
                          <button
                            disabled={isSubmitting || isLoading}
                            onClick={e => {
                              e.stopPropagation();
                              setIsOpen(false);
                            }}
                          >
                            {t("LOGIN.CANCEL")}
                          </button>
                        </div>
                      </div>
                    )}
                  </form>
                </FormProvider>
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};
