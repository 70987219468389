import { ImagesList } from "@/components/products/ProductsForm/ProductImageList";
import { TicketInputs } from "@/components/purchases/PurchaseShow/Actions/ConfirmPurchaseDelivery";
import { DragAndDropFile } from "@/components/shared/DragAndDropFIle/DragAndDropFIle";
import { TextArea } from "@/components/shared/Input/TextArea";
import { RadioGroupV2 } from "@/components/shared/RadioGroup/RadioGroupV2";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { createTicket, TPurchase } from "@/http";
import { uploadImageTicketObject } from "@/utils/image-upload";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

type TTicketCreationForm = {
  purchase: TPurchase;
  onDone?: (values?: any) => void;
  onCancel?: (values?: any) => void;
};
export const TicketCreationForm = ({
  purchase,
  onDone,
  onCancel,
}: TTicketCreationForm) => {
  const [isLoading, setLoading] = useState(false);
  const methods = useForm();
  const [t] = useTranslation("purchases");
  const [tGlobal] = useTranslation("global");
  const files = methods.watch("files") || [];

  const onSubmit = async (values: any) => {
    try {
      setLoading(true);
      const products = values?.products as {
        [key: string]: { value: boolean; quantity?: any };
      };
      const filteredProducts = Object.fromEntries(
        Object.entries(products)
          .filter(([_, { value }]) => !!value)
          .map(p => [p[0], Number(p[1]?.quantity ?? "0")])
      );

      if (!Object.keys(filteredProducts).length) {
        showToastError({ message: t("CHOOSE_ONE_PRODUCT") });
        return;
      }

      if (!files?.length) {
        showToastError({ message: t("ADD_EVIDENCE") });
        return;
      }

      const payload = {
        evidenceLink: "",
        reason: values.reason,
        type: values.type,
        productReturned: filteredProducts,
      };

      if (files.length) {
        const image = await uploadImageTicketObject(files[0]);
        payload.evidenceLink = image?.src;
      }

      await createTicket(purchase.id, payload);
      showToastSuccess(t("TICKET_CREATED"));

      onDone?.();
    } catch (error) {
      showToastError({ message: tGlobal("ERROR_SOME_BAD"), error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <TextArea
          name="reason"
          label={t("TELL_US_WHAT_HAPPEN")}
          rows={6}
          required
        />
        <TicketInputs purchase={purchase} />
        <p>{t("EVIDENCE")}</p>
        {!files.length && (
          <DragAndDropFile
            minifiedStyle
            accept="image/png,image/jpeg"
            files={files}
            setFiles={files => methods.setValue("files", files)}
          />
        )}
        <ImagesList
          images={files}
          setImages={files => methods.setValue("files", files)}
        />
        <div className="spacer" />
        <RadioGroupV2
          color="purple"
          required
          name={"type"}
          label={t("TYPE_LABEL")}
          inline
          options={[
            { label: t("RETURN"), value: "RETURN" },
            { label: t("REFUND"), value: "REFUND" },
          ]}
        />
      </FormProvider>
      <div className="spacer" />
      <div className="divider" />
      <div className="spacer" />
      <div className="rigthAlign">
        <button
          disabled={isLoading}
          onClick={() => {
            methods.reset();
            onCancel?.();
          }}
        >
          {t("CANCEL")}
        </button>
        <button
          className="primary md"
          onClick={methods.handleSubmit(onSubmit)}
          disabled={isLoading}
        >
          {t("CREATE_TICKET")}
        </button>
      </div>
    </>
  );
};
