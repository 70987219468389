import HorizontalTimeline from "@/components/shared/HorizontalTimeline/HorizontalTimeline";
import {
  usePaymentMethods,
  usePurchaseTimeline,
} from "@/hooks/usePurchaseProducts";
import { getEventCopy } from "@/hooks/usePurchaseTimeline";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

type TPurchaseTimeline = {
  id: string;
};
export const PurchaseTimeline = ({ id }: TPurchaseTimeline) => {
  const paymentMethods = usePaymentMethods();
  const timeline = usePurchaseTimeline(id);
  const [t] = useTranslation("global");

  if (!timeline) return null;

  if (!timeline?.length) return null;

  const timeLineMapped = timeline.map((modification: any) => ({
    date: modification?.createdAt,
    description: getEventCopy(modification, paymentMethods, t),
  }));
  return (
    <div className={styles.timeline}>
      <HorizontalTimeline events={timeLineMapped} />
    </div>
  );
};
