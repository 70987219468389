import { Card } from "@/components/shared/Card/Card";
import { Checkbox } from "@/components/shared/Input/Checkbox";
import { InputV2 } from "@/components/shared/Input/InputV2";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { isUSA } from "@/constants/core";
import { getMetadataTaxTypes } from "@/http";
import { validateDecimalNumber, validateOnlyNumber } from "@/utils/keyboard";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

export const PriceInputs = () => {
  const [t] = useTranslation("products-page");
  const methods = useFormContext();
  const chargeTaxes = methods.watch("chargeTaxes");
  const { data: metadataTaxes } = useSWR(
    ["getMetadataTaxTypes"],
    getMetadataTaxTypes
  );

  return (
    <Card>
      <div className="flexGap flexSmColumn">
        <div className="col-4 col-sm-12 noPadding">
          <p className="bold">{t("PRICE")}</p>
          <div className="spacer" />
          <InputV2
            name="wholesalePrice"
            label={t("WHOLESALE_PRICE")}
            onKeyPress={validateDecimalNumber}
            required
          />
          <InputV2
            name="purchasePrice"
            label={t("PURCHASE_PRICE")}
            onKeyPress={validateDecimalNumber}
          />
          {isUSA && (
            <InputV2
              name="unitPrice"
              label={<span className="textLine">{t("UNIT_PRICE")}</span>}
              type="text"
              onKeyPress={validateDecimalNumber}
            />
          )}
          <div className="spacer" />
          <Checkbox name="chargeTaxes" label={t("CHARGE_TAXES")} />
          {chargeTaxes && metadataTaxes && (
            <SelectV2
              name="taxesType"
              label={t("TAX")}
              choices={metadataTaxes
                .sort((a, b) => (a.value > b.value ? 1 : -1))
                .map(t => ({
                  value: t.code,
                  label: `${t.value}%`,
                }))}
            />
          )}
        </div>
        <Spacer />
        <div className="col-4 col-sm-12 noPadding">
          <p className="bold">{t("PRODUCT.INVENTORY")}</p>
          <div className="spacer" />
          <InputV2
            name="barCode"
            label={t("PRODUCT.SUPPLIER_BAR_CODE")}
            onKeyPress={validateOnlyNumber}
          />
          {!isUSA && (
            <InputV2
              name="supplierSku"
              label={t("PRODUCT.DETAIL.SKU")}
              type="text"
            />
          )}
          <InputV2
            name="stock"
            label={t("STOCK_LABEL")}
            onKeyPress={validateDecimalNumber}
            required
          />
          {!!methods.watch("useSecurityStock") && (
            <InputV2
              name="securityStock"
              label={t("PRODUCT.SECURITY_STOCK")}
              onKeyPress={validateDecimalNumber}
              required
            />
          )}
          <div className="spacer" />
          <div className="spacer" />
          <Checkbox
            name="useSecurityStock"
            label={t("PRODUCT.SECURITY_STOCK")}
          />
        </div>
        <Spacer />
        <div className="col-4 col-sm-12 noPadding">
          <p className="bold">{t("SHIPPING")}</p>
          <div className="spacer" />
          <InputV2
            name="height"
            label={t("PRODUCT.DETAIL.HEIGHT")}
            onKeyPress={validateDecimalNumber}
          />
          <InputV2
            name="width"
            label={t("PRODUCT.DETAIL.WIDTH")}
            onKeyPress={validateDecimalNumber}
          />
          <InputV2
            name="length"
            label={t("PRODUCT.DETAIL.LENGTH")}
            onKeyPress={validateDecimalNumber}
          />
          <InputV2
            name="weigth"
            label={t("PRODUCT.DETAIL.WEIGHT")}
            onKeyPress={validateDecimalNumber}
          />
        </div>
      </div>
    </Card>
  );
};

const Spacer = () => {
  return (
    <>
      <div className="spacerVertical smHide" />
      <div className="dividerVertical smHide" />
      <div className="spacerVertical smHide" />
    </>
  );
};
