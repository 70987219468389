import { useSideBarStatus } from "@/store/ui";
import { useEffect } from "react";
import { SidebarContent } from "./Sidebar";
import styles from "./styles.module.scss";

export const SidebarMobile = ({ isOpen, setOpen }: any) => {
  const { setSideBarCollapsed } = useSideBarStatus();

  useEffect(() => setSideBarCollapsed(false), [isOpen]);

  return (
    <div
      className={`${styles.sidebarMobile} ${isOpen ? styles.open : ""}`}
      onClick={() => setOpen(false)}
    >
      <div
        className={styles.content}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <SidebarContent onClick={() => setOpen(false)} />
      </div>
    </div>
  );
};
