import { getBrands, TBrand } from "@/http";
import { useFormContext } from "react-hook-form";
import { Autocomplete } from "@/components/shared/Autocomplete/Autocomplete";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type TBrandInput = { label?: string | ReactNode; placeholder?: string };
export const BrandInput = ({ label, placeholder }: TBrandInput) => {
  const { setValue } = useFormContext();
  const [autocompleteOptions, setAutocompleteOptions] = useState<any[]>([]);
  const [t] = useTranslation("products-page");

  const getFilterProducts = async (name: string) => {
    const allBrands = await getBrands({ search: `name:${name}` });
    const content = allBrands?.content?.filter(brand =>
      brand.name.toLowerCase().includes(name.toLowerCase())
    );

    return (content ?? []) as any[];
  };

  const setProduct = (option: TBrand) => {
    if (option) {
      const { id, name } = option;
      setValue("brandId", id);
      setValue("brandName", name);
    }
  };

  useEffect(() => {
    const fetchInitialOptions = async () => {
      const initialOptions = await getFilterProducts("");
      setAutocompleteOptions(initialOptions);
    };

    fetchInitialOptions();
  }, []);

  if (!autocompleteOptions?.length) {
    return null;
  }

  return (
    <>
      <Autocomplete
        inputName="brandName"
        inputLabel={label ?? t("BRAND")}
        inputPlaceholder={placeholder ?? t("BRAND_PLACEHOLDER")}
        required
        choices={autocompleteOptions}
        customSearch
        action={setProduct}
        customSearchFunction={getFilterProducts}
      />
    </>
  );
};
