import { Modal } from "@/components/shared/Modal/Modal";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { updatePurchaseInvoiceStatus } from "@/http";
import { usePurchase } from "@/store/purchase";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type TInvoiceManual = { id: string };
export const InvoiceManual = ({ id }: TInvoiceManual) => {
  const [t] = useTranslation("global");
  const { getPurchase } = usePurchase();
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setLoading(true);
      await updatePurchaseInvoiceStatus(id, true);
      await getPurchase(id);
      setOpen(false);
      showToastSuccess(t("PURCHASE_UPDATED"));
    } catch (_) {
      showToastError(t("ERROR_SOME_BAD"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <button className="primary" onClick={() => setOpen(true)}>
        {t("MARK_INVOICE_BUTTON")}
      </button>
      <Modal
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        title={t("INVOICE_MARK_TITLE")}
        size="sm"
        footer={
          <>
            <button onClick={() => setOpen(false)} disabled={isLoading}>
              {t("CANCEL")}
            </button>
            <button
              className="primary lg"
              onClick={onSubmit}
              disabled={isLoading}
            >
              {t("CONFIRM")}
            </button>
          </>
        }
      >
        <p>{t("INVOICE_MARK_DESCRIPTION")}</p>
      </Modal>
    </div>
  );
};
