export const FEAT_ADVANCED_DASHBOARD = "ADVANCED_DASHBOARD";
export const FEAT_BASIC_DASHBOARD = "BASIC_DASHBOARD";
export const FEAT_BULK = "BULK";
export const FEAT_CATALOG = "CATALOG";
export const FEAT_CLIENT_SEGMENTATION = "CLIENT_SEGMENTATION";
export const FEAT_CREDIT = "CREDIT";
export const FEAT_CUSTOM_ATTRIBUTES = "CUSTOM_ATTRIBUTES";
export const FEAT_DISCOUNTS = "DISCOUNTS";
export const FEAT_INTEGRATIONS = "INTEGRATIONS";
export const FEAT_LOGISTICS = "LOGISTICS";
export const FEAT_OFFERS = "OFFERS";
export const FEAT_PURCHASES = "PURCHASES";
export const FEAT_SELL_IN = "SELL_IN";
export const FEAT_USERS = "USERS";
export const FEAT_MAX_USERS = "MAX_USERS";
export const FEAT_TICKETS = "TICKETS";
