import { Modal } from "@/components/shared/Modal/Modal";
import { showToastError } from "@/hooks/useToast";
import { TCreateVoucherRequest, TPurchase, addVoucher } from "@/http";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IoMdRemoveCircle } from "react-icons/io";
import { uploadImagePaymentObject } from "@/utils/image-upload";
import { DragAndDropFile } from "@/components/shared/DragAndDropFIle/DragAndDropFIle";
import { InputV2 } from "@/components/shared/Input/InputV2";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { formatCurrency } from "@/utils/currency";
import { MdOutlineAdd } from "react-icons/md";

type TCreateVoucher = {
  maxAmount: number;
  purchase: TPurchase;
  setDone: () => void;
};

export const CreateVoucherModal = ({
  purchase,
  setDone,
  maxAmount,
}: TCreateVoucher) => {
  const methods = useForm<TCreateVoucherRequest>({});
  const [t] = useTranslation("global");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [files, setFiles] = useState<any>([]);

  const onSubmit = async (values: TCreateVoucherRequest) => {
    values.url = (await uploadImagePaymentObject(files[0])).src;
    setIsLoading(true);
    try {
      const payload = {
        ...values,
      };
      addVoucher(purchase.id, [payload]).then(() => {
        setDone?.();
        setIsOpen(false);
      });
    } catch (error) {
      showToastError({ message: t("ERROR_SOME_BAD"), error });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className="rigthAlign">
        <ToolTip title={t("VOUCHERS.ADD_VOUCHER")} position="Left">
          <span
            className="pointer circleButton"
            onClick={() => setIsOpen(true)}
          >
            <MdOutlineAdd />
          </span>
        </ToolTip>
      </div>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        size="sm"
        title={t("VOUCHERS.ADD_VOUCHER")}
        footer={
          <button
            className="primary lg"
            disabled={isLoading}
            onClick={methods.handleSubmit(onSubmit)}
          >
            {t("SAVE")}
          </button>
        }
      >
        <FormProvider {...methods}>
          <div className="spacer" />
          <InputV2
            type="number"
            name="amount"
            label={t("VOUCHERS.AMOUNT")}
            validate={(amount: number) => amount <= maxAmount}
            validMessage={`${t("VOUCHERS.VALIDATIONS.REQUIRED_AMOUNT", {
              min: formatCurrency(0),
              max: formatCurrency(maxAmount || 0),
            })}`}
            required
          />
          {files.length == 0 && (
            <DragAndDropFile
              minifiedStyle
              accept="image/png,image/jpeg"
              files={files}
              setFiles={setFiles}
            />
          )}
          {files.length > 0 && (
            <div>
              <ToolTip title={t("VOUCHERS.DELETE_IMAGE")}>
                <IoMdRemoveCircle
                  className="redText"
                  cursor="pointer"
                  onClick={() => setFiles([])}
                />
              </ToolTip>
              {files[0].name}
            </div>
          )}
          <div className="divider" />
          <div className="spacer" />
        </FormProvider>
      </Modal>
    </>
  );
};
