import { create } from "zustand";
import { TOrderDetailsStatus } from "@/models/order-details";
import {
  TSubPurchaseComment,
  addComment,
  getComments,
  getSupplierOrderById,
} from "@/http";

export const useOrderDetails = create<TOrderDetailsStatus>((set, get) => ({
  getOrderDetail: async (orderId: string, userId: string) => {
    set(() => ({ isLoading: true, comments: [] }));
    try {
      if (orderId && userId) {
        const orderDetail = (await getSupplierOrderById(orderId)) as any;
        set(() => ({ orderDetail, isLoading: false }));
      }
    } catch (error) {
      set(() => ({ orderDetail: undefined, isLoading: false }));
    }
  },
  setOrderDetail: (orderDetail: any) => set(() => ({ orderDetail })),
  orderDetail: {} as any,
  isLoading: true,
  isLoadingComment: false,
  comments: [],
  getCommentsByOrder: async (subPurchaseId: string) => {
    try {
      const comments = await getComments(subPurchaseId);

      set({ comments });
    } catch (error) {
      set({});
    }
  },
  createComment: async (
    subPurchaseId: string,
    data: Omit<TSubPurchaseComment, "createdAt">
  ) => {
    try {
      set({ isLoadingComment: true });
      await addComment(subPurchaseId, data);

      set({ isLoadingComment: false });
      get().getCommentsByOrder(subPurchaseId);
    } catch (error) {
      set({
        isLoadingComment: false,
      });
    }
  },
}));
