import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  onAuthStateChanged,
  User,
} from "firebase/auth";
import "firebase/compat/firestore";
import { isProd } from "@/constants/env";
import { CURRENT_URL } from "@/constants/core";
import { logError } from "@/utils/errors";

const configDev = {
  apiKey: "AIzaSyBEPsehUqoFXBQX8razE2S67yE86Ddi9Kc",
  authDomain: "seeri-dev.firebaseapp.com",
  projectId: "seeri-dev",
  storageBucket: "seeri-dev.appspot.com",
  messagingSenderId: "425297633803",
  appId: "1:425297633803:web:092af3b14f792b79d65d0e",
  measurementId: "G-84RY012EQS",
};

const configProd = {
  apiKey: "AIzaSyAIu6gSsfBFUipuK3ihd4lC5ZPKVKLrPVg",
  authDomain: "seeri-b81c0.firebaseapp.com",
  projectId: "seeri-b81c0",
  storageBucket: "seeri-b81c0.appspot.com",
  messagingSenderId: "292039451305",
  appId: "1:292039451305:web:315c1adec303eecbbdffb8",
  measurementId: "G-0SQXH7077N",
};
export const firebaseConfig = isProd ? configProd : configDev;

export const app = firebase.initializeApp(firebaseConfig, "primary");
export const auth = firebase.auth(app);
export const db = app.firestore();

export const getCurrentUser = async (): Promise<User | null> => {
  return new Promise(resolve => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      unsubscribe();
      resolve(user);
    });
  });
};

export const getToken = async () => {
  const user = await getCurrentUser();
  return user?.getIdToken();
};

export const firebaseLogin = async (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const firebaseLogout = () => {
  return signOut(auth);
};

export const sendPasswordResetMail = async (email: string) => {
  return sendPasswordResetEmail(auth, email);
};

export const firebaseSendSigninLink = async (email: string) => {
  const actionCodeSettings = {
    url: `${CURRENT_URL}/link-login`,
    handleCodeInApp: true,
  };

  sendSignInLinkToEmail(auth, email, actionCodeSettings)
    .then(() => {
      window.localStorage.setItem("emailForSignIn", email);
    })
    .catch(error => {
      logError({ type: "firebase error", error });
    });
};

export const firebaseConfirmSigninLink = async () => {
  return isSignInWithEmailLink(auth, window.location.href);
};

export const firebaseSigninEmailLink = async (email: string) => {
  return signInWithEmailLink(auth, email, window.location.href);
};
