import { FilterTabs } from "@/components/app/ButtonFilterTabs/ButtonFilterTabs";
import { PaymentsList } from "@/components/payments/List/PaymentsList";
import { PendingTransactionsList } from "@/components/payments/List/PendingTransactionsList";
import { useAuth } from "@/store/auth";
import { usePayments } from "@/store/payments";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const PaymentsListPage = () => {
  const { currentFilter, setCurrentFilter } = usePayments();
  const [t] = useTranslation("global");
  const { setTitle } = useHeaderTitle();
  const { refreshUserData } = useAuth();

  useEffect(() => {
    setTitle(t("PAYMENTS.TITLE"));
  }, [t]);

  useEffect(() => {
    refreshUserData();
  }, []);

  return (
    <>
      <FilterTabs
        options={[
          { text: t("PAYMENTS.TAB_PAYMENTS"), value: "" },
          { text: t("PAYMENTS.TAB_PENDING"), value: "pending-transactions" },
        ]}
        currentFilter={currentFilter}
        action={setCurrentFilter}
      />
      <div className="spacer" />
      {!currentFilter && <PaymentsList />}
      {!!currentFilter && <PendingTransactionsList />}
    </>
  );
};
