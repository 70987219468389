import { ReactNode } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

type InputProps = {
  name: string;
  label: string | ReactNode;
  required?: boolean;
  onChange?: (...params: any[]) => void;
  onClick?: (...params: any[]) => void;
  disabled?: boolean;
  direction?: "inline" | "column";
  value?: string;
  defaultChecked?: boolean;
  singleInput?: boolean;
};

export const Checkbox = ({
  name,
  label,
  required = false,
  onChange,
  disabled,
  onClick,
  direction = "inline",
  value,
  defaultChecked,
  singleInput,
}: InputProps) => {
  const [t] = useTranslation("global");
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div
      className={`${styles.field} ${
        errors && errors[name] ? styles.required : ""
      } ${singleInput ? styles.singleInput : ""}`}
    >
      <label className={direction === "inline" ? styles.inline : styles.column}>
        <input
          defaultChecked={defaultChecked}
          value={value}
          type="checkbox"
          disabled={disabled}
          onClick={e => onClick?.(e)}
          {...register(name, {
            required: required ? t("LOGIN.FIELD_REQUIRED") : false,
            onChange: onChange,
            disabled: disabled,
          })}
        />
        <span>{label}</span>
      </label>
    </div>
  );
};
