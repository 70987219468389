import { Spinner } from "@/components/shared/Spinner/Spinner";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { getCredits } from "@/http/resources/sellers/credits";
import { getSellersbyIds } from "@/http";
import { Link } from "react-router-dom";
import { CreditModalForm } from "./CreditModalForm";
import { FormProvider, useForm } from "react-hook-form";
import { SellerResume } from "../shared/Sellers/SellerResume/SellerResume";
import { SellerInputSearch } from "../shared/Sellers/SellerInputSearch/SellerInputSearch";
import { formatCurrency } from "@/utils/currency";
import ToolTip from "../shared/Tooltip/Tooltip";
import { PaginatedTable } from "../shared/PaginatedTable/PaginatedTable";
import useUrlState from "@ahooksjs/use-url-state";
import { Card } from "../shared/Card/Card";
import { format } from "date-fns";
import { isUSA } from "@/constants/core";
import {
  DATE_MONT_YEAR_SLASH,
  USA_DATE_MONT_YEAR_SLASH,
} from "@/constants/date";
import { IoEyeOutline } from "react-icons/io5";

export const CreditsList = () => {
  const [{ page }] = useUrlState({ page: "0" });
  const methods = useForm<any>({});
  const [t] = useTranslation("global");
  const seller = methods.watch("seller");

  const params: any = {};
  if (seller?.id) {
    params.search = `sellerId:${seller.id}`;
  }
  params.size = 10;
  params.page = page;

  const swrData = useSWR([params, "credits"], getCredits);
  const { data: credits, error, mutate } = swrData;

  const sellerIds = credits?.content.map(({ sellerId }) => sellerId) || [];
  const { data } = useSWR(sellerIds, () => getSellersbyIds(sellerIds));
  const sellers = data?.reduce((map, seller) => {
    map.set(seller.id, seller);
    return map;
  }, new Map());

  if (error) {
    return <p>Error</p>;
  }

  if (!credits) {
    return <Spinner />;
  }

  return (
    <>
      <div className={"flexGap contentInline flexSmColumn"}>
        <div>
          <FormProvider {...methods}>
            <SellerInputSearch />
          </FormProvider>
        </div>
        <div>
          <CreditModalForm onDone={() => mutate()} />
        </div>
      </div>
      <div className="spacer" />

      <Card className="noPadding">
        <PaginatedTable swrData={swrData}>
          <table className={styles.table}>
            <thead>
              <tr className={styles.purpleHead}>
                <th>{t("DATE")}</th>
                <th className="leftAlign">{t("CREDITS.TABLE.CLIENT")}</th>
                <th>{t("CREDITS.TABLE.AMOUNT")}</th>
                <th>{t("CREDITS.TABLE.BALANCE")}</th>
                <th>{t("ACTIONS")}</th>
              </tr>
            </thead>
            <tbody>
              {credits.content.map(credit => (
                <tr key={credit.id} className={styles.row}>
                  <td>
                    <Link to={`/credits/${credit?.id}/transactions`}>
                      {format(
                        new Date(credit.createdAt),
                        isUSA ? USA_DATE_MONT_YEAR_SLASH : DATE_MONT_YEAR_SLASH
                      )}
                    </Link>
                  </td>
                  <td>
                    <div className="flexGap center leftAlign">
                      <Link
                        to={`/clients/${credit?.sellerId}/show`}
                        className={"bold"}
                      >
                        <SellerResume
                          seller={sellers?.get(credit.sellerId)}
                          hideEdit
                          className={styles.sellerResumeContainer}
                        />
                      </Link>
                    </div>
                  </td>
                  <td>{formatCurrency(credit.amount || 0)}</td>
                  <td>{formatCurrency(credit.balance || 0)}</td>
                  <td>
                    <div className="flexGap center centerAlign purpleText">
                      <ToolTip title={t("SHOW")} position="Left">
                        <Link to={`/credits/${credit?.id}/transactions`}>
                          <IoEyeOutline className="bold" />
                        </Link>
                      </ToolTip>
                      <CreditModalForm
                        credit={credit}
                        seller={sellers?.get(credit.sellerId)}
                        onDone={() => mutate()}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </PaginatedTable>
      </Card>
    </>
  );
};
