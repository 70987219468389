import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { TSellerBillingAddress } from "@/http";
import { buildCountryLine, buildLineAddress } from "../utils";
import { billComponent, billComponentPOS } from "./Styles";

type Props = {
  billing: TSellerBillingAddress;
  translate: any;
  forPOS?: boolean;
};

const styles = StyleSheet.create(billComponent);
const stylesPOS = StyleSheet.create(billComponentPOS);

const BillTo = ({ translate, billing, forPOS }: Props) => {
  return (
    <View style={forPOS ? stylesPOS.headerContainer : styles.headerContainer}>
      <Text style={styles.billTo}>{translate("BILL_TO")}:</Text>
      <View style={styles.infoContainer}>
        <Text>
          {billing.firstName} {billing.lastName}
        </Text>
        <Text>
          {billing.documentType}
          {billing.documentId}
        </Text>
        <Text>
          {billing.phonePrefix} {billing.phone}
        </Text>
        <Text>{buildLineAddress(billing)}</Text>
        <Text>{buildCountryLine(billing)}</Text>
        <Text>{billing.email}</Text>
      </View>
    </View>
  );
};

export default BillTo;
