import RangeDatePicker from "../RangeDatePicker/RangeDatePicker";
import { useOrderFilter } from "@/store/orders";
import { useTranslation } from "react-i18next";
import { OrderFilters } from "@/models/orders";
import SearchFilter from "../IdFilter/SearchFilter";
import { FilterTabs } from "@/components/app/ButtonFilterTabs/ButtonFilterTabs";
import styles from "./styles.module.scss";
import { Export } from "../Export/Export";

const OrderFilter = () => {
  const { setFilter, filter: activeFilter } = useOrderFilter();
  const [t] = useTranslation("orders-page");

  return (
    <>
      <div>
        <FilterTabs
          options={[
            ...OrderFilters.map(filter => ({
              ...filter,
              text: t(`FILTER.${filter.text}`),
            })),
          ]}
          currentFilter={activeFilter}
          action={setFilter}
        />
        <div className="contentInline smContentColumn">
          <div className={`${styles.orderFilter} col-sm-12`}>
            <SearchFilter />
            <RangeDatePicker />
          </div>
          <div className={`${styles.actions} col-sm-12`}>
            <Export />
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderFilter;
