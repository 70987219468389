import { PaginationSelect } from "@/models/orders";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { useAuth } from "@/store/auth";
import { useProducts } from "@/store/products";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Paginate from "react-paginate";
import { ProductRow } from "./ProductRow";
import styles from "./styles.module.scss";
import { isUSA } from "@/constants/core";

export const ProductsTable = () => {
  const [t] = useTranslation("products-page");
  const {
    currentPageGroup,
    nameFilter,
    brandFilter,
    tabCurrentFilter,
    setCurrentPageGroup,
    getVariants,
    variants,
    isLoadingVariants,
    isStockFilter,
    isNotStockFilter,
  } = useProducts();
  const { user } = useAuth();

  useEffect(() => {
    getVariants(user.id);
  }, [currentPageGroup]);

  useEffect(() => {
    setCurrentPageGroup(0);
    getVariants(user.id);
  }, [
    nameFilter,
    brandFilter,
    tabCurrentFilter,
    isStockFilter,
    isNotStockFilter,
  ]);

  if (isLoadingVariants) {
    return <Spinner />;
  }

  return (
    <>
      {variants?.length > 0 ? (
        <div className={styles.container}>
          <div className="tableScroll">
            <table className={styles.table}>
              <thead>
                <tr className={`${styles.headerRow} softLilaBg`}>
                  <th />
                  <th />
                  <th>{t("TABLE.NAME")}</th>
                  <th>{t("TABLE.PRICE")}</th>
                  {isUSA && (
                    <th className="textLine">{t("TABLE.UNIT_PRICE")}</th>
                  )}
                  <th>{t("TABLE.STOCK")}</th>
                  {!isUSA && <th>{t("TABLE.CONN")}</th>}
                  <th>{t("TABLE.STATE")}</th>
                  <th className="textLine">{t("TABLE.MARKETPLACE_STATE")}</th>
                </tr>
              </thead>
              <tbody>
                {variants.map((variant, idx) => (
                  <ProductRow key={`${idx}_${variant.id}`} product={variant} />
                ))}
              </tbody>
            </table>
            <div className="spacer" />
            <div className="spacer" />
          </div>

          <ProductsGroupPagination />
        </div>
      ) : (
        <h2 className={styles.noData}>{t("NO_DATA")}</h2>
      )}
    </>
  );
};

const ProductsGroupPagination = () => {
  const { totalPagesVariants, currentPageGroup, setCurrentPageGroup } =
    useProducts();

  const handlePageChange = (selectedObject: PaginationSelect) => {
    setCurrentPageGroup(selectedObject.selected);
  };

  if (totalPagesVariants < 2) {
    return null;
  }

  return (
    <div className={styles.paginate}>
      <Paginate
        forcePage={currentPageGroup}
        pageCount={totalPagesVariants}
        previousLabel={"←"}
        nextLabel={"→"}
        marginPagesDisplayed={3}
        onPageChange={handlePageChange}
        containerClassName={styles.pagination}
        previousLinkClassName={styles.previous}
        nextLinkClassName={styles.next}
        disabledClassName={styles.disabled}
        activeClassName={styles.selected}
        breakClassName={styles.break}
      />
    </div>
  );
};
