import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { getCurrentCountry } from "@/constants/core";
import { invoiceItemsStyle, invoiceItemsStylePOS } from "./Styles";

type Props = {
  translate: any;
  hasDiscount: boolean;
  showPrices: boolean;
  forPOS?: boolean;
  showUnitTypes?: boolean;
};

const InvoiceTableHeader = ({
  translate,
  hasDiscount,
  showPrices,
  forPOS,
  showUnitTypes,
}: Props) => {
  const productSkuKey = getCurrentCountry() != "us" ? "SKU" : "UPC";
  const showImages = localStorage.getItem("showImagesOnInvoice") === "true";

  const styles = StyleSheet.create(
    forPOS ? invoiceItemsStylePOS : invoiceItemsStyle
  );

  return (
    <View style={styles.container}>
      {showImages && <Text style={styles.image}>{translate("IMAGE")}</Text>}

      {!forPOS && <Text style={styles.key}>{translate(productSkuKey)}</Text>}

      <Text style={styles.description}>{translate("DESCRIPTION")}</Text>
      <Text style={styles.qty}>{translate("QUANTITY")}</Text>
      {showUnitTypes && (
        <Text style={styles.key}>{translate("UNIT_TYPE")}</Text>
      )}
      {showPrices && <Text style={styles.amount}>{translate("PRICE")}</Text>}
      {!forPOS && (
        <>
          {showPrices && hasDiscount && (
            <Text style={styles.amount}>{translate("SUB_TOTAL")}</Text>
          )}
          {showPrices && hasDiscount && (
            <Text style={styles.amount}>{translate("DISCOUNT")}</Text>
          )}
        </>
      )}
      {showPrices && <Text style={styles.amount}>{translate("TOTAL")}</Text>}
    </View>
  );
};

export default InvoiceTableHeader;
