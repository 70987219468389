import { Filter, TOrderFilterStatus } from "@/models/orders";
import { create } from "zustand";
import { getSupplierOrders, TRestListData } from "@/http";
import {
  MAX_ORDERS_PER_PAGE,
  TOTAL_ORDERS_PER_PAGE,
} from "@/constants/export-orders";
import { getCommonSearchFilter } from "@/utils/orders";
import { Supplier } from "@/models/supplier";
import { TSupplierOrderDetails } from "@/models/order-details";

export const useOrderFilter = create<TOrderFilterStatus>(set => ({
  filter: Filter.PENDING,
  setFilter: filter => set(() => ({ filter })),
  idFilter: "",
  setIdFilter: idFilter => set(() => ({ idFilter })),
  startDateFilter: null,
  endDateFilter: null,
  setDatesFilter: (startDateFilter, endDateFilter) =>
    set(() => ({ startDateFilter, endDateFilter })),
  sortBy: "createdAt",
  sortDirection: "DESC",
  setSorting: (sortBy, sortDirection) => set(() => ({ sortBy, sortDirection })),
}));

export type TOrdersStatus = {
  orders: TRestListData<TSupplierOrderDetails>;
  getOrders: (
    user: Supplier,
    filter: string,
    idFilter: string,
    startDate: Date | null,
    endDate: Date | null,
    page: number,
    sortBy: string | string[],
    sortDirection: string | string[]
  ) => void;
  currentPage: number;
  totalPages: number;
  loadingOrders: boolean;
  getOrdersExport: (
    user: Supplier,
    filter: string,
    startDate: Date | null,
    endDate: Date | null
  ) => void;
  ordersToExport: any[];
  ordersIdToInvoice: string[];
  addIdToInvoice: (orderId: string) => void;
  removeIdToInvoice: (orderId: string) => void;
};

export const useOrders = create<TOrdersStatus>((set, get) => ({
  orders: { content: [], totalPages: 0, totalElements: 0, numberOfElements: 0 },
  currentPage: 0,
  totalPages: 0,
  loadingOrders: true,
  ordersToExport: [],
  ordersIdToInvoice: [],
  addIdToInvoice: (orderId: string) => {
    const { ordersIdToInvoice: invoiceIds } = get();
    set(() => ({ ordersIdToInvoice: [...invoiceIds, orderId] }));
  },
  removeIdToInvoice: (orderId: string) => {
    const { ordersIdToInvoice: invoiceIds } = get();

    set(() => ({ ordersIdToInvoice: invoiceIds.filter(id => id !== orderId) }));
  },
  getOrders: async (
    user,
    filter,
    idFilter,
    startDate,
    endDate,
    page,
    sortBy,
    sortDirection
  ) => {
    try {
      set(() => ({ loadingOrders: true, ordersIdToInvoice: [] }));
      let search = getCommonSearchFilter(startDate, endDate, filter);
      const sort = `${sortBy},${sortDirection}`;
      if (idFilter) {
        search = `${search}${search.endsWith(",") ? "" : ","}text:${idFilter}`;
      }
      const orders = (await getSupplierOrders(user.id, {
        size: TOTAL_ORDERS_PER_PAGE,
        page,
        search,
        sort,
      })) as TRestListData<any>;
      set(() => ({
        orders,
        totalPages: orders.totalPages,
        loadingOrders: false,
        currentPage: page,
      }));
    } catch (error) {
      set(() => ({
        orders: {
          content: [],
          totalPages: 0,
          totalElements: 0,
          numberOfElements: 0,
          currentPage: 0,
        },
        totalPages: 0,
        loadingOrders: false,
      }));
    }
  },
  getOrdersExport: async (user, filter, startDate, endDate) => {
    try {
      const search = getCommonSearchFilter(startDate, endDate, filter);
      const orders = await getSupplierOrders(user.id, {
        size: MAX_ORDERS_PER_PAGE,
        page: 0,
        search,
      });

      set(() => ({ ordersToExport: orders.content }));
    } catch (error) {
      set(() => ({ ordersToExport: [] }));
    }
  },
}));
