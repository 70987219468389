import { InputHTMLAttributes, ReactNode } from "react";
import styles from "./styles.module.scss";

type InputProps = {
  label?: string | ReactNode;
  disabled?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export const CheckboxButton = ({ label, disabled, ...props }: InputProps) => {
  return (
    <div className={`${styles.field} ${styles.checkboxContainer}`}>
      <label className={styles.inline}>
        <input type="checkbox" disabled={disabled} {...props} />
        {label && <span>{label}</span>}
      </label>
    </div>
  );
};
