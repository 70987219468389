import { PaginationSelect, TPaginatorTableOrdersProps } from "@/models/orders";
import { useOrders } from "@/store/orders";
import Paginate from "react-paginate";

import styles from "./PaginatorTableOrders.module.scss";

const PaginatorTableOrders = ({ getOrders }: TPaginatorTableOrdersProps) => {
  const { totalPages, currentPage } = useOrders();

  const handlePageChange = (selectedObject: PaginationSelect) => {
    getOrders(selectedObject.selected);
  };

  return (
    <div className={styles.paginatorContainer}>
      <Paginate
        forcePage={currentPage}
        pageCount={totalPages}
        previousLabel={"←"}
        nextLabel={"→"}
        marginPagesDisplayed={3}
        onPageChange={handlePageChange}
        containerClassName={styles.pagination}
        previousLinkClassName={styles.previous}
        nextLinkClassName={styles.next}
        disabledClassName={styles.disabled}
        activeClassName={styles.selected}
        breakClassName={styles.break}
      />
    </div>
  );
};

export default PaginatorTableOrders;
