export const isValidEmail = (email: string) => {
  if (!email) {
    return true;
  }

  return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};

export const isValidPhone = (phone: string) => {
  if (!phone) {
    return true;
  }

  return /^\d{5,10}$/.test(phone);
};

export const isValidHttpsUrl = (url: string) => {
  if (!url) {
    return true;
  }

  return /^https:\/\/((([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,})|(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})|localhost)(:\d+)?(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?$/.test(
    url
  );
};
