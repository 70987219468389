import { InputV2 } from "@/components/shared/Input/InputV2";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { useAddresses } from "@/store/profile";
import { validateDecimalNumber, validateOnlyNumber } from "@/utils/keyboard";
import { useTranslation } from "react-i18next";
import { BoxesConfirmDispatch } from "../CommentsSubPurchase/BoxesConfirmDispatch";

export const SeeriLogisticDispatchForm = () => {
  const [t] = useTranslation("orders-page");
  const { addresses } = useAddresses();

  return (
    <>
      <InputV2
        name="declaredValue"
        label={t("DECLARED_VALUE")}
        required
        onKeyPress={validateDecimalNumber}
      />
      <InputV2
        name="boxes"
        label={t("DETAILS.AMOUNT_BOXES")}
        required
        onKeyPress={validateOnlyNumber}
      />
      <BoxesConfirmDispatch />

      <SelectV2
        required
        name="warehouseId"
        label={t("DETAILS.WAREHOUSE")}
        choices={addresses?.content?.map(address => ({
          value: address?.id ?? "",
          label: `${address.addressLine1 ?? "-"}, ${
            address.addressLine2 ?? "-"
          } ;
    ${address.city ?? "-"}, ${address.country ?? "-"}`,
        }))}
      />
    </>
  );
};
