import { usePurchase } from "@/store/purchase";
import { ChangeEvent, useRef } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineSearch } from "react-icons/ai";
import { PurchasesListSort } from "../PurchaseListActions/PurchasesListSort";
import { PurchaseTableFilters } from "../PurchaseListActions/PurchaseTableFilters";
import styles from "./styles.module.scss";

export const PurchaseListFilters = () => {
  const debounceRef = useRef<any>();
  const [t] = useTranslation("global");
  const { setPurchaseNumberFilter } = usePurchase();

  const onPurchaseNumberQueryChanged = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      setPurchaseNumberFilter(event.target.value);
    }, 350);
  };

  return (
    <div className={styles.containerFilters}>
      <div className={styles.inputGroup}>
        <AiOutlineSearch className={styles.icon} />
        <input
          autoComplete={"off"}
          type="text"
          placeholder={t("SEARCH")}
          onChange={onPurchaseNumberQueryChanged}
        />
      </div>
      <PurchasesListSort />
      <PurchaseTableFilters />
    </div>
  );
};
