import { customToast } from "@/hooks/useToast";
import { AxiosError } from "@/http";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { useAuth } from "@/store/auth";
import { getFirebaseErrorText } from "@/utils/validations/firebase";
import { FirebaseError } from "firebase/app";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const EmailLinkLogin = () => {
  const { emailLogin } = useAuth();
  const navigate = useNavigate();
  const [t] = useTranslation("global");

  useEffect(() => {
    login();
  }, []);

  const login = async () => {
    try {
      await emailLogin();
      customToast({
        title: t("LOGIN.LOGIN_WITH_EMAIL"),
        status: "success",
      });
    } catch (error: any) {
      let errorMessage = t("LOGIN.QUICK_LOGIN_FAILED");
      if (error instanceof FirebaseError) {
        errorMessage = getFirebaseErrorText(error.code, error.message);
        navigate("/login");
        return customToast({
          status: "warn",
          title: errorMessage,
        });
      }

      if (error instanceof AxiosError) {
        errorMessage = error.response?.data.message;
      }
      customToast({
        status: "warn",
        title: errorMessage,
      });
      navigate("/login");
    }
  };

  return (
    <>
      <Spinner />
    </>
  );
};

export default EmailLinkLogin;
