import { getProductV2 } from "@/http";
import useSWR from "swr";
import { Chip } from "./Chip";

type TProductNameChip = {
  id: string;
  onDelete?: () => void;
  hideDelete?: boolean;
};
export const ProductNameChip = ({
  id,
  onDelete,
  hideDelete = false,
}: TProductNameChip) => {
  const { data } = useSWR([id, "ProductIdChip"], getProductV2);

  if (!data) {
    return null;
  }
  return (
    <Chip>
      {data.name}
      {!hideDelete && <span onClick={() => onDelete?.()}>x</span>}
    </Chip>
  );
};
