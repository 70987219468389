import { DragAndDropFile } from "@/components/shared/DragAndDropFIle/DragAndDropFIle";
import { InputV2 } from "@/components/shared/Input/InputV2";
import { TextArea } from "@/components/shared/Input/TextArea";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ImagesList } from "../ProductsForm/ProductImageList";

export const GroupInfo = () => {
  const methods = useFormContext();
  const [t] = useTranslation("products-page");

  return (
    <>
      <div className="flexGap flexSmColumn ">
        <div className="col-6 col-sm-12 noPadding">
          <div className="row">
            <InputV2 name="name" label={t("PRODUCT_NAME_DETAIL")} required />
          </div>
          <div className="row">
            <TextArea label={t("DESCRIPTION")} name="description" rows={6} />
          </div>
        </div>
        <div className="spacerVertical" />
        <div className="col-6 col-sm-12 noPadding">
          <div className="row">
            <div className="col-12 noPadding">
              <h1 className="bold">{t("PRODUCT.IMAGES")}</h1>
            </div>

            <div className="col-12 noPadding">
              <div className="spacer" />
              <DragAndDropFile
                accept="image/png,image/jpeg"
                files={methods.watch("images")}
                setFiles={(images: any[]) => methods.setValue("images", images)}
              />
            </div>
            <div className="col-12">
              <ImagesList
                images={methods.watch("images")}
                setImages={(images: any[]) =>
                  methods.setValue("images", images)
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
