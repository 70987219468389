import { Table, TableCell } from "@/components/clients/ui/Table/Table";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { useAuth } from "@/store/auth";
import { useRoles } from "@/store/roles";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BiEdit } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import styles from "../styles.module.scss";
import { RoleModal } from "./RoleModal";

export const RolesList = () => {
  const [t] = useTranslation("global");
  const { user } = useAuth();
  const {
    getRoles,
    roles,
    isLoading,
    currentPage,
    totalPages,
    setCurrentPage,
  } = useRoles();

  useEffect(() => {
    getRoles(user.id);
  }, [currentPage]);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {roles.length > 0 ? (
            <div className="tableScroll">
              <Table records={roles}>
                <TableCell label={t("COMMERCIALS.NAME")} source="name" />
                <TableCell
                  label={t("COMMERCIALS.DESCRIPTION")}
                  source="description"
                />
                <TableCell
                  label={t("ACTIONS")}
                  render={role => (
                    <div className={styles.actions}>
                      <RoleModal
                        role={role}
                        button={
                          <ToolTip title="Editar rol" position="Left">
                            <BiEdit />
                          </ToolTip>
                        }
                      />
                    </div>
                  )}
                />
              </Table>
              <div className={styles.paginate}>
                <ReactPaginate
                  forcePage={currentPage}
                  onPageChange={p => setCurrentPage(p.selected)}
                  pageCount={totalPages}
                  previousLabel="←"
                  nextLabel="→"
                  marginPagesDisplayed={3}
                  containerClassName={styles.pagination}
                  previousLinkClassName={styles.previous}
                  nextLinkClassName={styles.next}
                  disabledClassName={styles.disabled}
                  activeClassName={styles.selected}
                  breakClassName={styles.break}
                />
              </div>
            </div>
          ) : (
            <h6 className="bolder">{t("NO_DATA")}</h6>
          )}
        </>
      )}
    </>
  );
};
