import { TPaymentProvider } from "@/http";
import styles from "./PaymentMethodGroup.module.scss";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "@/utils/currency";
import { exists } from "i18next";

type Props = {
  providers: TPaymentProvider[];
  defaultProvider?: string;
};

export const PaymentProvidersGroup = ({ providers }: Props) => {
  const [t] = useTranslation("purchases");
  const [globalT] = useTranslation("global");
  const choisesProviders = providers.map((pp: TPaymentProvider) => ({
    label: (
      <>
        {exists(`global:PAYMENT_METHOD.PROVIDERS.${pp?.code}`)
          ? globalT(`PAYMENT_METHOD.PROVIDERS.${pp?.code}`)
          : pp?.code}{" "}
        {pp.availableAmount || 0 > 0 ? (
          <>{formatCurrency(Number(pp?.availableAmount))} </>
        ) : (
          <></>
        )}
        {pp.isValid ? null : <>({t(pp.errors?.[0])})</>}
      </>
    ),
    value: pp?.code,
    disabled: !pp?.isValid,
    isValid: pp?.isValid,
    errors: pp?.errors,
    selected: providers.length === 1,
  }));
  choisesProviders.sort((x, y) =>
    x.disabled === y.disabled ? 0 : x.disabled ? 1 : -1
  );
  return (
    <div className={styles.marginRadio}>
      <SelectV2
        name="paymentProvider"
        choices={choisesProviders}
        label=""
        defaultValue={
          choisesProviders.length === 1 ? choisesProviders[0].value : ""
        }
      />
    </div>
  );
};
