import { create } from "zustand";
import { getSupplierVariantProcurementV2 } from "@/http";

type TUseProductProcurement = {
  getProductProcurement: (...args: any) => void;
  productProcurement: any[];
  isLoading: boolean;
  totalPages: number;
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
};
export const useProductProcurement = create<TUseProductProcurement>(
  (set, get) => ({
    getProductProcurement: async (productId: string) => {
      set(() => ({ isLoading: true }));
      try {
        const { currentPage } = get();
        if (productId) {
          const procurement = await getSupplierVariantProcurementV2(productId, {
            page: currentPage,
            size: 10,
            sort: "createdAt,desc",
          });

          set({
            productProcurement: procurement.content,
            isLoading: false,
            totalPages: procurement.totalPages,
          });
        }
      } catch (error) {
        set(() => ({ productProcurement: [], isLoading: false }));
      }
    },
    productProcurement: [],
    isLoading: false,
    totalPages: 0,
    currentPage: 0,
    setCurrentPage: currentPage => set(() => ({ currentPage })),
  })
);
