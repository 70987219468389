import { Tab } from "@headlessui/react";
import clsx from "clsx";
import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import styles from "./styles.module.scss";

type TabsHeadersContextValue = {
  headers: Set<string>;
  setHeaders: Dispatch<SetStateAction<Set<string>>>;
};

const TabsHeadersContext = createContext<TabsHeadersContextValue>({
  headers: new Set(),
  setHeaders: () => undefined,
});

export const Tabs: FC<{ children: ReactNode; panelsClassName?: string }> = ({
  children,
  panelsClassName,
}) => {
  const [headers, setHeaders] = useState<Set<string>>(new Set());
  return (
    <TabsHeadersContext.Provider value={{ headers, setHeaders }}>
      <Tab.Group>
        <Tab.List className={styles.tabList}>
          {[...headers].map(tab => (
            <Tab
              key={tab}
              className={({ selected }) =>
                clsx(styles.tab, selected ? styles.selected : styles.unselected)
              }
            >
              {tab}
            </Tab>
          ))}
        </Tab.List>
        <div className="spacer" />
        <Tab.Panels className={panelsClassName}>{children}</Tab.Panels>
      </Tab.Group>
    </TabsHeadersContext.Provider>
  );
};

type TabItemProps = {
  label: string;
  children: ReactNode;
  className?: string;
};

export const TabItem: FC<TabItemProps> = ({ children, label, className }) => {
  const { setHeaders } = useContext(TabsHeadersContext);

  useEffect(() => {
    setHeaders(prev => new Set([...prev, label]));
    return () => {
      setHeaders(prev => new Set([...prev].filter(l => l !== label)));
    };
  }, []);

  return <Tab.Panel className={className}>{children}</Tab.Panel>;
};
