import { formatCurrency } from "@/utils/currency";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

type TTieredDiscountSupplierTable = {
  sales: any[];
  retailPrice: number;
};
export const TieredDiscountSupplierTable = ({
  sales,
  retailPrice,
}: TTieredDiscountSupplierTable) => {
  const [t] = useTranslation("products-page");

  return (
    <div className={`noPadding ${styles.containerTable}`}>
      <table className={`${styles.tableScaled} ${styles.table}`}>
        <thead>
          <tr>
            <th>{t("AMOUNT")}</th>
            <th>{t("DISCOUNT_SMALL")} (%)</th>
            <th>{t("PRICE_SMALL")}</th>
          </tr>
        </thead>
        <tbody>
          {sales?.map((sale: any, index: number) => (
            <tr key={index}>
              <td>
                {sale.valueType === "UNITS"
                  ? sale.minValue
                  : formatCurrency(sale.minValue)}
              </td>
              <td>{sale.discountPercentage}%</td>
              <td>
                {formatCurrency(
                  retailPrice
                    ? retailPrice -
                        (retailPrice * sale.discountPercentage) / 100
                    : 0
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
