import { DashboardGrid } from "@/components/dashboard/DashboardGrid";
import { DRIVE_VIDEOS } from "@/constants/drive-videos";
import { useTranslation } from "react-i18next";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useSubscription } from "@/hooks/useSubscription";
import { FEAT_BASIC_DASHBOARD } from "@/constants/features";
import { LockedFeaturePlaceholder } from "@/components/subscription/LockedFeaturePlaceholder";

const DashboardPage: React.FC = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");
  const { hasFeature } = useSubscription();

  useEffect(() => {
    setTitle(t("SIDEBAR.DASHBOARD"), "", DRIVE_VIDEOS.DASHBOARD);
  }, [t]);

  if (!hasFeature(FEAT_BASIC_DASHBOARD)) {
    return <LockedFeaturePlaceholder />;
  }

  return <DashboardGrid />;
};

export default DashboardPage;
