import { QUOTED, TPurchase } from "@/http";
import { FC } from "react";
import { CancelPurchase } from "../CancelPurchase/CancelPurchase";
import { ShippingPurchase } from "../ShippingPurchase/ShippingPurchase";
import { CreatePurchaseFromQuote } from "../Quotes/CreatePurchase";
import { PurchaseRetryButton } from "../RetryPurchase/RetryPurchase";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import { CancelQuote } from "../Quotes/CancelQuote";
import { useTranslation } from "react-i18next";
import { UpdateProducts } from "./UpdateProducts";
import { useAuth } from "@/store/auth";
import { ConfirmPurchaseDelivery } from "./ConfirmPurchaseDelivery";
import { InvoiceButton } from "@/components/order-details/ProductsDetails/InvoiceButton";
import { useHasInvoiceIntegration } from "@/hooks/useIntegrations";
import { InvoiceManual } from "@/components/purchases/PurchaseShow/Actions/InvoiceManual";

export type Props = {
  purchase: TPurchase;
};
export const PurchaseActions: FC<Props> = ({ purchase }) => {
  const [t] = useTranslation("purchases");
  const { user } = useAuth();

  const isStockIn = purchase?.sellerId === user.id;

  const hasInvoicingIntegration = useHasInvoiceIntegration();

  return (
    <>
      <div className={`flexGap flexSmColumn ${styles.actions}`}>
        {!isStockIn && (
          <>
            {hasInvoicingIntegration ? (
              <InvoiceButton
                products={purchase.products}
                id={purchase.id}
                type={"PURCHASE"}
              />
            ) : (
              <>{!purchase?.hasInvoice && <InvoiceManual id={purchase.id} />}</>
            )}
          </>
        )}
        <UpdateProducts purchase={purchase} />
        {purchase.status === QUOTED ? (
          <>
            <div>
              <CancelQuote purchaseId={purchase.id} />
            </div>
            <div>
              <CreatePurchaseFromQuote
                purchaseId={purchase.id}
                purchaseProducts={purchase.products}
              />
            </div>
          </>
        ) : (
          <>
            <ShippingPurchase purchase={purchase} />
            <CancelPurchase purchase={purchase} />
            <PurchaseRetryButton purchase={purchase} />

            {isStockIn && <ConfirmPurchaseDelivery purchase={purchase} />}
          </>
        )}
        {!isStockIn && (
          <div>
            <Link to={`/purchases/${purchase.id}/invoice`} target="_blank">
              <button className="outlined">{t("SEE_PDF")}</button>
            </Link>
          </div>
        )}
      </div>
    </>
  );
};
