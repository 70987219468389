import { TRestListData, TRestListParams } from "@/http/types/rest";
import { getInstance } from "../../core/axios-gateway";

const BASE_PATH = "/api/integrations";

export interface TSupplierIntegration {
  id: string;
  type: string;
  supplier: string;
  name: string;
  version: string;
  description: string;
  path: string;
  enabled: boolean;
  createdAt: string;
  updatedAt: null;
  deletedAt: null;
  credentialId?: string;
  imageUrl?: string;
}

export interface TIntegration {
  id: string;
  checks: string[];
  type: string;
  supplier: string;
  name: string;
  version: string;
  description: string;
  path: string;
  enabled: boolean;
  createdAt: string;
  updatedAt: null;
  deletedAt: null;
  credentials: TIntegrationField[];
  parameters: TIntegrationField[];
  credentialId?: string;
  imageUrl?: string;
  authUrl?: string;
}

export interface TIntegrationField {
  id: string;
  name: string;
  key: string;
  description: string;
  hint: string;
  visible: boolean;
  format: "TEXT" | "SELECT" | "BOOLEAN";
}

export const getIntegrations = async (supplierId: string) => {
  const params: TRestListParams & { supplierId: string } = {
    size: 100,
    supplierId,
  };
  return getInstance()
    .get<TRestListData<TSupplierIntegration>>(BASE_PATH, { params })
    .then(res => res.data);
};

export const getIntegration = async (integrationId: string) => {
  return getInstance()
    .get<TIntegration>(`${BASE_PATH}/${integrationId}`)
    .then(res => res.data);
};

export interface TCredential {
  id: string;
  integrationId: string;
  supplierId: string;
  enabled: boolean;
  values: Record<string, string>;
  credentials: Record<string, string>;
  parameters: Record<string, string>;
  validations: TCredentialValidation[];
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export interface TCredentialValidation {
  id: string;
  integrationCredentialId: string;
  type: string;
  description: string;
  recordsToVerify: number;
  recordsVerified: number;
  information: Record<string, string>;
  lastCheck: string;
  status: "PENDING" | "INITIALIZED" | "IN_PROGRESS" | "DONE";
}

export const getIntegrationCredential = async (credentialId: string) => {
  return getInstance()
    .get<TCredential>(`${BASE_PATH}/credentials/${credentialId}`)
    .then(res => {
      res.data.validations.sort((a, b) => b.type.localeCompare(a.type));
      return res.data;
    });
};

export const deleteIntegrationCredential = async (credentialId: string) => {
  return getInstance().delete(`${BASE_PATH}/credentials/${credentialId}`);
};

export interface CreateCredentialPayload {
  supplierId: string;
  integrationId: string;
  credentials: any;
}

export const createIntegrationCredentials = async (
  body: CreateCredentialPayload
) => {
  return getInstance()
    .post(`${BASE_PATH}/credentials`, body)
    .then(res => res.data);
};

export const createOauthCredentials = async (body: CreateCredentialPayload) => {
  return getInstance()
    .post(`${BASE_PATH}/credentials-oauth`, body)
    .then(res => res.data);
};

export const updateIntegrationCredentials = async (
  credentialId: string,
  body: { parameters?: any; credentials?: any }
) => {
  return getInstance()
    .put(`${BASE_PATH}/credentials/${credentialId}`, body)
    .then(res => res.data);
};

export const runCredentialSynchronizations = async (credentialId: string) => {
  return getInstance()
    .put<TCredentialValidation[]>(
      `${BASE_PATH}/credentials/${credentialId}/validations`
    )
    .then(res => res.data);
};

export const runCredentialSynchronization = async (
  credentialId: string,
  type: string
) => {
  return getInstance()
    .put<TCredentialValidation>(
      `${BASE_PATH}/credentials/${credentialId}/validations/${type}`
    )
    .then(res => res.data);
};

export const updateIntegrationCredentialParameters = async (
  credentialId: string,
  data: any
) => {
  return getInstance()
    .put(`${BASE_PATH}/credentials/${credentialId}/parameters`, data)
    .then(res => res.data);
};

export const getIntegrationCredentialParameterValues = async (
  credentialId: string,
  parameterKey: string
) => {
  return getInstance()
    .get<{ text: string; value: string }[]>(
      `${BASE_PATH}/credentials/${credentialId}/parameters/${parameterKey}/values`
    )
    .then(res => res.data);
};

export const getInvoices = (subPurchaseId: string) => {
  return getInstance()
    .get<{ id: string; name: string }[]>(
      `/api/invoice-gateway/sub-purchase/${subPurchaseId}`
    )
    .then(res => res.data);
};

export const getInvoicesByIds = (ids: string[]) => {
  return getInstance()
    .post<{ id: string; name: string }[]>(`/api/invoice-gateway/by-ids`, {
      ids,
    })
    .then(res => res.data);
};

export interface InvoiceType {
  text: string;
  value: string;
}

export const getInvoiceTypes = async (supplierId: string) => {
  return getInstance()
    .get<InvoiceType[]>(
      `/api/invoice-gateway/supplier/${supplierId}/invoice-types`
    )
    .then(({ data }) => {
      data.sort((a, b) => a.text.localeCompare(b.text));
      return data;
    });
};

export interface InvoicePayload {
  invoiceTypeId: string;
  productIds: string[];
  sendToTaxCollector: boolean;
  notifyClientByEmail: boolean;
}

export const createInvoice = (
  subPurchaseId: string,
  payload: InvoicePayload,
  type?: string
) => {
  const path = type === "PURCHASE" ? "purchase" : "sub-purchase";
  return getInstance().post(
    `/api/invoice-gateway/${path}/${subPurchaseId}`,
    payload
  );
};

export const createPendingInvoice = (payload: any) => {
  return getInstance().post(`/api/invoice-gateway/sub-purchase`, payload);
};

export const getInvoicePDFLink = async (
  subPurchaseId: string,
  invoiceId: string,
  type?: string
) => {
  const path = type === "PURCHASE" ? "purchase" : "sub-purchase";
  return getInstance()
    .get<{ url: string }>(
      `/api/invoice-gateway/${path}/${subPurchaseId}/invoices/${invoiceId}/file`
    )
    .then(res => res.data);
};
