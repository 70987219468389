import { useAuth } from "@/store/auth";
import { useSideBarStatus } from "@/store/ui";
import { hasPermission } from "@/utils/validations/permissions";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { RxExit } from "react-icons/rx";
import { HiOutlineFaceSmile } from "react-icons/hi2";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router";
import { createPortal } from "react-dom";
import { PiGearSixBold } from "react-icons/pi";
import { PremiumIcon } from "@/lib/subscriptions/components/icons/Premium";
import { onboardingSelectors } from "@/components/onboarding/selectors";
import { useTour } from "@reactour/tour";
import { FiUser } from "react-icons/fi";
import { useSubscription } from "@/hooks/useSubscription";
import { FEAT_CATALOG } from "@/constants/features";

export const AccountMenu = ({ onClick }: { onClick?: () => void }) => {
  const { user, isCommercial, role, logout } = useAuth();
  const [t] = useTranslation("global");
  const [isActive, setIsActive] = useState(false);
  const { isSideBarCollapsed, setSideBarCollapsed } = useSideBarStatus();
  const navigate = useNavigate();
  const fixedDivRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const { isOpen: isOnboarding } = useTour();
  const { hasFeature } = useSubscription();

  const onNavigate = (url: string) => {
    setSideBarCollapsed(true);
    setIsActive(false);
    navigate(url);
    onClick?.();
  };

  useEffect(() => {
    if (isSideBarCollapsed) {
      setIsActive(false);
    }
  }, [isSideBarCollapsed]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        !isOnboarding &&
        optionsRef.current &&
        !optionsRef.current.contains(event.target as Node) &&
        fixedDivRef.current &&
        !fixedDivRef.current.contains(event.target as Node)
      ) {
        setIsActive(false);
      }
    };

    const handleOutsideScroll = (event: Event) => {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target as Node)
      ) {
        setIsActive(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    document.addEventListener("wheel", handleOutsideScroll);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("wheel", handleOutsideScroll);
    };
  }, [isOnboarding]);

  return (
    <div className={styles.containerConfig} ref={fixedDivRef}>
      <div
        data-onboarding={onboardingSelectors.accountMenu}
        className="flexGap center centerAlign pointer"
        onClick={() => setIsActive(true)}
      >
        <span className={styles.acountButton}>
          <FiUser size={"1.2rem"} />
        </span>
        <div className={isSideBarCollapsed ? styles.hide : ""}>
          <span className="textLine">{t("USER_CONFIG")}</span>
        </div>
      </div>

      {isActive &&
        createPortal(
          <div
            className={styles.configMenu}
            ref={optionsRef}
            style={{
              top: fixedDivRef.current
                ? `${
                    fixedDivRef.current.getBoundingClientRect().top -
                    310 +
                    (window.pageYOffset || document.documentElement.scrollTop)
                  }px`
                : "0",
              left: fixedDivRef.current
                ? `${fixedDivRef.current.getBoundingClientRect().left + 20}px`
                : "100%",
            }}
          >
            <div className="bold contentInline">
              <span>{`${t("HELLO")}, ${user.name}!`}</span>
              <span
                className="pointer bolder"
                onClick={() => setIsActive(false)}
              >
                x
              </span>
            </div>
            <div className="divider" />

            {hasPermission(isCommercial, role, ["route.account.show"]) && (
              <div
                className="flexGap center pointer bold phmd grayHover"
                onClick={() => onNavigate("/account")}
              >
                <HiOutlineFaceSmile height={"1rem"} width="1rem" />
                <span className="textLine">{t("USER_MENU.PROFILE")}</span>
              </div>
            )}
            {(hasPermission(isCommercial, role, ["route.integrations"]) ||
              hasPermission(isCommercial, role, ["route.paymnts.list"])) && (
              <>
                <div className="flexGap center phmd">
                  <PiGearSixBold height={"1rem"} width="1rem" />
                  <span className="textLine bold">{t("USER_MENU.CONFIG")}</span>
                </div>
                {hasPermission(isCommercial, role, ["route.paymnts.list"]) && (
                  <div
                    className="ml2 phmd pointer grayHover"
                    onClick={() => onNavigate("/payments")}
                  >
                    {t("USER_MENU.PAYMENTS")}
                  </div>
                )}
                {hasPermission(isCommercial, role, ["route.integrations"]) && (
                  <div
                    data-onboarding={onboardingSelectors.integrations}
                    className="ml2 phmd pointer grayHover"
                    onClick={() => onNavigate("/integrations")}
                  >
                    {t("USER_MENU.INTEGRATIONS")}{" "}
                    <PremiumIcon className="purpleText" />
                  </div>
                )}
                {hasFeature(FEAT_CATALOG) && (
                  <div
                    className="ml2 phmd pointer grayHover"
                    onClick={() => onNavigate("/customize")}
                  >
                    {t("USER_MENU.CUSTOMIZE")}{" "}
                    <PremiumIcon className="purpleText" />
                  </div>
                )}
              </>
            )}

            {hasPermission(isCommercial, role, ["route.help.show"]) && (
              <div
                className="flexGap center pointer bold phmd grayHover"
                onClick={() => onNavigate("/help")}
              >
                <AiOutlineInfoCircle height={"1rem"} width="1rem" />
                <span className="textLine">{t("SIDEBAR.HELP")}</span>
              </div>
            )}
            <div
              className="flexGap center pointer bold phmd grayHover"
              onClick={logout}
            >
              <RxExit height={"1rem"} width="1rem" />
              <span className="textLine">{t("USER_MENU.LOGOUT")}</span>
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};
