import { Modal } from "@/components/shared/Modal/Modal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlinePlusCircle } from "react-icons/ai";
import styles from "./styles.module.scss";

type TModalComment = {
  comment: string;
};
export const ModalComment = ({ comment }: TModalComment) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [t] = useTranslation("orders-page");

  return (
    <>
      <div className={styles.icon}>
        <AiOutlinePlusCircle onClick={() => setModalOpen(true)} size="1.1rem" />
      </div>
      <Modal
        isOpen={isModalOpen}
        size="sm"
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <div className={styles.modalContent}>
          <h6>{t("DETAILS.REJECT_REASON")}</h6>
          <div className="divider" />
          <p>{comment}</p>
        </div>
      </Modal>
    </>
  );
};
