import { TUnitType } from "@/http";
import { useTranslation } from "react-i18next";
import { UnitTypeModal } from "./UnitTypeModal";
import { UnitTypesTable } from "./UnitTypesTable";

type TUnitTypes = {
  unitTypes: TUnitType[];
};
export const UnitTypes = ({ unitTypes }: TUnitTypes) => {
  const [t] = useTranslation("global");

  return (
    <>
      <div className="contentInline">
        <span className="bold">{t("UNIT_TYPES")}</span>
        <UnitTypeModal />
      </div>

      <UnitTypesTable unitTypes={unitTypes} />
    </>
  );
};
