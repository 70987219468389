import { defineLib } from "../define-lib";
import { SUBSCRIPTIONS } from "./constants";
import en from "./lang/en.json";
import es from "./lang/es.json";
import { Packages } from "./components/Packages";

export const LibSubscriptions = defineLib({
  name: SUBSCRIPTIONS,
  lang: { en, es },
  components: {
    Packages,
  },
});
