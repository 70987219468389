import { Modal } from "@/components/shared/Modal/Modal";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IoMdRemoveCircle } from "react-icons/io";
import { RED_ALERT } from "@/constants/colors";
import { DragAndDropFile } from "@/components/shared/DragAndDropFIle/DragAndDropFIle";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { AiOutlineLink } from "react-icons/ai";
import { uploadImagePaymentObject } from "@/utils/image-upload";

export const UploadVoucher = () => {
  const { setValue, watch } = useFormContext();
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const files = watch("files");

  const addUrl = async (files: any) => {
    setValue("files", files);
    const { src } = await uploadImagePaymentObject(files[0]);
    setValue("voucherUrl", src);
  };

  const deleteFiles = () => {
    setValue("files", []);
  };

  return (
    <>
      <div className="rigthAlign">
        <ToolTip title={t("ADD_VOUCHER")} position="Left">
          <button
            onClick={() => {
              setIsOpen(true);
            }}
            className={files && files.length ? "primary" : "purple"}
          >
            <AiOutlineLink size={"1.1rem"} />
          </button>
        </ToolTip>
      </div>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        size="sm"
        title={t("ADD_VOUCHER")}
      >
        <div className="spacer" />
        {(!files || files.length == 0) && (
          <DragAndDropFile
            minifiedStyle
            accept="image/png,image/jpeg"
            files={[]}
            setFiles={addUrl}
          />
        )}
        {files && files.length > 0 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              marginTop: "0.7rem",
            }}
          >
            <ToolTip title={t("DELETE")} position="Right">
              <IoMdRemoveCircle
                color={RED_ALERT}
                cursor="pointer"
                onClick={() => deleteFiles()}
              />
            </ToolTip>
            {files[0].name}
          </div>
        )}
      </Modal>
    </>
  );
};
