import { TSupplierAddress } from "@/http";
import { useTranslation } from "react-i18next";
import { AiFillStar } from "react-icons/ai";
import { BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";
import AddressDelete from "../AddressDelete/AddressDelete";
import AddressDetail from "../AddressDetail";
import styles from "./styles.module.scss";

type TAddressCard = {
  address: TSupplierAddress;
};
export const AddressCard = ({ address }: TAddressCard) => {
  const [t] = useTranslation("global");
  return (
    <div className={styles.container}>
      {address.isMain && (
        <div className={`bold flexGap ${styles.mainAddress}`}>
          <span className={styles.mainIcon}>
            <AiFillStar size={"0.8rem"} />
          </span>
          {t("MAIN_ADDRESS")}
        </div>
      )}
      <div className={styles.addressCard}>
        <div>
          <p className="bold">{address.name}</p>
          <p>{address.addressLine1}</p>
          {address.addressLine2 && <p>{address.addressLine2}</p>}
          <p>{`${address.city} | ${address.state}`}</p>
          {!!address.phone && <p>{`Tel. ${address.phone}`}</p>}
          {!!address?.contactName && (
            <p>
              <span className="bold">{`${t(
                "ACCOUNT.ADDRESSES.CONTACT_NAME"
              )}: `}</span>
              {address.contactName}
            </p>
          )}

          <p className="flexGap centerVAlign">
            {address?.overStock ? (
              <span className="greenText">
                <BsFillCheckCircleFill />
              </span>
            ) : (
              <span className="redText">
                <BsFillXCircleFill />
              </span>
            )}
            {t("OVERSTOCK")}
          </p>
        </div>
        <div className={styles.actions}>
          <AddressDetail address={address} />
          <AddressDelete address={address} />
        </div>
      </div>
    </div>
  );
};
