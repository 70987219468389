import clsx from "clsx";
import { debounce } from "lodash-es";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type TSwitchLocalFlag = {
  item: string;
  setLoading: (value: boolean) => void;
  isLoading: boolean;
};
export const SwitchLocalFlag = ({
  item,
  setLoading,
  isLoading,
}: TSwitchLocalFlag) => {
  const [t] = useTranslation("global");
  const [value, setValue] = useState(localStorage.getItem(item) === "true");

  const updateFlag = () => {
    setLoading(true);
    const newValue = value ? "false" : "true";
    localStorage.setItem(item, newValue);
    setValue(newValue === "true");
    setLoading(false);
  };

  const handleChange = async () => {
    setLoading(true);
    try {
      const debounceUpdate = debounce(updateFlag, 1000);
      debounceUpdate();
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <div className="flexGap centerVAlign">
      <label className="switchBtn">
        <input
          type="checkbox"
          onChange={handleChange}
          checked={value}
          className={clsx({ ["loading"]: isLoading })}
        />
        <div className={"slide"}></div>
      </label>
      <span>{t(`LOCAL_FLAG.${item?.toUpperCase()}`)}</span>
    </div>
  );
};
