import { CreditsList } from "@/components/credits/CreditsList";
import { LockedFeaturePlaceholder } from "@/components/subscription/LockedFeaturePlaceholder";
import { FEAT_CREDIT } from "@/constants/features";
import { useSubscription } from "@/hooks/useSubscription";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const CreditsPage = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");
  const { hasFeature } = useSubscription();

  useEffect(() => {
    setTitle(t("CREDITS.TITLE"));
  }, [t]);

  if (!hasFeature(FEAT_CREDIT)) {
    return <LockedFeaturePlaceholder />;
  }

  return <CreditsList />;
};

export default CreditsPage;
