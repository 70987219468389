import { ClientModal } from "@/components/clients/ClientModal";
import Input from "@/components/shared/Input/Input";
import { getSellers } from "@/http";
import { useAuth } from "@/store/auth";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { ClientNameChip } from "./ClientNameChip";

type TClientInput = {
  name: string;
  products: string[];
  setProducts: (products: string[]) => void;
  onDelete: (idx: number) => void;
};

export const ClientInput = ({
  name,
  products,
  setProducts,
  onDelete,
}: TClientInput) => {
  const {
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();
  const [t] = useTranslation("purchases");
  const { user } = useAuth();
  const [options, setOptions] = useState<Record<string, string>[]>([]);
  const [showOptions, setShowOptions] = useState(false);
  const debounceRefSeller = useRef<any>();
  const debounceRefSellerLastName = useRef<any>();
  const optionsRef = useRef<HTMLDivElement>(null);
  const sellerRef = useRef<HTMLDivElement>(null);
  const sellerLastNameRef = useRef<HTMLDivElement>(null);
  const sellerName = useWatch({ name: "seller" });
  const sellerLastName = useWatch({ name: "sellerLastName" });

  const onSellerNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (debounceRefSeller.current) {
      clearTimeout(debounceRefSeller.current);
    }

    debounceRefSeller.current = setTimeout(() => {
      getFilterSellers(event.target.value, sellerLastName);
    }, 250);
  };

  const onSellerLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (debounceRefSellerLastName.current) {
      clearTimeout(debounceRefSeller.current);
    }

    debounceRefSellerLastName.current = setTimeout(() => {
      getFilterSellers(sellerName, event.target.value);
    }, 250);
  };

  const getFilterSellers = async (name: string, lastName: string) => {
    const { content } = await getSellers({
      search: `${name ? `firstName:${name},` : ""}${
        lastName ? `lastName:${lastName},` : ""
      }supplierId:${user.id}`,
    });
    setOptions(
      content.map(({ id, email, firstName, lastName }) => ({
        id,
        email,
        firstName,
        lastName,
      }))
    );
    setShowOptions(true);
  };

  const setSeller = ({ id }: Record<string, string>) => {
    const parsedValuesSet = new Set([...products, id]);
    setProducts(Array.from(parsedValuesSet));
    setValue("seller", "");
    setValue("sellerLastName", "");
    setShowOptions(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target as Node) &&
        sellerRef.current &&
        !sellerRef.current.contains(event.target as Node) &&
        sellerLastNameRef.current &&
        !sellerLastNameRef.current.contains(event.target as Node) &&
        showOptions
      ) {
        setValue("seller", "");
        setValue("sellerLastName", "");
        setValue("sellerId", "");
        trigger(["seller", "sellerLastName", "sellerId"]);
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className={styles.containerInput}>
        <div className={`flexGap ${styles.inputs}`}>
          <div ref={sellerRef} className="col-6 noPadding">
            <Input
              name="seller"
              label={t("SELLER_NAME")}
              type="text"
              placeholder={t("SELLER_NAME_PLACEHOLDER")}
              register={register}
              onChange={onSellerNameChange}
              error={errors.seller}
            />
          </div>
          <div ref={sellerLastNameRef} className="col-6 noPadding">
            <Input
              name="sellerLastName"
              label={t("SELLER_LAST_NAME")}
              type="text"
              placeholder={t("SELLER_LAST_NAME_PLACEHOLDER")}
              register={register}
              onChange={onSellerLastNameChange}
              error={errors.sellerLastName}
            />
          </div>
        </div>
        {showOptions && (
          <div ref={optionsRef}>
            <ul>
              {options.map(option => (
                <li key={option.id} onClick={() => setSeller(option)}>
                  {`${option.firstName} ${option.lastName}`}

                  <span className={styles.email}> {option.email}</span>
                </li>
              ))}
              {!options?.length && <li>{t("NO_OPTIONS")}</li>}
            </ul>
          </div>
        )}
        <div className="rigthAlign">
          <ClientModal
            onDone={client => {
              setValue("seller", client.firstName);
              setValue("sellerLastName", client.lastName);
              getFilterSellers(client.firstName, client.lastName);
            }}
          />
        </div>
      </div>
      <br />

      {products?.length > 0 && (
        <div>
          {products.map((product, idx) => (
            <ClientNameChip
              key={product}
              id={product}
              onDelete={() => onDelete(idx)}
            />
          ))}
        </div>
      )}
    </>
  );
};
