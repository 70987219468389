import { useAuth } from "@/store/auth";
import { FormEventHandler, useState } from "react";
import { SafeParseError } from "zod";
import useSWRImmutable from "swr/immutable";
import { AxiosError, getSeeriApi, getSegments } from "@/http";
import styles from "../styles.module.scss";
import { groupBy } from "lodash-es";
import { BulkImportMode, ResultStatus, UploadResult } from "./types";
import { apiModes } from "./modes";
import { useProducts } from "@/store/products";
import { useTranslation } from "react-i18next";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { toast } from "react-toastify";
import { useAddresses } from "@/store/profile";
import useSWR from "swr";

export const BulkUpload = ({ mode }: { mode: BulkImportMode }) => {
  const [globalT] = useTranslation("global");
  const [t] = useTranslation("products-page");
  const { addresses } = useAddresses();
  const { data: segments = [] } = useSWR("segments", getSegments);

  const warehousesMap = new Map<string, string>(
    addresses.content.map((w, i) => [
      w.id,
      `${t("WAREHOUSE")} ${w.name ?? i + 1}`,
    ])
  );

  const segmentPricesMap = new Map<string, string>(
    segments.map(s => [`segmentPrice_${s.id}`, `${t("PRICE")}: ${s.name}`])
  );

  const segmentUnitPricesMap = new Map<string, string>(
    segments.map(s => [
      `segmentUnitPrice_${s.id}`,
      `${t("UNIT_PRICE")}: ${s.name}`,
    ])
  );

  const statusTexts = {
    created: t("BULK_CHARGE.UPLOAD.STATUS.CREATED"),
    modified: t("BULK_CHARGE.UPLOAD.STATUS.MODIFIED"),
    unmodified: t("BULK_CHARGE.UPLOAD.STATUS.UNMODIFIED"),
    assigned: t("BULK_CHARGE.UPLOAD.STATUS.ASSIGNED"),
    error: t("BULK_CHARGE.UPLOAD.STATUS.ERROR"),
  };

  const apiMode = apiModes[mode];
  const supplier = useAuth(s => s.user);
  const loadProducts = useProducts(s => s.getVariants);
  const refreshTable = () => loadProducts(supplier.id);

  const [uploading, setUploading] = useState(false);
  const [validationErr, setValidationErr] = useState<SafeParseError<unknown>>();
  const [uploadResult, setUploadResult] = useState<UploadResult>();

  const { data: headers } = useSWRImmutable(`${apiMode.path}/headers`, path =>
    getSeeriApi()
      .get(path)
      .then(res => res.data)
  );

  const handleSubmit: FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault();
    const form = event.currentTarget;
    const formData = new FormData(form);

    setUploading(true);
    setValidationErr(undefined);
    setUploadResult(undefined);
    try {
      const data = await getSeeriApi()
        .request({
          method: apiMode.method,
          url: apiMode.path + "/upload",
          data: formData,
          params: { supplierId: supplier.id },
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(res => res.data);
      setUploadResult(data);
      refreshTable();
      form.reset();
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response && error.response.status < 500) {
          setValidationErr(error.response?.data);
        } else {
          toast.error(globalT("ERROR_SOME_BAD").toString());
        }
      }
    }
    setUploading(false);
  };

  return (
    <div className={styles.bulkContainer}>
      <form onSubmit={handleSubmit} className={styles.spacing}>
        <input
          name="excel"
          type="file"
          multiple={false}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          className={styles.whiteContainer}
          required
        />
        {mode === "CREATE" && (
          <>
            <p>{t("BULK_CHARGE.UPLOAD.CHOOSE_IMAGES")}</p>
            <input
              name="images[]"
              type="file"
              multiple
              accept=".jpeg, .jpg, .png"
              required
              className={styles.whiteContainer}
            />
          </>
        )}
        <div className="contentInline">
          {uploading ? <Spinner /> : <span />}
          <button className="primary" disabled={uploading}>
            {t("BULK_CHARGE.UPLOAD.START_UPLOAD_PROCESS")}
          </button>
        </div>
      </form>

      <br />

      {validationErr && (
        <div className={styles.alert__warning}>
          <p>
            <strong>{t("BULK_CHARGE.UPLOAD.VALIDATION_ERROR_FOUND")}</strong>
          </p>

          <ul>
            {Object.entries(groupBy(validationErr?.error.issues, "path.0")).map(
              ([productIdx, issues]) => (
                <li key={productIdx}>
                  <h3>
                    {t("BULK_CHARGE.UPLOAD.PRODUCT")} {+productIdx + 1}
                  </h3>
                  <ul>
                    {issues.map((issue, i) => (
                      <li key={i}>
                        <b>
                          {headers[issue.path[1]] ??
                            warehousesMap.get(issue.path[1] as string) ??
                            segmentPricesMap.get(issue.path[1] as string) ??
                            segmentUnitPricesMap.get(issue.path[1] as string) ??
                            issue.path[1] ??
                            "???"}
                          :
                        </b>{" "}
                        <span
                          dangerouslySetInnerHTML={{ __html: issue.message }}
                        />
                      </li>
                    ))}
                  </ul>
                </li>
              )
            )}
          </ul>
        </div>
      )}

      {uploadResult && (
        <div className={styles.spacing}>
          <p>
            <strong>{t("BULK_CHARGE.UPLOAD.PROCESS_COMPLETED")}</strong>
          </p>

          {Object.entries(groupBy(uploadResult.results, "status")).map(
            ([status, results]) => (
              <div key={status} className={styles["alert__" + status]}>
                <p>
                  <strong>{statusTexts[status as ResultStatus]}</strong>:{" "}
                  <strong>{results.length}</strong>{" "}
                  {t("BULK_CHARGE.UPLOAD.ROWS")}.
                </p>
                <ul>
                  {results.map((result, i) => (
                    <li key={i}>
                      <b>{result.row.name}</b>
                      {result.message && <>: {result.message}</>}
                    </li>
                  ))}
                </ul>
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};
