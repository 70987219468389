import { InputV2 } from "@/components/shared/Input/InputV2";
import { formatCurrency } from "@/utils/currency";
import { validateDecimalNumber } from "@/utils/keyboard";
import { ReactNode, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaCartArrowDown } from "react-icons/fa";
import { MdOutlineEdit } from "react-icons/md";

import styles from "./Prepurchase.module.scss";

type TShipLabel = {
  name: string | ReactNode;
  price: number;
  bold?: boolean;
  showZero?: boolean;
  negative?: boolean;
  secondary?: boolean;
  helper?: string;
  hideDivider?: boolean;
  green?: boolean;
  purpleResult?: boolean;
  editable?: boolean;
};
export const ShipLabel = ({
  name,
  bold,
  price,
  negative,
  showZero,
  secondary,
  helper,
  hideDivider = false,
  green = false,
  purpleResult = false,
  editable = false,
}: TShipLabel) => {
  if (price === 0 && !showZero) return null;
  const [isEdit, setIsEdit] = useState(false);
  const [t] = useTranslation("global");
  const { watch, setValue, trigger } = useFormContext();

  const setShipping = async () => {
    const isValid = await trigger("editableShipping");
    if (isValid) {
      const shipping = watch("editableShipping");
      setValue("shippingPrice", shipping);
      setIsEdit(false);
    }
  };

  return (
    <>
      <div className={styles.priceLabel}>
        <div
          className={green ? "greenText" : secondary ? styles.secondary : ""}
        >
          {bold ? <h6 className="bold">{name}</h6> : <p>{name}</p>}
          {helper && <span className={styles.helper}>{helper}</span>}
        </div>
        <div
          className={` flexGap center${bold ? "bold" : ""} ${
            purpleResult
              ? "statusColorHelper purpleText pw1 bolder phmd"
              : green
              ? "greenText"
              : secondary
              ? styles.secondary
              : negative
              ? styles.danger
              : ""
          }`}
        >
          {!isEdit && (
            <>
              {negative && !green && <FaCartArrowDown />}
              {formatCurrency(price)}
            </>
          )}
          {editable && !isEdit && (
            <span className="pointer circleButton">
              <MdOutlineEdit
                onClick={() => {
                  setIsEdit(true);
                }}
              />
            </span>
          )}
          {isEdit && (
            <div className="flexColumn">
              <InputV2
                name="editableShipping"
                label=""
                required
                onKeyPress={validateDecimalNumber}
              />
              <div className="rigthAlign">
                <button onClick={() => setIsEdit(false)}>{t("CANCEL")}</button>
                <button onClick={setShipping} className="primary">
                  {t("SAVE")}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {!hideDivider && <div className="divider" />}
    </>
  );
};
