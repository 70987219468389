import ToolTip from "../shared/Tooltip/Tooltip";
import { useTranslation } from "react-i18next";
import { PremiumIcon } from "@/lib/subscriptions/components/icons/Premium";
import { useSubscription } from "@/hooks/useSubscription";
import { Link } from "react-router-dom";

export const PremiumFeatureIndicator = ({
  feature: featureCode,
  size = 18,
}: {
  feature: string;
  size?: number;
}) => {
  const [t] = useTranslation("global");
  const { hasFeature, features } = useSubscription();

  const feature = features.find(f => f.code === featureCode);

  return (
    <ToolTip
      title={
        <>
          <span className="font-normal">
            {t("SUBSCRIPTION.PREMIUM_FEATURE")}:
          </span>{" "}
          <span className="bold">{feature?.name}</span>
        </>
      }
      containerClass="purpleText"
    >
      {hasFeature(featureCode) ? (
        <PremiumIcon width={size} height={size} />
      ) : (
        <Link to="/subscription" onClick={e => e.stopPropagation()}>
          <PremiumIcon width={size} height={size} />
        </Link>
      )}
    </ToolTip>
  );
};
