import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { useCategoriesTree } from "@/hooks/useCategory";
import { useTranslation } from "react-i18next";
import { BrandInput } from "../ProductsForm/BrandInput";
import { CategoryInput } from "../ProductsForm/CategoryInput";

type Category = {
  id: string;
  name: string;
  subCategories: Category[];
};

export const GroupSetup = () => {
  const categories: Category[] = useCategoriesTree();
  const [t] = useTranslation("products-page");

  return (
    <>
      <h6 className="bold">{t("PRODUCTS_ORGANIZATION")}</h6>
      <div className="spacer"></div>
      <div className="flexGap flexSmColumn ">
        <div className="col-6 col-sm-12 noPadding">
          <div className="row">
            <SelectV2
              name="type"
              label={t("PRODUCT_TYPE")}
              required
              choices={[
                { value: "PRODUCT", label: t("PRODUCT_NAME") },
                { value: "GIFT", label: t("GIFT") },
              ]}
            />
          </div>
          <div className="row">
            <BrandInput label="Marca" placeholder={t("PRODUCT_BRAND")} />
          </div>
        </div>
        <div className="spacerVertical" />
        <div className="col-6 col-sm-12 noPadding">
          <CategoryInput categories={categories} index={0} />
        </div>
      </div>
    </>
  );
};
