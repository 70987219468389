import {
  getCitiesByStateId,
  getCountries,
  getStatesByCountryId,
  TState,
} from "@/http";
import { customToast } from "./useToast";
import { locale } from "@/config/locale";
import useSWR from "swr";
import { CURRENT_LANG, ES } from "@/constants/core";

export const useCountry = () => {
  switch (locale) {
    case "es-CO":
      return {
        id: 48,
        name: "Colombia",
        prefix: "CO",
        phoneCode: "57",
        currency: "COP",
        latitude: 4.0,
        longitude: -72.0,
        emoji: "🇨🇴",
        support: "18286772628",
      };
    case "es-MX":
      return {
        id: 142,
        name: "Mexico",
        prefix: "MX",
        phoneCode: "52",
        currency: "MXN",
        latitude: 23.0,
        longitude: -102.0,
        emoji: "🇲🇽",
        support: "18339333855",
      };
    case "en-US": {
      return {
        id: 233,
        name: "United States",
        prefix: "US",
        phoneCode: "1",
        currency: "USD",
        latitude: 38,
        longitude: -97,
        emoji: "🇺🇸",
        support: "18286772628",
      };
    }
    default:
      return {
        id: 48,
        name: "Colombia",
        prefix: "CO",
        phoneCode: "57",
        currency: "COP",
        latitude: 4.0,
        longitude: -72.0,
        emoji: "🇨🇴",
        support: "18286772628",
      };
  }
};

export const useStates = (countryId: number) => {
  const isES = CURRENT_LANG() === ES;
  const { data, error, mutate } = useSWR(
    () => (countryId ? [countryId, "states"] : null),
    getStatesByCountryId,
    {
      onError: () => {
        customToast({
          status: "warn",
          title: isES
            ? "La carga de estados ha fallado"
            : "State loading has failed",
        });
      },
    }
  );

  return {
    states: data,
    statesIsLoading: !error && !data,
    statesIsError: error,
    mutate,
  };
};

export const useCities = (states: TState[] | undefined, stateName: string) => {
  const state = states?.find((s: TState) => s.name === stateName);
  const isES = CURRENT_LANG() === ES;
  const { data, error, mutate } = useSWR(
    state?.id ? () => [state.id, "cities"] : null,
    getCitiesByStateId,
    {
      onError: () => {
        customToast({
          status: "warn",
          title: isES
            ? "La carga de ciudades ha fallado"
            : "Cities loading has failed",
        });
      },
    }
  );
  return {
    cities: data,
    citiesIsLoading: !error && !data,
    citiesIsError: error,
    mutate,
  };
};

export const useCountries = () => {
  const { data } = useSWR("countries", getCountries);
  return data;
};
