import { useCompletePurchase } from "@/hooks/usePurchaseProducts";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

type TShippingInfo = {
  purchaseId: string;
};
export const ShippingInfo = ({ purchaseId }: TShippingInfo) => {
  const purchase = useCompletePurchase(purchaseId);
  const [t] = useTranslation("purchases");

  return (
    <>
      {!!purchase?.shippingMethod && (
        <div className={styles.item}>
          <span className={styles.title}>{t("SHIPPING_METHOD")}</span>
          <span className={`${styles.description} textLine`}>
            {t(purchase?.shippingMethod)}
          </span>
        </div>
      )}
      {!!purchase?.paymentProvider && (
        <div className={styles.item}>
          <span className={styles.title}>{t("PAYMENT_PROVIDER")}</span>
          <span className={`${styles.description} textLine`}>
            {purchase?.paymentProvider}
          </span>
        </div>
      )}
    </>
  );
};
