import { TextArea } from "@/components/shared/Input/TextArea";
import { useTranslation } from "react-i18next";

export const Comment = () => {
  const [t] = useTranslation("global");
  return (
    <div className="noPadding bold">
      <TextArea name="comment" label={t("PURCHASES.ORDERS.COMMENT")} rows={2} />
    </div>
  );
};
