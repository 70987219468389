import { isES } from "./core";

export const renderingPaymentStatus = {
  PAID: isES ? "Pagado" : "Paid",
  PENDING: isES ? "Pendiente" : "Pending",
};

export const renderingPaymentType = {
  PRODUCT_SELL: isES ? "Pago por Producto" : "Payment by Product",
  LOGISTIC_SERVICE: isES ? "Cargo por logistica" : "Logistics charge",
  PAYMENT_SERVICE: isES
    ? "Cargo por pasarela de pago"
    : "Payment gateway charge",
};

export const renderingPaymentReference = {
  UPON_DELIVERY: isES ? "Contraentrega" : "Upon delivery",
  SUPPLIER_PAY: isES ? "Pago a proveedor" : "Payment to supplier",
  UPON_DELIVERY_SEERI: isES ? "Crédito seeri" : "Seeri credit",
  ADDI: isES ? "Addi" : "Addi",
  ONLINE: isES ? "En linea" : "Online",
  PARTIAL_PAYMENT: isES ? "Pago parcial" : "Partial payment",
  CREDIT: isES ? "Crédito" : "Credit",
  APLAZO: "Aplazo",
  BANK_TRANSFER: isES ? "Transferencia bancaria" : "Bank transfer",
  KALTO_CREDIT: isES ? "Crédito kalto" : "kalto credit",
  KALTO: isES ? "Kalto" : "Kalto",
  LOGISTIC_SERVICE: isES ? "Cargo por logistica" : "Logistics charge",
};
