import { Modal } from "@/components/shared/Modal/Modal";
import { isUSA } from "@/constants/core";
import { DATE_HOUR, USA_DATE_HOUR } from "@/constants/date";
import { getPurchases, TPurchase } from "@/http";
import { useAuth } from "@/store/auth";
import { useTicket } from "@/store/ticket";
import { formatCurrency } from "@/utils/currency";
import { format, setHours, setMinutes, setSeconds, subDays } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiEdit } from "react-icons/bi";
import styles from "./styles.module.scss";
import { TicketCreationForm } from "./TicketCreationForm";

export const TicketCreateButton = () => {
  const [t] = useTranslation("purchases");
  const [globalT] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [purchases, setPurchases] = useState<TPurchase[]>([]);
  const [selectedPurchase, setSelectedPurchase] = useState<TPurchase>();
  const { user, isCommercial, commercial } = useAuth();
  const { getTickets } = useTicket();

  const loadPurchases = async () => {
    const date = subDays(new Date(), 10);
    const filterDate = setSeconds(setMinutes(setHours(date, 0), 0), 1);
    getPurchases({
      size: 1000,
      page: 0,
      search: `status:DELIVERED;DONE;,sellerId:${user.id},createdAt>${filterDate
        .toISOString()
        .replace("T", " ")}${
        isCommercial ? `,createdBy:${commercial?.id}` : ""
      }`,
      sort: "createdAt,DESC",
    })
      .then(data => setPurchases(data.content ?? []))
      .catch(() => setPurchases([]));
  };

  useEffect(() => {
    loadPurchases();
  }, []);

  return (
    <>
      <div className="rigthAlign">
        <button className="primary" onClick={() => setIsOpen(true)}>
          {t("CREATE_TICKET")}
        </button>
      </div>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={t("CREATE_TICKET")}
        size={selectedPurchase?.id || !purchases.length ? "sm" : ""}
      >
        {purchases?.length > 0 ? (
          <>
            {!selectedPurchase?.id && (
              <div className="tableScroll">
                <p className="bold">{t("SELECT_ORDER")}</p>
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th>{t("CREATED_AT")}</th>
                      <th>{t("ORDER_NUMBER")}</th>
                      <th>{t("PURCHASE_PAYMENT_METHOD")}</th>
                      <th>{t("TOTAL")}</th>
                      <th>{t(isUSA ? "LEGAL_NAME" : "CLIENT")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {purchases.map(p => (
                      <tr
                        key={p.id}
                        className={`${styles.row} pointer`}
                        onClick={() => setSelectedPurchase(p)}
                      >
                        <td>
                          {format(
                            new Date(p.createdAt),
                            isUSA ? USA_DATE_HOUR : DATE_HOUR
                          )}
                        </td>
                        <td>{p.purchaseNumber}</td>
                        <td>{globalT(`PAYMENT_METHOD.${p.paymentMethod}`)}</td>
                        <td>{formatCurrency(p.total)}</td>
                        <td>
                          {isUSA
                            ? p?.client?.legalName ?? "-"
                            : p?.client?.email ?? "-"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {selectedPurchase?.id && (
              <>
                <p className="bold">
                  {t("ORDER_NUMBER") + ": " + selectedPurchase.purchaseNumber}{" "}
                  <span
                    className="pointer"
                    onClick={() => setSelectedPurchase(undefined)}
                  >
                    <BiEdit />
                  </span>
                </p>
                <div className="spacer" />
                <TicketCreationForm
                  purchase={selectedPurchase}
                  onDone={() => {
                    setIsOpen(false);
                    getTickets(user.id);
                  }}
                  onCancel={() => setIsOpen(false)}
                />
              </>
            )}
          </>
        ) : (
          <>{t("NO_DATA")}</>
        )}
      </Modal>
    </>
  );
};
