import { SVGAttributes } from "react";

export const PremiumIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.6893 6.00842L10.1817 5.81804L8 0L5.81804 5.81804L5.31074 6.00842L0 8L5.81804 10.1817L8 16L10.1817 10.1817L16 8L10.6893 6.00842Z" />
      <path
        d="M10.6893 6.00842L10.1817 5.81804L8 0L5.81804 5.81804L5.31074 6.00842L0 8L5.81804 10.1817L8 16L10.1817 10.1817L16 8L10.6893 6.00842Z"
        fillOpacity="0.2"
      />
    </svg>
  );
};
