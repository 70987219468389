import { useHeaderTitle } from "@/store/ui";
import { GiHamburgerMenu } from "react-icons/gi";
import RupeeTitle from "../RupeeTitle/RupeeTitle";
import { SidebarMobile } from "../Sidebar/SidebarMobile";
import { useState } from "react";
import styles from "./styles.module.scss";
import { Notifications } from "./Notifications/Notificactions";
import { I18nSelector } from "../I18nSelector/I18nSelector";
import { OnboardingButton } from "@/components/onboarding/OnboardingButton";
import clsx from "clsx";

const Header = () => {
  const {
    title: titleText,
    goBack,
    videoId,
    action,
    specificUrl,
  } = useHeaderTitle();
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <div className={styles.header}>
        <div className={styles.menu} onClick={() => setOpen(true)}>
          <span className={styles.menuButton}>
            <GiHamburgerMenu size={"1.5rem"} />
          </span>
        </div>
        <div>
          <RupeeTitle
            title={titleText}
            goBack={goBack}
            videoId={videoId}
            specificURL={specificUrl}
          />
        </div>
        <div className={styles.actions}>
          <div className={styles.customActions}>{action}</div>
          <div className={clsx(styles.globalActions, "flexGap")}>
            <Notifications />
            <div className="flexDividerVertical" />
            <I18nSelector />
            <OnboardingButton />
          </div>
        </div>
      </div>
      <SidebarMobile isOpen={isOpen} setOpen={setOpen} />
    </>
  );
};

export default Header;
