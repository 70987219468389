import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { Styles } from "@react-pdf/renderer";

type Props = {
  observations: string;
  translate: any;
};
const styles = StyleSheet.create({
  headerContainer: {
    justifyContent: "flex-start",
    width: "100%",
    border: "1px solid white",
    borderColor: "#E6E6E6",
    height: "auto",
    marginTop: "10px",
  } as Styles,
  observations: {
    marginTop: 0,
    fontFamily: "Helvetica-Oblique",
    backgroundColor: "#F5F5F5",
    width: "100%",
    padding: "5px",
    marginBotton: "20px",
  } as Styles,
  supplierInfoContainer: {
    width: "100%",
    padding: "10px",
  },
});

export const ObservationsInfo = ({ observations, translate }: Props) => {
  return (
    <>
      <View style={styles.headerContainer}>
        <Text style={styles.observations}>{translate("OBSERVATIONS")}:</Text>
        <div style={styles.supplierInfoContainer}>
          <Text>{observations}</Text>
        </div>
      </View>
    </>
  );
};
