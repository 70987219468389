import { useAuth } from "@/store/auth";
import { hasPermission } from "@/utils/validations/permissions";
import { ReactNode } from "react";
import { Navigate } from "react-router";

type TRoutePermission = {
  element: ReactNode;
  permissions: string[];
};
export const RoutePermission = ({ element, permissions }: TRoutePermission) => {
  const { isCommercial, role } = useAuth();

  return hasPermission(isCommercial, role, permissions) ? (
    <>{element}</>
  ) : (
    <Navigate replace to="/restricted" />
  );
};
