import { Spinner } from "@/components/shared/Spinner/Spinner";
import { useOrders, useOrderFilter } from "@/store/orders";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/store/auth";
import PaginatorTableOrders from "../PaginatorTableOrders/PaginatorTableOrders";
import { format, isSameDay } from "date-fns";
import { DATE_MONT_YEAR, USA_DATE_MONT_YEAR } from "@/constants/date";
import styles from "./styles.module.scss";
import { isUSA } from "@/constants/core";
import { TSupplierOrderDetails } from "@/models/order-details";
import { hasPermission } from "@/utils/validations/permissions";
import { DeleteDispatchOrder } from "./DeleteOrder";
import { AiOutlineDelete } from "react-icons/ai";
import { useCompletePurchase } from "@/hooks/usePurchaseProducts";
import { CheckboxButton } from "@/components/shared/Input/CheckboxButton";
import clsx from "clsx";
import { ModalPendingInvoice } from "./ModalPendingInvoice";

const TableOrders = () => {
  const {
    filter: activeFilter,
    idFilter,
    startDateFilter,
    endDateFilter,
    sortBy,
    sortDirection,
  } = useOrderFilter();

  const { getOrders, orders, loadingOrders, totalPages, ordersIdToInvoice } =
    useOrders();
  const { user, isCommercial, role } = useAuth();
  const [t] = useTranslation("orders-page");

  useEffect(() => {
    getOrders(
      user,
      activeFilter,
      idFilter,
      startDateFilter,
      endDateFilter,
      0,
      sortBy,
      sortDirection
    );
  }, [
    activeFilter,
    idFilter,
    startDateFilter,
    endDateFilter,
    sortBy,
    sortDirection,
  ]);

  const updateOrders = (page: number) => {
    getOrders(
      user,
      activeFilter,
      idFilter,
      startDateFilter,
      endDateFilter,
      page,
      sortBy,
      sortDirection
    );
  };

  if (loadingOrders) {
    return <Spinner />;
  }

  return orders?.content?.length > 0 ? (
    <>
      {ordersIdToInvoice?.length > 0 && (
        <>
          <div className="spacer" />
          <div className={clsx(styles.greenLigthContainer)}>
            <ModalPendingInvoice />
          </div>
        </>
      )}
      <div className="tableScroll">
        <table className={styles.table}>
          <thead>
            <tr>
              <th>
                {activeFilter !== "is_invoiced" ? t("DETAILS.STATUS") : <></>}
              </th>
              <th>{t("ORDERS.ID")}</th>
              <th>{t("CLIENT")}</th>
              <th>{t("CITY")}</th>
              <th>{t("CREATED_BY")}</th>
              <th>{t("ORDERS.CREATED_AT")}</th>
              {["ALL", "ATTENDED"].includes(activeFilter.toUpperCase()) && (
                <th>{t("ORDERS.APPROVED_AT")}</th>
              )}

              {hasPermission(isCommercial, role, ["action.orders.delete"]) && (
                <th>{t("ACTIONS")}</th>
              )}
            </tr>
          </thead>
          <tbody>
            {orders?.content?.map(order => (
              <DispatchOrderRow order={order} key={order.id} />
            ))}
          </tbody>
        </table>

        {totalPages > 1 ? (
          <PaginatorTableOrders getOrders={updateOrders} />
        ) : (
          <></>
        )}
      </div>
    </>
  ) : ["PENDING"].includes(activeFilter.toUpperCase()) ? (
    <>
      <div className="spacer" />
      <p>{t("NO_ORDERS_PENDING")}</p>
    </>
  ) : (
    <>
      <div className="spacer" />
      <p>{t("NO_ORDERS")}</p>
    </>
  );
};

const DispatchOrderRow = ({ order }: { order: TSupplierOrderDetails }) => {
  const { user, isCommercial, role } = useAuth();
  const purchase = useCompletePurchase(order?.purchaseId ?? "");
  const isOwnDispatch = purchase?.supplierId === user.id;
  const [t] = useTranslation("orders-page");
  const navigate = useNavigate();
  const { addIdToInvoice, removeIdToInvoice } = useOrders();

  const {
    filter: activeFilter,
    idFilter,
    startDateFilter,
    endDateFilter,
    sortBy,
    sortDirection,
  } = useOrderFilter();
  const { getOrders, currentPage } = useOrders();

  const reloadOrders = () => {
    getOrders(
      user,
      activeFilter,
      idFilter,
      startDateFilter,
      endDateFilter,
      currentPage,
      sortBy,
      sortDirection
    );
  };

  const isInvalidDate = (date = "", status = "") => {
    const expiresAt = new Date(date);
    const currentDate = new Date();

    return (
      isSameDay(currentDate, expiresAt) &&
      ["PENDING", "REMISSION_GENERATED"].includes(status)
    );
  };

  return (
    <tr
      key={order.id}
      className={`${styles.purchaseRow} ${
        isInvalidDate(order.expiresAt, order.supplierStatus)
          ? styles.dangerRow
          : ""
      }`}
    >
      <td
        onClick={() => {
          if (activeFilter !== "is_invoiced") {
            navigate(`/orders/${order.id}`);
          }
        }}
      >
        {activeFilter !== "is_invoiced" ? (
          <span
            className={clsx({
              redText: ["PENDING", "REMISSION_GENERATED"].includes(
                order.supplierStatus
              ),
              greenText: ["APPROVED"].includes(order.supplierStatus),
            })}
          >
            {order?.supplierStatus ? t(`STATUS.${order.supplierStatus}`) : "-"}
          </span>
        ) : (
          <CheckboxButton
            onClick={(e: any) => {
              e?.stopPropagation?.();
              if (e?.target?.checked) {
                addIdToInvoice(order.id);
              } else {
                removeIdToInvoice(order.id);
              }
            }}
          />
        )}
      </td>
      <td onClick={() => navigate(`/orders/${order.id}`)}>
        <div>
          <p>{order?.subPurchaseNumber ?? "-"}</p>
          {isInvalidDate(order.expiresAt, order.supplierStatus) && (
            <p>{t("TO_EXPIRE_ORDER")}</p>
          )}
        </div>
      </td>
      <td>
        <span>
          <span className="flexColumn">
            {isOwnDispatch || user.showClientInfoOnInvoice ? (
              <Link to={`/clients/${order?.sellerId}/show`} className={"bold"}>
                <span>{order?.clientName || "-"}</span>
              </Link>
            ) : (
              "-"
            )}
          </span>
        </span>
      </td>
      <td onClick={() => navigate(`/orders/${order.id}`)}>
        {order.clientCity || "-"}
      </td>
      <td>
        <span>
          <span className="flexColumn">
            {order?.createdByType === "SELLER" ? (
              <Link to={`/clients/${order?.createdBy}/show`} className={"bold"}>
                <span>{order?.createdByName || "-"}</span>
              </Link>
            ) : (
              <span>{order?.createdByName || "-"}</span>
            )}
          </span>
        </span>
      </td>
      <td onClick={() => navigate(`/orders/${order.id}`)}>
        {format(
          new Date(order.createdAt),
          isUSA ? USA_DATE_MONT_YEAR : DATE_MONT_YEAR
        )}
      </td>
      {["ALL", "ATTENDED"].includes(activeFilter.toUpperCase()) && (
        <td>
          {order?.approvedAt
            ? format(
                new Date(order.approvedAt),
                isUSA ? USA_DATE_MONT_YEAR : DATE_MONT_YEAR
              )
            : "-"}
        </td>
      )}
      {hasPermission(isCommercial, role, ["action.orders.delete"]) && (
        <td onClick={e => e.stopPropagation()}>
          <DeleteDispatchOrder
            button={<AiOutlineDelete className="pointer" />}
            orderNumber={order.subPurchaseNumber}
            orderId={order.id}
            onDone={() => reloadOrders()}
          />
        </td>
      )}
    </tr>
  );
};

export default TableOrders;
