import { TPurchaseProduct, TSupplier } from "@/http";

export const groupByPending = (products: TPurchaseProduct[]) => {
  return products.reduce(
    function (
      memo: {
        [key: string]: TPurchaseProduct[];
      },
      currentValue
    ) {
      if (currentValue.isLocked && !currentValue.subPurchaseId) {
        memo["PREPARING"].push(currentValue);
      }
      if (!currentValue.isLocked && !currentValue.subPurchaseId) {
        memo["PENDING"].push(currentValue);
      }
      return memo;
    },
    {
      PENDING: [],
      PREPARING: [],
    }
  );
};

export const getProductSupplierFromProduct = (
  product: any,
  userId: string,
  useFirstIfEmpty = false
) => {
  let productSupplier = product?.suppliers?.find(
    ({ supplierId }: TSupplier) => supplierId === userId
  );

  if (!productSupplier && useFirstIfEmpty) {
    productSupplier = product?.suppliers?.[0];
  }

  return productSupplier || {};
};
