import { Select } from "@/components/shared/Input/Select";
import { useStates } from "@/hooks/useCountry";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const StateInput = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const country = useWatch({ name: "countryId" });
  const { states, statesIsLoading } = useStates(country);

  const [t] = useTranslation("purchases");

  const options = states?.map(state => ({
    label: state.name || "",
    value: state.name,
  }));

  return (
    <Select
      name="state"
      label={t("STATE")}
      register={register}
      error={errors.state}
      required
      disabled={statesIsLoading}
      choices={options || []}
    />
  );
};
