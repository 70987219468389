import { ACTIONS_GRAY } from "@/constants/colors";
import { getBrandById } from "@/http";
import { CSSProperties } from "react";
import useSWR from "swr";

type TBrandImage = {
  id: string;
  style?: CSSProperties | undefined;
  useEmpty?: boolean;
};
const BrandImage = ({ id, style, useEmpty }: TBrandImage) => {
  const { data: brandDetail } = useSWR(id, getBrandById);

  if (!brandDetail?.logo?.src) {
    return useEmpty ? null : (
      <div
        style={{
          width: 50,
          height: 50,
          backgroundColor: ACTIONS_GRAY,
          margin: "auto",
        }}
      />
    );
  }

  return (
    <img
      src={brandDetail?.logo?.src}
      width={100}
      height={45}
      style={{ objectFit: "contain", height: "50px", margin: "auto", ...style }}
    />
  );
};

export default BrandImage;
