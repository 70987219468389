import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BulkUpload } from "../products/ProductsGroupActions/BulkImport/shared/BulkUpload";
import { BulkImportMode } from "../products/ProductsGroupActions/BulkImport/shared/types";
import { RadioGroupV2 } from "../shared/RadioGroup/RadioGroupV2";

export const ProductBulkProcess = () => {
  const [t] = useTranslation("products-page");
  const methods = useForm({ defaultValues: { type: "UPDATE" } });
  const typeWatch = methods.watch("type") as BulkImportMode;

  return (
    <div className="pw2 ph1">
      <div className=" contentInline flexSmColumn">
        <p className="bold">2.- {t("PROCESS_TEMPLATES")}</p>
        <FormProvider {...methods}>
          <RadioGroupV2
            color="purple"
            name="type"
            inline
            options={[
              { label: t("BULK_UPDATE"), value: "UPDATE" },
              { label: t("BULK_CREATE"), value: "CREATE" },
              { label: t("BULK_ASSIGN"), value: "ASSIGN" },
            ]}
          />
        </FormProvider>
      </div>
      <div className="spacer" />
      <BulkUpload mode={typeWatch} />
    </div>
  );
};
