import { PaymentDetail } from "@/components/payments/Show/PaymentDetail";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { usePayments } from "@/store/payments";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

export const PaymentDetailPage = () => {
  const { setTitle } = useHeaderTitle();
  const { id = "" } = useParams();
  const [t] = useTranslation("global");
  const { payment, getPayment, isLoadingPayment } = usePayments();

  useEffect(() => {
    if (id) {
      getPayment(id);
    }
  }, [id]);

  useEffect(() => {
    setTitle(
      `${t("PAYMENTS.PAYMENT")} » ${payment?.paymentNumber ?? "-"}`,
      "/payments"
    );
  }, [payment]);

  if (isLoadingPayment) {
    return <Spinner />;
  }

  return (
    <>
      <PaymentDetail />
    </>
  );
};
