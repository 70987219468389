import { TSupplierCommercial } from "@/http";
import styles from "./styles.module.scss";

type TCommercialResume = {
  commercial?: TSupplierCommercial;
};

export const CommercialResume = ({ commercial }: TCommercialResume) => {
  return commercial && commercial.id ? (
    <div className={styles.container}>
      <p>{`${commercial?.firstName ?? ""} ${commercial?.lastName ?? ""}`}</p>
      <div className={styles.description}>{commercial?.email}</div>
    </div>
  ) : (
    <></>
  );
};
