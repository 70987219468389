import { getInstance } from "../../core/axios-gateway";
import { TCity, TCountry, TState } from "../../types/location";

const BASE_PATH = "/api/locations";

export const getCountries = async () => {
  return getInstance()
    .get<TCountry[]>(`${BASE_PATH}/countries`)
    .then(res => res.data);
};

export const getStatesByCountryId = async (countryId: string) => {
  return getInstance()
    .get<TState[]>(`${BASE_PATH}/countries/${countryId}/states`)
    .then(res => res.data);
};

export const getCitiesByStateId = async (stateId: string) => {
  return getInstance()
    .get<TCity[]>(`${BASE_PATH}/states/${stateId}/cities`)
    .then(res => res.data);
};
