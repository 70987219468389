import { Modal } from "@/components/shared/Modal/Modal";
import { getPaymentById } from "@/http";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ToolTip from "../shared/Tooltip/Tooltip";
import useSWR from "swr";
import { BiInfoCircle } from "react-icons/bi";
import { Link } from "react-router-dom";
import { formatCurrency } from "@/utils/currency";
import { format } from "date-fns";
import { isUSA } from "@/constants/core";
import { USA_DATE_HOUR, DATE_HOUR } from "@/constants/date";

import styles from "./styles.module.scss";

type TCreatePayment = {
  creditId: number;
  paymentId?: string;
  setDone: () => void;
};

export const PaymentDetail = ({ creditId, paymentId }: TCreatePayment) => {
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const { data: payment } = useSWR(String(paymentId), () =>
    getPaymentById(creditId, paymentId)
  );

  return (
    <>
      <div className="flexGap mlmd center">
        <ToolTip title={t("SHOW")} position="Left">
          <span className="circleButton">
            <BiInfoCircle
              className="pointer"
              onClick={() => {
                setIsOpen(true);
              }}
            />
          </span>
        </ToolTip>
      </div>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        size="sm"
        title={<span className="lFont bold">{t("CREDITS.VOUCHER_TITLE")}</span>}
      >
        {payment && (
          <>
            <div>
              <p className="bold">{t("CREDITS.CREATED_AT")}</p>
              {format(
                new Date(payment.createdAt),
                isUSA ? USA_DATE_HOUR : DATE_HOUR
              )}
            </div>
            <div className="spacer" />
            <div>
              <p className="bold">{t("CREDITS.AMOUNT")}</p>
              {formatCurrency(payment?.amount || 0)}
            </div>
          </>
        )}

        <div className="spacer" />
        {payment && (
          <>
            <p className="bold">{t("CREDITS.VOUCHER")}</p>
            <div>
              <Link to={payment.referenceUrl} target="_blank">
                <img
                  src={payment?.referenceUrl}
                  className={styles.img}
                  style={{ objectFit: "contain", display: "unset" }}
                />
              </Link>
            </div>
          </>
        )}
        <div className="spacer" />
      </Modal>
    </>
  );
};
