import { Spinner } from "@/components/shared/Spinner/Spinner";
import { SegmentModalForm } from "./SegmentModalForm";
import styles from "./styles.module.scss";
import { DeleteSegment } from "./DeleteSegment";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { getSegments } from "@/http/resources/sellers/segments";
import clsx from "clsx";

export const SegmentsList = () => {
  const [t] = useTranslation("global");
  const { data: segments, error, mutate } = useSWR("segments", getSegments);

  if (error) {
    return <p>Error</p>;
  }

  if (!segments) {
    return <Spinner />;
  }

  return (
    <div className={styles.container}>
      <div className={clsx(styles.main, "flexGap")}>
        <div>
          <h6 style={{ margin: 0 }}>{t("SEGMENTS.TITLE")}</h6>
        </div>
        <SegmentModalForm onDone={() => mutate()} />
      </div>
      {!!segments.length && (
        <>
          <div className="tableScroll">
            <table className={styles.table}>
              <thead>
                <tr className={styles.row}>
                  <th>{t("NAME")}</th>
                  <th>{t("ACTIONS")}</th>
                </tr>
              </thead>
              <tbody>
                {segments.map(segment => (
                  <tr key={segment.id} className={styles.row}>
                    <td>{segment.name}</td>
                    <td>
                      <div className="flexGap center centerAlign">
                        <SegmentModalForm
                          segment={segment}
                          onDone={() => mutate()}
                        />
                        <DeleteSegment
                          segment={segment}
                          onDone={() => mutate()}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
      {!segments.length && <h6 className="bold">{t("NO_AVAILABLE_INFO")}</h6>}
    </div>
  );
};
