import { getSupplierBoxById } from "@/http";
import { useAuth } from "@/store/auth";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import styles from "./styles.module.scss";

type TBoxDetailField = {
  boxId: string;
};
export const BoxDetailField = ({ boxId }: TBoxDetailField) => {
  const { user } = useAuth();
  const [t] = useTranslation("orders-page");
  const { data } = useSWR(
    boxId ? [user.id, boxId, "boxSize"] : null,
    getSupplierBoxById
  );

  if (!boxId || !data) {
    return null;
  }

  return (
    <>
      <div className={styles.item}>
        <span className={styles.title}>{t("BOX_SIZE")}</span>
        <span className={styles.description}>
          {data.name} ({data.height} {t("HEIGHT")} x {data.length} {t("LONG")} x{" "}
          {data.width} {t("WIDTH")})
        </span>
      </div>
    </>
  );
};
