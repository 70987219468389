import { getProductV2 } from "@/http";
import styles from "./styles.module.scss";
import useSWR from "swr";
import { useTranslation } from "react-i18next";

type TProductGifTChip = {
  id: string;
  quantity: number;
  invoiceIncluded: boolean;
};
export const ProductGifTChip = ({
  id,
  quantity,
  invoiceIncluded,
}: TProductGifTChip) => {
  const [t] = useTranslation("global");
  const { data } = useSWR([id, "ProductIdChip"], getProductV2);

  if (!data) {
    return null;
  }

  return (
    <span className={styles.productGiftChip}>
      <b>x{quantity}</b> | {data.name} |{" "}
      <b>
        {t("OFFERS.INVOICE_INCLUDED")}: {invoiceIncluded ? t("YES") : t("NO")}
      </b>
    </span>
  );
};
