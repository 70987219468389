import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

export const SupplierRegister = () => {
  const [t] = useTranslation("global");

  return (
    <div className={styles.supplierRegister}>
      <Link to="/signup" className={styles.buttonLogin}>
        <span className={styles.title}>{t("REGISTER_TITLE")}</span>
      </Link>
    </div>
  );
};
