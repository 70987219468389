import { useEffect } from "react";
import styles from "./styles.module.scss";
import { useOrderDetails } from "@/store/order-detail";
import { format } from "date-fns";
import { DATE_HOUR_SLASH, USA_DATE_HOUR_SLASH } from "@/constants/date";
import { useTranslation } from "react-i18next";
import { isUSA } from "@/constants/core";

export const ListComments = ({ orderId }: { orderId: string }) => {
  const { comments, getCommentsByOrder } = useOrderDetails();
  const [t] = useTranslation("orders-page");

  useEffect(() => {
    getCommentsByOrder(orderId);
  }, []);

  return (
    <div className="col-4 col-sm-12 noPadding">
      <div className={styles.header}>
        <span className="bold">{t("LIST_COMMENTS")}</span>
      </div>
      {comments.map((comment, index) => {
        return (
          <div key={`${comment}-${index}`}>
            <div className="contentInline">
              <span>- {comment.message}</span>
              <span className="textLine smFont centerVAlign ml1">
                {format(
                  new Date(comment.createdAt),
                  isUSA ? USA_DATE_HOUR_SLASH : DATE_HOUR_SLASH
                )}
              </span>
            </div>
            <div className="divider" />
          </div>
        );
      })}
    </div>
  );
};
