import { Select } from "@/components/shared/Input/Select";
import { useCities, useStates } from "@/hooks/useCountry";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const CityInput = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const currentState = useWatch({ name: "state" });
  const country = useWatch({ name: "countryId" });
  const { states } = useStates(country);
  const { cities, citiesIsLoading } = useCities(states, currentState);
  const [t] = useTranslation("purchases");

  const options = cities?.map(city => ({
    label: city.name || "",
    value: city.name,
  }));

  return (
    <Select
      name="city"
      label={t("CITY")}
      register={register}
      error={errors.city}
      required={(options?.length ?? 0) > 0}
      disabled={citiesIsLoading || !currentState}
      choices={options || []}
    />
  );
};
