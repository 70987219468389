import { InputV2 } from "@/components/shared/Input/InputV2";
import { useProductsCreation } from "@/store/products-creation";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

export const SearchProductsInputs = () => {
  const {
    brandId,
    brandName,
    productName,
    setProductName,
    getProducts,
    setCurrentPage,
  } = useProductsCreation();
  const methods = useForm({
    defaultValues: { name: productName, brandName, brandId },
  });
  const productNameWatch = methods.watch("name");
  const [t] = useTranslation("products-page");

  useEffect(() => {
    setProductName(productNameWatch);
  }, [productNameWatch]);

  useEffect(() => {
    setCurrentPage(0);
    getProducts();
  }, [productName]);

  return (
    <>
      <FormProvider {...methods}>
        <div className={styles.inputsContainer}>
          <div className={styles.inputWrapper}>
            <InputV2
              name="name"
              label=""
              placeholder={t("PRODUCT_NAME_DETAIL")}
            />
          </div>
        </div>
      </FormProvider>
    </>
  );
};
