import { SVGAttributes } from "react";

export const StarterIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.1815 5.81854L7.99978 0L5.81788 5.81788L0 8.00022L5.81788 10.1821L7.99978 16L10.1815 10.1821L16 8.00022L10.1815 5.81854ZM6.04985 6.43675L6.33089 6.33134L6.43609 6.05029L7.99868 1.88082L9.56126 6.05029L9.66668 6.33134L9.94773 6.43675L14.1172 7.99934L9.94773 9.56193L9.68693 9.65964H6.30866L6.04787 9.56193L1.87841 7.99934L6.04985 6.43675Z" />
      <path
        d="M10.1815 5.81854L7.99978 0L5.81788 5.81788L0 8.00022L5.81788 10.1821L7.99978 16L10.1815 10.1821L16 8.00022L10.1815 5.81854ZM6.04985 6.43675L6.33089 6.33134L6.43609 6.05029L7.99868 1.88082L9.56126 6.05029L9.66668 6.33134L9.94773 6.43675L14.1172 7.99934L9.94773 9.56193L9.68693 9.65964H6.30866L6.04787 9.56193L1.87841 7.99934L6.04985 6.43675Z"
        fillOpacity="0.2"
      />
    </svg>
  );
};
