import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

type TFilterTabs = {
  options: { text: string; value: string }[];
  currentFilter: string;
  action: (value: string) => void;
};

export const FilterTabs = ({ options, currentFilter, action }: TFilterTabs) => {
  const [activeFilter, setActiveFilter] = useState(currentFilter);
  const [t] = useTranslation("products-page");

  const handleChange = (value: string) => {
    action(value);
    setActiveFilter(value);
  };

  return (
    <>
      <div className={styles.tabs}>
        {options.map((option, index) => (
          <button
            key={`${option.value}_${index}_button`}
            className={activeFilter === option.value ? styles.isActive : ""}
            onClick={() => handleChange(option.value)}
          >
            {t(option.text)}
          </button>
        ))}
      </div>
      <div className={styles.select}>
        <select
          value={activeFilter}
          onChange={e => handleChange(e.target.value)}
        >
          {options.map((option, index) => (
            <option
              key={`${option.value}_${index}_select`}
              value={option.value}
            >
              {t(option.text)}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};
