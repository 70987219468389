import {
  DATE_MONT_YEAR_SLASH,
  USA_DATE_MONT_YEAR_SLASH,
} from "@/constants/date";
import { TTicketModification } from "@/http";
import format from "date-fns/format";
import { ticketStatus } from "@/constants/tickets";
import { HiOutlineTicket } from "react-icons/hi";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { isUSA } from "@/constants/core";

type TTicketModificationRow = {
  modification: TTicketModification;
};
export const TicketModificationRow = ({
  modification,
}: TTicketModificationRow) => {
  const [t] = useTranslation("global");
  return (
    <div className={`contentInline ${styles.itemRow}`}>
      <div className={styles.item}>
        <HiOutlineTicket size={20} />
      </div>
      <div className={styles.item}>
        {format(
          new Date(modification.createdAt),
          isUSA ? USA_DATE_MONT_YEAR_SLASH : DATE_MONT_YEAR_SLASH
        )}
      </div>
      <div className={styles.item}>
        <span
          className={`statusColorHelper statusChip ${ticketStatus[
            modification.status.toUpperCase()
          ].style!}`}
        >
          {ticketStatus[modification.status.toUpperCase()].name}
        </span>
      </div>
      <div className={styles.item}>
        <p className="bold">{t("TICKETS.COMMENT")}</p>
        <span>{modification.comment}</span>
      </div>
    </div>
  );
};
