import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

export const CreateOfferButton = () => {
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  return (
    <div className="rigthAlign">
      <button className="primary" onClick={() => navigate("/offers/create")}>
        {t("OFFERS.CREATE_TITLE")}
      </button>
    </div>
  );
};
