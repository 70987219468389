import { PurchaseList } from "@/components/purchases/PurchaseList/PurchaseList";
import { QUOTED } from "@/http";
import { usePurchase } from "@/store/purchase";
import { useHeaderTitle } from "@/store/ui";
import { debounce } from "lodash-es";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

export const QuotesListPage = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");
  const {
    setIsProcurement,
    isProcurementFilter,
    statusFilters,
    setStatusFilters,
  } = usePurchase();
  const isQuotes = statusFilters["status"] === QUOTED;
  const navigate = useNavigate();

  const debouncedEffectFunction = debounce(() => {
    setIsProcurement(false);
    setStatusFilters({ key: "status", value: QUOTED });
  }, 500);

  useEffect(() => {
    debouncedEffectFunction();
  }, []);

  useEffect(() => {
    setTitle(t("QUOTES.LIST_TITLE"));
  }, [t]);

  return (
    <>
      <div className="rigthAlign">
        <button className="primary" onClick={() => navigate("/quotes/create")}>
          {t("QUOTES.CREATE_QUOTE")}
        </button>
        <div className="spacer" />
      </div>

      {isQuotes && !isProcurementFilter && <PurchaseList quotes />}
    </>
  );
};
