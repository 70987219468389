import { Spinner } from "@/components/shared/Spinner/Spinner";
import { useAuth } from "@/store/auth";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PurchaseListPagination } from "./TicketListPagination";
import { TicketListRow } from "./TicketListRow";
import styles from "./styles.module.scss";
import { Modal } from "@/components/shared/Modal/Modal";
import TicketDetailModal from "../TicketDetail/TicketDetailModal";
import { createPortal } from "react-dom";
import { useTicket } from "@/store/ticket";

export const TicketListTable = () => {
  const [t] = useTranslation("products-page");
  const {
    isOpenModal,
    currentFilter,
    setCurrentPage,
    getTickets,
    tickets,
    currentPage,
    isLoading,
    sortBy,
    sortDirection,
    setOpenModal,
    setSelectedTicket,
  } = useTicket();
  const { user } = useAuth();

  useEffect(() => {
    getTickets(user.id);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(0);
    getTickets(user.id);
  }, [currentFilter, sortBy, sortDirection]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {tickets?.length > 0 ? (
        <div className={styles.container}>
          <div className="tableScroll">
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>{t("TICKET_NUMBER")}</th>
                  <th>{t("CREATION")}</th>
                  <th>{t("ORDER_NUMBER")}</th>
                  <th>{t("TYPE")}</th>
                  <th>{t("STATUS_NAME")}</th>
                  <th>{t("CREATED_BY")}</th>
                </tr>
              </thead>
              <tbody>
                {tickets.map(ticket => (
                  <TicketListRow key={ticket.id} ticket={ticket} />
                ))}
              </tbody>
            </table>
          </div>
          <PurchaseListPagination />
        </div>
      ) : (
        <h2 className={styles.noData}>{t("NO_DATA")}</h2>
      )}
      {createPortal(
        <Modal
          isOpen={isOpenModal}
          onClose={() => {
            setSelectedTicket(undefined);
            setOpenModal(false);
          }}
          size="md"
        >
          <TicketDetailModal />
        </Modal>,
        document.body
      )}
    </>
  );
};
