import { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { createPrePurchaseV5 } from "@/http";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/store/auth";
import { Pricing } from "../../PurchaseCreateForm/Prepurchase/Pricing";
import { Card } from "@/components/shared/Card/Card";

type TResume = {
  setLoading: (loading: boolean) => void;
  setPrepurchase: (prepurchase: any) => void;
  prepurchase: any;
};

export const Resume = ({
  setLoading,
  prepurchase,
  setPrepurchase,
}: TResume) => {
  const { user } = useAuth();
  const [t] = useTranslation("global");
  const products = useWatch({ name: "products" });
  const supplierId = useWatch({ name: "supplierId" });
  const sellerId = user.id;
  const paymentMethod = useWatch({ name: "paymentMethod" });
  const addressId = useWatch({ name: "warehouseIdentificator" });
  const isParentSupplier =
    (useWatch({ name: "parentSupplier" }) ?? "") === "parentSupplier";

  const [finalProducts, setFinalProducts] = useState<any[]>([]);

  const isProductsValid = () => {
    const productError = finalProducts.some(
      ({ id, quantity, unitPrice }: Record<string, string>) => {
        if (isParentSupplier) {
          return !id || !Number(quantity) || !unitPrice;
        } else {
          return !id || !Number(quantity);
        }
      }
    );

    return !productError;
  };

  useEffect(() => {
    if (finalProducts?.length && isProductsValid() && sellerId) {
      setLoading(true);
      const payload = {
        isForSeller: true,
        products: finalProducts,
        sellerId,
        paymentMethod,
        supplierId,
        addressId,
      } as any;

      createPrePurchaseV5(payload)
        .then(prepurchase => setPrepurchase(prepurchase))
        .finally(() => setLoading(false));
    } else {
      setPrepurchase({});
    }
  }, [finalProducts, paymentMethod, addressId]);

  useEffect(() => {
    if (products?.length) {
      const productsMapped = (products ?? [])?.map((p: any) => ({
        id: p.id,
        supplierId: isParentSupplier
          ? supplierId
          : p?.productSupplier?.supplierId,
        quantity: p?.quantity,
        unitPrice: isParentSupplier ? p?.unitPrice : null,
        availableStock: isParentSupplier
          ? null
          : p?.productSupplier?.availableStock ?? 0,
        status: isParentSupplier ? null : p?.productSupplier?.status,
      }));

      setFinalProducts(
        productsMapped.filter((p: any) => {
          if (isParentSupplier) {
            return !!p?.id;
          } else {
            return !!p?.id && p.availableStock > 0 && p.status;
          }
        })
      );
    }
  }, [products]);

  return (
    <Card className="noPadding">
      <div className="pw2 ph1">{t("PURCHASES.ORDERS.RESUME_INFO")}</div>
      <div className="divider noPadding" />
      <div className="pw2 ph1">
        {Object.keys(prepurchase).length > 0 ? (
          <Pricing prepurchase={prepurchase} ligthStyle2 />
        ) : (
          <>{t("PURCHASES.ORDERS.FILL_PURCHASE")}</>
        )}
      </div>
    </Card>
  );
};
