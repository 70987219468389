import { TRestListData, TRestListParams } from "../../types/rest";
import { getInstance } from "../../core/axios-gateway";
import { TGlobalOffer, TGlobalOfferGift } from "../../types/global-offers";

const BASE_PATH = "/api/offers";

export const getOffers = async (params: TRestListParams & { supplierId: string }) => {
  return getInstance()
    .get<TRestListData<TGlobalOffer>>(`${BASE_PATH}`, { params })
    .then(res => res.data);
};

export const getOfferById = async (id: string) => {
  return getInstance()
    .get<TGlobalOffer>(`${BASE_PATH}/${id}`)
    .then(res => res.data);
};

export type TOfferDTO = Partial<Omit<TGlobalOffer, "gifts"> & {
  gifts?: Partial<TGlobalOfferGift>[]
}>

export const createOffer = async (data: TOfferDTO) => {
  return getInstance()
    .post<TGlobalOffer>(BASE_PATH, data)
    .then(res => res.data);
};

export const updateOffer = async (
  id: string,
  data: TOfferDTO
) => {
  return getInstance()
    .put<TGlobalOffer>(`${BASE_PATH}/${id}`, data)
    .then(res => res.data);
};

export const deleteOffer = async (id: string) => {
  return getInstance()
    .delete(`${BASE_PATH}/${id}`)
    .then(res => res.data);
};
