import { useSupplierAddressById } from "@/hooks/useSupplier";
import { useAuth } from "@/store/auth";

type TWarehouseName = {
  id: string;
  useOnlyName?: boolean;
};
export const WarehouseName = ({ id, useOnlyName }: TWarehouseName) => {
  const { user } = useAuth();
  const address = useSupplierAddressById(user.id, id);

  if (!address) {
    return <>-</>;
  }

  if (useOnlyName && address?.name) {
    return <>{address.name}</>;
  }

  return (
    <>{`${address?.addressLine1 ?? "-"}, ${address.addressLine2 ?? "-"} ;
${address.city ?? "-"}, ${address.country ?? "-"}`}</>
  );
};
