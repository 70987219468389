import { VariantsTable } from "@/components/products/Variants/Create/VariantsTable";
import { SearchProductsInputs } from "@/components/products/Variants/Search/SearchInputs";
import { DRIVE_VIDEOS } from "@/constants/drive-videos";
import { useAuth } from "@/store/auth";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const SearchProductsPage = () => {
  const { setTitle } = useHeaderTitle();
  const { refreshUserData } = useAuth();
  const [t] = useTranslation("products-page");

  useEffect(() => {
    setTitle(t("PRODUCTS"), "/products", DRIVE_VIDEOS.PRODUCTS);
  }, [t]);

  useEffect(() => {
    refreshUserData();
  }, []);
  return (
    <>
      <div className="grayContainer ">
        <div>
          <div className="spacer" />
          <div className="spacer" />
          <h6 className="centerAlign bolder">{t("SEARCH_PRODUCTS")}</h6>
          <p className="centerAlign">{t("FAST_CONFIG")}</p>
          <div className="spacer" />
          <div className="spacer" />
          <SearchProductsInputs />
          <div className="spacer" />
          <div className="spacer" />
        </div>
      </div>
      <VariantsTable />
    </>
  );
};
