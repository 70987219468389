import { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import debounce from "lodash/debounce";
import { getSupplierBrandsV3 } from "@/http";
import { useTranslation } from "react-i18next";
import { selectMultipleStyles } from "@/constants/input";
import { useAuth } from "@/store/auth";

type TMultipleSelectionBrand = {
  values: any[];
  disabled?: boolean;
  onValueChange: (values: any[]) => void;
};

export const MultipleSelectionBrand = ({
  values = [],
  disabled,
  onValueChange,
}: TMultipleSelectionBrand) => {
  const [t] = useTranslation("global");
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<any[]>([]);
  const [selectedOptions, setSelectedOptions] = useState(values);
  const { user } = useAuth();

  const fetchOptions = async () => {
    try {
      const response = await getSupplierBrandsV3(user.id);
      const optionsData = response?.map(item => ({
        value: item.id,
        label: item.name,
      }));
      setOptions(optionsData);
    } catch (_) {
      setOptions([]);
    }
  };

  const debouncedFetchOptions = useCallback(debounce(fetchOptions, 500), []);

  const handleInputChange = (newValue: any) => {
    setInputValue(newValue);
  };

  const handleChange = (selected: any) => {
    setSelectedOptions(selected);
  };

  useEffect(() => {
    onValueChange?.(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    debouncedFetchOptions();
  }, [inputValue, debouncedFetchOptions]);

  return (
    <>
      <p>{t("BRAND")}</p>
      <Select
        isMulti
        options={options}
        value={selectedOptions}
        onInputChange={handleInputChange}
        onChange={handleChange}
        isDisabled={disabled}
        placeholder={t("SELECT_OPTION")}
        styles={selectMultipleStyles}
      />
    </>
  );
};
