import { FormEventHandler, useState } from "react";
import {
  AxiosError,
  TCredential,
  TIntegration,
  TIntegrationField,
  getIntegrationCredential,
  getIntegrationCredentialParameterValues,
  updateIntegrationCredentials,
} from "@/http";
import { toast } from "react-toastify";
import useSWR from "swr";
import { Combobox } from "@headlessui/react";
import { BiCheck, BiChevronDown } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { logError } from "@/utils/errors";

type IntegrationFormProps = {
  integration: TIntegration;
  credentialId: string;
};

export function IntegrationParametersForm({
  integration,
  credentialId,
}: IntegrationFormProps) {
  const {
    data: credential,
    error,
    mutate: refreshCredential,
  } = useSWR([credentialId, "credential"], getIntegrationCredential);
  const [t] = useTranslation("global");

  if (error) return <div>{t("INTEGRATIONS.ERROR_LOAD_SYNC")}</div>;

  if (!credential) return <div>{t("INTEGRATIONS.LOADING_SYNC")}.</div>;

  const onSubmit: FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault();
    const form = event.currentTarget;
    const values = Object.fromEntries(new FormData(form));

    const toastId = toast.loading(
      t(credentialId ? "INTEGRATIONS.UPDATING" : "INTEGRATIONS.UPDATING") as any
    );

    try {
      await updateIntegrationCredentials(credentialId, { parameters: values });
      toast.update(toastId, {
        render: t(
          credentialId
            ? "INTEGRATIONS.UPDATED"
            : "INTEGRATIONS.SUCCESS_CONNECTION"
        ) as any,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      refreshCredential();
    } catch (error) {
      logError(error);
      toast.update(toastId, {
        render:
          error instanceof AxiosError && error.status === 422
            ? error.response?.data.message
            : t("INTEGRATIONS.ERROR"),
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <form
        key={credential.updatedAt}
        onSubmit={onSubmit}
        className={styles.dialogForm}
      >
        {integration.parameters.map(field => (
          <div key={field.id} className={styles.field}>
            <label className={styles.label}>{field.name}</label>
            <div>
              {field.format === "TEXT" && (
                <input
                  name={field.key}
                  defaultValue={credential.parameters[field.key]}
                  placeholder={field.hint}
                />
              )}

              {field.format === "SELECT" && (
                <IntegrationFieldCombobox
                  credential={credential}
                  field={field}
                />
              )}

              {field.format === "BOOLEAN" && (
                <input
                  name={field.key}
                  defaultChecked={credential.parameters[field.key] === "true"}
                />
              )}
            </div>
            <p className={styles.description}>{field.description}</p>
          </div>
        ))}
        <div className={styles.actions}>
          <button type="submit" className={clsx("primary", styles.save)}>
            {t("SAVE")}
          </button>
        </div>
      </form>
    </>
  );
}

type ComboFieldProps = {
  credential: TCredential;
  field: TIntegrationField;
};
const IntegrationFieldCombobox = ({ credential, field }: ComboFieldProps) => {
  const [query, setQuery] = useState("");
  const [t] = useTranslation("global");

  const { data: options = [] } = useSWR(
    [credential.id, field.key],
    getIntegrationCredentialParameterValues
  );

  const filteredOptions =
    query === ""
      ? options
      : options.filter(option => {
          return option.text.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Combobox
      name={field.key}
      defaultValue={credential.parameters[field.key]}
      as={"div"}
      className={styles.combobox}
      nullable
    >
      <div className={styles.combobox}>
        <Combobox.Input
          onChange={event => setQuery(event.target.value)}
          displayValue={(option: string) =>
            options.find(o => o.value === option)?.text ?? option
          }
          placeholder={t("SELECT_OPTION")}
        />
        <div className={styles.chevronBtn}>
          <Combobox.Button>
            <BiChevronDown className={styles.icon} aria-hidden="true" />
          </Combobox.Button>
        </div>
      </div>

      <Combobox.Options className={styles.options}>
        {filteredOptions.length === 0 && query !== "" && (
          <div className={styles.empty}>{t("NO_OPTIONS")}</div>
        )}
        {filteredOptions.map(option => (
          <Combobox.Option
            key={option.value}
            value={option.value}
            className={({ active }) =>
              clsx(styles.option, active && styles.active)
            }
          >
            {({ selected, active }) => (
              <>
                <span
                  className={clsx(styles.text, selected && styles.selected)}
                >
                  {option.text}
                </span>
                {selected && (
                  <span className={clsx(styles.check, active && styles.active)}>
                    <BiCheck className={styles.icon} aria-hidden="true" />
                  </span>
                )}
              </>
            )}
          </Combobox.Option>
        ))}
      </Combobox.Options>
    </Combobox>
  );
};
