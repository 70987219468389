import { locale } from "@/config/locale";
import { enUS, esCO, esMX, getLocaleByUrl } from "@/utils/locale";
import { isProd } from "./env";

export const APP_COUNTRIES = ["co", "mx", "us"];
export const ES = "es";
export const EN = "en";

export const APP_URLS = isProd
  ? [
      { country: "co", locale: esCO, url: "https://portal.seeri.co" },
      { country: "mx", locale: esMX, url: "https://proveedores.mx.seeri.co" },
      { country: "us", locale: enUS, url: "https://portal.seeri.co" },
    ]
  : [
      { country: "co", locale: esCO, url: "https://proveedores-dev.seeri.co" },
      { country: "us", locale: enUS, url: "https://proveedores-dev.seeri.co" },
      {
        country: "mx",
        locale: esMX,
        url: "https://proveedores-dev.mx.seeri.co",
      },
    ];

export const CURRENT_COUNTRY =
  APP_URLS.find(config => locale === config.locale)?.country || "co";

export const getCurrentCountry = () => {
  const lsCountry = localStorage.getItem("country");

  if (!lsCountry) {
    return (
      APP_URLS.find(config => getLocaleByUrl() === config.locale)?.country ||
      "co"
    );
  }

  return lsCountry;
};

export const isUSA = getCurrentCountry() === "us";
export const isCO = getCurrentCountry() === "co";
export const isMX = getCurrentCountry() === "mx";

export const CURRENT_URL =
  APP_URLS.find(config => locale === config.locale)?.url ||
  "https://proveedores.seeri.co";

export const CURRENT_LANG = () => {
  const localLang = localStorage.getItem("lang") ?? "";
  if ([ES, EN].includes(localLang)) {
    return localLang;
  } else {
    const userLanguage =
      navigator?.language?.split("-")?.[0]?.toLowerCase?.() || "es";
    return userLanguage;
  }
};
export const isES = CURRENT_LANG() === ES;
