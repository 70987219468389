import {
  getPaymentMethods,
  getPurchaseAvailblePaymentMethods,
  getPurchaseById,
  getPurchaseListFilters,
  getPurchaseModification,
  TPurchase,
  TSubPurchase,
} from "@/http";
import useSWR from "swr";

export type TSupplierPurchase = TPurchase & {
  supplierId?: string;
  createdBy?: string;
};

export const usePurchaseProducts = (products: any[], orderId: string) => {
  const { data: purchase } = useSWR([orderId, "purchaseId"], getPurchaseById);
  const couponType = purchase?.couponInfo?.type;
  let orderProducts = products ?? [];
  if (purchase?.products?.length) {
    const purchaseProducts = purchase.products;

    orderProducts =
      orderProducts?.map(product => ({
        ...(purchaseProducts.find(
          ({ id, type }) =>
            (product.id === id && product.type === type) ||
            (product.productId === id && product.type == type)
        ) || product),
        supplierSku: product.sku,
        quantity: product.quantity,
        productId: product.productId,
        couponType,
        subTotal: product.subTotal,
        subTotalWithDiscount: product.subTotalWithDiscount,
      })) ?? [];
  }
  return orderProducts;
};

export const usePurchaseClient = (id: string) => {
  const { data: purchase } = useSWR([id, "purchaseId"], getPurchaseById);

  return purchase?.client;
};

export const useCompletePurchase = (id: string) => {
  const { data: purchase } = useSWR(
    id ? [id, "purchaseId"] : null,
    getPurchaseById
  );

  return purchase as TSupplierPurchase;
};

export const usePurchaseTimeline = (id: string) => {
  const { data: timeline } = useSWR(
    id ? [id, "purchaseTimeline"] : null,
    getPurchaseModification
  );

  return timeline;
};

export const usePurchaseListFilters = () => {
  const { data: filters = {} } = useSWR(
    "purchaseListFilters",
    getPurchaseListFilters
  );

  return filters;
};

export const usePaymentMethods = () => {
  const { data: paymentMethods = [] } = useSWR(
    "paymentMethods",
    getPaymentMethods
  );
  return paymentMethods;
};

export const useAvailablePaymentMethods = (purchaseId: string) => {
  const { data: paymentMethods = [] } = useSWR(
    "getPurchaseAvailableMethods",
    () => getPurchaseAvailblePaymentMethods(purchaseId)
  );
  return paymentMethods;
};

export const groupTotalByStatus = (products: any[]) => {
  return products.reduce(
    function (
      memo: {
        [key: string]: number;
      },
      currentValue
    ) {
      if (!currentValue.subPurchaseId) {
        memo["PENDING"] = memo["PENDING"] + currentValue.subTotalWithDiscount;
      }
      return memo;
    },
    {
      PENDING: 0,
    }
  );
};

export const groupTotalBySubpurchaseStatus = (subPurchases: TSubPurchase[]) => {
  return subPurchases.reduce(function (
    memo: {
      [key: string]: number;
    },
    currentValue: any
  ) {
    if (typeof memo[currentValue.deliveryStatus] !== "number") {
      memo[currentValue.deliveryStatus] = 0;
    }

    const subPurchaseTotal = currentValue.products.reduce(function (
      memoSum: number,
      product: any
    ) {
      return memoSum + (product.subTotalWithDiscount ?? 0);
    },
    0);

    memo[currentValue.deliveryStatus] =
      memo[currentValue.deliveryStatus] + subPurchaseTotal;

    return memo;
  }, {});
};
