import { getCurrentCountry } from "@/constants/core";
import { useProductsForSale } from "@/hooks/useProducts";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { createPurchase } from "@/http";
import { useAuth } from "@/store/auth";
import { useProducts } from "@/store/products";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SaleProductsResult } from "../../SaleCreation/SaleProducts/SaleProductsResult";
import { PurchaseDeliveryInfo } from "../PurchaseDeliveryInfo/PurchaseDeliveryInfo";
import { PurchaseOrderProducts } from "../PurchaseOrderProducts/PurchaseOrderProducts";
import { Resume } from "../Resume/Resume";
import styles from "./styles.module.scss";

export const PurchaseOrderForm = () => {
  const [search] = useSearchParams("from=orders");
  const origin = search.get("from");
  const defaultValues: Record<string, any> = {
    products: [],
    purchaseType: "QUOTE",
  };
  const { selectedProducts, resetSelectedProducts } = useProducts();
  const [prepurchase, setPrepurchase] = useState<any>({});
  const [isLoading, setLoading] = useState(false);
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { data } = useProductsForSale(origin === "products", selectedProducts);
  const { user } = useAuth();
  const methods = useForm({ defaultValues });

  const onSubmit = async (data: any) => {
    try {
      const isParentSupplier = data?.parentSupplier === "parentSupplier";
      const productsMapped = (data?.products ?? [])
        ?.map((p: any) => ({
          id: p?.id,
          supplierId: isParentSupplier
            ? data?.supplierId
            : p?.productSupplier?.supplierId,
          quantity: p?.quantity,
          unitPrice: isParentSupplier ? p?.unitPrice : null,
          availableStock: isParentSupplier
            ? null
            : p?.productSupplier?.availableStock ?? 0,
          status: isParentSupplier ? null : p?.productSupplier?.status,
        }))
        .filter((p: any) => {
          if (isParentSupplier) {
            return !!p?.id;
          } else {
            return !!p?.id && p.availableStock > 0 && p.status;
          }
        });

      if (!productsMapped?.length) {
        showToastError({ message: t("PURCHASES.ORDERS.ADD_PRODUCTS") });
        return;
      }

      setLoading(true);
      const payload = {
        addressId: data?.warehouseIdentificator,
        sellerId: user.id,
        supplierId: data?.supplierId,
        isForSeller: true,
        clientTarget: "own",
        userType: "SELLER",
        countryCode: getCurrentCountry(),
        products: productsMapped,
        paymentMethod: data?.paymentMethod,
      };
      const purchase = await createPurchase(payload);
      resetSelectedProducts();
      setLoading(false);
      showToastSuccess(t("PURCHASES.ORDERS.PURCHASE_CREATED"));
      navigate(`/acquisitions/${purchase.id}/show`);
    } catch (error) {
      setLoading(false);
      showToastError({ message: t("ERROR_SOME_BAD"), error });
    }
  };

  useEffect(() => {
    methods.setValue("products", data ?? []);
  }, [data]);

  return (
    <>
      <FormProvider {...methods}>
        <PurchaseDeliveryInfo prepurchase={prepurchase} />
        <div className="spacer" />
        <PurchaseOrderProducts />
        <div className="spacer" />
        <SaleProductsResult />
        <div className="spacer" />
        <div className="flexGap flexWrap flex1 flexSmColumn">
          <div />
          <Resume
            setPrepurchase={setPrepurchase}
            prepurchase={prepurchase}
            setLoading={setLoading}
          />
        </div>
        {createPortal(
          <div className={styles.footer}>
            <button
              className="outlined md"
              onClick={() => navigate(-1)}
              disabled={isLoading}
            >
              {t("CANCEL")}
            </button>
            <button
              className="primary md"
              onClick={methods.handleSubmit(onSubmit)}
              disabled={isLoading}
            >
              {t("PURCHASES.ORDERS.CREATE_PURCHASE")}
            </button>
          </div>,
          document.body
        )}
      </FormProvider>
    </>
  );
};
