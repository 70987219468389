import { Modal } from "@/components/shared/Modal/Modal";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { MdOutlineVideocam } from "react-icons/md";
import styles from "./rupee-title.module.scss";

type TVideoPlayer = {
  id: string;
};
export const VideoPlayer = ({ id }: TVideoPlayer) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [t] = useTranslation("global");

  return (
    <>
      <ToolTip title={t("LAYOUT.SEE_TUTORIAL")} position="Right">
        <MdOutlineVideocam
          size={20}
          className="centerVAlign greenText"
          cursor="pointer"
          onClick={() => {
            setModalOpen(true);
          }}
        />
      </ToolTip>
      {createPortal(
        <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
          <div className={styles.videoContainer}>
            {id && (
              <iframe
                className={styles.video}
                src={`https://drive.google.com/file/d/${id}/preview`}
                title="tutorial"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}
          </div>
        </Modal>,
        document.body
      )}
    </>
  );
};
