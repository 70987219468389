import { TSupplierSeller } from "@/http";

export const getSupplierSellerFromSuppliers = (
  suppliers: TSupplierSeller[],
  userId: string,
  useFirstIfEmpty = false
) => {
  let productSupplier = suppliers?.find(
    ({ supplierId }) => supplierId === userId
  );

  if (!productSupplier && useFirstIfEmpty) {
    productSupplier = suppliers?.[0];
  }

  return productSupplier;
};
