import { getSupplierById, TProduct } from "@/http";
import { Supplier } from "@/models/supplier";
import { useAuth } from "@/store/auth";
import useSWR from "swr";

type TProductSupplierName = {
  product: TProduct;
};

export const ProductSupplierName = ({ product }: TProductSupplierName) => {
  const { user } = useAuth();

  const supplierProduct = getSupplierProduct(product, user);

  if (supplierProduct?.supplierName) {
    return <>{supplierProduct.supplierName}</>;
  }

  const { data: supplier } = useSWR(
    supplierProduct?.id ? [supplierProduct?.supplierId, "supplierId"] : null,
    getSupplierById
  );

  return <>{supplier?.name || "-"}</>;
};

export const getSupplierProduct = (product: TProduct, user: Supplier) => {
  let supplierProduct = product?.suppliers?.find(
    productSupplier => productSupplier?.supplierId === user.id
  );

  if (!supplierProduct) {
    supplierProduct = product?.suppliers[0];
  }

  return supplierProduct;
};

export const ProductSupplierStock = ({ product }: TProductSupplierName) => {
  const { user } = useAuth();

  const supplierProduct = getSupplierProduct(product, user);

  return (
    <>
      {(supplierProduct?.availableStock > 0
        ? supplierProduct.availableStock
        : 0) || 0}
    </>
  );
};
