import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import styles from "./styles.module.scss";

export const Restricted = () => {
  const navigate = useNavigate();
  const [t] = useTranslation("global");

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className={styles.container}>
      <div className={styles.lock}></div>
      <div className={styles.message}>
        <h1>{t("RESTRICTED_PAGE")}</h1>
        <p>{t("RESTRICTED_MESSAGE")}</p>
      </div>
      <div className="spacer" />
      <div className="spacer" />
      <button className="primary" onClick={handleGoBack}>
        {t("GO_BACK")}
      </button>
    </div>
  );
};
