import {
  createBanner,
  getCategoriesFullTree,
  getProductByIds,
  getSupplierBrandsV3,
  TBanner,
  updateBanner,
} from "@/http";
import { bannerTypeChoices } from "@/constants/banners";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { useAuth } from "@/store/auth";
import { uploadImageBannerObject } from "@/utils/image-upload";
import { useEffect, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { InputV2 } from "../shared/Input/InputV2";
import { MultipleSelectionCategory } from "../shared/Input/MultipleSelectionCategory";
import { SelectV2 } from "../shared/Input/SelectV2";
import { SmallImageInput } from "../shared/Input/SmallImageInput";
import styles from "./styles.module.scss";
import { MultipleSelectionBrand } from "../shared/Input/MultipleSelectionBrand";
import { MultipleSelectionProduct } from "../shared/Input/MultipleSelectionProduct";
import { DeleteBanner } from "./BannerDelete";
import { isValidHttpsUrl } from "@/utils/validations/email";
import clsx from "clsx";

type TBannerForm = {
  banner?: TBanner;
  onDone: () => void;
  onCancel?: () => void;
};

export const BannerForm = ({ banner, onDone, onCancel }: TBannerForm) => {
  const minDate = new Date().toLocaleDateString();
  const [t] = useTranslation("global");
  const { user } = useAuth();
  const isEdit = !!banner?.id;
  const defaultValues = (isEdit ? { ...banner } : {}) as TBanner;
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm({ defaultValues });
  const [allowModify, setAllowModify] = useState(!isEdit);

  const onCancelForm = () => {
    methods.reset({ ...banner });
    setAllowModify(false);
    onCancel?.();
  };

  const onSubmit = async (values: any) => {
    if (values.type !== "HOME" && values?.typeIds?.length <= 0) {
      showToastError(t("NO_TYPE_IDS"));
      return;
    }
    try {
      setIsLoading(true);
      const [webImage, mobileImage] = await Promise.all([
        uploadImageBannerObject(values?.images?.webImage),
        uploadImageBannerObject(values?.images?.mobileImage),
      ]);

      const payload = {
        ...values,
        supplierId: user.id,
        typeIds:
          values.type !== "HOME"
            ? values?.typeIds?.map((v: any) => v?.value)
            : [],
        images: { webImage: webImage?.src, mobileImage: mobileImage?.src },
      };
      let newBanner;

      if (isEdit) {
        newBanner = await updateBanner(banner?.id, payload);
        showToastSuccess(t("BANNER_UPDATED"));
      } else {
        newBanner = await createBanner(payload);
        showToastSuccess(t("BANNER_CREATED"));
      }
      methods.reset(newBanner);
      onDone?.();
    } catch (error) {
      showToastError({ message: t("ERROR_SOME_BAD"), error });
    } finally {
      onDone?.();
      setAllowModify(false);
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      {isEdit && !banner?.enabled && (
        <span className="bold redText">{t("BANNER_DELETED")}</span>
      )}
      <div
        className={clsx(styles.bannerContainer, {
          [styles.disabled]: !banner?.enabled && isEdit,
        })}
      >
        <div className={styles.images}>
          {allowModify ? (
            <>
              <div className={styles.item}>
                <SmallImageInput
                  required
                  name="images.webImage"
                  label={t("BANNER_WEB_IMAGE")}
                  imageUrl={isEdit ? banner?.images?.webImage : null}
                  buttonH={"100px"}
                  buttonW={"290px"}
                />
              </div>
              <div className={styles.item}>
                <SmallImageInput
                  required
                  name="images.mobileImage"
                  label={t("BANNER_MOBILE_IMAGE")}
                  imageUrl={isEdit ? banner?.images?.mobileImage : null}
                  buttonH={"100px"}
                  buttonW={"290px"}
                />
              </div>
            </>
          ) : (
            <>
              <div className={styles.item}>
                <p className="bold">{t("BANNER_WEB_IMAGE")}</p>
                <img
                  src={banner?.images?.webImage}
                  alt="webImage"
                  className={styles.image}
                />
              </div>
              <div className={styles.item}>
                <p className="bold">{t("BANNER_MOBILE_IMAGE")}</p>
                <img
                  src={banner?.images?.mobileImage}
                  alt="mobileImage"
                  className={styles.image}
                />
              </div>
            </>
          )}
        </div>
        <div className={styles.content}>
          <div className={styles.item}>
            <InputV2
              name="name"
              label={t("BANNER_NAME")}
              required
              disabled={!allowModify}
            />
          </div>
          <div className={styles.item}>
            <InputV2
              name="description"
              label={t("BANNER_DESCRIPTION")}
              required
              disabled={!allowModify}
            />
          </div>
          <div className={styles.item}>
            <SelectV2
              name="type"
              label={t("BANNER_TYPE")}
              required
              choices={bannerTypeChoices.map((type: string) => ({
                label: t(type),
                value: type,
              }))}
              onChange={() => methods.setValue("typeIds", [])}
              disabled={!allowModify}
            />
          </div>
          {["BRAND", "PRODUCT", "CATEGORY"].includes(methods.watch("type")) && (
            <div className={styles.item}>
              {methods.watch("type") === "BRAND" && (
                <>
                  <BrandInput
                    values={banner?.typeIds ?? []}
                    disabled={!allowModify}
                  />
                </>
              )}
              {methods.watch("type") === "PRODUCT" && (
                <>
                  <ProductInput
                    values={banner?.typeIds ?? []}
                    disabled={!allowModify}
                  />
                </>
              )}
              {methods.watch("type") === "CATEGORY" && (
                <>
                  <CategoryInput
                    values={banner?.typeIds ?? []}
                    disabled={!allowModify}
                  />
                </>
              )}
            </div>
          )}
          <div className={styles.item}>
            <SelectV2
              name="status"
              label={t("BANNER_STATUS")}
              required
              choices={[
                {
                  label: t("ENABLED"),
                  value: "ACTIVE",
                },
                {
                  label: t("DISABLED"),
                  value: "DISABLED",
                },
              ]}
              disabled={!allowModify}
            />
          </div>
          <div className={styles.item}>
            <InputV2
              name="availableSince"
              label={t("BANNER_AVAILABLE_SINCE")}
              type="datetime-local"
              min={minDate}
              required
              disabled={!allowModify}
            />
          </div>
          <div className={styles.item}>
            <InputV2
              name="availableUntil"
              label={t("BANNER_AVAILABLE_UNTIL")}
              type="datetime-local"
              min={minDate}
              required
              disabled={!allowModify}
            />
          </div>
          <div className={styles.item}>
            <SelectV2
              name="redirectTo.type"
              label={t("REDIRECT_TO_TYPE")}
              required
              disabled={!allowModify}
              choices={[
                {
                  label: t("REDIRECT_NONE"),
                  value: "NONE",
                },
                {
                  label: t("REDIRECT_EXTERNAL"),
                  value: "EXTERNAL",
                },
              ]}
            />
          </div>
          {methods.watch("redirectTo.type") === "EXTERNAL" && (
            <div className={styles.item}>
              <InputV2
                name="redirectTo.target"
                label={t("REDIRECT_TO_TARGET")}
                required
                validate={isValidHttpsUrl}
                validMessage={t("URL_NOT_VALID")}
                disabled={!allowModify}
              />
            </div>
          )}
        </div>
        <div className={styles.buttons}>
          {allowModify ? (
            <>
              <div>
                <button
                  className="primary"
                  onClick={methods.handleSubmit(onSubmit)}
                  disabled={isLoading}
                >
                  {t(isEdit ? "SAVE" : "CREATE")}
                </button>
              </div>
              <div>
                <button
                  className="outlined"
                  onClick={onCancelForm}
                  disabled={isLoading}
                >
                  {t("CANCEL")}
                </button>
              </div>
            </>
          ) : (
            <>
              {banner?.enabled && (
                <>
                  <div>
                    <button
                      className="primary"
                      onClick={() => setAllowModify(true)}
                    >
                      {t("EDIT")}
                    </button>
                  </div>
                  <DeleteBanner
                    banner={banner ?? ({} as TBanner)}
                    onDone={() => onDone?.()}
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
    </FormProvider>
  );
};

const CategoryInput = ({
  values = [],
  disabled = false,
}: {
  values: any[];
  disabled: boolean;
}) => {
  const {
    data: categories,
    isValidating,
    mutate,
  } = useSWR([{}, "category-tree-input"], getCategoriesFullTree);
  const methods = useFormContext();
  const defaultValues = categories
    ?.filter(({ id }) => values?.includes(id))
    ?.map(({ id, name }) => ({ value: id, label: name }));

  useEffect(() => {
    mutate();
  }, [values, disabled]);

  return (
    <>
      {!isValidating && (
        <MultipleSelectionCategory
          values={defaultValues ?? []}
          disabled={disabled}
          onValueChange={(values: any[]) => methods.setValue("typeIds", values)}
        />
      )}
    </>
  );
};

const BrandInput = ({
  values = [],
  disabled = false,
}: {
  values: any[];
  disabled: boolean;
}) => {
  const { user } = useAuth();
  const {
    data: brands,
    isValidating,
    mutate,
  } = useSWR([user.id, "brands-supplier"], getSupplierBrandsV3);
  const methods = useFormContext();
  const defaultValues = brands
    ?.filter(({ id }) => values?.includes(id))
    ?.map(({ id, name }) => ({ value: id, label: name }));

  useEffect(() => {
    mutate();
  }, [values, disabled]);

  return (
    <>
      {!isValidating && (
        <MultipleSelectionBrand
          values={defaultValues ?? []}
          disabled={disabled}
          onValueChange={(values: any[]) => methods.setValue("typeIds", values)}
        />
      )}
    </>
  );
};

const ProductInput = ({
  values = [],
  disabled = false,
}: {
  values: any[];
  disabled: boolean;
}) => {
  const {
    data: products,
    isValidating,
    mutate,
  } = useSWR([values, "products-supplier"], getProductByIds);
  const methods = useFormContext();
  const defaultValues = products
    ?.filter(({ id }) => values?.includes(id))
    ?.map(({ id, name }) => ({ value: id, label: name }));

  useEffect(() => {
    mutate();
  }, [values, disabled]);

  return (
    <>
      {!isValidating && (
        <MultipleSelectionProduct
          values={defaultValues ?? []}
          disabled={disabled}
          onValueChange={(values: any[]) => methods.setValue("typeIds", values)}
        />
      )}
    </>
  );
};
