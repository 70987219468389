import { getPurchases, getSupplierOrders } from "@/http";
import useSWR from "swr";

export const useUserPurchase = (
  isSubpurchases = false,
  userId: string,
  supplierId: string,
  isCommercial: boolean,
  commercialId?: string,
  showAllPurchases?: boolean
) => {
  const { data } = useSWR(
    userId && supplierId
      ? [
          ...(isSubpurchases ? [supplierId] : []),
          {
            size: 5,
            page: 0,
            search: `supplierId:${supplierId},sellerId:${userId}${
              isCommercial && !showAllPurchases
                ? `,commercialId:${commercialId}`
                : ""
            }`,
            sort: "createdAt,DESC",
          },
          "purchaseUser",
        ]
      : null,
    isSubpurchases ? (getSupplierOrders as any) : (getPurchases as any)
  );

  return data;
};
