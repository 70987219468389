import { getSupplierRoleById } from "@/http";
import useSWR from "swr";

export const useSupplierRoleByID = (supplierId: string, roleId: string) => {
  const { data: role } = useSWR(
    roleId ? [supplierId, roleId, "supplierBrands"] : null,
    getSupplierRoleById
  );

  return role ?? { name: "-" };
};
