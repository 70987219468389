import { usePurchaseClient } from "@/hooks/usePurchaseProducts";
import { TOrderDetailsPanel } from "@/models/order-details";
import { useTranslation } from "react-i18next";
import { LastPurchasesUser } from "../LastPurchasesUser/LastPurchasesUser";
import { CreateComment } from "./CreateComment";
import { ListComments } from "./ListComments";
import styles from "./styles.module.scss";

export const CommentsSubPurchase = ({ orderDetail }: TOrderDetailsPanel) => {
  const purchaseClient = usePurchaseClient(orderDetail?.purchaseId ?? "");
  const [t] = useTranslation("orders-page");

  return (
    <>
      <div className="spacer" />
      <div className="flexGap flexSmColumn">
        <CreateComment orderId={orderDetail!.id} />
        <ListComments orderId={orderDetail!.id} />
        <div className="col-4 col-sm-12 noPadding">
          <LastPurchasesUser
            useSubpurchases
            clientId={purchaseClient?.id ?? ""}
            customTitle={
              <div className={styles.header}>
                <span className="bold">{t("LAST_ORDERS")}</span>
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};
