import { TourProvider, useTour } from "@reactour/tour";
import { ReactNode } from "react";
import { getSteps } from "./steps";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import styles from "./styles.module.scss";

export const OnboardingProvider = ({ children }: { children: ReactNode }) => {
  const [t] = useTranslation("onboarding");
  const navigate = useNavigate();

  return (
    <TourProvider
      steps={getSteps(t, navigate)}
      prevButton={() => null}
      showDots={false}
      disableInteraction
      showCloseButton={true}
      styles={{
        badge: base => ({ ...base, backgroundColor: "#78cb46" }),
      }}
      components={{
        Close: Button,
      }}
    >
      {children}
    </TourProvider>
  );
};

const Button = () => {
  const { setIsOpen } = useTour();
  return (
    <button className={styles.closeBtn} onClick={() => setIsOpen(false)}>
      x
    </button>
  );
};
