import DatePicker from "react-datepicker";
import { useOrderFilter } from "@/store/orders";
import { useTranslation } from "react-i18next";
import { BiCalendarEvent } from "react-icons/bi";
import { format } from "date-fns";
import {
  DATE_MONT_YEAR_SLASH,
  USA_DATE_MONT_YEAR_SLASH,
} from "@/constants/date";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";
import styles from "../IdFilter/styles.module.scss";
import { isUSA } from "@/constants/core";

const RangeDatePicker = () => {
  const {
    startDateFilter: startDate,
    endDateFilter: endDate,
    setDatesFilter,
  } = useOrderFilter();
  const [t] = useTranslation("orders-page");

  const onChange = (dates: any[]) => {
    const [start, end] = dates;
    setDatesFilter(start, end);
  };

  return (
    <div className={styles.dateInput}>
      <DatePicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        dateFormat="dd/MM/yyyy"
        isClearable
        maxDate={new Date()}
        customInput={
          <div className={styles.inputGroup}>
            <BiCalendarEvent className={styles.icon} />
            <input
              type="text"
              value={
                startDate && endDate
                  ? `${format(
                      startDate,
                      isUSA ? USA_DATE_MONT_YEAR_SLASH : DATE_MONT_YEAR_SLASH
                    )} - ${format(
                      endDate,
                      isUSA ? USA_DATE_MONT_YEAR_SLASH : DATE_MONT_YEAR_SLASH
                    )}`
                  : ""
              }
              readOnly
              placeholder={t("FILTER.SINCE_TO")}
            />
          </div>
        }
      />
    </div>
  );
};

export default RangeDatePicker;
