import {
  auth,
  firebaseConfirmSigninLink,
  firebaseLogin,
  firebaseLogout,
  firebaseSigninEmailLink,
  getCurrentUser,
} from "@/core/firebase";
import {
  AxiosError,
  createSupplierWithoutId,
  getSupplierById,
  getSupplierCommercialById,
  getSupplierRoleById,
  TSupplier,
  TSupplierRole,
} from "@/http";
import { refreshSeeriApiConfig } from "@/config/seeri-api";
import { showToastError } from "@/hooks/useToast";
import { Supplier } from "@/models/supplier";
import { TSupplierCommercial } from "@/http";
import { TLoginForm, TSignupForm } from "@/models/auth";
import { signInWithCustomToken, User } from "firebase/auth";
import { create } from "zustand";
import { CURRENT_LANG, ES } from "@/constants/core";

export type TAuthStatus = {
  user: Supplier;
  isAuth: boolean;
  setUser: (user: Supplier) => void;
  isCommercial: boolean;
  setCommercial: (
    user: Supplier | null,
    commercial: TSupplierCommercial | null
  ) => void;
  commercial: TSupplierCommercial | null;
  role: TSupplierRole | null;
  login: (values: TLoginForm) => Promise<void>;
  emailLogin: () => Promise<void>;
  logout: () => void;
  refreshUserData: () => Promise<void>;
  loginOtp: (token: string) => Promise<void>;
  changePassword: (password: string) => Promise<void>;
  signup: (values: TSignupForm) => Promise<void>;
};

export const useAuth = create<TAuthStatus>()((set, get) => ({
  isCommercial: false,
  setCommercial: async (user, commercial) => {
    let role: TSupplierRole | null = null;
    if (commercial?.roleId && user?.id) {
      role = await getSupplierRoleById(user.id, commercial.roleId);
    }
    set(() => ({ commercial, isCommercial: !!commercial, role }));
  },
  commercial: null,
  role: null,
  user: {} as TSupplier,
  isAuth: false,
  setUser: user => set(() => ({ user, isAuth: !!user.id })),
  logout: async () => {
    await firebaseLogout();
    get().setUser({} as TSupplier);
    get().setCommercial(null, null);
    localStorage.clear();
  },
  login: async ({ email, password }) => {
    const credentials = await firebaseLogin(email, password);
    await setSupplierOrLogout(credentials.user);
  },
  emailLogin: async () => {
    const validSigninLink = await firebaseConfirmSigninLink();
    if (validSigninLink) {
      const email = window.localStorage.getItem("emailForSignIn") || "";
      window.localStorage.removeItem("emailForSignIn");
      const credentials = await firebaseSigninEmailLink(email);
      await setSupplierOrLogout(credentials.user);
    } else {
      throw new Error(
        CURRENT_LANG() === ES
          ? "Este link no es válido"
          : "This link is not valid"
      );
    }
  },
  refreshUserData: async () => {
    const user = await getCurrentUser();
    if (user) await setSupplierOrLogout(user);
  },
  loginOtp: async (token: string) => {
    const userCredential = await signInWithCustomToken(auth, token);
    setSupplierOrLogout(userCredential.user);
  },
  changePassword: async (password: string) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw CURRENT_LANG() === ES
          ? "Tiempo de sesión finalizado"
          : "No user session";
      }
      await user?.updatePassword(password);
    } catch (error) {
      throw { error };
    }
  },
  async signup(values) {
    await refreshSeeriApiConfig();
    await createSupplierWithoutId(values);
    await get().login(values);
  },
}));

export const setSupplierOrLogout = async (user: User) => {
  await refreshSeeriApiConfig();
  const userToken = await user.getIdTokenResult();
  const userId =
    userToken?.claims["seeri_user_id"] || userToken?.claims["user_id"];
  const supplierId = userToken?.claims["supplier_id"];
  const isSupplier = userId === supplierId;

  try {
    const commercial = isSupplier
      ? null
      : await getSupplierCommercialById(userId);
    const supplier = await getSupplierById(supplierId);
    useAuth
      .getState()
      .setCommercial(commercial != null ? supplier : null, commercial);
    useAuth.getState().setUser(supplier);
    localStorage.setItem("country", supplier.countryCode ?? "co");
  } catch (error) {
    if (error instanceof AxiosError && error.response?.data) {
      await firebaseLogout();
      useAuth.getState().setUser({} as TSupplier);
      useAuth.getState().setCommercial(null, null);
      localStorage.setItem("country", "co");
    }
    showToastError({
      message:
        CURRENT_LANG() === ES
          ? "Error con el usuario, por favor contacta a soporte."
          : "User error, please contact support.",
    });
  } finally {
    await refreshSeeriApiConfig(localStorage.getItem("country") ?? "");
  }
};
