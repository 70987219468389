import { CoinsBalance } from "@/components/profile/coins/CoinsBalance";
import { Card } from "@/components/shared/Card/Card";
import { CoinsList } from "@/components/profile/coins/CoinsList";
import { useParams } from "react-router";
import { useState } from "react";
import { AddCoinsModal } from "@/components/clients/AddCoinsModal";
import { debounce } from "lodash-es";
import { Spinner } from "@/components/shared/Spinner/Spinner";

export const ClientCoins = () => {
  const { id } = useParams();
  const [isLoading, setLoading] = useState(false);

  const reloadData = () => {
    setLoading(true);
    const debounceTimer = debounce(() => setLoading(false), 600);
    debounceTimer();
  };

  return (
    <>
      {id && (
        <>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <div className="contentInline center">
                <CoinsBalance sellerId={id} />
                <AddCoinsModal sellerId={id} onDone={reloadData} />
              </div>
              <div className="spacer" />
              <Card className="noPadding">
                <CoinsList sellerId={id} onEdit={reloadData} />
              </Card>
            </>
          )}
        </>
      )}
    </>
  );
};
