import { Styles } from "@react-pdf/renderer";

export const billComponent: Styles = {
  headerContainer: {
    justifyContent: "flex-start",
    width: "50%",
    border: "1px solid white",
    borderColor: "#E6E6E6",
    height: "120px",
  },
  billTo: {
    marginTop: 0,
    fontFamily: "Helvetica-Oblique",
    backgroundColor: "#F5F5F5",
    width: "100%",
    padding: "5px",
  },
  infoContainer: {
    padding: "10px",
  },
};

export const billComponentPOS: Styles = {
  ...billComponent,
  headerContainer: {
    justifyContent: "flex-start",
    width: "100% !important",
    border: "1px solid white",
    borderColor: "#E6E6E6",
    height: "95px",
  },
};

export const shipComponent: Styles = {
  headerContainer: {
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "50%",
    border: "1px solid white",
    borderColor: "#E6E6E6",
    height: "120px",
  },
  billTo: {
    marginTop: 0,
    fontFamily: "Helvetica-Oblique",
    backgroundColor: "#F5F5F5",
    width: "100%",
    padding: "5px",
  },
  infoContainer: {
    padding: "10px",
  },
};

export const shipComponentPOS: Styles = {
  ...shipComponent,
  headerContainer: {
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    border: "1px solid white",
    borderColor: "#E6E6E6",
    height: "95px",
  },
};

export const invoiceStyles: Styles = {
  page: {
    fontFamily: "Helvetica",
    fontSize: 9,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    paddingBottom: 40,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: "auto",
    marginRight: "auto",
    position: "absolute",
  },
  document: {
    width: "100%",
  },
  headerContainer: {
    position: "relative",
  },
  shippingContainer: {
    alignItems: "flex-start",
    position: "relative",
    flexDirection: "row",
    marginTop: "20px",
  },
  totals: {
    alignItems: "flex-end",
    marginTop: "10px",
  },
};

export const invoicePOSStyles: Styles = {
  ...invoiceStyles,
  page: {
    fontFamily: "Helvetica",
    fontSize: 6,
    paddingTop: 10,
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 20,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    width: 60,
    height: 50,
    marginLeft: "auto",
    marginRight: "auto",
    position: "absolute",
  },
};

export const invoiceTotalsStyles: Styles = {
  container: {
    alignItems: "center",
    border: "1px solid white",
    flexDirection: "row",
    borderColor: "#E6E6E6",
    fontSize: 7,
    width: "35%",
  },
  item: {
    backgroundColor: "#F5F5F5",
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
    height: 20,
    paddingRight: "10px",
    width: "40%",
  },
  value: {
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
    height: 20,
    paddingRight: "10px",
    width: "50%",
  },
};

export const invoiceTotalsStylesPOS: Styles = {
  container: {
    alignItems: "center",
    border: "1px solid white",
    flexDirection: "row",
    borderColor: "#E6E6E6",
    fontSize: 7,
    width: "75%",
  },
};

const borderColor = "#F5F5F5";

export const invoiceItemsStyle: Styles = {
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#E6E6E6",
  },
  tableContainerExtract: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 85,
    borderWidth: 1,
    borderColor: "#E6E6E6",
  },
  container: {
    flexDirection: "row",
    borderColor: "#F5F5F5",
    backgroundColor: "#F5F5F5",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
    fontSize: 7,
    justifyContent: "center",
  },
  row: {
    flexDirection: "row",
    borderBottomColor: "#F5F5F5",
    borderBottomWidth: 1,
    alignItems: "center",
    fontStyle: "bold",
    fontSize: 7,
    justifyContent: "center",
    width: "100%",
  },
  image: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    justifyContent: "center",
    textAlign: "center",
    padding: "2",
  },
  description: {
    width: "60%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    justifyContent: "center",
    textAlign: "left",
    paddingLeft: "2",
    paddingRight: "2",
  },
  extractMovement: {
    width: "33%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    justifyContent: "center",
    textAlign: "center",
    paddingLeft: "2",
    paddingRight: "2",
  },
  qty: {
    width: "5%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    justifyContent: "center",
    textAlign: "center",
    paddingLeft: "2",
    paddingRight: "2",
  },
  amount: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    justifyContent: "center",
    textAlign: "center",
    paddingLeft: "2",
    paddingRight: "2",
  },
  key: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    justifyContent: "center",
    textAlign: "center",
    paddingLeft: "2",
    paddingRight: "2",
  },
};

export const invoiceItemsStylePOS: Styles = {
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#E6E6E6",
  },
  tableContainerExtract: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 85,
    borderWidth: 1,
    borderColor: "#E6E6E6",
  },
  container: {
    flexDirection: "row",
    borderColor: "#F5F5F5",
    backgroundColor: "#F5F5F5",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
    fontSize: 5,
    justifyContent: "center",
  },
  row: {
    flexDirection: "row",
    borderBottomColor: "#F5F5F5",
    borderBottomWidth: 1,
    alignItems: "center",
    fontStyle: "bold",
    fontSize: 5,
    justifyContent: "center",
    width: "100%",
  },
  image: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    justifyContent: "center",
    textAlign: "center",
    padding: "2",
  },
  description: {
    width: "35%",
    fontSize: 5,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    justifyContent: "center",
    textAlign: "left",
    paddingLeft: "2",
    paddingRight: "2",
  },
  extractMovement: {
    width: "33%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    justifyContent: "center",
    textAlign: "center",
    paddingLeft: "2",
    paddingRight: "2",
  },
  qty: {
    width: "5%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    justifyContent: "center",
    textAlign: "center",
    paddingLeft: "2",
    paddingRight: "2",
  },
  amount: {
    width: "25%",
    fontSize: 5,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    justifyContent: "center",
    textAlign: "center",
    paddingLeft: "2",
    paddingRight: "2",
  },
  key: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    justifyContent: "center",
    textAlign: "center",
    paddingLeft: "2",
    paddingRight: "2",
  },
};

export const invoiceNoStyles: Styles = {
  invoiceNoContainer: {
    marginTop: 0,
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  invoiceDateContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  invoiceHeaderContainer: {
    position: "absolute",
    right: "0",
    width: "30%",
  },
  label: {
    fontStyle: "bold",
  },
};

export const poweredLabel: Styles = {
  container: {
    marginLeft: "50px",
    width: "100%",
    color: "#E6E6E6",
    position: "absolute",
    bottom: "3px",
    justifyContent: "center",
    alignItems: "center",
  },
};

export const deliverySign: Styles = {
  signContainer: {
    alignItems: "flex-start",
    flexDirection: "row",
    marginTop: "20px",
    position: "absolute",
    bottom: "20px",
  },
  container: {
    display: "flex",
    marginLeft: "50px",
    width: "50%",
  },
};
