import { useSideBarStatus } from "@/store/ui";
import { useTour } from "@reactour/tour";

export const useOnboarding = () => {
  const { setSideBarCollapsed } = useSideBarStatus();
  const { setIsOpen, setCurrentStep } = useTour();

  const startOnboarding = () => {
    if (window.innerWidth > 1201 && window.innerHeight > 580) {
      setSideBarCollapsed(false);
      setCurrentStep(0);
      setTimeout(() => {
        setIsOpen(true);
      }, 500);
    }
  };

  return {
    startOnboarding,
  };
};
