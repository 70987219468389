import { useAuth } from "@/store/auth";
import { FormEventHandler, useState } from "react";
import { SafeParseError } from "zod";
import useSWRImmutable from "swr/immutable";
import { AxiosError, getSeeriApi } from "@/http";
import styles from "./styles.module.scss";
import { groupBy } from "lodash-es";
import { useTranslation } from "react-i18next";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { toast } from "react-toastify";

type ResultStatus = "created" | "modified" | "unmodified" | "error";
type Result<T> = { row: T; status: ResultStatus; message?: string };
type UploadResult<T = any> = {
  results: Result<T>[];
};

export const ClientsBulkProcessUpload = ({
  mode,
  refresh,
}: {
  mode: "create" | "update";
  refresh: () => void;
}) => {
  const [globalT] = useTranslation("global");
  const [t] = useTranslation("products-page");

  const statusTexts = {
    created: t("BULK_CHARGE.UPLOAD.STATUS.CREATED"),
    modified: t("BULK_CHARGE.UPLOAD.STATUS.MODIFIED"),
    unmodified: t("BULK_CHARGE.UPLOAD.STATUS.UNMODIFIED"),
    error: t("BULK_CHARGE.UPLOAD.STATUS.ERROR"),
  };

  const supplier = useAuth(s => s.user);

  const modes = {
    create: {
      headers: "/api/seeri-excel/supplier-clients/bulk-create/headers",
      upload: "/api/seeri-excel/supplier-clients/bulk-create/upload",
    },
    update: {
      headers: "/api/seeri-excel/supplier-clients/bulk-update/headers",
      upload: "/api/seeri-excel/supplier-clients/bulk-update/upload",
    },
  };

  const [uploading, setUploading] = useState(false);
  const [validationErr, setValidationErr] = useState<SafeParseError<unknown>>();
  const [uploadResult, setUploadResult] = useState<UploadResult>();

  const { data: headers } = useSWRImmutable(modes[mode].headers, path =>
    getSeeriApi()
      .get(path)
      .then(res => res.data)
  );

  const handleSubmit: FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault();
    const form = event.currentTarget;
    const formData = new FormData(form);

    setUploading(true);
    setValidationErr(undefined);
    setUploadResult(undefined);

    try {
      const data = await getSeeriApi()
        .request({
          method: "POST",
          url: modes[mode].upload,
          data: formData,
          params: { supplierId: supplier.id },
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(res => res.data);
      setUploadResult(data);
      refresh();
      form.reset();
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response && error.response.status < 500) {
          setValidationErr(error.response?.data);
        } else {
          toast.error(globalT("ERROR_SOME_BAD").toString());
        }
      }
    }
    setUploading(false);
  };

  return (
    <div className={styles.bulkContainer}>
      <form onSubmit={handleSubmit} className={styles.spacing}>
        <input
          name="excel"
          type="file"
          multiple={false}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          className={styles.whiteContainer}
          required
        />
        <div className="contentInline">
          {uploading ? <Spinner /> : <span />}
          <button className="primary" disabled={uploading}>
            {t("BULK_CHARGE.UPLOAD.START_UPLOAD_PROCESS")}
          </button>
        </div>
      </form>

      <br />

      {validationErr && (
        <div className={styles.alert__warning}>
          <p>
            <strong>{t("BULK_CHARGE.UPLOAD.VALIDATION_ERROR_FOUND")}</strong>
          </p>

          <ul>
            {Object.entries(groupBy(validationErr?.error.issues, "path.0")).map(
              ([recordIdx, issues]) => (
                <li key={recordIdx}>
                  <h3>
                    {t("BULK_CHARGE.UPLOAD.ROW")} {+recordIdx + 1}
                  </h3>
                  <ul>
                    {issues.map((issue, i) => (
                      <li key={i}>
                        <b>
                          {headers[issue.path[1]] ?? issue.path[1] ?? "???"}:
                        </b>{" "}
                        <span
                          dangerouslySetInnerHTML={{ __html: issue.message }}
                        />
                      </li>
                    ))}
                  </ul>
                </li>
              )
            )}
          </ul>
        </div>
      )}

      {uploadResult && (
        <div className={styles.spacing}>
          <p>
            <strong>{t("BULK_CHARGE.UPLOAD.PROCESS_COMPLETED")}</strong>
          </p>

          {Object.entries(groupBy(uploadResult.results, "status")).map(
            ([status, results]) => (
              <div key={status} className={styles["alert__" + status]}>
                <p>
                  <strong>{statusTexts[status as ResultStatus]}</strong>:{" "}
                  <strong>{results.length}</strong>{" "}
                  {t("BULK_CHARGE.UPLOAD.ROWS")}.
                </p>
                <ul>
                  {results.map((result, i) => (
                    <li key={i}>
                      <b>{result.row.email}</b>
                      {result.message && <>: {result.message}</>}
                    </li>
                  ))}
                </ul>
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};
