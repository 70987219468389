import { useSideBarStatus } from "@/store/ui";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import { useSubscription } from "@/hooks/useSubscription";
import { PremiumIcon } from "@/lib/subscriptions/components/icons/Premium";

export const SubscriptionLink = ({ onClick }: { onClick: () => void }) => {
  const [t] = useTranslation("global");
  const { isSideBarCollapsed } = useSideBarStatus();
  const { currentPackage } = useSubscription();

  return (
    <Link to="/subscription" className={styles.subscriptionLink}>
      <div className={styles.sidebarItem} onClick={onClick}>
        <span className={styles.icon}>
          <PremiumIcon width={24} height={24} />
        </span>
        <div className={isSideBarCollapsed ? styles.hide : ""}>
          <p>{t("SUBSCRIPTION.TITLE")}</p>
          <p className={styles.name}>
            {currentPackage?.name ?? currentPackage?.code}
          </p>
        </div>
      </div>
    </Link>
  );
};
