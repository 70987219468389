import { CURRENT_LANG, ES } from "@/constants/core";
import { showToastError } from "@/hooks/useToast";
import {
  AxiosError,
  getSupplierAddresses,
  TRestListData,
  TSupplierAddress,
} from "@/http";
import { create } from "zustand";

type TUseAddresses = {
  addresses: TRestListData<TSupplierAddress>;
  currentPage: number;
  totalPages: number;
  loadingOrders: boolean;
  getAddresses: (supplierId: string, page: number) => void;
};

export const useAddresses = create<TUseAddresses>(set => ({
  addresses: {
    content: [],
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  },
  currentPage: 0,
  totalPages: 0,
  loadingOrders: false,
  getAddresses: async (supplierId, page) => {
    try {
      set(() => ({ loadingOrders: true }));
      const addresses = await getSupplierAddresses(supplierId, {
        page,
        size: 100,
      });

      addresses.content = addresses.content.sort((a, b) =>
        a.isMain === b.isMain ? 0 : a.isMain ? -1 : 1
      );

      set(() => ({
        loadingOrders: false,
        addresses,
        totalPages: addresses?.totalPages,
        currentPage: page,
      }));
    } catch (error: any) {
      set(() => ({
        loadingOrders: false,
        currentPage: 0,
        totalPages: 0,
        addresses: {
          content: [],
          totalPages: 0,
          totalElements: 0,
          numberOfElements: 0,
        },
      }));
      if (error instanceof AxiosError) {
        showToastError(error.response?.data?.message ?? error.message);
      } else {
        showToastError(
          CURRENT_LANG() === ES
            ? "No se pudieron obtener las direcciones"
            : "Could not get addresses"
        );
      }
    }
  },
}));
