import { getProductById } from "@/http";
import useSWR from "swr";
import { PreviewFile } from "../products/ProductsForm/ProductImage";
import styles from "./styles.module.scss";

type TProductImage = {
  id?: string;
  images?: { src: string; mimeType: string }[];
};
const ProductImage = ({ id, images }: TProductImage) => {
  const renderImage = (image: any) => (
    <div className={styles.containerThumbnailImages}>
      <PreviewFile file={image} />
    </div>
  );

  if (images?.length) {
    const image = images.find(i => i?.mimeType?.includes("image")) || images[0];
    return renderImage(image);
  }

  if (!id) {
    return (
      <div className={styles.containerThumbnailImages}>
        <div className={styles.noImage} />
      </div>
    );
  }
  const { data: productDetail } = useSWR(id, getProductById);

  if (!productDetail?.media?.length) {
    return (
      <div className={styles.containerThumbnailImages}>
        <div className={styles.noImage} />
      </div>
    );
  }

  return renderImage(productDetail.images[0]);
};

export default ProductImage;
