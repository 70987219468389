import { useDebounce } from "@/hooks/useDebounce";
import { useId, useRef, useState } from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";
import useSWR from "swr";
import { FaTimes } from "react-icons/fa";

type Value<T> = T & { id: string; name: string };

type Props<T> = {
  values: Value<T>[];
  onChange: (values: Value<T>[]) => void;
  searchFn: (query: string) => Promise<Value<T>[]>;
};

export function AutocompleteMultiple<T>({
  values,
  onChange,
  searchFn,
}: Props<T>) {
  const id = useId();
  const inputRef = useRef<HTMLInputElement>(null);
  const [query, setQuery] = useState("");
  const debQuery = useDebounce(query, 300);

  const { data } = useSWR({ id, q: debQuery }, ({ q }) => searchFn(q));

  return (
    <div className={styles.autocompleteMultiple}>
      <div className={styles.values}>
        {values.map(s => (
          <div key={s.id} className={styles.value}>
            {s.name}
            <div
              onClick={() => onChange(values.filter(v => v.id !== s.id))}
              className={styles.remove}
            >
              <FaTimes />
            </div>
          </div>
        ))}
        <input
          ref={inputRef}
          value={query}
          onChange={e => setQuery(e.target.value)}
          className={clsx(styles.value, styles.input)}
          placeholder="Busca..."
        />
      </div>
      <div className={clsx(styles.choices)}>
        {data?.map(choice => (
          <div
            key={choice.id}
            className={clsx(
              styles.choice,
              values.some(s => s.id === choice.id) && styles.disabled
            )}
            onClick={() => {
              onChange(values.concat([choice]));
              inputRef.current?.focus();
              setQuery("");
            }}
          >
            {choice.name}
          </div>
        ))}
      </div>
    </div>
  );
}
