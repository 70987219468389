export const validateOnlyNumber = (event: any) => {
  if (
    !/[0-9]/.test(event.key) &&
    !["Backspace", "ArrowRight", "ArrowLeft", "Tab"].includes(event.key)
  ) {
    event.preventDefault();
  }
};

export const validateNoSpaces = (event: any) => {
  if (!/^\S*$/.test(event.key)) {
    event.preventDefault();
  }
};

export const validateDecimalNumber = (event: any) => {
  const currentValue = event.currentTarget.value;
  const selectionStart = event.currentTarget.selectionStart ?? 0;
  const regex = /^\d*\.?\d{0,2}$/;

  const newValue =
    currentValue.slice(0, selectionStart) +
    event.key +
    currentValue.slice(selectionStart);

  if (!regex.test(newValue)) {
    event.preventDefault();
  }
  return;
};
