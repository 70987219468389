import global_en from "../i18n/en/global.json";
import global_es from "../i18n/es/global.json";
import orders_page_en from "../i18n/en/orders-page.json";
import orders_page_es from "../i18n/es/orders-page.json";
import products_page_en from "../i18n/en/products-page.json";
import products_page_es from "../i18n/es/products-page.json";
import clients_page_en from "../i18n/en/clients-page.json";
import clients_page_es from "../i18n/es/clients-page.json";
import zodEs from "zod-i18n-map/locales/es/zod.json";
import purchases_en from "../i18n/en/purchases.json";
import purchases_es from "../i18n/es/purchases.json";
import tiered_discounts_en from "../i18n/en/tiered-discounts.json";
import tiered_discounts_es from "../i18n/es/tiered-discounts.json";
import onboarding_en from "../i18n/en/onboarding.json";
import onboarding_es from "../i18n/es/onboarding.json";
import invoice_es from "../i18n/es/invoice.json";
import invoice_en from "../i18n/en/invoice.json";
import { EN, ES } from "@/constants/core";
import { LibSubscriptions } from "@/lib/subscriptions";

const langParam = new URL(location.href).searchParams.get("lang");

if (langParam && [EN, ES].includes(langParam)) {
  localStorage.setItem("lang", langParam);
}

export const I18nConfig = {
  interpolation: { escapeValue: false },
  lng: localStorage.getItem("lang") ?? ES,
  resources: {
    es: {
      invoice: invoice_es,
      global: global_es,
      "orders-page": orders_page_es,
      "products-page": products_page_es,
      "clients-page": clients_page_es,
      zod: zodEs,
      purchases: purchases_es,
      "tiered-discounts": tiered_discounts_es,
      onboarding: onboarding_es,
      [LibSubscriptions.name]: LibSubscriptions.lang.es,
    },
    en: {
      invoice: invoice_en,
      global: global_en,
      "orders-page": orders_page_en,
      "products-page": products_page_en,
      "clients-page": clients_page_en,
      purchases: purchases_en,
      "tiered-discounts": tiered_discounts_en,
      onboarding: onboarding_en,
      [LibSubscriptions.name]: LibSubscriptions.lang.en,
    },
  },
};
