import { isUSA } from "@/constants/core";
import { RadioGroupV2 } from "@/components/shared/RadioGroup/RadioGroupV2";
import { PremiumFeatureIndicator } from "@/components/subscription/PremiumFeatureIndicator";
import { FEAT_LOGISTICS } from "@/constants/features";
import { useAuth } from "@/store/auth";
import useSWR from "swr";
import { getSupplierDeliveryProviders } from "@/http/resources/suppliers/delivery-provider";
import { useSubscription } from "@/hooks/useSubscription";
import { useTranslation } from "react-i18next";

export const LogisticOptionsForm = () => {
  const { user } = useAuth();
  const [t] = useTranslation("orders-page");
  const showSeeriLogistic = user.canUseLogisticIntegration;
  const { hasFeature } = useSubscription();

  const { data: deliveryProviders } = useSWR(
    ["supplier-delivery-providers"],
    getSupplierDeliveryProviders
  );

  return (
    <>
      <RadioGroupV2
        label={""}
        name="dispatchValue"
        options={[
          { label: t("OWN_LOGISTIC"), value: "SUPPLIER" },
          ...(showSeeriLogistic && !isUSA
            ? [
                {
                  label: (
                    <>
                      {t("SEERI_LOGISTIC")}
                      <PremiumFeatureIndicator feature={FEAT_LOGISTICS} />
                    </>
                  ),
                  value: "SEERI",
                  disabled: !hasFeature(FEAT_LOGISTICS),
                },
              ]
            : []),
          ...(deliveryProviders ?? []).map(dp => ({
            label: dp.name,
            value: dp.code,
          })),
        ]}
        required
        inline
        color="purple"
      />
    </>
  );
};
