import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
type TDeliveryTimes = {
  deliveryTimes: { city: string; days: number }[];
};
export const DeliveryTimes = ({ deliveryTimes }: TDeliveryTimes) => {
  const [t] = useTranslation("products-page");

  if (!deliveryTimes.length) {
    return null;
  }

  return (
    <>
      <p className="bold">{t("DELIVERY_TIMES")}</p>
      <table className={styles.table}>
        <thead>
          <tr className={styles.thead}>
            <th>{t("CITY")}</th>
            <th>{t("DAYS")}</th>
          </tr>
        </thead>
        <tbody>
          {deliveryTimes.map((deliveryTime, index: number) => (
            <tr key={index}>
              <td>{deliveryTime.city}</td>
              <td>
                {deliveryTime.days === 0
                  ? t("SAME_DAY")
                  : Math.ceil(deliveryTime.days)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="spacer" />
      <div className="divider" />
      <div className="spacer" />
    </>
  );
};
