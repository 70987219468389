import { FilterTabs } from "@/components/app/ButtonFilterTabs/ButtonFilterTabs";
import { ticketsFilters } from "@/constants/tickets";
import { TicketListTable } from "./TicketListTable/TicketListTable";
import { useTicket } from "@/store/ticket";
import { TicketCreateButton } from "./TicketCreation/TicketCreateButton";

export const TicketList = () => {
  const { currentFilter, setCurrentFilter } = useTicket();

  return (
    <>
      <TicketCreateButton />
      <FilterTabs
        options={[
          { text: "FILTERS.ALL", value: "" },
          ...ticketsFilters.map(status => ({
            text: status.name,
            value: status.code,
          })),
        ]}
        currentFilter={currentFilter}
        action={setCurrentFilter}
      />
      <TicketListTable />
    </>
  );
};
