import { Checkbox } from "@/components/shared/Input/Checkbox";
import { InputV2 } from "@/components/shared/Input/InputV2";
import { Select } from "@/components/shared/Input/Select";
import RadioGroup from "@/components/shared/RadioGroup/RadioGroup";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { useAuth } from "@/store/auth";
import { getTimeFromIso } from "@/utils/date";
import { validateDecimalNumber, validateOnlyNumber } from "@/utils/keyboard";
import { createOffer, TOfferDTO, updateOffer } from "@/http";
import { ProductGiftArrayInput } from "./ProductGiftArrayInput";
import { TGlobalOffer } from "@/http/types/global-offers";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { BrandInput } from "./BrandInput";
import { ClientInput } from "./ClientInput";
import { DatesInput } from "./DatesInput";
import { ProductInput } from "./ProductInput";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

type TFormOffer = {
  offer?: TGlobalOffer;
  onDone?: (offer: TGlobalOffer) => void;
};

export const FormOffer = ({ offer, onDone }: TFormOffer) => {
  const isEdit = !!offer;
  const { user } = useAuth();
  const navigate = useNavigate();
  const [t] = useTranslation("global");

  const [targetProductsIds, setTargetProductsIds] = useState<string[]>(
    isEdit ? offer.targetProductsIds ?? [] : []
  );
  const [targetBrandsProducts, setTargetBrandsProducts] = useState<string[]>(
    isEdit ? offer.targetBrandsProducts ?? [] : []
  );
  const [clients, setClients] = useState<string[]>(
    isEdit ? offer.targetSellersIds ?? [] : []
  );

  const [isLoading, setisLoading] = useState(false);

  const defaultValues = {
    type: isEdit ? offer.type : "PAY_X_TAKE_Y",
    name: isEdit ? offer.name : "",
    minValue: isEdit ? offer.minValue : 0,
    productsToPay: isEdit ? offer.productsToPay ?? 1 : 1,
    customerArticles: isEdit
      ? offer.targetProductsIds?.length
        ? "specific"
        : offer.targetBrandsProducts?.length
        ? "specificBrand"
        : "specific"
      : "specific",
    targetProductsIds: "",
    discountType: "",
    clientSegment: isEdit
      ? clients.length
        ? "specificClientsSegment"
        : "allClientsSegment"
      : "",
    targetBrandsProducts: [],
    gifts: offer?.gifts ?? [
      { productId: undefined, quantity: 1, invoiceIncluded: false },
    ],
    value: offer?.value ?? 0,
    maxValueToOffer: offer?.maxValueToOffer ?? undefined,
    targetReferralCodes: isEdit ? offer.targetReferralCodes.join(", ") : "",
    targetCouponCodes: isEdit ? offer.targetCouponCodes.join(", ") : "",
    firstSale: isEdit ? offer.firstSale : false,
    forVip: isEdit ? offer.forVip : false,
    maxUsesOffer: isEdit ? !!offer.maximumUses : false,
    maxUsesOfferValue: isEdit ? offer.maximumUses : "",
    maxUsesClient: isEdit ? offer.maximumUsesByUser === 1 : false,
    enabled: isEdit ? offer.enabled : false,
    seller: "",
    endsAtDate: isEdit ? offer.endsAt.split("T")[0] : "",
    endsAtTime: isEdit ? getTimeFromIso(offer.endsAt) : "",
  };

  const methods = useForm({ defaultValues });

  const offerType = methods.watch("type");

  const isPayXTakeY = offerType === "PAY_X_TAKE_Y";
  const isDiscountNC = offerType === "DISCOUNT_PERCENTAGE_NON_CUMULATIVE";
  const isFreeShipping = offerType === "FREE_SHIPPING";

  const onSubmit = async (values: typeof defaultValues) => {
    setisLoading(true);
    try {
      if (values.customerArticles === "specific" && !targetProductsIds.length) {
        methods.setFocus("targetProductsIds");
        showToastError(t("OFFERS.ADD_ONE_PRODUCT"));
      }
      if (
        values.customerArticles === "specificBrand" &&
        !targetBrandsProducts.length
      ) {
        methods.setFocus("targetBrandsProducts");
        showToastError(t("OFFERS.ADD_ONE_BRAND"));
      }

      if (
        values.clientSegment === "specificClientsSegment" &&
        !clients.length
      ) {
        methods.setFocus("seller");
        showToastError(t("OFFERS.ADD_ONE_CLIENT"));
      }

      if (new Date(`${values.endsAtDate} ${values.endsAtTime}`) < new Date()) {
        methods.setFocus("endsAtDate");
        showToastError(t("OFFERS.ERROR_DATES"));
      }

      const data: TOfferDTO = {
        supplierId: user.id,
        createdBy: user.id,
        name: values.name,
        type: values.type,
        enabled: values.enabled,
        minValue: +values.minValue,
        productsToPay: isPayXTakeY ? +values.productsToPay : undefined,
        targetProductsIds:
          values.customerArticles === "specific"
            ? targetProductsIds
            : undefined,
        targetBrandsProducts:
          values.customerArticles === "specificBrand"
            ? targetBrandsProducts
            : undefined,
        gifts: values.gifts?.map(g => ({
          ...g,
          productId: (g.productId as any)?.id,
        })),
        value: isDiscountNC ? +values.value : undefined,
        maxValueToOffer:
          isDiscountNC && values.maxValueToOffer
            ? +values?.maxValueToOffer
            : undefined,
        targetSellersIds:
          values.clientSegment === "specificClientsSegment"
            ? clients
            : undefined,
        targetReferralCodes: (values.targetReferralCodes as string)
          .split(",")
          .map(s => s.trim())
          .filter(Boolean),
        targetCouponCodes: (values.targetCouponCodes as string)
          .split(",")
          .map(s => s.trim())
          .filter(Boolean),
        firstSale: values.firstSale,
        forVip: values.forVip,
        maximumUses: values.maxUsesOffer
          ? +values.maxUsesOfferValue
          : undefined,
        maximumUsesByUser: values.maxUsesClient ? 1 : undefined,
        endsAt: new Date(
          `${values.endsAtDate} ${values.endsAtTime}`
        ).toISOString(),
      };

      let newOffer;
      if (isEdit) {
        newOffer = await updateOffer(offer.id, data);
      } else {
        newOffer = await createOffer(data);
      }
      setisLoading(false);
      showToastSuccess(
        t(isEdit ? "OFFERS.OFFER_UPDATED" : "OFFERS.OFFER_CREATED")
      );
      onDone?.(newOffer);
      navigate("/offers");
    } catch (error) {
      showToastError({ message: t("ERROR_SOME_BAD"), error });
      setisLoading(false);
    }
  };

  const removeCustomerProduct = (idx: number) => {
    const filter = targetProductsIds.filter((_, i) => idx !== i);
    setTargetProductsIds(filter);
  };

  const removeCustomerBrand = (idx: number) => {
    const filter = targetBrandsProducts.filter((_, i) => idx !== i);
    setTargetBrandsProducts(filter);
  };

  const removeClient = (idx: number) => {
    const filter = clients.filter((_, i) => idx !== i);
    setClients(filter);
  };

  return (
    <FormProvider {...methods}>
      <form autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
        <div className={styles.section}>
          <div>
            <h6 className={styles.title}>{t("OFFERS.GENERAL")}</h6>
            <div className="divider" />

            <div className={styles.inputRow}>
              <div className="flexGap">
                <div className="col-5 col-sm-12 noPadding">
                  <Select
                    register={methods.register}
                    name="type"
                    label={t("OFFERS.OFFER_TYPE")}
                    choices={[
                      {
                        value: "PAY_X_TAKE_Y",
                        label: t("OFFERS.PAY_X_TAKE_Y"),
                      },
                      {
                        value: "DISCOUNT_PERCENTAGE_NON_CUMULATIVE",
                        label: t("OFFERS.DISCOUNT_PERCENTAGE_NON_CUMULATIVE"),
                      },
                      {
                        value: "FREE_SHIPPING",
                        label: t("OFFERS.FREE_SHIPPING"),
                      },
                    ]}
                    placeholder={t("SELECT")}
                    required
                    error={methods.formState.errors.type}
                  />
                </div>
                <div className="col-7 col-sm-12 noPadding">
                  <InputV2
                    name="name"
                    label={t("OFFERS.OFFER_NAME")}
                    required
                  />
                </div>
              </div>
            </div>

            <div className={`divider ${styles.inputRow}`} />

            <div className={styles.inputRow}>
              <h6 className="bold">{t("OFFERS.CLIENT_BUYS")}</h6>
              <InputV2
                name={"minValue"}
                label={t("OFFERS.MIN_VALUE")}
                onKeyPress={validateDecimalNumber}
              />

              <div className="flexGap">
                {isPayXTakeY && (
                  <div className="col-3 noPadding">
                    <InputV2
                      name={"productsToPay"}
                      label={t("OFFERS.QUANTITY")}
                      required
                      onKeyPress={validateOnlyNumber}
                    />
                  </div>
                )}
                <div className="col-12 noPadding">
                  <Select
                    register={methods.register}
                    name={"customerArticles"}
                    label={t("OFFERS.ARTICLE_FROM")}
                    choices={[
                      // { label: t("OFFERS.ANY_PRODUCT"), value: "all" },
                      {
                        label: t("OFFERS.SPECIFIC_PRODUCTS"),
                        value: "specific",
                      },
                      {
                        label: t("OFFERS.SPECIFIC_BRANDS"),
                        value: "specificBrand",
                      },
                    ]}
                    required
                    error={methods.formState.errors.customerArticles}
                  />
                </div>
              </div>

              {methods.watch("customerArticles") === "specific" && (
                <div className="row">
                  <ProductInput
                    name={"targetProductsIds"}
                    products={targetProductsIds}
                    setProducts={setTargetProductsIds}
                    onDelete={removeCustomerProduct}
                  />
                </div>
              )}
              {methods.watch("customerArticles") === "specificBrand" && (
                <div className="row">
                  <BrandInput
                    name={"targetBrandsProducts"}
                    brands={targetBrandsProducts}
                    setBrands={setTargetBrandsProducts}
                    onDelete={removeCustomerBrand}
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            <h6 className="bold">{t("OFFERS.CLIENT_RECEIVES")}</h6>

            {isPayXTakeY && (
              <>
                <p className={styles.description}>
                  {t("OFFERS.CLIENT_RECEIVES_MESSAGE")}
                </p>

                <div className={styles.inputRow}>
                  <ProductGiftArrayInput />
                </div>
              </>
            )}

            {isDiscountNC && (
              <>
                <InputV2
                  name={"value"}
                  label={t("OFFERS.DISCOUNT_RATE")}
                  onKeyPress={validateDecimalNumber}
                  min={0}
                  max={100}
                  required
                />
                <InputV2
                  name={"maxValueToOffer"}
                  label={t("OFFERS.MAX_VALUE_TO_OFFER")}
                  onKeyPress={validateDecimalNumber}
                />
              </>
            )}

            {isFreeShipping && <>{t("OFFERS.FREE_SHIPPING")}</>}
          </div>
        </div>

        <div className={styles.section}>
          <div>
            <h6 className={styles.title}>{t("OFFERS.CLIENT_SEGMENT")}</h6>
            <div className="divider" />
            <br />
            <RadioGroup
              name={"clientSegment"}
              register={methods.register}
              options={[
                { label: t("OFFERS.ALL_CLIENTS"), value: "allClientsSegment" },
                {
                  label: t("OFFERS.SPECIFIC_CLIENTS"),
                  value: "specificClientsSegment",
                },
              ]}
              error={methods.formState.errors.clientSegment}
              required
            />
            {methods.watch("clientSegment") === "specificClientsSegment" && (
              <>
                <br />
                <ClientInput
                  name="clientsInput"
                  setProducts={setClients}
                  onDelete={removeClient}
                  products={clients}
                />
              </>
            )}
          </div>
        </div>

        <div className={styles.section}>
          <h6 className={styles.title}>{t("OFFERS.COUPON_CODES")}</h6>
          <div className="divider" />
          <br />
          <InputV2
            name={`targetCouponCodes`}
            label={t("OFFERS.COUPON_CODES_LABEL")}
            placeholder="NEWYEAR, FIESTA50, etc"
          />
        </div>

        <div className={styles.section}>
          <h6 className={styles.title}>{t("OFFERS.REFERRAL_CODES")}</h6>
          <div className="divider" />
          <br />
          <InputV2
            name={`targetReferralCodes`}
            label={t("OFFERS.REFERRAL_CODES_LABEL")}
            placeholder="ABC123, XYZ789, etc"
          />
        </div>

        <div className={styles.section}>
          <h6 className={styles.title}>{t("OFFERS.LIMITATIONS")}</h6>
          <div className="divider" />
          <br />
          <Checkbox name={"firstSale"} label={t("OFFERS.FIRST_SALE_ONLY")} />
          <Checkbox name={"forVip"} label={t("OFFERS.FOR_VIP_ONLY")} />
          <Checkbox name={"maxUsesOffer"} label={t("OFFERS.LIMIT_DISCOUNT")} />
          {methods.watch("maxUsesOffer") && (
            <InputV2
              name="maxUsesOfferValue"
              label={t("OFFERS.NUMBER_USES")}
              onKeyPress={validateOnlyNumber}
              required
            />
          )}
          <Checkbox name={"maxUsesClient"} label={t("OFFERS.ONE_USE")} />
        </div>

        <div className={styles.section}>
          <h6 className={styles.title}>{t("OFFERS.ACTIVITY_DATES")}</h6>
          <div className="divider" />
          <br />
          <DatesInput />
        </div>

        <div className="col-12 rigthAlign">
          <button type="submit" className="primary lg" disabled={isLoading}>
            {t("SAVE")}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};
