import { useTranslation } from "react-i18next";
import { useHeaderTitle } from "@/store/ui";
import { FC, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  getAddressListBySellerId,
  getCredits,
  getSellerBillingAddresses,
  getSellerById,
  TSeller,
} from "@/http";
import useSWR from "swr";
import { get } from "lodash-es";
import { format } from "date-fns/esm";
import {
  RecordContext,
  useRecordContext,
} from "@/components/clients/ui/RecordContext";
import { AddressTable } from "@/components/clients/AddressTable";
import { TabItem, Tabs } from "@/components/clients/ui/Tab/Tab";
import { AddressModal } from "@/components/clients/AddressModal";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { BillingAddressTable } from "@/components/clients/BillingAddressTable";
import { CreateBillingAddressModal } from "@/components/purchases/PurchaseCreateForm/PurchaseInvoice/CreateBillingAddressModal";
import { ClientPurchasesTable } from "@/components/clients/ClientPurchasesTable";
import { getCurrentCountry } from "@/constants/core";
import styles from "./styles.module.scss";
import { ClientReportsGrid } from "@/components/clients/Report/ClientReportsGrid";
import { hasPermission } from "@/utils/validations/permissions";
import { useAuth } from "@/store/auth";
import { ClientSegmentButton } from "@/components/segments/ClientSegmentButton";
import { RecordCustomAttributes } from "@/components/custom-fields/RecordCustomAttributes";
import { ClientCommercial } from "./ClientCommercial";
import { PremiumFeatureIndicator } from "@/components/subscription/PremiumFeatureIndicator";
import { FEAT_CLIENT_SEGMENTATION } from "@/constants/features";
import { DisabledZone } from "@/components/shared/DisabledZone/DisabledZone";
import { useSubscription } from "@/hooks/useSubscription";
import { ClientModal } from "@/components/clients/ClientModal";
import { ClientComments } from "./ClientComments";
import { LuExternalLink } from "react-icons/lu";
import { formatCurrency } from "@/utils/currency";
import { ClientCoins } from "./ClientCoins";

const ClientShow = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("clients-page");
  const [tGlobal] = useTranslation("global");
  const isUSA = getCurrentCountry() === "us";
  const { isCommercial, role } = useAuth();
  const { hasFeature } = useSubscription();

  useEffect(() => {
    setTitle(t("CLIENTS.TITLE"), "/clients");
  }, [t]);

  const { id } = useParams();

  const { data, error, mutate, isValidating } = useSWR(
    id ? { key: "client", id } : null,
    ({ id }) => getSellerById(id)
  );

  const { data: credits } = useSWR(["credits"], () => {
    const params: any = {};
    if (id) {
      params.search = `sellerId:${id}`;
    }
    return getCredits(params);
  });

  useEffect(() => {
    mutate();
  }, [id]);

  if (error) return <>{t("ERROR")}</>;
  if (!data) return <Spinner />;

  const client = { ...data };
  client.birthDate &&= format(new Date(data.birthDate), "yyyy-MM-dd");

  return (
    <RecordContext.Provider value={client}>
      <div className={styles.mainContent}>
        <Tabs>
          <TabItem label={t("GENERAL")}>
            <div className={styles.actions}>
              {<ClientModal client={client} onDone={() => mutate()} />}
            </div>
            <div className={styles.tabGeneralGrid}>
              <Field name="legalName" label={t("CLIENTS.LEGAL_NAME")} />
              <Field
                name="firstName"
                label={t(isUSA ? "CLIENTS.CONTACT_NAME" : "NAME")}
              />
              {!isUSA && <Field name="lastName" label={t("LASTNAME")} />}
              <Field name="email" label={t("EMAIL")} />
              <Field name="phonePrefix" label={t("COUNTRY_CODE")} />
              <Field name="phone" label={t("PHONE")} />

              <Field name="documentType" label={t("DOCUMENT_TYPE")} />
              <Field name="documentId" label={t("DOCUMENT_NUMBER")} />

              <div>
                <div className={styles.field}>
                  {t("SEGMENT")}{" "}
                  <PremiumFeatureIndicator feature={FEAT_CLIENT_SEGMENTATION} />
                </div>
                <DisabledZone when={!hasFeature(FEAT_CLIENT_SEGMENTATION)}>
                  <ClientSegmentButton client={client} onDone={mutate} />
                </DisabledZone>
              </div>
              {(credits?.content?.length ?? 0) > 0 && (
                <div>
                  <Link
                    className="bold textUnderline flexGap center"
                    to={`/credits/${credits?.content[0].id}/transactions`}
                  >
                    {t("CREDIT")}{" "}
                    <span className="circleButton">
                      <LuExternalLink />
                    </span>
                  </Link>
                  <p>
                    {t("AMOUNT") +
                      ": " +
                      formatCurrency(credits?.content[0].amount || 0)}
                  </p>
                  <p>
                    {t("BALANCE") +
                      ": " +
                      formatCurrency(credits?.content[0].balance || 0)}
                  </p>
                </div>
              )}
            </div>
            <div className="col-12 noPadding spacer" />

            {!isValidating && (
              <>
                <ClientComments record={client} onDone={mutate} />
                <div className="spacer" />
                <RecordCustomAttributes appliesTo="SELLERS" record={client} />
              </>
            )}

            <div className="spacer col-12 noPadding" />
            <div className="col-12 noPaddding">
              <h6 className="bold">{t("COMMERCIAL_RELATED")}</h6>
              <div className="spacer" />
              {!isValidating && (
                <ClientCommercial
                  suppliers={client?.suppliers || []}
                  onDone={() => mutate()}
                  clientId={client.id}
                />
              )}
            </div>
          </TabItem>
          <TabItem label={t("ADRESSES")}>
            <Addresses />
          </TabItem>
          <TabItem label={t("INVOICE_ADDRESSES")}>
            <BillingAddresses />
          </TabItem>
          <TabItem label={t("SHOPPING")}>
            <ClientPurchasesTable />
          </TabItem>
          <TabItem label={tGlobal("COINS")}>
            <ClientCoins />
          </TabItem>
        </Tabs>
      </div>
      {hasPermission(isCommercial, role, ["action.clients.show.report"]) && (
        <>
          <div className="spacer"></div>
          <div className="spacer"></div>
          <ClientReportsGrid />
        </>
      )}
    </RecordContext.Provider>
  );
};

export default ClientShow;

const Field: FC<{ label: string; name: keyof TSeller }> = ({ label, name }) => {
  const client = useRecordContext<TSeller>();
  if (!client) return null;

  const value = get(client, name);
  return (
    <div>
      <p className={styles.field}>{label}</p>
      <p>{value ? value + "" : "-"}</p>
    </div>
  );
};

const Addresses = () => {
  const [t] = useTranslation("clients-page");
  const [page, setPage] = useState(0);
  const client = useRecordContext<TSeller>();

  const {
    data: list,
    error,
    mutate,
  } = useSWR(
    { key: `client-addresses`, clientId: client.id, page },
    ({ clientId, page }) =>
      getAddressListBySellerId(clientId, { size: 10, page })
  );

  if (error) return <>{t("ERROR")}</>;
  if (!list) return <Spinner />;

  return (
    <>
      <div className={styles.actions}>
        <AddressModal clientId={client.id} onDone={() => mutate()} />
      </div>
      {list.content.length ? (
        <AddressTable
          page={page}
          onPageChange={setPage}
          list={list}
          refresh={mutate}
        />
      ) : (
        <h2 className="bold textCenter">{t("NO_DATA")}</h2>
      )}
    </>
  );
};

const BillingAddresses = () => {
  const [t] = useTranslation("clients-page");
  const [page, setPage] = useState(0);
  const client = useRecordContext<TSeller>();

  const {
    data: list,
    error,
    mutate,
  } = useSWR(
    { key: `client-billing-addresses`, clientId: client.id, page },
    ({ page, clientId }) =>
      getSellerBillingAddresses({
        search: `sellerId:${clientId}`,
        sort: "id,asc",
        size: 10,
        page,
      })
  );

  if (error) return <>{t("ERROR")}</>;
  if (!list) return <Spinner />;

  return (
    <>
      <div className={styles.actions}>
        <CreateBillingAddressModal
          sellerId={client.id}
          onDone={() => mutate()}
        />
      </div>
      {list.content.length ? (
        <BillingAddressTable
          page={page}
          onPageChange={setPage}
          list={list}
          refresh={mutate}
        />
      ) : (
        <h2 className="bold textCenter">{t("NO_DATA")}</h2>
      )}
    </>
  );
};
