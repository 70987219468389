import clsx from "clsx";
import { NavLink as RouteLink, useLocation } from "react-router-dom";
import { SideBarItemProps } from "@/models/side-bar";
import { useSideBarStatus } from "@/store/ui";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { CURRENT_COUNTRY } from "@/constants/core";
import { PremiumIcon } from "@/lib/subscriptions/components/icons/Premium";
import { useSubscription } from "@/hooks/useSubscription";
import { LockedFeatureIndicator } from "@/components/subscription/LockedFeatureIndicator";
import { hasPermission } from "@/utils/validations/permissions";
import { useAuth } from "@/store/auth";
import { GoDotFill } from "react-icons/go";

const SideBarItem = ({
  name,
  icon: Icon,
  children,
  link,
  onClick,
  subItems,
  feature,
  isSubItem,
}: SideBarItemProps) => {
  const { isBasicFeature } = useSubscription();
  const { isSideBarCollapsed } = useSideBarStatus();
  const [t] = useTranslation("global");
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const hasSubItems = subItems && subItems.length > 0;
  const { hasFeature } = useSubscription();
  const { isCommercial, role } = useAuth();
  const { pathname } = useLocation();

  const isParentUrlActive = !!subItems?.find(i => pathname.includes(i.link));

  const toggleSubMenu = (e: any) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  useEffect(() => {
    if (isParentUrlActive && !isSubMenuOpen) {
      setIsSubMenuOpen(true);
    }
  }, [isParentUrlActive]);

  return (
    <div
      className={clsx({
        [styles.parentActive]:
          isSideBarCollapsed && (isParentUrlActive || pathname.includes(link)),
      })}
    >
      <RouteLink
        to={link}
        data-onboarding={`sidebar-${name}`}
        className={values => {
          const { isActive } = values;
          return clsx(
            isActive && [
              (isParentUrlActive || pathname.includes(link)) &&
                !isSideBarCollapsed &&
                styles.activeLink,
              isSideBarCollapsed && styles.collapsed,
            ],
            feature && !isBasicFeature(feature) && styles.premium,
            !hasFeature(feature ?? "") && styles.locked,
            {
              [styles.collapsedActive]:
                (isParentUrlActive || pathname.includes(link)) &&
                isSideBarCollapsed,
            }
          );
        }}
        style={{ textDecoration: "none" }}
      >
        <div role="button" className={styles.sidebarItem} onClick={onClick}>
          <span
            className={clsx(styles.icon, {
              [styles.noIndicator]: !Icon,
              ["mlmd"]: isSubItem,
            })}
          >
            {Icon && <Icon size={isSubItem ? "0.8rem" : "1.15rem"} />}
            {feature && !isBasicFeature(feature) && (
              <PremiumIcon
                width={10}
                height={10}
                className={styles.premiumIcon}
              />
            )}
          </span>
          <div
            className={
              isSideBarCollapsed
                ? styles.hide
                : clsx("textLine mdFont", { ["pl1"]: !!Icon })
            }
          >
            {children}
          </div>

          <div className={styles.lockedIndicator}>
            {!isSideBarCollapsed && feature && (
              <LockedFeatureIndicator feature={feature} />
            )}
          </div>

          {hasSubItems && !isSideBarCollapsed && (
            <span
              data-toggle={isSubMenuOpen ? "opened" : "closed"}
              onClick={toggleSubMenu}
            >
              {isSubMenuOpen ? (
                <FaChevronDown size={"0.6rem"} />
              ) : (
                <FaChevronUp size={"0.6rem"} />
              )}
            </span>
          )}
        </div>
      </RouteLink>
      {hasSubItems && isSubMenuOpen && (
        <div
          className={clsx({ ["ml2"]: !isSideBarCollapsed }, "flexGap noGap")}
        >
          {!isSideBarCollapsed && <div className={styles.lineSub} />}
          <div className="flex1">
            {subItems
              .filter(item => item.activeCountry.includes(CURRENT_COUNTRY))
              .map((link, idx) =>
                hasPermission(isCommercial, role, link.permission ?? []) ? (
                  <SideBarItem
                    key={idx}
                    name={link.name}
                    icon={isSideBarCollapsed ? GoDotFill : null}
                    link={link.link}
                    onClick={onClick}
                    subItems={link.subItems}
                    feature={link.feature}
                    isSubItem
                  >
                    {link?.label ?? t(`SIDEBAR.${link.name.toUpperCase()}`)}
                  </SideBarItem>
                ) : null
              )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SideBarItem;
