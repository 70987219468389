import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { Modal } from "@/components/shared/Modal/Modal";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { showToastError } from "@/hooks/useToast";
import { updateDeliveryStatusDeliveryProvider } from "@/http";
import { getSupplierDeliveryProviderByCode } from "@/http/resources/suppliers/delivery-provider";
import { useAuth } from "@/store/auth";
import { useOrderDetails } from "@/store/order-detail";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiOutlineEdit } from "react-icons/ai";
import useSWR from "swr";
import styles from "./styles.module.scss";

export const UpdateProviderDeliveryStatus = () => {
  const { getOrderDetail, orderDetail } = useOrderDetails();
  const {
    deliveryProvider,
    providerDeliveryStatus,
    providerDeliveryStatusName = "-",
  } = orderDetail;
  const methods = useForm({ defaultValues: { deliveryStatus: "" } });
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { data: deliveryProviderStatus } = useSWR(
    deliveryProvider ? [deliveryProvider, "supplier-delivery-providers"] : null,
    getSupplierDeliveryProviderByCode
  );
  const [t] = useTranslation("orders-page");
  const [tGlobal] = useTranslation("global");
  const { user } = useAuth();

  const getColor = (status: string | undefined) => {
    let color = "";
    switch (status) {
      case "supplier_pending":
      case "pending":
      case "rejected":
      case "cancelled":
      case "failed_to_send":
      case "supplier_rejected":
        color = styles.red;
        break;
      case "approved":
      case "delivered":
      case "in_process":
        color = styles.green;
        break;
      case "in_transit":
      case "preparing":
      case "preparation":
      case "delivered_to_supplier":
      case "pickup_from_supplier":
        color = styles.purple;
        break;
    }

    return color;
  };

  const onSubmit = async ({ deliveryStatus }: any) => {
    try {
      setIsLoading(true);
      await updateDeliveryStatusDeliveryProvider(
        orderDetail.id,
        orderDetail.trackingCode,
        deliveryStatus
      );
      getOrderDetail(orderDetail.id, user.id);
    } catch (error) {
      showToastError({ message: tGlobal("ERROR_SOME_BAD"), error });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className={styles.item}>
        <span className={styles.title}>{t("DELIVERY_STATUS")}</span>
        <span
          className={`${styles.description} flexGap ${getColor(
            orderDetail?.deliveryStatus?.toLowerCase()
          )}`}
        >
          <span>{providerDeliveryStatusName}</span>
          {orderDetail?.deliveryStatus !== "DELIVERED" && (
            <span>
              <ToolTip title={t("UPDATE_DELIVERY_STATUS")}>
                <AiOutlineEdit
                  cursor="pointer"
                  onClick={e => {
                    e.stopPropagation();
                    setIsOpen(true);
                  }}
                />
              </ToolTip>
            </span>
          )}
        </span>
      </div>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        size="sm"
        title={t("UPDATE_DELIVERY_STATUS")}
        footer={
          <>
            <button
              disabled={isLoading}
              onClick={() => {
                methods.reset({ deliveryStatus: "" });
                setIsOpen(false);
              }}
            >
              {t("CANCEL")}
            </button>
            <button
              className="primary lg"
              disabled={isLoading}
              onClick={methods.handleSubmit(onSubmit)}
            >
              {t("UPDATE")}
            </button>
          </>
        }
      >
        <FormProvider {...methods}>
          <SelectV2
            label={t("SELECT_DELIVERY_STATUS")}
            name="deliveryStatus"
            choices={deliveryProviderStatus?.map(ds => ({
              label: ds.providerDeliveryStatusName,
              value: ds.providerDeliveryStatus,
              disabled: ds.providerDeliveryStatus === providerDeliveryStatus,
            }))}
          />
        </FormProvider>
      </Modal>
    </>
  );
};
