import { buckets } from "@/core/buckets";
import { nanoid } from "nanoid";
import { uploadFile } from "./file-upload";

export const uploadImageProductObject = (image: any) => {
  return image?.name
    ? uploadFile(
        buckets.products,
        "SupplierProductGroups",
        nanoid(),
        image
      ).then(url => ({ src: url, mimeType: image?.type ?? image?.mimeType }))
    : image;
};

export const uploadImageTicketObject = (image: any) =>
  image?.name
    ? uploadFile(buckets.products, "SupplierTickets", nanoid(), image).then(
        url => ({ src: url })
      )
    : image;

export const uploadImagePaymentObject = (image: any) =>
  image?.name
    ? uploadFile(buckets.products, "payments", nanoid(), image).then(url => ({
        src: url,
      }))
    : image;

export const uploadImageBannerObject = (image: any) =>
  image?.name
    ? uploadFile(buckets.banners, "banners", nanoid(), image).then(url => ({
        src: url,
      }))
    : { src: image };
