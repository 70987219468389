import { useProductsCreation } from "@/store/products-creation";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

type Image = {
  src: string;
};

export const ImagesList = () => {
  const [t] = useTranslation("products-page");
  const { productRelate } = useProductsCreation();

  const images =
    productRelate?.group?.images?.length > 0
      ? productRelate?.group?.images
      : productRelate?.variant?.images ?? [];

  const [selectedImage, setSelectedImage] = useState(images[0]);

  const handleImageClick = (src: string) => {
    setSelectedImage(src);
  };

  useEffect(() => {
    if (images.length > 0) {
      setSelectedImage(images[0].src);
    } else {
      setSelectedImage(null);
    }
  }, [images]);

  return (
    <div className={styles.imageGallery}>
      <div>
        {selectedImage && (
          <img
            src={selectedImage}
            alt={t("SELECTED_IMAGE")}
            className={styles.selectedImage}
          />
        )}
      </div>
      <div className={styles.imageList}>
        {images.map((image: Image, index: number) => (
          <img
            key={index}
            src={image.src}
            alt={`${t("IMAGE_SMALL")} ${index}`}
            onClick={() => handleImageClick(image.src)}
            className={selectedImage === image.src ? styles.selected : ""}
          />
        ))}
      </div>
    </div>
  );
};
