import "@/components/orders/RangeDatePicker/styles.scss";
import styles from "./styles.module.scss";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { format, subMonths } from "date-fns";
import {
  DATE_MONT_YEAR_SLASH,
  USA_DATE_MONT_YEAR_SLASH,
} from "@/constants/date";
import { BiCalendarEvent } from "react-icons/bi";
import { useEffect, useState } from "react";
import { isUSA } from "@/constants/core";

export const DatesInputs = () => {
  const { setValue } = useFormContext();
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState<Date>(subMonths(new Date(), 1));
  const [t] = useTranslation("orders-page");

  const onChange = (dates: any[]) => {
    const [start, end] = dates;
    setValue("from", start);
    setValue("to", end);
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    setValue("to", endDate);
    setValue("from", startDate);
  }, []);

  return (
    <>
      <div className={styles.dateInput}>
        <DatePicker
          selected={startDate}
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          dateFormat="dd/MM/yyyy"
          maxDate={new Date()}
          customInput={
            <div className={styles.inputGroup}>
              <BiCalendarEvent className={styles.icon} />
              <input
                type="text"
                value={
                  startDate && endDate
                    ? `${format(
                        startDate,
                        isUSA ? USA_DATE_MONT_YEAR_SLASH : DATE_MONT_YEAR_SLASH
                      )} - ${format(
                        endDate,
                        isUSA ? USA_DATE_MONT_YEAR_SLASH : DATE_MONT_YEAR_SLASH
                      )}`
                    : ""
                }
                readOnly
                placeholder={t("FILTER.SINCE_TO")}
              />
            </div>
          }
        />
      </div>
    </>
  );
};
