import { getInstance } from "../../core/axios-gateway";
import { TRestListParams } from "../../types/rest";

export const getProductOptions = async (params: TRestListParams) => {
  return getInstance()
    .get("/api/product-options", { params })
    .then((res) => res.data);
};

export const getProductOption = async (id: string) => {
  return getInstance()
    .get("/api/product-options/" + id)
    .then((res) => res.data);
};

export const createProductOption = async (data: any) => {
  return getInstance()
    .post("/api/product-options", data)
    .then((res) => res.data);
};

export const updateProductOption = async (id: string, data: any) => {
  return getInstance()
    .put("/api/product-options/" + id, data)
    .then((res) => res.data);
};
