import ReactDOM from "react-dom/client";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import { FC } from "react";

type LangValue = string | { [key: string]: LangValue };

interface LibraryOpts {
  name: string;
  lang: {
    es: LangValue;
    en: LangValue;
  };
  components: Record<string, FC<any>>;
}

export function defineLib(opts: LibraryOpts) {
  return {
    ...opts,
    ...opts.components,
    mount: createMounter(opts),
  };
}

function createMounter(opts: LibraryOpts) {
  return function (rootElement: HTMLElement, Component: FC, props: any = {}) {
    if (!rootElement) {
      return console.error(`[${opts.name}]: element is required`);
    }

    const i18nInstance = i18next.createInstance(
      {
        lng: props.lang || localStorage.getItem("lang") || "en",
        fallbackLng: "en",
        resources: {
          en: { [opts.name]: opts.lang.en },
          es: { [opts.name]: opts.lang.es },
        },
      },
      console.error // No borrar el callback porque se rompe
    );

    ReactDOM.createRoot(rootElement).render(
      <I18nextProvider i18n={i18nInstance}>
        <Component {...props} />
      </I18nextProvider>
    );
  };
}
