import { useRef, useState } from "react";
import styles from "./styles.module.scss";

type TPinInput = {
  onComplete: (pin: string) => void;
  numInputs?: number;
};

export const PinInput = ({ onComplete, numInputs = 4 }: TPinInput) => {
  const [digits, setDigits] = useState<string[]>(Array(numInputs).fill(""));
  const inputRefs = Array.from({ length: numInputs }, (_, i) =>
    useRef<HTMLInputElement | null>(null)
  );

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.target;
    if (/\d/.test(value)) {
      digits[index] = value;
      setDigits([...digits]);

      if (index < numInputs - 1 && value !== "") {
        inputRefs[index + 1].current?.focus();
      }

      if (digits.every(digit => digit.length === 1)) {
        onComplete(digits.join(""));
      }
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace") {
      if (digits[index] === "") {
        if (index > 0) {
          inputRefs[index - 1].current?.focus();
        }
      } else {
        digits[index] = "";
        setDigits([...digits]);
      }
    }
  };

  return (
    <div className={styles.pinInput}>
      {digits.map((digit, index) => (
        <input
          key={index}
          ref={inputRefs[index]}
          value={digit}
          onChange={e => handleInputChange(e, index)}
          onKeyDown={e => handleKeyDown(e, index)}
          maxLength={1}
          autoComplete="off"
        />
      ))}
    </div>
  );
};
