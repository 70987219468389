import { ClientName } from "@/components/offers/_form/ClientNameChip";
import { ModalSide } from "@/components/shared/Modal/ModalSide/ModalSide";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoEyeOutline } from "react-icons/io5";
import { CommercialDetail } from "./CommercialVisitsList";
import { VscLocation } from "react-icons/vsc";
import styles from "./styles.module.scss";

export const CommercialVisitDetail = ({ visit }: { visit: any }) => {
  const [isOpen, setOpen] = useState(false);
  const [t] = useTranslation("global");

  return (
    <>
      <ToolTip position="Left" title={t("SEE_DETAILS")}>
        <IoEyeOutline className="pointer" onClick={() => setOpen(true)} />
      </ToolTip>

      <ModalSide
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        title={t("CLIENT_VISIT_DETAIL")}
      >
        <div className={styles.visitContent}>
          {visit.clientId && (
            <>
              <p className="bold">{t("CLIENT_NAME")}</p>
              <p>
                <ClientName id={visit.clientId} isUnderline />
              </p>
              <div className="spacer" />
            </>
          )}
          <p className="bold">{t("COMMERCIAL_NAME")}</p>
          <p>
            <CommercialDetail id={visit?.commercialId} />
          </p>
          <div className="spacer" />
          <p className="bold">{t("DESCRIPTION")}</p>
          <p>{visit?.description ?? "-"}</p>
          <div className="spacer" />
          <div className="spacer" />
          {
            <a
              href={`https://www.google.com/maps?q=${visit?.latitude},${visit?.longitude}`}
              className="bold textUnderline purpleText pointer"
              target="_blank"
              rel="norefferrer noreferrer"
            >
              <span className="flexGap center">
                <VscLocation size={"1.5rem"} />

                {t("SEE_MAP")}
              </span>
            </a>
          }
        </div>
      </ModalSide>
    </>
  );
};
