import { TOTAL_ORDERS_PER_PAGE } from "@/constants/export-orders";
import { getOffers } from "@/http";
import { TGlobalOffer } from "@/http/types/global-offers";
import { create } from "zustand";

type TUseOffers = {
  getOffers: (supplierId: string) => void;
  isLoadingOffers: boolean;
  offers: TGlobalOffer[];
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
  totalPages: number;
  setCurrentFilter: (value: string) => void;
  currentFilter: string;
};
export const useOffers = create<TUseOffers>((set, get) => ({
  setCurrentFilter: (currentFilter: string) => set(() => ({ currentFilter })),
  currentFilter: "",
  getOffers: async (supplierId: string) => {
    try {
      set({ isLoadingOffers: true });
      const { currentPage } = get();
      const data = await getOffers({
        supplierId,
        size: TOTAL_ORDERS_PER_PAGE,
        page: currentPage,
        sort: "name,asc",
        // search: `${
        //   currentFilter ? `enabled:${currentFilter}` : ""
        // }`,
      });
      set({
        offers: data.content,
        totalPages: data.totalPages,
        isLoadingOffers: false,
      });
    } catch (e) {
      set({
        offers: [],
        totalPages: 0,
        isLoadingOffers: false,
      });
    }
  },
  isLoadingOffers: false,
  offers: [],
  currentPage: 0,
  totalPages: 0,
  setCurrentPage: currentPage => set(() => ({ currentPage })),
}));
