import { DATE_HOUR, USA_DATE_HOUR } from "@/constants/date";
import { TListedTicket } from "@/http";
import format from "date-fns/format";
import styles from "./styles.module.scss";
import { ticketStatus } from "@/constants/tickets";
import { useTicket } from "@/store/ticket";
import { isUSA } from "@/constants/core";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/store/auth";

type TTicketListRow = {
  ticket: TListedTicket;
};
export const TicketListRow = ({ ticket }: TTicketListRow) => {
  const { setOpenModal, setSelectedTicket } = useTicket();
  const [t] = useTranslation("purchases");
  const { user } = useAuth();

  return (
    <tr
      className={`pointer ${styles.row}`}
      onClick={() => {
        setSelectedTicket(ticket as any);
        setOpenModal(true);
      }}
    >
      <td>{ticket.number}</td>
      <td>
        {ticket?.createdAt
          ? format(
              new Date(ticket.createdAt),
              isUSA ? USA_DATE_HOUR : DATE_HOUR
            )
          : "-"}
      </td>
      <td>{ticket?.purchaseNumber}</td>
      <td>{t(ticket?.type)}</td>
      <td>
        <span
          className={`statusColorHelper statusChip ${
            ticketStatus?.[ticket?.status?.toUpperCase()]?.style
          }`}
        >
          {ticketStatus?.[ticket?.status?.toUpperCase()]?.name}
        </span>
      </td>
      <td className={ticket.userId === user.id ? "greenText" : "orangeText"}>
        {ticket.userId === user.id ? t("CREATED_BY_ME") : t("CREATED_BY_THIRD")}
      </td>
    </tr>
  );
};
