import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { MdOutlineNotifications } from "react-icons/md";
import { db } from "@/core/firebase";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "@/store/auth";
import styles from "./styles.module.scss";
import { AiOutlineDelete } from "react-icons/ai";
import { useNavigate } from "react-router";
import { differenceInDays, format } from "date-fns";
import { DEFAULT_FORMAT } from "@/constants/date";
import { useTranslation } from "react-i18next";
import { logError } from "@/utils/errors";

export const Notifications = () => {
  const { user } = useAuth();
  const [notifications, setNotifications] = useState<any[]>([]);
  const [isOpen, setOpen] = useState(false);
  const [animate, setAnimate] = useState(false);
  const navigate = useNavigate();
  const optionsRef = useRef<HTMLDivElement>(null);
  const notificationsRef = useRef();
  const [t] = useTranslation("global");

  const markAsRead = async (id: string) => {
    try {
      const notifictionRef = db
        .collection("Notifications")
        .doc(user.id)
        .collection("suppliers")
        .doc(id);

      const doc = await notifictionRef.get();

      if (doc.exists) {
        await notifictionRef.update({
          markAsRead: true,
        });
      }
    } catch (e) {
      logError(e);
    }
  };

  const deleteNotification = async (id: string) => {
    try {
      db.collection("Notifications")
        .doc(user.id)
        .collection("suppliers")
        .doc(id)
        .delete();
    } catch (e) {
      logError(e);
    }
  };

  const executeAction = (id: string, type: string, reference: string) => {
    markAsRead(id);

    switch (type) {
      case "SUB_PURCHASE":
        navigate(`/orders/${reference}`);
        setOpen(false);
        return;
    }
  };

  useEffect(() => {
    const unsubscribe = db
      .collection("Notifications")
      .doc(user.id)
      .collection("suppliers")
      .orderBy("createdAt", "desc")
      .onSnapshot(snapshot => {
        const updatedNotifications: any[] = [];
        const expiredNotifications: string[] = [];
        snapshot.forEach(doc => {
          const currentDate = new Date(format(new Date(), DEFAULT_FORMAT));
          const notificationDate = new Date(
            format(new Date(doc.data()?.createdAt?.toDate()), DEFAULT_FORMAT)
          );

          if (differenceInDays(currentDate, notificationDate) < 4) {
            updatedNotifications.push({
              id: doc.id,
              ...doc.data(),
            });
          } else if (doc.data()?.markAsRead) {
            expiredNotifications.push(doc.id);
          } else {
            updatedNotifications.push({
              id: doc.id,
              ...doc.data(),
            });
          }
        });

        setNotifications(updatedNotifications);
        expiredNotifications?.forEach(id => deleteNotification(id));
      });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const currentNotifications = notifications.filter(
      notification => !notification.markAsRead
    ).length;

    if (currentNotifications > (notificationsRef?.current ?? 0)) {
      setAnimate(true);
      setTimeout(() => {
        setAnimate(false);
      }, 4000);
    }
  }, [notifications]);

  return (
    <div ref={optionsRef}>
      <div className={styles.notificationIcon}>
        <div className={styles.icon}>
          <MdOutlineNotifications
            className="pointer"
            size={24}
            onClick={() => setOpen(!isOpen)}
          />
          {!isOpen &&
            notifications.filter(notification => !notification.markAsRead)
              .length > 0 && (
              <div className={styles.counter}>
                {notifications.filter(notification => !notification.markAsRead)
                  .length > 9
                  ? "+9"
                  : notifications.filter(
                      notification => !notification.markAsRead
                    ).length}
              </div>
            )}
          <div className={animate ? styles.ripple : ""}>
            <div />
            <div />
          </div>
        </div>
        {isOpen && (
          <div className={styles.notificationPanel}>
            <h6 className="bolder">{t("LAYOUT.NOTIFICATIONS")}</h6>
            <div className="divider" />
            {notifications?.length > 0 ? (
              <ul>
                {notifications.map(notification => (
                  <li key={notification.id}>
                    <div className={styles.notificationItem}>
                      {!notification?.markAsRead && (
                        <div className={styles.markAction}>
                          <ToolTip
                            title={t("LAYOUT.MARK_AS_READ")}
                            position="Right"
                          >
                            <span
                              onClick={() => markAsRead(notification?.id ?? "")}
                            />
                          </ToolTip>
                        </div>
                      )}
                      <div
                        onClick={() =>
                          executeAction(
                            notification?.id,
                            notification?.type,
                            notification?.referenceId
                          )
                        }
                      >
                        {notification.message}
                      </div>
                      <div className={styles.deleteItem}>
                        <ToolTip
                          title={t("LAYOUT.DELETE_NOTIFICATION")}
                          position="Left"
                        >
                          <AiOutlineDelete
                            onClick={() => deleteNotification(notification.id)}
                          />
                        </ToolTip>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <h6>{t("LAYOUT.NO_NOTIFICATIONS")}</h6>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
