import { getBrandById, getCategory } from "@/http";
import { logError } from "./errors";

export const generateRandomSKU = async (
  categoryId: string,
  brandId: string
) => {
  let categoryCode = "";
  let brandCode = "";
  await getCategory(categoryId)
    .then(category => (categoryCode = category.code))
    .catch(logError);

  await getBrandById(brandId)
    .then(
      brand =>
        (brandCode = brand.name
          .replace(/[^a-zA-Z0-9]/g, "")
          .toUpperCase()
          .substring(0, 3))
    )
    .catch(logError);

  const timestap = Date.now();

  return `${categoryCode}${brandCode}${timestap}`;
};
