declare global {
  interface Window {
    showSaveFilePicker: any;
  }
}

export const saveFile = async (blob: Blob, suggestedName: string) => {
  const blobURL = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = blobURL;
  a.download = suggestedName;
  a.style.display = "none";
  document.body.append(a);
  a.click();
  setTimeout(() => {
    URL.revokeObjectURL(blobURL);
    a.remove();
  }, 1000);
};
