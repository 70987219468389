import { useTranslation } from "react-i18next";
import { useHeaderTitle } from "@/store/ui";
import { useEffect, useRef, useState, useCallback } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import {
  getSellers,
  getSupplierCommercialById,
  getSupplierCommercials,
} from "@/http";
import { useAuth } from "@/store/auth";
import useSWR from "swr";
import { InputV2 } from "@/components/shared/Input/InputV2";
import { FormProvider, useForm } from "react-hook-form";
import { ClientsBulkAction } from "./bulk-import/ClientsBulkAction";
import { ClientModal } from "@/components/clients/ClientModal";
import { onboardingSelectors } from "@/components/onboarding/selectors";
import { PaginatedTable } from "@/components/shared/PaginatedTable/PaginatedTable";
import styles from "./styles.module.scss";
import { isUSA } from "@/constants/core";
import { PremiumFeatureIndicator } from "@/components/subscription/PremiumFeatureIndicator";
import { FEAT_CLIENT_SEGMENTATION } from "@/constants/features";
import { ClientSegmentButton } from "@/components/segments/ClientSegmentButton";
import { BiInfoCircle } from "react-icons/bi";
import { ClientDelete } from "@/components/clients/ClientDelete/ClientDelete";
import Select from "react-select";
import debounce from "lodash/debounce";
import { singleSelectStyles } from "@/constants/input";

const buildSearch = (
  search: string,
  param: string,
  paramSearch: string | undefined | null
) => {
  let searchString = search;
  if (paramSearch) {
    searchString = search
      ? `${search},${param}${paramSearch}`
      : `${param}${paramSearch}`;
  }
  return searchString;
};

const ClientList = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("clients-page");

  useEffect(() => {
    setTitle(t("CLIENTS.TITLE"));
  }, [t]);

  const { isCommercial, commercial } = useAuth();
  const methods = useForm();
  const [search, setSearch] = useSearchParams("page=0");
  const debounceRef = useRef<Record<string, any>>({});
  const page = +search.get("page")!;
  const text = search.get("text");
  const commercialId = search.get("commercialId");
  const { data: commercialFilter, isValidating } = useSWR(
    commercialId ? [commercialId, "commercialId"] : null,
    getSupplierCommercialById
  );

  const swrData = useSWR(
    { key: `clients${page}${text}`, page, text, commercialId },
    ({ page }) => {
      let search = buildSearch(
        "",
        `commercialId:`,
        isCommercial ? commercial?.id : commercialId
      );
      search = buildSearch(search, `text:`, text);

      return getSellers({ size: 8, page, search });
    }
  );

  const { data: list, error, mutate } = swrData;

  const onFilterChange = (property: string, value: string) => {
    const newSearch = new URLSearchParams(search);
    newSearch.set(property, value);
    if (debounceRef.current.sellerName) {
      clearTimeout(debounceRef.current.sellerName);
    }

    debounceRef.current.sellerName = setTimeout(() => {
      newSearch.set("page", "0");
      setSearch(newSearch);
    }, 250);
  };

  useEffect(() => {
    mutate();
  }, [isCommercial]);

  useEffect(() => {
    if (methods.watch("text") !== text) {
      methods.setValue("text", text);
    }
  }, [text]);

  return (
    <>
      <div className="contentInline smContentColumn gapSm">
        <div className="flexGap gapSm smContentColumn">
          <FormProvider {...methods}>
            <div className="flexGap noPadding smContentColumn gapSm">
              <div>
                <InputV2
                  label={t("CLIENTS.SEARCH")}
                  placeholder={t("CLIENTS.SEARCH")}
                  name="text"
                  onChange={e => onFilterChange("text", e.target.value)}
                />
              </div>
              {!isValidating && !isCommercial && (
                <div>
                  <CommercialSelection
                    values={
                      commercialFilter
                        ? {
                            value: commercialFilter.id,
                            label:
                              commercialFilter.firstName +
                              " " +
                              commercialFilter.lastName,
                          }
                        : null
                    }
                    onValueChange={(item: any) =>
                      onFilterChange("commercialId", item?.value ?? "")
                    }
                  />
                </div>
              )}
            </div>
          </FormProvider>
        </div>
        <div
          data-onboarding={onboardingSelectors.clientsActions}
          className="flexGap flexWrap rigthAlignFlex mt1"
        >
          <div>
            <ClientsBulkAction mode="create" refresh={mutate} />
          </div>
          <div>
            <ClientsBulkAction mode="update" refresh={mutate} />
          </div>
          <div>
            <ClientModal />
          </div>
        </div>
      </div>
      {error ? (
        <>
          <div className="spacer" />
          <div className="spacer" />
          <p className="bold textCenter">{t("ERROR")}</p>
        </>
      ) : !list ? (
        <Spinner />
      ) : (
        <div>
          <div className="spacer" />
          <div className="spacer" />

          <PaginatedTable swrData={swrData}>
            <table className={styles.table}>
              <thead>
                <tr className={styles.row}>
                  <th>{t("CLIENTS.LEGAL_NAME")}</th>
                  <th>{t(isUSA ? "CLIENTS.CONTACT_NAME" : "CLIENTS.NAMES")}</th>
                  {!isUSA && <th>{t("CLIENTS.LAST_NAMES")}</th>}
                  <th>{t("CLIENTS.EMAIL")}</th>
                  <th>{t("CLIENTS.PHONE")}</th>
                  <th>
                    {t("CLIENTS.SEGMENT")}{" "}
                    <PremiumFeatureIndicator
                      feature={FEAT_CLIENT_SEGMENTATION}
                    />
                  </th>
                  <th>{t("ACTIONS")}</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {list.content.map(client => (
                  <tr key={client.id} className={styles.row}>
                    <td>{client.legalName}</td>
                    <td>{client.firstName}</td>
                    {!isUSA && <td>{client.lastName}</td>}
                    <td>{client.email}</td>
                    <td>{client.phone}</td>
                    <td>
                      <ClientSegmentButton
                        client={client}
                        onDone={() => mutate()}
                      />
                    </td>
                    <td>
                      <div className={styles.actions}>
                        <Link to={`/clients/${client.id}/show`}>
                          <BiInfoCircle size={24} />
                        </Link>
                        <ClientDelete client={client} onDone={() => mutate()} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </PaginatedTable>
        </div>
      )}
    </>
  );
};

export default ClientList;

type TCommercialSelection = {
  values: any;
  disabled?: boolean;
  onValueChange: (values: any[]) => void;
};

export const CommercialSelection = ({
  values,
  disabled,
  onValueChange,
}: TCommercialSelection) => {
  const [t] = useTranslation("global");
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<any[]>([]);
  const [selectedOptions, setSelectedOptions] = useState(values);
  const { user } = useAuth();

  const fetchOptions = async (input: string) => {
    try {
      const payload: any = {
        sort: "firstName,asc",
        page: 0,
        size: 10,
      };
      if (input) {
        payload.search = `text:${input}`;
      }
      const response = await getSupplierCommercials(user.id, payload);
      const optionsData = response?.content?.map(item => ({
        value: item.id,
        label: item.firstName + " " + item.lastName,
      }));
      setOptions(optionsData);
    } catch (_) {
      setOptions([]);
    }
  };

  const debouncedFetchOptions = useCallback(debounce(fetchOptions, 500), []);

  const handleInputChange = (newValue: any) => {
    setInputValue(newValue);
  };

  const handleChange = (selected: any) => {
    setSelectedOptions(selected);
  };

  useEffect(() => {
    onValueChange?.(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    debouncedFetchOptions(inputValue);
  }, [inputValue, debouncedFetchOptions]);

  return (
    <>
      <p>{t("COMMERCIAL_NAME")}</p>
      <Select
        options={options}
        value={selectedOptions}
        onInputChange={handleInputChange}
        onChange={handleChange}
        isDisabled={disabled}
        placeholder={t("SELECT_OPTION")}
        styles={singleSelectStyles}
        isClearable
      />
    </>
  );
};
