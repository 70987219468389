import { InputV2 } from "@/components/shared/Input/InputV2";
import { TSeller, getSupplierCommercials, TSupplierCommercial } from "@/http";
import { useAuth } from "@/store/auth";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

type TSellerInput = {
  sellerInput?: TSeller;
  setDone?: () => void;
  disabled?: boolean;
};

export const CommercialSellerSearchInput = ({
  setDone,
  disabled,
}: TSellerInput) => {
  const { setValue, trigger, watch } = useFormContext();
  const [t] = useTranslation("global");
  const { user } = useAuth();
  const [options, setOptions] = useState<TSupplierCommercial[]>([]);
  const [showOptions, setShowOptions] = useState(false);
  const debounceRef = useRef<Record<string, any>>({});
  const optionsRef = useRef<HTMLDivElement>(null);
  const fixedDivRef = useRef<HTMLDivElement>(null);
  const searchTypeWatch = watch("sellerSearchType");

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (debounceRef.current.sellerName) {
      clearTimeout(debounceRef.current.sellerName);
    }

    debounceRef.current.sellerName = setTimeout(() => {
      getFilterSellers(event.target.value);
    }, 250);
  };

  const getFilterSellers = async (value: string) => {
    const { content } = await getSupplierCommercials(user.id, {
      search: `text:${value}`,
    });
    setOptions(content);
    setShowOptions(true);
  };

  const setSeller = (seller: TSupplierCommercial | undefined) => {
    setValue("commercial", seller);
    setValue("commercialId", seller?.id);
    trigger(["commercialName", "sellerSearchType"]);
    setValue("commercialName", seller?.firstName + " " + seller?.lastName);
    setShowOptions(false);
    setDone?.();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target as Node)
      ) {
        setValue("commercialName", "");
        setValue("commercial", {});
        setShowOptions(false);
      }
    };

    const handleOutsideScroll = (event: Event) => {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target as Node)
      ) {
        setValue("commercial", {});
        setValue("commercialName", "");
        setShowOptions(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    document.addEventListener("wheel", handleOutsideScroll);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("wheel", handleOutsideScroll);
    };
  }, [showOptions]);

  useEffect(() => {
    const sellerName = watch("commercialName");
    if (sellerName) {
      getFilterSellers(sellerName);
    }
  }, [searchTypeWatch]);

  return (
    <div className={styles.containerSeller}>
      <div ref={fixedDivRef} className={styles.inputs}>
        <InputV2
          name="commercialName"
          placeholder={t("COMMERCIAL_SEARCH")}
          onChange={e => onInputChange(e)}
          label=""
          required
          disabled={disabled}
        />
      </div>

      {showOptions &&
        createPortal(
          <div
            ref={optionsRef}
            style={{
              top: fixedDivRef.current
                ? `${
                    fixedDivRef.current.getBoundingClientRect().bottom +
                    5 +
                    (window.pageYOffset || document.documentElement.scrollTop)
                  }px`
                : "0",
              width: fixedDivRef.current
                ? `${fixedDivRef.current.getBoundingClientRect().width}px`
                : "100%",
              left: fixedDivRef.current
                ? `${fixedDivRef.current.getBoundingClientRect().left}px`
                : "100%",
            }}
            className={styles.list}
          >
            <ul>
              {options.map(option => {
                return (
                  <li
                    key={option.id}
                    onClick={() => {
                      setSeller(option);
                    }}
                  >
                    {option.firstName + " " + option.lastName}

                    <br />
                    <span className={styles.email}>
                      {t("COMMERCIAL_BILLING_EMAIL")}: {option.email}
                    </span>
                  </li>
                );
              })}
              {!options?.length && <li>{t("NO_OPTIONS")}</li>}
            </ul>
          </div>,
          document.body
        )}
    </div>
  );
};
