import { InputV2 } from "@/components/shared/Input/InputV2";
import { Modal } from "@/components/shared/Modal/Modal";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { createSupplierWithoutId } from "@/http";
import { useAuth } from "@/store/auth";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const CreateSupplierModal = ({
  onDone,
}: {
  onDone?: (value: any) => void;
}) => {
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm();
  const { user } = useAuth();

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      const supplier = await createSupplierWithoutId({
        parentSupplierId: user.id,
        name: values.name,
      });
      await onDone?.(supplier);
      setIsOpen(false);
      methods.reset();
      showToastSuccess(t("SUPPLIER_CREATED"));
    } catch (error) {
      showToastError({ message: t("ERROR_SOME_BAD"), error });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <button className="purple" onClick={() => setIsOpen(true)}>
        {"+ " + t("CREATE_SUPPLIER")}
      </button>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={t("CREATE_SUPPLIER")}
        size="sm"
      >
        <FormProvider {...methods}>
          <InputV2
            type="text"
            name="name"
            label={t("SUPPLIER_NAME")}
            required
            validate={(value: string) => value.length > 2}
            validMessage={t("AT_LEAST_THREE")}
            disabled={isLoading}
          />
        </FormProvider>
        <div className="spacer" />
        <div className="divider" />
        <div className="spacer" />
        <div className="rigthAlign">
          <button onClick={() => setIsOpen(false)} disabled={isLoading}>
            {t("CANCEL")}
          </button>
          <button
            className="primary lg"
            disabled={isLoading}
            onClick={methods.handleSubmit(onSubmit)}
          >
            {t("SAVE")}
          </button>
        </div>
      </Modal>
    </>
  );
};
