import { useOrderFilter } from "@/store/orders";
import { useTranslation } from "react-i18next";
import { ChangeEvent, useRef } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import styles from "./styles.module.scss";

const SearchFilter = () => {
  const { setIdFilter, idFilter: filter } = useOrderFilter();
  const debounceRef = useRef<any>();
  const onQueryChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      setIdFilter(event.target.value);
    }, 350);
  };

  const [t] = useTranslation("orders-page");

  return (
    <>
      <div className={styles.inputGroup}>
        <AiOutlineSearch className={styles.icon} />
        <input
          type="text"
          defaultValue={filter}
          placeholder={t("SEARCH")}
          onChange={onQueryChanged}
        />
      </div>
    </>
  );
};

export default SearchFilter;
