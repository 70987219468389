import { TLocale } from "@/models/locale";
import { isProd } from "@/constants/env";

export const esCO = "es-CO";
export const esMX = "es-MX";
export const enUS = "en-US";

const devDomains = [
  {
    domain: "proveedores-dev.seeri.co",
    defaultLocale: esCO,
  },
  {
    domain: "proveedores-dev.mx.seeri.co",
    defaultLocale: esMX,
  },
  {
    domain: "proveedores-dev.seeri.co",
    defaultLocale: enUS,
  },
];

const prodDomains = [
  {
    domain: "portal.seeri.co",
    defaultLocale: esCO,
  },
  {
    domain: "proveedores.mx.seeri.co",
    defaultLocale: esMX,
  },
  {
    domain: "portal.seeri.co",
    defaultLocale: enUS,
  },
];

const domains = isProd ? prodDomains : devDomains;

export const getLocaleByUrl = () => {
  let locale = esCO;

  const localCountry = localStorage.getItem("country") ?? "";
  if (["us"].includes(localCountry)) {
    return enUS;
  }

  if (typeof window !== "undefined") {
    const { hostname } = location;
    locale = domains.find(d => d.domain === hostname)?.defaultLocale || esCO;
  }

  return locale as TLocale;
};

export const seeriAppDomain = isProd
  ? "https://seeri.store"
  : "https://seeri-app.dev.seeri.co";
