import { es, enUS } from "date-fns/locale";
import format from "date-fns/format";
import styles from "./HorizontalTimeline.module.scss";
import { useTranslation } from "react-i18next";
import { isUSA } from "@/constants/core";

type TTimelineEvent = {
  date: string;
  title: string;
  description: string;
};

type THorizontalTimeline = {
  events: TTimelineEvent[];
};

const HorizontalTimeline = ({ events }: THorizontalTimeline) => {
  const [t] = useTranslation("global");
  const sortedEvents = events
    .slice()
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  const formatDate = (dateString: string) => {
    return format(
      new Date(dateString),
      isUSA
        ? `MMMM d '${t("OF")}' yyyy HH:mm`
        : `d '${t("OF")}' MMMM '${t("OF")}' yyyy HH:mm`,
      {
        locale: isUSA ? enUS : es,
      }
    );
  };

  return (
    <div className={styles.horizontalTimeline}>
      {sortedEvents.map((event, index) => (
        <div className={styles.timelineEvent} key={index}>
          <div className={styles.line}>
            <div className={styles.eventContent}>
              {event.title && (
                <h3 className={styles.eventTitle}>{event.title}</h3>
              )}
              {event.description && (
                <p className={styles.eventDescription}>{event.description}</p>
              )}
              <div className={styles.eventDate}>{formatDate(event.date)}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HorizontalTimeline;
