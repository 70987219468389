import { TRestListData, TRestListParams } from "../../types/rest";
import { TSupplierAddress } from "../../types/supplier";
import { getInstance } from "../../core/axios-gateway";

const BASE_PATH = "/api/sellers";

export const getSupplierAddresses = async (
  supplierId: string,
  params: TRestListParams
) => {
  return getInstance()
    .get<TRestListData<TSupplierAddress>>(
      `${BASE_PATH}/${supplierId}/address/list`,
      { params }
    )
    .then(res => res.data);
};

export const getSupplierAddressbyId = async (
  supplierId: string,
  addressId: string
) => {
  return getInstance()
    .get<TSupplierAddress>(`${BASE_PATH}/${supplierId}/address/${addressId}`)
    .then(res => res.data);
};

export const deleteSupplierAddressbyId = async (
  supplierId: string,
  addressId: string
) => {
  return getInstance()
    .delete(`${BASE_PATH}/${supplierId}/address/${addressId}`)
    .then(res => res.data);
};

export const deleteSupplierAddressAndMoveStock = async (
  supplierId: string,
  moveFromAddressId: string,
  moveToAddressId?: string
) => {
  return getInstance()
    .delete(`${BASE_PATH}/${supplierId}/address`, {
      data: {
        moveFromAddressId,
        moveToAddressId,
      },
    })
    .then(res => res.data);
};

export const createSupplierAddress = async (
  supplierId: string,
  data: TSupplierAddress
) => {
  return getInstance()
    .post<TSupplierAddress>(`${BASE_PATH}/${supplierId}/address`, data)
    .then(res => res.data);
};

export const updateSupplierAddress = async (
  supplierId: string,
  addressId: string,
  data: Partial<TSupplierAddress>
) => {
  return getInstance()
    .put<TSupplierAddress>(
      `${BASE_PATH}/${supplierId}/address/${addressId}`,
      data
    )
    .then(res => res.data);
};
