import { CreditModalForm } from "@/components/credits/CreditModalForm";
import { getCredits } from "@/http";
import { get } from "lodash-es";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import useSWR from "swr";

type TCreditCreation = {
  errors: string[];
};

export const CreditCreation = ({ errors }: TCreditCreation) => {
  const methods = useFormContext();
  const isBalance = !errors.includes("NOT_ENOUGH_BALANCE");
  const sellerId = methods.watch("sellerId");
  const { data: credits, mutate } = useSWR("credits", () => {
    const params: any = {};
    if (sellerId) {
      params.search = `sellerId:${sellerId}`;
    }
    return getCredits(params);
  });

  useEffect(() => {
    mutate();
  }, [sellerId]);

  return (
    <>
      {!isBalance && get(credits, "content.length", 0) && (
        <CreditModalForm
          seller={methods.watch("seller")}
          buttonOutlined
          editButton
          hideEditSeller
          onDone={() => {
            mutate();
            methods.reset(methods.watch());
          }}
          credit={credits?.content?.[0]}
          activeCredit
        />
      )}
      {get(credits, "content.length", 0) <= 0 && (
        <CreditModalForm
          seller={methods.watch("seller")}
          buttonOutlined
          hideEditSeller
          onDone={() => {
            mutate();
            methods.reset(methods.watch());
          }}
        />
      )}
    </>
  );
};
