import { RecordCustomAttributes } from "@/components/custom-fields/RecordCustomAttributes";
import { Card } from "@/components/shared/Card/Card";
import { isUSA } from "@/constants/core";
import { NEGOTIATION_TYPE } from "@/constants/supplier";
import { useSupplierAddressMain } from "@/hooks/useSupplier";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import {
  createSupplierProcurementV2,
  createSupplierVariantV2,
  getProductById,
} from "@/http";
import { useAuth } from "@/store/auth";
import { uploadImageProductObject } from "@/utils/image-upload";
import { generateSlug } from "@/utils/slug";
import { useState } from "react";
import { createPortal } from "react-dom";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { ImagesInputs } from "./ImagesInputs";
import { NameInputs } from "./NameInputs";
import { PriceInputs } from "./PriceInputs";
import styles from "./styles.module.scss";

export const ProductCreationForm = () => {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const methods = useFormContext();
  const [t] = useTranslation("products-page");
  const { user } = useAuth();
  const { id: supplierId, marginSeeri = 0, negotiationType } = user;
  const mainAddress = useSupplierAddressMain(user.id);
  const { FIXED_MARGIN, VARIABLE_MARGIN } = NEGOTIATION_TYPE;
  const isMarginUser = [FIXED_MARGIN, VARIABLE_MARGIN].includes(
    negotiationType
  );

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      const payload: Record<string, any> = {
        brandId: values?.brandId,
        name: values?.name,
        description: values?.description,
        longDescription: values?.description,
        status: true,
        isMarketplace: true,
        featured: true,
        images: values?.images ?? [],
        slug: generateSlug(values?.name),
        marginSeeri,
        weight: values?.weigth,
        height: values?.height,
        width: values?.width,
        length: values?.length,
        taxesType:
          values?.chargeTaxes && values?.taxesType
            ? [values?.taxesType]
            : ["IVA_0"],
      };

      if (payload?.images?.length) {
        await Promise.all(payload?.images?.map(uploadImageProductObject)).then(
          images => {
            payload.media = images;
            payload.images = images;
          }
        );
      }

      payload.suppliers = [
        {
          supplierId,
          purchasePrice: values?.purchasePrice,
          wholesalePrice: isMarginUser
            ? values?.wholesalePrice
            : values?.wholesalePrice /
              (1 - (user.priceListIncreasePercentage ?? 0) / 100),
          supplierPrice: isMarginUser ? 0 : values?.wholesalePrice,
          unitPrice: isUSA ? values?.unitPrice : null,
          marginSeeri,
          disableInStockOut: true,
          securityStock: values?.useSecurityStock ? values.securityStock : 0,
          status: true,
          customAttributes: values?.customAttributes,
        },
      ];

      const temporalProduct = await createSupplierVariantV2(payload);
      const product = await getProductById(temporalProduct.id);
      await createSupplierProcurementV2(product?.suppliers[0]?.id, {
        type: "SUPPLY",
        createdBy: user.id,
        warehouseId: mainAddress?.id,
        amount: values?.stock,
        destiny: "AVAILABLE",
      });
      showToastSuccess(t("PRODUCT_CREATED"));
      navigate(`/products/${product?.id}`);
    } catch (e) {
      showToastError({ e, message: t("SOME_WRONG_CREATE_VARIANT") });
    } finally {
      setLoading(false);
    }
  };

  const formId = "ProductCreationForm";

  return (
    <form id={formId} onSubmit={methods.handleSubmit(onSubmit)}>
      <NameInputs />
      <div className="spacer" />
      <ImagesInputs />
      <div className="spacer" />
      <PriceInputs />
      <div className="spacer" />
      <Card>
        <RecordCustomAttributes appliesTo="PRODUCTS" forForm />
      </Card>

      {createPortal(
        <div className={styles.footer}>
          <div className="spacerVertical" />
          <button
            type="button"
            className="outlined md"
            onClick={() => navigate(-1)}
            disabled={isLoading}
          >
            {t("CANCEL")}
          </button>
          <button
            type="submit"
            form={formId}
            className="primary md"
            disabled={isLoading}
          >
            {t("CREATE")}
          </button>
        </div>,
        document.body
      )}
    </form>
  );
};
