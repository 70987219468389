import { CopyClipboard } from "@/components/shared/CopyClipboard/CopyClipboard";
import { useAuth } from "@/store/auth";
import { addressToString } from "@/utils/address";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import AddressDetail from "../addresses/AddressDetail";
import { CatalogOTPFlag } from "./CatalogOTPFlag";
import { ChangePassword } from "./ChangePassword";
import { CommunicationChannel } from "./CommunicationChannel";
import { InvoiceButton } from "./InvoiceButton";
import { MarketplaceView } from "./MarketplaceView";
import { PaymentLink } from "./PaymentLink";
import styles from "./styles.module.scss";

const PersonalData = () => {
  const [t] = useTranslation("global");
  const { user, isCommercial, commercial, refreshUserData } = useAuth();

  const data = isCommercial
    ? {
        ...commercial,
        name: `${commercial?.firstName ?? ""} ${commercial?.lastName ?? ""}`,
      }
    : { ...user, referralCode: "" };

  return (
    <div className={clsx(styles.container, "flexGap flexSmColumn")}>
      <div className="col-6 noPadding col-sm-12">
        <p className={styles.title}>{t("ACCOUNT.PERSONAL_DATA.MY_DATA")}</p>
        <p className="bold">{t("ACCOUNT.PERSONAL_DATA.NAME")}</p>
        <p>{data?.name}</p>
        <div className={styles.spacer} />
        <p className="bold">{t("ACCOUNT.PERSONAL_DATA.PHONE")}</p>
        <p>{data?.phone}</p>
        <p className="bold">{t("ACCOUNT.PERSONAL_DATA.EMAIL")}</p>
        <p>{data?.email}</p>
        {isCommercial && !!data?.referralCode && (
          <>
            <div className={styles.spacer} />
            <p className="bold">{t("ACCOUNT.PERSONAL_DATA.REFERRAL")}</p>
            <CopyClipboard
              render={data?.referralCode}
              toCopy={data.referralCode}
            />
          </>
        )}
        <div className={styles.spacer} />
        <CommunicationChannel />
        <PaymentLink />
        <MarketplaceView />
        <InvoiceButton />
        <div className="spacer" />
        <CatalogOTPFlag />
        <div className="spacer" />
        <div className="spacer" />
        <ChangePassword />
      </div>
      <div className="spacerVertical" />
      <div className="col-6 noPadding col-sm-12">
        <p className={styles.title}>
          <span className="mr1">{t("ACCOUNT.PERSONAL_DATA.CONTACT")}</span>
          {Object.keys(user?.address ?? {}).length > 0 && (
            <AddressDetail
              supplierAddress
              action={refreshUserData}
              address={user.address}
            />
          )}
        </p>
        {Object.keys(user?.address ?? {}).length > 0 ? (
          <>
            <div className="contentInline">
              <div>
                <p className="bold">{t("ACCOUNT.PERSONAL_DATA.RESPONSIBLE")}</p>
                <p>{user?.address?.contactName}</p>
                <div className={styles.spacer} />
              </div>
            </div>
            <p className="bold">{t("ACCOUNT.PERSONAL_DATA.ADDRESS_CONTACT")}</p>
            <p>{addressToString(user?.address ?? {})}</p>
            <div className={styles.spacer} />
            <p className="bold">{t("ACCOUNT.PERSONAL_DATA.PHONE")}</p>
            <p>{user?.address?.phone || "-"}</p>
            <div className={styles.spacer} />
            <p className="bold">{t("ACCOUNT.PERSONAL_DATA.EMAIL")}</p>
            <p>{user?.address?.email || "-"}</p>
            <div className={styles.spacer} />
          </>
        ) : (
          <>
            <AddressDetail supplierAddress action={refreshUserData} />
          </>
        )}
      </div>
    </div>
  );
};

export default PersonalData;
