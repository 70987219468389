import { getBrandById } from "@/http";
import useSWR from "swr";
import { Chip } from "./Chip";

type TBrandNameChip = {
  id: string;
  onDelete?: () => void;
  hideDelete?: boolean;
};
export const BrandNameChip = ({
  id,
  onDelete,
  hideDelete = false,
}: TBrandNameChip) => {
  const { data } = useSWR([id, "brandIdChip"], getBrandById);

  if (!data) {
    return null;
  }

  return (
    <Chip>
      {data.name}
      {!hideDelete && <span onClick={() => onDelete?.()}>x</span>}
    </Chip>
  );
};
