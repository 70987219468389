import { GroupInfo } from "@/components/products/Group/GroupInfo";
import { GroupSetup } from "@/components/products/Group/GroupSetup";
import { GroupOptions } from "@/components/products/Group/GroupOptions";
import { GroupVariantsTable } from "@/components/products/Group/GroupVariantsTable";
import { useProductsCreation } from "@/store/products-creation";
import { useAuth } from "@/store/auth";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const CreateProductGroupPage = () => {
  const { brandId, brandName } = useProductsCreation();
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");
  const { refreshUserData } = useAuth();
  const methods = useForm({
    defaultValues: {
      brandId,
      brandName,
      variants: [],
      newVariants: [],
      generateSku: true,
      options: [{ name: "", value: "", optionValues: [], optionsSelected: {} }],
      images: [],
    } as any,
  });

  useEffect(() => {
    setTitle(t("CREATE_PRODUCT"), "/products/search");
  }, [t]);

  useEffect(() => {
    refreshUserData();
  }, []);

  return (
    <div className="grayContainer">
      <FormProvider {...methods}>
        <GroupSetup />
        <div className="spacer" />
        <div className="divider" />
        <div className="spacer" />
        <GroupInfo />
        <div className="spacer" />
        <div className="divider" />
        <div className="spacer" />
        <GroupOptions />
        <div className="spacer" />
        <div className="spacer" />
        <div className="spacer" />
        <GroupVariantsTable />
      </FormProvider>
    </div>
  );
};
