import { useCountry } from "@/hooks/useCountry";
import { useSubscription } from "@/hooks/useSubscription";
import { TPeriod } from "@/lib/subscriptions/components/Packages";
import { IPackage } from "@/lib/subscriptions/types";
import { FormEventHandler } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

export const PackageAddons = ({
  chosenPack,
  chosenPeriod,
  onDone,
}: {
  chosenPack: IPackage;
  chosenPeriod: TPeriod;
  onDone: (addons: Record<string, number>) => void;
}) => {
  const country = useCountry();
  const { t, i18n } = useTranslation("global");
  const { addons } = useSubscription();

  const priceAttr = {
    MONTHLY: "monthly",
    BI_YEARLY: "biyearly",
    YEARLY: "yearly",
  } as const;

  const onSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();
    const entries = new FormData(event.currentTarget).entries();

    const data: Record<string, number> = {};
    for (const [key, value] of entries) {
      data[key] = +value;
    }

    onDone(data);
  };

  return (
    <form onSubmit={onSubmit} className={styles.addons}>
      <h2 className={styles.row}>
        <div className="bold">
          {chosenPack?.name} {t("SUBSCRIPTION." + chosenPeriod)}
        </div>
        <div>1</div>
        <div>
          {new Intl.NumberFormat(
            `${i18n.resolvedLanguage}-${country.prefix.toUpperCase()}`,
            {
              style: "currency",
              currency: chosenPack.currency,
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }
          ).format(chosenPack[priceAttr[chosenPeriod]])}{" "}
          <span className="bold">
            {t("SUBSCRIPTION." + chosenPeriod).toLowerCase()}
          </span>
        </div>
      </h2>

      {addons.map(addon => (
        <div key={addon.id} className={styles.row}>
          <label htmlFor={addon.code}>{addon.name}</label>
          <input
            type="number"
            id={addon.code}
            name={addon.code}
            defaultValue={0}
            min={0}
            max={chosenPack.features.MAX_USERS?.value || undefined}
            step={1}
            required
            pattern="\d+"
            title={t("NOT_VALID_NUMBER")}
          />
          <p>
            {new Intl.NumberFormat(
              `${i18n.resolvedLanguage}-${country.prefix.toUpperCase()}`,
              {
                style: "currency",
                currency: chosenPack.currency,
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              }
            ).format(addon[priceAttr[chosenPeriod]])}{" "}
            <span className="bold">
              {t("SUBSCRIPTION." + chosenPeriod).toLowerCase()}
            </span>{" "}
            {t("SUBSCRIPTION.EACH").toLowerCase()}
          </p>
        </div>
      ))}

      <div>
        <button type="submit" className="primary">
          {t("CONFIRM")}
        </button>
      </div>
    </form>
  );
};
