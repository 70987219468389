import {
  getBrandById,
  getProductById,
  getProductByIds,
  getSupplierProductGroupByIdV2,
  TProduct,
  TSupplierProduct,
} from "@/http";
import useSWR from "swr";

export const useProductGroup = (id: string) => {
  const { data } = useSWR(
    id ? [id, "productGroup"] : null,
    getSupplierProductGroupByIdV2
  );

  return data ?? ({} as TSupplierProduct);
};

export const useProductVariant = (id: string) => {
  const { data } = useSWR(id ? [id, "variantProduct"] : null, getProductById);

  return data ?? ({} as TProduct);
};

export const useIsProductBrandOwner = (productId: string, userId: string) => {
  const { data: product } = useSWR(
    productId ? [productId, "variantProduct"] : null,
    getProductById
  );
  const { data: brand } = useSWR(
    product?.brandId ? [product.brandId, "ProductBrand"] : null,
    getBrandById
  );

  return brand?.supplierId === userId;
};

export const useIsBrandOwner = (brandId: string, userId: string) => {
  const { data: brand } = useSWR(
    brandId ? [brandId, "ProductBrand"] : null,
    getBrandById
  );

  return brand?.supplierId === userId;
};

export const useProductsForSale = (
  fromProducts: boolean,
  selectedProducts: Record<string, any>
) => {
  const ids = Object.keys(selectedProducts || {}).map(
    p => selectedProducts[p].productId
  );

  return useSWR(fromProducts ? [ids, "byIDs"] : null, getProductByIds);
};
