import { Modal } from "@/components/shared/Modal/Modal";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { createSupplierLimit, updateSupplierLimit } from "@/http";
import { useAuth } from "@/store/auth";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiOutlineEdit } from "react-icons/ai";
import styles from "./styles.module.scss";
import { TSupplierLimit } from "@/http/types/supplier-limit";
import { InputV2 } from "@/components/shared/Input/InputV2";
import { Select } from "@/components/shared/Input/Select";
import { LIMIT_TYPES } from "@/constants/limits";
import { ProductInput } from "../purchases/PurchaseCreateForm/Products/ProductInput";
import { ProductNameString } from "../shared/Details";
import { useSupplierBrands } from "@/hooks/useSupplier";
import { useTieredDiscounts } from "@/store/limits";
import { validateDecimalNumber } from "@/utils/keyboard";

type TTieredDiscountModal = {
  isEdit?: boolean;
  index?: number;
  discount?: TSupplierLimit;
};
export const TieredDiscountModal = ({
  isEdit = false,
  discount,
}: TTieredDiscountModal) => {
  const [t] = useTranslation("tiered-discounts");
  const [tGlobal] = useTranslation("global");
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const { getTieredDiscounts } = useTieredDiscounts();
  const brands = useSupplierBrands(user.id);
  const productName =
    discount?.applier === "PRODUCT"
      ? ProductNameString(discount.referenceId)
      : "";
  const defaultValues = {
    value: discount?.minValue || "",
    percentage: discount?.discountPercentage || "",
    applier: discount?.applier || "",
    valueType: discount?.valueType || "",
    products: productName
      ? [{ id: discount?.referenceId, name: productName }]
      : [],

    referenceId: discount?.referenceId || "",
  };
  const methods = useForm({ defaultValues });
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = async (values: any) => {
    setIsLoading(true);
    try {
      if (values.applier === "PRODUCT") {
        values.referenceId = values.products[0].id;
      }
      if (values.applier === "SUPPLIER") {
        values.referenceId = user.id;
      }

      values.supplierId = user.id;
      values.type = "DISCOUNT";
      values.valueType = "AMOUNT";

      delete values.products;

      if (isEdit) {
        await updateSupplierLimit(discount?.id ?? "", values);
      } else {
        await createSupplierLimit(values);
      }

      getTieredDiscounts(user.id);
      setIsLoading(false);
      setModalOpen(false);
      reset(defaultValues);
      showToastSuccess(t(isEdit ? "DISCOUNT_SAVED" : "DISCOUNT_CREATED"));
    } catch (error) {
      setIsLoading(false);
      showToastError({ message: tGlobal("ERROR_SOME_BAD"), error });
    }
  };

  useEffect(() => reset(defaultValues), [discount]);

  useEffect(() => {
    if (discount?.applier === "PRODUCT" && !!productName) {
      methods.setValue("products", [
        { id: discount.referenceId, name: productName },
      ] as any);
    }
  }, [productName]);

  return (
    <>
      {isEdit ? (
        <ToolTip title={t("EDIT_DISCOUNT")} position="Left">
          <AiOutlineEdit cursor="pointer" onClick={() => setModalOpen(true)} />
        </ToolTip>
      ) : (
        <div className="rigthAlign">
          <button className="primary lg" onClick={() => setModalOpen(true)}>
            {t("CREATE_DISCOUNT")}
          </button>
        </div>
      )}
      <Modal
        isOpen={isModalOpen}
        size="sm"
        showOverflow
        onClose={() => {
          reset(defaultValues);
          setModalOpen(false);
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="false"
            className={styles.modal}
          >
            <h1 className={styles.title}>
              {isEdit ? t("EDIT_DISCOUNT") : t("CREATE_DISCOUNT")}
            </h1>
            <div className={"divider"} />
            <div className={styles.content}>
              <Select
                name="applier"
                label={t("DISCOUNT")}
                register={register}
                error={errors.applier}
                required
                choices={LIMIT_TYPES.map(limit => ({
                  value: limit.id,
                  label: limit.value,
                }))}
              />
              {methods.watch("applier") === "PRODUCT" && (
                <ProductInput
                  hideQuantity
                  index={0}
                  canDelete={false}
                  source=""
                  onDelete={() => {
                    return;
                  }}
                />
              )}
              {methods.watch("applier") === "BRAND" && (
                <Select
                  name="referenceId"
                  label={t("BRAND")}
                  register={register}
                  error={errors.referenceId}
                  required
                  choices={brands.map(brand => ({
                    value: brand.id,
                    label: brand.name,
                  }))}
                />
              )}
              <InputV2
                name="value"
                label={t("MIN_AMOUNT")}
                onKeyPress={validateDecimalNumber}
                placeholder={t("MIN_VALUE_PLACEHOLDER")}
                min={0}
                required
              />
              <InputV2
                name="percentage"
                label={t("DISCOUNT_PERCENTAGE")}
                type="number"
                placeholder={t("DISCOUNT_PERCENTAGE_PLACEHOLDER")}
                min={0}
                max={100}
                required
              />
            </div>
            <div className="divider" />
            <div className="rigthAlign">
              <button type="submit" className="primary" disabled={isLoading}>
                {t(isEdit ? "SAVE_DISCOUNT" : "CREATE_DISCOUNT_ACTION")}
              </button>
            </div>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};
