import { useCustomAttributes } from "@/hooks/useCustomAttributes";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiEdit, BiPlus, BiTrash } from "react-icons/bi";
import { Modal } from "../shared/Modal/Modal";
import { CustomAttributeFormModal } from "./CustomAttributeFormModal";
import styles from "./styles.module.scss";
import { DisabledZone } from "../shared/DisabledZone/DisabledZone";
import { FEAT_CUSTOM_ATTRIBUTES } from "@/constants/features";
import { useSubscription } from "@/hooks/useSubscription";
import { PremiumFeatureIndicator } from "../subscription/PremiumFeatureIndicator";

type Props = {
  trigger: (show: () => void) => ReactNode;
  appliesTo: "PRODUCTS" | "SELLERS" | "PURCHASES" | "CLIENTS";
};

export const CustomAttributesTableModal = ({ trigger, appliesTo }: Props) => {
  const [t] = useTranslation("global");
  const { customAttributes, deleteCustomAttribute, savingCustomAttribute } =
    useCustomAttributes(appliesTo);
  const [open, setOpen] = useState(false);
  const { hasFeature } = useSubscription();

  return (
    <>
      {trigger(() => setOpen(true))}

      <Modal isOpen={open} onClose={() => setOpen(false)} size="xl">
        <br />
        <div className="contentInline">
          <h3 className="bold">
            {t("CUSTOM_ATTRIBUTES.PLURAL")} ({t("CUSTOM_ATTRIBUTES.GLOBAL")}){" "}
            <PremiumFeatureIndicator feature={FEAT_CUSTOM_ATTRIBUTES} />
          </h3>
          <CustomAttributeFormModal
            trigger={show => (
              <button type="button" className="primary" onClick={show}>
                <BiPlus /> {t("ADD")}
              </button>
            )}
            appliesTo={appliesTo}
          />
        </div>
        <br />
        <div className={styles.tableContainer}>
          <DisabledZone when={!hasFeature(FEAT_CUSTOM_ATTRIBUTES)}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>{t("CUSTOM_ATTRIBUTES.MODEL.NAME")}</th>
                  <th>{t("CUSTOM_ATTRIBUTES.MODEL.DATA_TYPE")}</th>
                  <th>{t("CUSTOM_ATTRIBUTES.MODEL.MANDATORY")}</th>
                  <th>{t("CUSTOM_ATTRIBUTES.MODEL.VISIBILITY")}</th>
                  <th>{t("CUSTOM_ATTRIBUTES.MODEL.VISIBILE_IN")}</th>
                  <th>{t("ACTIONS")}</th>
                </tr>
              </thead>
              <tbody>
                {customAttributes?.map(attr => (
                  <tr key={attr.id}>
                    <td>{attr.name}</td>
                    <td>
                      {t(`CUSTOM_ATTRIBUTES.DATA_TYPES.${attr.dataType}`)}
                    </td>
                    <td>{attr.mandatory ? t("YES") : t("NO")}</td>
                    <td>{attr.visible ? t("PUBLIC") : t("PRIVATE")}</td>
                    <td>
                      {attr.visible && attr?.visibleWhen
                        ? t(
                            `CUSTOM_ATTRIBUTES.VISIBILITY_${attr.visibleWhen.toUpperCase()}`
                          )
                        : "-"}
                    </td>
                    <td>
                      <CustomAttributeFormModal
                        appliesTo={appliesTo}
                        record={attr}
                        trigger={open => (
                          <button
                            type="button"
                            className=""
                            onClick={open}
                            disabled={savingCustomAttribute}
                          >
                            <BiEdit />
                          </button>
                        )}
                      />
                      <button
                        type="button"
                        onClick={() => {
                          if (!confirm(`${t("DELETE")} ${attr.name}?`)) return;
                          deleteCustomAttribute(attr.id);
                        }}
                        disabled={savingCustomAttribute}
                      >
                        <BiTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </DisabledZone>
        </div>
      </Modal>
    </>
  );
};
