import { offersType } from "@/constants/offers";
import { getGlobalOfferById } from "@/http";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

export const GlobalOfferDetail = ({ id }: { id: string }) => {
  const [t] = useTranslation("purchases");
  const { data } = useSWR([id, "GlobalOfferDetail"], getGlobalOfferById);
  if (!data) {
    return null;
  }

  return (
    <div className="">
      <div>
        <span className="bold">{`${t("NAME")}: `}</span>
        {data.name}
      </div>

      <div>
        <span className="bold">{`${t("TYPE")}: `}</span>
        {offersType.find(({ id }: any) => id === data?.type)?.name ?? "-"}
      </div>
      <div className="spacer" />
    </div>
  );
};
