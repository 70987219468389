import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { FiAlertCircle } from "react-icons/fi";

export const LockedFeaturePlaceholder = () => {
  const [t] = useTranslation("global");

  return (
    <div className={styles.placeholder}>
      <div>
        <FiAlertCircle size={32} className="purpleText" />
      </div>
      <p>{t("SUBSCRIPTION.PLACEHOLDER")}</p>
      <div>
        <Link to="/subscription">
          <button className="outlined bold">
            {t("SUBSCRIPTION.START_NOW")}
          </button>
        </Link>
      </div>
    </div>
  );
};
