import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

import styles from "./styles.module.scss";

type TCollapsible = {
  title: string;
  enabled?: boolean;
  open?: boolean;
  index?: number;
  onToggle?: (index: number) => void;
  children: ReactNode;
  className?: string;
};
export const Collapsible: FC<TCollapsible> = ({
  open,
  title,
  enabled = true,
  children,
  onToggle,
  index,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(open ?? false);
  const [height, setHeight] = useState<number | undefined>(
    open ? undefined : 0
  );
  const ref = useRef<HTMLDivElement>(null);

  const handleFilterOpening = () => {
    setIsOpen(prev => !prev);
    if (onToggle) {
      onToggle(index ?? -1);
    }
  };

  useEffect(() => {
    if (open !== undefined) {
      setIsOpen(open);
    }
  }, [open]);

  useEffect(() => {
    if (isOpen) {
      setHeight(ref.current?.getBoundingClientRect().height);
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  return (
    <>
      <div className={`${styles.card} ${className}`}>
        <div className={styles.titleContainer}>
          <h6 className={styles.title}>{title}</h6>
          {enabled && (
            <button type="button" onClick={handleFilterOpening}>
              {!isOpen ? <FiChevronDown /> : <FiChevronUp />}
            </button>
          )}
        </div>
        <div
          className={`${styles.contentContainer} ${
            isOpen ? styles.contentContainerOpen : ""
          }`}
          style={{ height }}
        >
          <div className={isOpen ? styles.content : ""} ref={ref}>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};
