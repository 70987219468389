import { CreateBox } from "@/components/profile/boxes/CreateBox";
import { NumericInput } from "@/components/shared/Input/NumericInput";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { getSupplierBoxes } from "@/http";
import { TSupplierBox } from "@/http/types/supplier-box";
import { useAuth } from "@/store/auth";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const BoxesConfirmDispatch = () => {
  const methods = useFormContext();
  const { user } = useAuth();
  const [t] = useTranslation("global");

  const [boxes, setBoxes] = useState<TSupplierBox[]>([]);

  const getUserBoxes = async (userId: string, newId?: string) => {
    const boxes = await getSupplierBoxes(userId, {
      page: 0,
      size: 100,
    });
    setBoxes(boxes.content);

    if (newId) {
      methods.setValue("singleBoxSize", newId);
    }
  };

  useEffect(() => {
    getUserBoxes(user.id);
  }, []);
  return (
    <>
      <SelectV2
        required
        label={t("ORDERS.BOX_SIZE")}
        name="singleBoxSize"
        choices={boxes?.map(box => ({
          value: box.id!,
          label: `${box.name}, ${t("ORDERS.MEASURES")}: ${box.height} ${t(
            "ORDERS.HIGH"
          )} x ${box.length} ${t("ORDERS.LONG")} x ${box.width} ${t(
            "ORDERS.WIDTH"
          )}`,
        }))}
        infoLabel={boxes?.length > 0 ? "" : t("ORDERS.NO_BOXES_CREATED")}
      />
      <CreateBox
        simpleButton={true}
        onDone={box => getUserBoxes(user.id, box.id)}
      />
      <NumericInput
        required
        label={t("ORDERS.WEIGHT_BY_BOX")}
        name="singleWeight"
        min={0.1}
        decimals
      />
    </>
  );
};
