import { useTicket } from "@/store/ticket";
import styles from "./styles.module.scss";
import { format } from "date-fns";
import { DATE_HOUR_SLASH, USA_DATE_HOUR_SLASH } from "@/constants/date";
import { useTranslation } from "react-i18next";
import { isUSA } from "@/constants/core";
import { validateTimezoneDate } from "@/utils/date";

const RenderInformation = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => {
  return (
    <div className="col-4 centerAlign">
      <div>
        <p className="bold">{label}</p>
        <span>{value}</span>
      </div>
    </div>
  );
};

export const TicketInformation = () => {
  const [selectedTicket] = useTicket(state => [state.selectedTicket]);
  const [t] = useTranslation("global");

  return (
    <div className={`row centerAlign ${styles.infoContainer}`}>
      <RenderInformation
        label={t("TICKETS.CREATION")}
        value={
          selectedTicket?.createdAt
            ? format(
                new Date(validateTimezoneDate(selectedTicket!.createdAt)),
                isUSA ? USA_DATE_HOUR_SLASH : DATE_HOUR_SLASH
              )
            : "-"
        }
      />
      <div className="dividerVertical" />
      <RenderInformation
        label={t("TICKETS.TYPE")}
        value={t(selectedTicket?.type ?? "")}
      />
      <div className="dividerVertical" />
      <RenderInformation
        label={t("TICKETS.REASON")}
        value={selectedTicket?.reason ?? ""}
      />
    </div>
  );
};
