import { getInstance } from "../../core/axios-gateway";
import { TCategory, TCategoryTree } from "../../types/category";
import { TRestListData, TRestListParams } from "../../types/rest";

const BASE_PATH = "/api/categories";

export const getCategories = async (params: TRestListParams) => {
  return getInstance()
    .get<TRestListData<TCategory>>(`${BASE_PATH}/list`, {
      params,
    })
    .then(res => res.data);
};

export const getCategoryTrees = async () => {
  return getInstance()
    .get<TCategoryTree[]>(BASE_PATH)
    .then(res => res.data);
};

export const getCategory = async (categoryId: string) => {
  return getInstance()
    .get<TCategory>(`${BASE_PATH}/${categoryId}`)
    .then(res => res.data);
};

export const createCategory = async (data: TCategory) => {
  return getInstance()
    .post<TCategory>(BASE_PATH, data)
    .then(res => res.data);
};

export const updateCategory = async (categoryId: string, data: TCategory) => {
  return getInstance()
    .put<TCategory>(`${BASE_PATH}/id/${categoryId}`, data)
    .then(res => res.data);
};

export const getCategoriesFullTree = async (params: TRestListParams) => {
  return getInstance()
    .get<TCategory[]>(`${BASE_PATH}/v2`, {
      params: { ...params, fullTree: "true" },
    })
    .then(res => res.data);
};
