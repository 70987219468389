import ToolTip from "@/components/shared/Tooltip/Tooltip"
import { useTranslation } from "react-i18next";
import { RiErrorWarningLine } from "react-icons/ri";
import styles from "../styles.module.scss";

type TErrorTooltip = {
  errors: string[];
};

export const ErrorsTooltip = ( { errors } : TErrorTooltip ) => {
  const [t] = useTranslation("purchases");

  return (
    <ToolTip
      containerClass={styles.top}
      title={t(errors?.[0])}
      position="Right"
      width="15rem"
      theme="orange"
    >
      <span className="mlmd">
        <RiErrorWarningLine className="orangeText" />
      </span>
    </ToolTip>
  )
}
