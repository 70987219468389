import { TOTAL_ORDERS_PER_PAGE } from "@/constants/export-orders";
import {
  getSupplierPaymentById,
  getSupplierPayments,
  getSupplierPendingTransactions,
} from "@/http";
import { create } from "zustand";

type TUsePayments = {
  getPayments: (supplierId: string) => void;
  isLoadingPayments: boolean;
  payments: any[];
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
  totalPages: number;
  setCurrentFilter: (value: string) => void;
  currentFilter: string;
  setNameFilter: (value: string) => void;
  nameFilter: string;
  getPayment: (paymentId: string) => void;
  payment: any;
  isLoadingPayment: boolean;
  getPendingTransactions: (supplierId: string) => void;
  isLoadingTransactions: boolean;
  pendingTransactios: any[];
  totalPagesTransactions: number;
  currentPageTransaction: number;
  setCurrentPageTransaction: (currentPage: number) => void;
};
export const usePayments = create<TUsePayments>((set, get) => ({
  setCurrentFilter: (currentFilter: string) => set(() => ({ currentFilter })),
  currentFilter: "",
  getPayments: async (supplierId: string) => {
    try {
      set({ isLoadingPayments: true });
      const { currentPage } = get();
      const data = await getSupplierPayments(supplierId, {
        size: TOTAL_ORDERS_PER_PAGE,
        page: currentPage,
        sort: "",
        search: ``,
      });
      set({
        payments: data.content,
        totalPages: data.totalPages,
        isLoadingPayments: false,
      });
    } catch (e) {
      set({
        payments: [],
        totalPages: 0,
        isLoadingPayments: false,
      });
    }
  },
  setNameFilter: nameFilter => set(() => ({ nameFilter })),
  nameFilter: "",
  isLoadingPayments: false,
  payments: [],
  currentPage: 0,
  totalPages: 0,
  setCurrentPage: currentPage => set(() => ({ currentPage })),
  getPayment: async (paymentId: string) => {
    try {
      set({ isLoadingPayment: true });
      const payment = await getSupplierPaymentById(paymentId);
      set({ payment, isLoadingPayment: false });
    } catch (e) {
      set({ payment: null, isLoadingPayment: false });
    }
  },
  payment: null,
  isLoadingPayment: false,
  getPendingTransactions: async (supplierId: string) => {
    try {
      set({ isLoadingTransactions: true });
      const { currentPageTransaction } = get();
      const data = await getSupplierPendingTransactions(supplierId, {
        size: TOTAL_ORDERS_PER_PAGE,
        page: currentPageTransaction,
        sort: "id,asc",
        search: ``,
      });
      set({
        pendingTransactios: data.content,
        totalPagesTransactions: data.totalPages,
        isLoadingTransactions: false,
      });
    } catch (e) {
      set({
        pendingTransactios: [],
        totalPagesTransactions: 0,
        isLoadingTransactions: false,
      });
    }
  },
  isLoadingTransactions: false,
  pendingTransactios: [],
  totalPagesTransactions: 0,
  currentPageTransaction: 0,
  setCurrentPageTransaction: currentPageTransaction =>
    set(() => ({ currentPageTransaction })),
}));
