import { TSeller } from "@/http";
import { usePurchase } from "@/store/purchase";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PurchaseListTable } from "../purchases/PurchaseList/PurchaseListTable/PurchaseListTable";
import { useRecordContext } from "./ui/RecordContext";

export const ClientPurchasesTable = () => {
  const client = useRecordContext<TSeller>();
  const { setSupplierIdFilter, supplierIdFilter } = usePurchase();
  const [t] = useTranslation("global");

  useEffect(() => {
    setSupplierIdFilter(client.id);
  }, []);

  if (supplierIdFilter !== client.id) {
    return <h6>{t("NO_DATA")}</h6>;
  }

  return (
    <>
      <PurchaseListTable />
    </>
  );
};
