const PNG = "image/png";
const JPG = "image/jpeg";
const GIF = "image/gif";
const PDF = "application/pdf";

export const knownMimeTypes = [PNG, JPG, GIF, PDF] as const;

type KnownType = (typeof knownMimeTypes)[number];

export function getRealMimeType(file: File): Promise<"unknown" | KnownType> {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const buffer = reader.result as ArrayBuffer;
      const arr = new Uint8Array(buffer).subarray(0, 4);
      const arrEnd = new Uint8Array(buffer).slice(-2);

      let header = "";
      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16).padStart(2, "0");
      }

      let footer = "";
      for (let i = 0; i < arrEnd.length; i++) {
        footer += arrEnd[i].toString(16).padStart(2, "0");
      }

      switch (header) {
        case "89504e47":
          return resolve(PNG);
        case "47494638":
          return resolve(GIF);
        case "25504446":
          return resolve(PDF);
        default:
          break;
      }

      if (header.startsWith("ffd8ff") && footer === "ffd9") {
        return resolve(JPG);
      }

      return resolve("unknown");
    };
    reader.readAsArrayBuffer(file);
  });
}
