import { locale } from "../config/locale";

const defaultOptions = {
  significantDigits: 2,
  thousandsSeparator: ",",
  decimalSeparator: ".",
  symbol: "$",
};

export const currencyFormatter = (
  value: number | string | undefined,
  options = defaultOptions
) => {
  if (typeof value !== "number") {
    value = 0.0;
  }
  options = { ...defaultOptions, ...options };
  value = value.toFixed(options.significantDigits);

  const [currency, decimal] = value.split(".");

  return `${options.symbol} ${currency.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    options.thousandsSeparator
  )}${options.decimalSeparator}${decimal}`;
};

export const getCurrency = () => {
  let currency = "COP";
  const minimumFractionDigits = 2;

  switch (locale) {
    case "es-CO":
      currency = "COP";
      break;
    case "es-MX":
      currency = "MXN";
      break;
    case "en-US":
      currency = "USD";
      break;
  }

  return { currency, minimumFractionDigits };
};

export const formatCurrency = (amount: number) => {
  const currency = getCurrency();
  return new Intl.NumberFormat(locale, {
    style: "currency",
    ...currency,
  }).format(amount);
};
