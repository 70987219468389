import { useOrderFilter } from "@/store/orders";
import { useTranslation } from "react-i18next";
import { MultipleMenu } from "../shared/Menu/MultipleMenu";

const SortOrders = () => {
  const { sortBy, sortDirection, setSorting } = useOrderFilter();
  const [t] = useTranslation("orders-page");

  const setSortBy = (sortBy: string | string[]) => {
    if (typeof sortBy === "string") {
      setSorting(sortBy, sortDirection);
    }
  };
  const setSortDirection = (sortDirection: string | string[]) =>
    setSorting(sortBy, sortDirection);

  const sortValues: Record<string, string> = {
    subPurchaseNumber: "ID",
    createdAt: "DATE",
    priority: "PRIORITY",
  };

  return (
    <>
      <MultipleMenu
        width="15rem"
        label={
          <div className="menuTooltip">
            {t("SORT.TITLE")}
            {sortBy && `: ${t(`SORT.${sortValues[sortBy]}`)}`}
            {sortDirection && ` - ${t(`SORT.${sortDirection}`)}`}
          </div>
        }
        values={[
          {
            onSelect: setSortBy,
            title: t("PROPERTY"),
            selectedValue: sortBy,
            options: [
              { label: t("SORT.ID"), value: "subPurchaseNumber" },
              { label: t("SORT.DATE"), value: "createdAt" },
              { label: t("SORT.PRIORITY"), value: "priority" },
            ],
          },
          {
            onSelect: setSortDirection,
            title: t("ORDER"),
            selectedValue: sortDirection,
            options: [
              { label: t("SORT.ASC"), value: "ASC" },
              { label: t("SORT.DESC"), value: "DESC" },
            ],
          },
        ]}
      />
    </>
  );
};

export default SortOrders;
