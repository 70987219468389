import { useTranslation } from "react-i18next";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useCountry } from "@/hooks/useCountry";
import styles from "./Help.module.scss";

const HelpPage = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");

  useEffect(() => {
    setTitle(t("HELP.TITLE"));
  }, [t]);

  return (
    <div className={styles.help}>
      <img src="/logo_blue.svg" alt="Seeri logo" />

      <h6>{t("SEERI_HELP")}</h6>
      <p>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://help.seeri.co/knowledge"
        >
          {t("KNOWLEDGE_BASE_COPY")}
        </a>
      </p>
      <div className="spacer" />
      <div className="spacer" />
      <div className="spacer" />

      <p>{t("HELP.SUPPORT_DESCRIPTION")}</p>
      <p>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://wa.me/message/27PY25ZVV3QIM1"
        >
          {t("HELP.SUPPORT_LINK")}
        </a>
      </p>
    </div>
  );
};

export default HelpPage;
