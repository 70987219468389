import { TGlobalOffer } from "@/http/types/global-offers";
import format from "date-fns/format";
import { DATE_MONT_YEAR, USA_DATE_MONT_YEAR } from "@/constants/date";
import { offersType } from "@/constants/offers";
import { useNavigate } from "react-router";
import styles from "./styles.module.scss";
import { useState } from "react";
import { updateOffer } from "@/http";
import { showToastError } from "@/hooks/useToast";
import { DeleteOffer } from "../delete/DeleteOffer";
import { useTranslation } from "react-i18next";
import { isUSA } from "@/constants/core";
import { formatCurrency } from "@/utils/currency";
import { EditOffer } from "../edit/EditOffer";

type TOfferRow = {
  rowOffer: TGlobalOffer;
};
export const OfferRow = ({ rowOffer }: TOfferRow) => {
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const [isLoading, setIsLoading] = useState(false);
  const [offer, setOffer] = useState(rowOffer);

  const handleChange = async () => {
    try {
      setIsLoading(true);
      const offerNew = await updateOffer(offer.id, {
        enabled: !offer.enabled,
      });
      setOffer(offerNew);
      setIsLoading(false);
    } catch (error) {
      showToastError({ message: t("ERROR_SOME_BAD"), error });
      setIsLoading(false);
    }
  };

  return (
    <tr className={`${styles.row} pointer`}>
      <td onClick={() => navigate(`/offers/${offer.id}/show`)}>
        {format(
          new Date(offer.createdAt),
          isUSA ? USA_DATE_MONT_YEAR : DATE_MONT_YEAR
        )}
      </td>
      <td onClick={() => navigate(`/offers/${offer.id}/show`)}>{offer.name}</td>
      <td onClick={() => navigate(`/offers/${offer.id}/show`)}>
        {offersType.find(({ id }: any) => id === offer?.type)?.name ?? "-"}
      </td>
      <td onClick={() => navigate(`/offers/${offer.id}/show`)}>
        {formatCurrency(offer.minValue ?? 0)}
      </td>
      <td onClick={() => navigate(`/offers/${offer.id}/show`)}>
        {format(
          new Date(offer.endsAt),
          isUSA ? USA_DATE_MONT_YEAR : DATE_MONT_YEAR
        )}
      </td>
      <td>
        <div className="flexGap centerAlign centerVAlign">
          <label className="switchBtn">
            <input
              type="checkbox"
              checked={offer?.enabled ?? false}
              onChange={handleChange}
              className={isLoading ? "loading" : ""}
            />
            <div className={`slide`} />
          </label>
          <div>{t(offer?.enabled ? "ENABLED" : "DISABLED")}</div>
        </div>
      </td>
      <td>
        <DeleteOffer offer={offer} />
        <EditOffer offer={offer} onDone={offer => setOffer(offer)} />
      </td>
    </tr>
  );
};
