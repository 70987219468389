export const SIDEBAR_ITEM_FOCUS = "#01E5DC";
export const APP_BG = "#FFFFFF";
export const FONT_BACKDROP = "rgba(50, 77, 109, 0.1)";
export const TABLE_BACKDROP = "rgba(217, 217, 217, 0.2)";
export const MODAL_BACKDROP = "rgba(246, 249, 252, 0.8)";
export const RED_ALERT = "#FD4281";
export const GREEN_SUCCESS = "#25D366";
export const ACTIONS_GRAY = "#DDE3E9";
export const BLUE_LIGHT = "#dffffe";

export const COLOR_GREEN_SEERI = "#78cb46";
export const COLOR_LILA_SEERI = "#9189ff";
export const COLOR_DEEP_BLUE_SEERI = "#0b263f";
export const COLOR_ORANGE_SEERI = "#ff833e";
export const COLOR_YELLOW_SEERI = "#ffdb76";
export const COLOR_GRAY = "#F7F9FC";
export const COLOR_SOFT_GRAY = "#e4e9f2";
