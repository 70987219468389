import { TSupplierAddress } from "@/http";
import { useAddresses } from "@/store/profile";
import { useTranslation } from "react-i18next";
import { AiFillWarning } from "react-icons/ai";
import { AddressCard } from "./AddressCard/AddressCard";
import styles from "./styles.module.scss";

const AddressesList = () => {
  const [t] = useTranslation("global");
  const { addresses } = useAddresses();

  return (
    <div className={styles.addressesListContainer}>
      {addresses?.content?.length ? (
        <div className={styles.card}>
          {addresses.content.map((address: TSupplierAddress, key: number) => (
            <AddressCard address={address} key={key} />
          ))}
        </div>
      ) : (
        <div className={styles.alert}>
          <span>
            <AiFillWarning size={"1.5rem"} />
          </span>
          {t("ACCOUNT.ADDRESSES.NO_ADDRESSES")}
        </div>
      )}
    </div>
  );
};

export default AddressesList;
