import {
  fetchAndActivate,
  getRemoteConfig,
  getValue,
} from "firebase/remote-config";
import { isProd } from "@/constants/env";
import { app } from "@/core/firebase";

export const getRCBrowser = async () => {
  const remoteConfig = getRemoteConfig(app);
  remoteConfig.settings.minimumFetchIntervalMillis = isProd
    ? 6 * 60 * 60 * 1000
    : 10 * 60 * 1000;
  await fetchAndActivate(remoteConfig);
  return remoteConfig;
};

export const getBaseURL = async (locale: string) => {
  const remoteConfig = await getRCBrowser();
  const microservicesHost = getValue(remoteConfig, "microservices_host");

  const values = JSON.parse(microservicesHost.asString() || "{}");

  switch (locale) {
    case "es-CO":
    case "en-US":
      return values.co;
    case "es-MX":
      return values.mx;
    default:
      return values.co;
  }
};

export const getRemoteConfigValue = async (value: string) => {
  const remoteConfig = await getRCBrowser();
  const remoteConfigValue = getValue(remoteConfig, value);
  const values = JSON.parse(remoteConfigValue.asString() || "{}");

  return values;
};
