import { FilterTabs } from "@/components/app/ButtonFilterTabs/ButtonFilterTabs";
import { Checkbox } from "@/components/shared/Input/Checkbox";
import { NumericInput } from "@/components/shared/Input/NumericInput";
import { WarehouseName } from "@/components/shared/WarehouseName";
import { useAuth } from "@/store/auth";
import { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";
import styles from "../ProductsTable/styles.module.scss";

type TProductStockDetail = {
  suppliers: any[];
  isSupplierBrand?: boolean;
  isEdit?: boolean;
};
export const ProductStockDetail = ({
  suppliers,
  isSupplierBrand = false,
  isEdit = false,
}: TProductStockDetail) => {
  const [t] = useTranslation("products-page");
  const { user } = useAuth();

  const [selectedSupplier, setSelectedSupplier] = useState(user.id);

  const sortedSuppliers = isSupplierBrand
    ? suppliers
        ?.sort((s: any) => (s?.supplierId === user.id ? -1 : 1))
        ?.filter((s: any) => s?.sales?.length > 0)
    : [suppliers?.find((s: any) => s.supplierId === user.id) ?? {}];

  const optionSuppliers = suppliers?.reduce((dict: any, o: any) => {
    dict[o["supplierId"]] = o;
    return dict;
  }, {});

  useEffect(() => {
    setSelectedSupplier(user.id);
  }, [isEdit]);

  return (
    <>
      <h6 className="bold">{t("STOCK_AVAILABLE")}</h6>
      <div className="spacer" />
      {!isEdit && (
        <>
          <FilterTabs
            options={
              sortedSuppliers?.map((s: any) => ({
                text:
                  s?.supplierId === user.id
                    ? t("MY_AVAILABILITY")
                    : s.supplierName,
                value: s.supplierId,
              })) ?? []
            }
            currentFilter={selectedSupplier}
            action={setSelectedSupplier}
          />
          <div className="spacer" />
        </>
      )}

      <ProductStockTable
        warehouses={optionSuppliers[selectedSupplier]?.warehouses ?? []}
        isEdit={isEdit}
      />
      <div className="spacer" />
    </>
  );
};

type TProductStockTable = {
  warehouses: any[];
  isEdit?: boolean;
};
const ProductStockTable = ({
  warehouses,
  isEdit = false,
}: TProductStockTable) => {
  const [t] = useTranslation("products-page");
  const [tGlobal] = useTranslation("global");

  if (!warehouses?.length) {
    return null;
  }

  return (
    <>
      <table className={styles.table}>
        <thead>
          <tr className={styles.thead}>
            <th>{t("WAREHOUSE")}</th>
            <th>Stock</th>
            <th>{tGlobal("OVERSTOCK")}</th>
            <th>{tGlobal("OVERSTOCK_AMOUNT")}</th>
          </tr>
        </thead>
        <tbody>
          {warehouses.map((wh, index: number) => {
            const overStock = useWatch({
              name: `overStock.${wh?.id}.overStock`,
            });

            return (
              <tr key={index}>
                <td>
                  <WarehouseName useOnlyName id={wh.warehouseId} />
                </td>
                <td>{wh?.availableStock}</td>
                <td>
                  {isEdit ? (
                    <div className="flexGap center centerAlign mt1">
                      <Checkbox
                        name={`overStock.${wh?.id}.overStock`}
                        label=""
                        defaultChecked={!!wh?.overStock}
                      />
                    </div>
                  ) : (
                    <>
                      {wh?.overStock ? (
                        <span className="greenText">
                          <BsFillCheckCircleFill />
                        </span>
                      ) : (
                        <span className="redText">
                          <BsFillXCircleFill />
                        </span>
                      )}
                    </>
                  )}
                </td>
                <td>
                  {isEdit ? (
                    <div className="noPadding flexGap centerAlign">
                      <NumericInput
                        name={`overStock.${wh?.id}.overStockAmount`}
                        label=""
                        required={overStock}
                      />
                    </div>
                  ) : (
                    wh?.overStockAmount ?? 0
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
