import { Checkbox } from "@/components/shared/Input/Checkbox";
import { usePaymentMethods } from "@/hooks/usePurchaseProducts";
import { showToastError } from "@/hooks/useToast";
import { getSupplierPaymentMethods, setSupplierPaymentMethods } from "@/http";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaCheck } from "react-icons/fa";
import { useSubscription } from "@/hooks/useSubscription";
import { PremiumFeatureIndicator } from "@/components/subscription/PremiumFeatureIndicator";
import styles from "./styles.module.scss";
import useSWR from "swr";
import { FEAT_CREDIT } from "@/constants/features";

export const UserPaymentMethods = () => {
  const availablePaymentMethods = usePaymentMethods();
  const availableOrigins = [
    "suppliers",
    "supplier_catalog_mobile",
    "supplier_catalog",
    "marketplace",
  ];

  const { data: supplierPaymentMethods = [], mutate } = useSWR(
    "supplierPaymentMethods",
    getSupplierPaymentMethods
  );
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [t] = useTranslation("global");
  const methods = useForm();
  const { hasFeature } = useSubscription();

  const onSubmit = async (values: any) => {
    try {
      setLoading(true);
      const mapPaymentMethods = availablePaymentMethods?.reduce((map, pm) => {
        map.set(pm.code, pm);
        return map;
      }, new Map());

      const methods = Object.keys(
        Object.keys(values).reduce((acc: any, key) => {
          if (values[key] && !key.startsWith("origin-")) {
            acc[key] = values[key];
          }
          return acc;
        }, {}) ?? {}
      );

      const methodsOrigins = methods.map(m => {
        const originsValues = values[`origin-${m}`];
        const origins = [
          ...Object.keys(originsValues).filter(key => originsValues[key]),
          "backoffice",
        ];

        return {
          ...mapPaymentMethods.get(m),
          origins: [...new Set(origins)],
        };
      });

      mutate(setSupplierPaymentMethods(methodsOrigins));
      setLoading(false);
      setIsEditing(false);
    } catch (error: any) {
      showToastError({ message: t("ERROR_SOME_BAD"), error });
    }
  };

  const handleEdit = () => {
    const values = supplierPaymentMethods?.reduce((map: any, paymentMethod) => {
      map[paymentMethod.code] = true;

      map[`origin-${paymentMethod.code}`] = (
        supplierPaymentMethods.find(p => p.code === paymentMethod.code)
          ?.origins ?? []
      ).reduce((acc: any, curr) => {
        acc[curr] = true;
        return acc;
      }, {});

      return map;
    }, {});

    methods.reset(values);
    setIsEditing(true);
  };

  return (
    <div className={styles.container}>
      <div className="contentInline">
        <span className={styles.title}>{t("PAYMENT_METHODS")}</span>
        {!isEditing && (
          <button className="primary" onClick={handleEdit}>
            {t("EDIT")}
          </button>
        )}
      </div>

      {isEditing ? (
        <FormProvider {...methods}>
          <p className="mdFont bold">{t("PAYMENT_METHOD_PLATFORM")}</p>
          <div className="spacer" />
          <ColumnList
            items={availablePaymentMethods.map(pm => (
              <>
                <Checkbox
                  name={pm.code}
                  label={
                    <>
                      {t(`PAYMENT_METHOD.${pm.code}`)}{" "}
                      {pm.code === "CREDIT" && (
                        <PremiumFeatureIndicator feature={FEAT_CREDIT} />
                      )}
                    </>
                  }
                  key={pm.code}
                  disabled={pm.code === "CREDIT" && !hasFeature(FEAT_CREDIT)}
                />

                <div className="ml1">
                  {availableOrigins.map(pmo => (
                    <>
                      <Checkbox
                        name={`origin-${pm.code}.${pmo}`}
                        label={
                          <>{t(`PAYMENT_METHOD_CODE.${pmo?.toUpperCase()}`)}</>
                        }
                        disabled={!methods.watch(pm.code)}
                        key={pmo}
                      />
                    </>
                  ))}
                </div>
              </>
            ))}
          />
          <div>
            <div className="spacer" />
            <button disabled={isLoading} onClick={() => setIsEditing(false)}>
              {t("CANCEL")}
            </button>
            <button
              disabled={isLoading}
              className="lg primary"
              onClick={methods.handleSubmit(onSubmit)}
            >
              {t("SAVE")}
            </button>
          </div>
        </FormProvider>
      ) : (
        <>
          <ColumnList
            items={supplierPaymentMethods.map(pm => (
              <div key={pm.code} className="flexGap center">
                <span>
                  <FaCheck className="greenText" />
                </span>
                <span className="flexColumn">
                  <span>{t(`PAYMENT_METHOD.${pm.code}`) ?? "-"}</span>
                  <span>
                    <span className="bold mdFont">{t("ORIGIN") + ": "}</span>
                    <span>
                      {pm.origins
                        .filter(o => o !== "backoffice")
                        .map((o, idx) => {
                          return (
                            t(`PAYMENT_METHOD_CODE.${o?.toUpperCase()}`) +
                            (idx ===
                            pm.origins.filter(o => o !== "backoffice").length -
                              1
                              ? ""
                              : ", ")
                          );
                        })}
                    </span>
                  </span>
                </span>
              </div>
            ))}
          />
        </>
      )}
    </div>
  );
};

const ColumnList = ({ items }: { items: any[] }) => {
  const columns: any[] = [[], []];
  items.forEach((item, index) => {
    columns[index % columns.length].push(item);
  });

  return (
    <div className={styles.columnList}>
      {columns.map((column, columnIndex) => (
        <div key={columnIndex} className={styles.column}>
          {column.map((item: any, itemIndex: number) => (
            <div key={itemIndex} className={styles.item}>
              {item}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
