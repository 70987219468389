import ProductImage from "@/components/layout/ProductImage";
import { Card } from "@/components/shared/Card/Card";
import { NumericInput } from "@/components/shared/Input/NumericInput";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { useAuth } from "@/store/auth";
import { formatCurrency } from "@/utils/currency";
import { getProductSupplierFromProduct } from "@/utils/products";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineWarningAmber } from "react-icons/md";
import { ProductSearch } from "../ProductSearch/ProductSearch";
import styles from "./styles.module.scss";

export const PurchaseOrderProducts = () => {
  const { watch } = useFormContext();
  const products = watch("products");
  const supplierIdWatch = watch("supplierId");
  const [t] = useTranslation("global");

  return (
    <Card
      contentBottom={
        supplierIdWatch ? (
          <div className="flexGap centerAlign">
            <div className="col-6 col-sm-12">
              <ProductSearch />
            </div>
          </div>
        ) : null
      }
      className="noPadding"
    >
      <div className="tableScroll">
        <table className={styles.table}>
          <thead>
            <tr className={`${styles.row} bold`}>
              <td></td>
              <td></td>
              <td>{t("PURCHASES.ORDERS.PRODUCT")}</td>
              <td>{t("PURCHASES.ORDERS.PRICE")}</td>
              <td>{t("PURCHASES.ORDERS.QUANTITY")}</td>
              <td>{t("PURCHASES.ORDERS.ACTIONS")}</td>
            </tr>
          </thead>
          <tbody>
            {(products ?? []).map((p: string, idx: number) => (
              <PurchaseOrderRow product={p} idx={idx} key={idx} />
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

const PurchaseOrderRow = ({ product, idx }: { product: any; idx: number }) => {
  const [productSupplier, setProductSupplier] = useState<any>();
  const { watch, setValue } = useFormContext();
  const products = watch("products");
  const supplierIdWatch = watch("supplierId");
  const [t] = useTranslation("global");
  const { user } = useAuth();
  const useParentSupplier =
    (watch("parentSupplier") ?? "") === "parentSupplier";

  const onDelete = (idx: number) => {
    const productsFiltered = (products ?? []).filter(
      (p: string, i: number) => idx !== i
    );
    setValue("products", productsFiltered);
  };

  useEffect(() => {
    if (product && supplierIdWatch) {
      const ps = getProductSupplierFromProduct(
        product,
        useParentSupplier ? user.id : supplierIdWatch
      );
      setProductSupplier(ps);
      setValue(`products[${idx}].quantity`, product?.quantity);
      setValue(`products[${idx}].unitPrice`, product?.unitPrice);
      setValue(`products[${idx}].productSupplier`, ps);
    } else {
      setProductSupplier({});
      setValue(`products[${idx}].productSupplier`, {});
      setValue(`products[${idx}].quantity`, product?.quantity);
      setValue(`products[${idx}].unitPrice`, product?.unitPrice);
    }
  }, [product, supplierIdWatch, useParentSupplier]);

  return (
    <tr
      className={
        useParentSupplier
          ? ""
          : supplierIdWatch &&
            (!productSupplier?.id ||
              !productSupplier?.status ||
              productSupplier?.availableStock <= 0)
          ? styles.warningProduct
          : ""
      }
    >
      <td className="orangeText">
        {supplierIdWatch &&
          (!productSupplier?.id ||
            !productSupplier?.status ||
            productSupplier?.availableStock <= 0) &&
          !useParentSupplier && (
            <ToolTip
              title={t("PURCHASES.ORDERS.WARNING_PRODUCT")}
              position="Right"
            >
              <MdOutlineWarningAmber />
            </ToolTip>
          )}
      </td>
      <td>
        <ProductImage images={product?.images ?? []} />
      </td>
      <td>{product?.name}</td>
      {!supplierIdWatch ? (
        <>
          <td>--</td>
          <td>--</td>
        </>
      ) : (productSupplier?.status && productSupplier?.availableStock > 0) ||
        useParentSupplier ? (
        <>
          <td className={useParentSupplier ? styles.inputContainer : ""}>
            {useParentSupplier ? (
              <>
                <NumericInput
                  name={`products[${idx}].unitPrice`}
                  label=""
                  required
                  hideControls
                  min={0.1}
                />
              </>
            ) : (
              formatCurrency(productSupplier?.wholesalePrice ?? 0)
            )}
          </td>
          <td className={styles.inputContainer}>
            <NumericInput
              name={`products[${idx}].quantity`}
              label=""
              required
              min={product?.partialUnitsAllowed ? 0.1 : 1}
              max={useParentSupplier ? null : productSupplier.availableStock}
              infoLabel={
                useParentSupplier
                  ? undefined
                  : `${t("PURCHASES.ORDERS.STOCK_AVAILABLE")}: ${
                      productSupplier.availableStock
                    }`
              }
            />
          </td>
        </>
      ) : (
        <td colSpan={2} className="orangeText">
          {t("PURCHASES.ORDERS.NO_SUPPLIER_PRODUCT")}
        </td>
      )}
      <td>
        <AiOutlineDelete className="pointer" onClick={() => onDelete(idx)} />
      </td>
    </tr>
  );
};
