import { isES } from "./core";

export const offersType = [
  { id: "PAY_X_TAKE_Y", name: isES ? "Paga X lleva Y" : "Pay X get Y" },
  {
    id: "DISCOUNT_PERCENTAGE_NON_CUMULATIVE",
    name: isES
      ? "Descuento porcentual no acumulativo"
      : "Non-cumulative percentage discount",
  },
  {
    id: "DISCOUNT_PERCENTAGE_CUMULATIVE",
    name: isES
      ? "Descuento porcentual acumulativo"
      : "Cumulative percentage discount",
  },
  { id: "FREE_SHIPPING", name: isES ? "Envío gratis" : "Free shipping" },
  {
    id: "COINS_PERCENTAGE",
    name: isES
      ? "Cashback porcentual en coins"
      : "Percentage cashback in coins",
  },
];
