import { DEFAULT_FORMAT } from "@/constants/date";
import { Filter } from "@/models/orders";
import { format } from "date-fns";

export const getCommonSearchFilter = (
  startDate: Date | null,
  endDate: Date | null,
  filter: string
) => {
  let search = "";
  if (!!startDate && !!endDate) {
    const formatedStart = format(startDate, DEFAULT_FORMAT);
    const formatedEnd = format(endDate, DEFAULT_FORMAT);
    search = `createdAt>${formatedStart} 00:00:00,createdAt<${formatedEnd} 23:59:59,`;
  }
  switch (filter) {
    case Filter.PENDING:
      search = `${search}supplierStatus:PENDING;REMISSION_GENERATED,`;
      break;
    case Filter.REJECTED:
      search = `${search}supplierStatus:REJECTED,`;
      break;
    case Filter.ATTENDED:
      search = `${search}supplierStatus:APPROVED,`;
      break;
    case Filter.IS_INVOICED:
      search = `${search}supplierStatus:APPROVED,isInvoiced:false,`;
      break;
    case Filter.CANCELLED:
      search = `${search}supplierStatus:CANCELLED,`;
      break;
    case Filter.IN_PROCESS:
      search = `${search}supplierStatus:IN_PROCESS,`;
      break;
    case Filter.REMISSION_GENERATED:
      search = `${search}supplierStatus:REMISSION_GENERATED,`;
      break;
  }

  return search;
};
