import { create } from "zustand";
import { getSupplierRoles, TSupplierRole } from "@/http";

type TUseRoles = {
  getRoles: (...args: any) => void;
  roles: TSupplierRole[];
  isLoading: boolean;
  totalPages: number;
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
};
export const useRoles = create<TUseRoles>((set, get) => ({
  getRoles: async (userId: string) => {
    set(() => ({ isLoading: true }));
    try {
      const { currentPage } = get();
      if (userId) {
        const roles = await getSupplierRoles(userId, {
          page: currentPage,
          size: 10,
        });

        set(() => ({
          roles: roles.content,
          isLoading: false,
          totalPages: roles.totalPages,
        }));
      }
    } catch (error) {
      set(() => ({ roles: [], isLoading: false }));
    }
  },

  roles: [],
  isLoading: false,
  totalPages: 0,
  currentPage: 0,
  setCurrentPage: currentPage => set(() => ({ currentPage })),
}));
