import { TRestListData, TRestListParams } from "../../types/rest";
import { getInstance } from "../../core/axios-gateway";
import { TGlobalOffer } from "../../types/global-offers";

const BASE_PATH = "/api/global-offers";

export const getGlobalOffers = async (params: TRestListParams) => {
  return getInstance()
    .get<TRestListData<TGlobalOffer>>(`${BASE_PATH}/list`, { params })
    .then(res => res.data);
};

export const getGlobalOfferById = async (id: string) => {
  return getInstance()
    .get<TGlobalOffer>(`${BASE_PATH}/id/${id}`)
    .then(res => res.data);
};

export const createGlobalOffer = async (data: Partial<TGlobalOffer>) => {
  return getInstance()
    .post<TGlobalOffer>(BASE_PATH, data)
    .then(res => res.data);
};

export const updateGlobalOffer = async (
  id: string,
  data: Partial<TGlobalOffer>
) => {
  return getInstance()
    .put<TGlobalOffer>(`${BASE_PATH}/id/${id}`, data)
    .then(res => res.data);
};

export const deleteGlobalOffer = async (id: string) => {
  return getInstance()
    .delete(`${BASE_PATH}/${id}`)
    .then(res => res.data);
};
