import { getBrandById, getCategory, getProductById } from "@/http";
import useSWR from "swr";

type TBrandName = {
  id: string;
};
export const BrandName = ({ id }: TBrandName) => {
  const { data: brandDetail } = useSWR(id, getBrandById);
  return <>{brandDetail?.name}</>;
};

export const CategoryName = ({ id }: TBrandName) => {
  const { data } = useSWR(id, getCategory);
  return <>{data?.name}</>;
};

export const ProductName = ({ id }: TBrandName) => {
  const { data } = useSWR(id, getProductById);
  return <>{data?.name}</>;
};

export const ProductNameString = (id: string) => {
  const { data } = useSWR([id, "PRODUCT:ID:STRING"], getProductById);
  return data?.name;
};
