import { usePurchase } from "@/store/purchase";
import { useTranslation } from "react-i18next";
import { MultipleMenu } from "@/components/shared/Menu/MultipleMenu";
import styles from "./styles.module.scss";
import { FaChevronDown } from "react-icons/fa";
import clsx from "clsx";
import { IoFilterSharp } from "react-icons/io5";
import { useEffect, useState } from "react";
import { getPurchaseListFilters } from "@/http";

export const PurchaseTableFilters = () => {
  const [filters, setFilters] = useState<any>({});

  const getFilters = () => {
    getPurchaseListFilters()
      .then(data => setFilters(data))
      .catch(() => setFilters({}));
  };
  useEffect(() => {
    getFilters();
  }, []);

  return (
    <>
      {Object.keys(filters ?? {}).length > 0 && (
        <FiltersMenu filtersList={filters} />
      )}
    </>
  );
};

const FiltersMenu = ({ filtersList = {} }: { filtersList: any }) => {
  const { statusFilters, setStatusFilters } = usePurchase();
  const [t] = useTranslation("orders-page");
  const currentFilters = Object.keys(statusFilters ?? {}).filter(
    f => !!statusFilters[f] && f !== "status"
  );

  return (
    <>
      <MultipleMenu
        className={styles.lineContent}
        label={
          <div className="col-12 noPadding">
            <p className="mdFont flexGap center">
              <IoFilterSharp />
              {t("FILTER.TITLE")}
            </p>
            <div className={clsx(styles.filterContainer, "contentInline")}>
              <span>
                {currentFilters.length === 0
                  ? t("FILTER.SELECT_FILTER")
                  : currentFilters
                      .map(cf => t("FILTER." + cf?.toUpperCase()))
                      .join(", ")}
              </span>
              <span>
                <FaChevronDown size={"0.7rem"} />
              </span>
            </div>
          </div>
        }
        values={Object.keys(filtersList ?? {})
          .filter(f => f !== "status")
          .map(f => ({
            onSelect: (value: string) => setStatusFilters({ key: f, value }),
            title: t(`FILTER.${f.toUpperCase()}`),
            selectedValue: statusFilters[f] ?? "",
            options: filtersList[f]
              .filter((o: string) => !!o)
              .map((o: string) => ({
                label: t(`FILTER.${o?.toUpperCase()}`),
                value: o,
              })),
          }))}
      />
    </>
  );
};
