import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { BiSolidCheckCircle, BiXCircle } from "react-icons/bi";
import { FaChevronUp } from "react-icons/fa";
import { SUBSCRIPTIONS } from "../constants";
import { IFeature, IFeatureDetails, IPackage } from "../types";
import baseStyles from "../../base.module.css";
import styles from "./styles.module.scss";
import { FC, useState } from "react";
import { StarterIcon } from "./icons/Starter";
import { PremiumIcon } from "./icons/Premium";
import { EnterpriseIcon } from "./icons/Enterprise";
import { useSubscriptionResources } from "../usePackageAndFeatures";
import { BsArrowRight } from "react-icons/bs";
import { isProd } from "@/constants/env";

export type TPeriod = "MONTHLY" | "BI_YEARLY" | "YEARLY";

export const Packages = ({
  currentPackage,
  initialPeriod = "YEARLY",
  onChoose,
  country = "co",
}: {
  initialPeriod?: TPeriod;
  currentPackage?: string;
  country?: string;
  onChoose: (pack: IPackage, period: TPeriod) => void;
}) => {
  const { t } = useTranslation(SUBSCRIPTIONS);
  const [period, setPeriod] = useState<TPeriod>(initialPeriod);

  const { data, error } = useSubscriptionResources(country);

  if (error) return <p>ERROR</p>;
  if (!data) return <div>{t("Loading")}...</div>;

  return (
    <section className={clsx(baseStyles.base, styles.wrapper)}>
      <div className={styles.packages}>
        <aside className={styles.element}>
          <header className={styles.header}>
            <div>
              <PeriodSwitcher value={period} onChange={setPeriod} />
            </div>
            <span>{t("Get started")}</span>
            <p>{t("Service details")}</p>
          </header>
          <ul className={styles.feats}>
            {data.features.map(feat => (
              <li key={feat.code} className={styles.feat}>
                {feat.name}
              </li>
            ))}
          </ul>
        </aside>

        {data.packages
          .filter(pack => pack.visible)
          .map(pack => {
            const isActive = currentPackage
              ? currentPackage === pack.code ||
                currentPackage === pack.packageTrial
              : pack.packageTrial === "TRIAL_PREMIUM";
            const trialPackage = data.packages.find(
              p => p.code === pack.packageTrial
            );

            return (
              <article
                key={pack.code}
                className={clsx(
                  styles.element,
                  styles.package,
                  isActive && styles.active
                )}
              >
                <header className={styles.header}>
                  <div className={styles.heading}>
                    <PackageIcon code={pack.code} />
                    <p>{pack.name}</p>
                  </div>
                  <div>
                    <PackagePrice
                      pack={pack}
                      country={country}
                      period={period}
                    />
                  </div>
                  <div>
                    <PackageAction
                      pack={pack}
                      currentPackage={currentPackage}
                      period={period}
                      onChoose={onChoose}
                      packs={data.packages}
                    />
                  </div>
                </header>
                <ul className={styles.feats}>
                  {data.features.map(feat => (
                    <li
                      key={feat.code}
                      className={clsx(styles.feat, {
                        [styles.blueText]: feat.type !== "BOOLEAN",
                      })}
                    >
                      <PackageFeatureValue
                        isActiveTrial={currentPackage === pack.packageTrial}
                        pack={pack}
                        trialPack={trialPackage}
                        feature={feat}
                      />
                    </li>
                  ))}
                </ul>
                {currentPackage && isActive && (
                  <div className={styles.indicator}>
                    <FaChevronUp />
                    <p>{t("This is your current plan")}</p>
                  </div>
                )}
              </article>
            );
          })}
      </div>
      <p className={styles.currencyDisclaimer}>
        *{" "}
        {t(
          "The prices shown are for reference only. The final amount will be charged in US dollars."
        )}
      </p>
    </section>
  );
};

export const PackageIcon = ({ code }: { code: string }) => {
  const PackageIcons: Record<string, FC> = {
    STARTER: StarterIcon,
    PREMIUM: PremiumIcon,
    ENTERPRISE: EnterpriseIcon,
  };
  const Icon = PackageIcons[code];

  if (!Icon) return null;

  return <Icon />;
};

const PeriodSwitcher = ({
  value,
  onChange,
}: {
  value: TPeriod;
  onChange: (p: TPeriod) => void;
}) => {
  const { t } = useTranslation(SUBSCRIPTIONS);
  const items: { name: string; value: TPeriod }[] = [
    { name: t("Monthly"), value: "MONTHLY" },
    { name: t("Biyearly"), value: "BI_YEARLY" },
    { name: t("Yearly"), value: "YEARLY" },
  ];

  return (
    <menu className={styles.periodSwitcher}>
      {items.map(item => (
        <li
          key={item.value}
          className={clsx(value === item.value && styles.active)}
        >
          <button onClick={() => onChange(item.value)}>{item.name}</button>
        </li>
      ))}
    </menu>
  );
};

const PackageFeatureValue = ({
  pack,
  trialPack,
  feature,
}: {
  pack: IPackage;
  trialPack?: IPackage;
  feature: IFeature;
  isActiveTrial?: boolean;
}) => {
  const [t] = useTranslation(SUBSCRIPTIONS);

  const value = (pack: IPackage): IFeatureDetails | null => {
    return pack.features[feature.code];
  };

  const render = (pack: IPackage) => {
    const featureValue = value(pack);

    if (feature.type === "BOOLEAN") {
      return featureValue?.has ? <BiSolidCheckCircle /> : <BiXCircle />;
    }

    if (feature.type === "NUMERIC") {
      return (
        <>{featureValue?.unlimited ? t("Unlimited") : featureValue?.value}</>
      );
    }

    return <>{featureValue?.value}</>;
  };

  if (feature.code === "INTEGRATIONS") {
    if (pack.code === "ENTERPRISE") return <>{t("Free")}</>;
    if (pack.code === "PREMIUM") return <>{t("Paid")}</>;
  }

  const hasDiff = (p1: IPackage, p2: IPackage) =>
    value(p1)?.has !== value(p2)?.has ||
    value(p1)?.value !== value(p2)?.value ||
    value(p1)?.unlimited !== value(p2)?.unlimited;

  if (trialPack && hasDiff(pack, trialPack)) {
    return (
      <span>
        {feature?.type !== "BOOLEAN" && (
          <>
            {render(trialPack)} <BsArrowRight />{" "}
          </>
        )}
        {render(pack)}
      </span>
    );
  }

  return render(pack);
};

const PackagePrice = ({
  pack,
  country,
  period,
}: {
  pack: IPackage;
  country: string;
  period: TPeriod;
}) => {
  const { t, i18n } = useTranslation(SUBSCRIPTIONS);

  if (!pack.purchasable) {
    return <>{t("Let's talk")}</>;
  }

  const priceAttr = {
    MONTHLY: "monthly",
    BI_YEARLY: "biyearly",
    YEARLY: "yearly",
  } as const;

  const discountAttr =
    period === "BI_YEARLY"
      ? "biyearlyDiscount"
      : period === "YEARLY"
      ? "yearlyDiscount"
      : null;

  const price = pack[priceAttr[period]];

  if (!price) {
    return <span className={styles.packagePrice}>{t("Always free")}</span>;
  }

  return (
    <>
      <span className={styles.packagePrice}>
        {new Intl.NumberFormat(
          `${i18n.resolvedLanguage}-${country.toUpperCase()}`,
          {
            style: "currency",
            currency: pack.currency,
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }
        ).format(price)}
      </span>
      <br />
      <span className={styles.packagePriceDiscount}>
        ({discountAttr ? pack[discountAttr] : 0}% off)
      </span>
    </>
  );
};

const PackageAction = ({
  pack,
  currentPackage,
  period,
  onChoose,
  packs,
}: {
  pack: IPackage;
  currentPackage?: string;
  period: TPeriod;
  onChoose: (pack: IPackage, period: TPeriod) => void;
  packs: IPackage[];
}) => {
  const { t, i18n } = useTranslation(SUBSCRIPTIONS);
  const isPublic = !currentPackage;

  if (!pack.purchasable) {
    return (
      <button
        className={clsx(styles.button, styles.outline)}
        onClick={() => {
          window.open(
            "https://wa.me/573245221078?text=" +
              t("Hello, I am interested in the Enterprise plan"),
            "_blank"
          );
        }}
      >
        {t("Contact us")}
      </button>
    );
  }

  if (isPublic) {
    return pack.packageTrial ? (
      <button
        className={styles.button}
        onClick={() => {
          if (
            ["//portal.", "//localhost"].some(term =>
              location.href.includes(term)
            )
          ) {
            const trialPack = packs.find(p => p.code === pack.packageTrial);
            if (trialPack) onChoose(trialPack, period);
          } else {
            window.open(
              `https://portal${isProd ? "" : ".dev"}.seeri.co/signup?lang=${
                i18n.resolvedLanguage
              }`,
              "_blank"
            );
          }
        }}
      >
        {t("Start free trial")}
      </button>
    ) : null;
  }

  if (pack.notAvailableWhen === currentPackage) {
    return null;
  }

  if (currentPackage === pack.code) {
    if (pack.addonnable) {
      return (
        <button
          onClick={() => onChoose(pack, period)}
          className={clsx(styles.button)}
        >
          {t("Modify plan")}
        </button>
      );
    }

    return (
      <button className={clsx(styles.button)} disabled>
        {t("Current plan")}
      </button>
    );
  }

  if (currentPackage === pack.packageTrial) {
    return (
      <button onClick={() => onChoose(pack, period)} className={styles.button}>
        {t("Start full plan")}
      </button>
    );
  }

  const isFree = !pack.monthly;

  return (
    <button
      className={clsx(styles.button, isFree && styles.outline)}
      onClick={() => onChoose(pack, period)}
    >
      {!isFree ? t("Start plan") : t("Downgrade")}
    </button>
  );
};
