import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "../shared/Card/Card";
import { LuFilePlus, LuFileSpreadsheet } from "react-icons/lu";
import styles from "./styles.module.scss";
import { BulkDownloadTemplate } from "../products/ProductsGroupActions/BulkImport/shared/BulkDownload";

export const ProductBulkTemplates = () => {
  const [t] = useTranslation("products-page");
  return (
    <div>
      <p className="bold">1.- {t("DOWNLOAD_TEMPLATES")}</p>
      <div className="spacer" />
      <div className="flexGap flexSmColumn">
        <div className="col-6 noPadding col-sm-12">
          <ProductTemplateCard
            title={t("EDIT_EXISTING_PRODUCTS")}
            icon={<LuFileSpreadsheet />}
            description={t("CURRENT_INVENTORY")}
            button={<BulkDownloadTemplate mode="UPDATE" />}
            helperText={t("HELPER_CURRENT_INVENTORY")}
          />
        </div>
        <div className="col-6 noPadding col-sm-12">
          <ProductTemplateCard
            title={t("CREATE_NEW_PRODUCTS")}
            icon={<LuFilePlus />}
            description={t("EMPTY_TEMPLATE")}
            button={<BulkDownloadTemplate mode="CREATE" />}
            helperText={t("HELPER_EMPTY_TEMPLATE")}
          />
        </div>
      </div>
      <div className="spacer" />
      <div className="flexGap flexSmColumn">
        <div className="col-6 noPadding col-sm-12">
          <ProductTemplateCard
            title={t("ASSIGN_EXISTING_PRODUCTS")}
            icon={<LuFileSpreadsheet />}
            description={t("CURRENT_BRAND_PRODUCTS")}
            button={<BulkDownloadTemplate mode="ASSIGN" />}
            helperText={t("HELPER_CURRENT_BRAND_PRODUCTS")}
            buttonRow
          />
        </div>
        <div className="col-6 noPadding col-sm-12" />
      </div>
    </div>
  );
};

const ProductTemplateCard = ({
  title,
  icon,
  description,
  button,
  buttonRow,
  helperText,
}: {
  title: ReactNode;
  icon: ReactNode;
  description: ReactNode;
  button: ReactNode;
  buttonRow?: boolean;
  helperText?: ReactNode;
}) => (
  <Card allowOverflow>
    <p className="bold">{title}</p>
    <p className="mdFont">{helperText}</p>
    <div className="spacer" />
    <div className="spacer" />
    <div className="contentInline flexGap flexWrap">
      <div className="flexGap centerVAlign">
        <div className={styles.templateIcon}>{icon}</div>
        <div>{description}</div>
      </div>
      {!buttonRow && <>{button}</>}
    </div>
    {buttonRow && (
      <>
        <div className="spacer" />
        {button}
      </>
    )}
    <div className="spacer" />
  </Card>
);
