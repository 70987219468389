import { TRestListData } from "../../types/rest";
import { getInstance } from "../../core/axios-gateway";
import { TRestListParamsSuppliers, TSupplierProduct } from "../../types/supplier-product";

const BASE_PATH = "/api/suppliers-products";

export const getSupplierProductsGroups = async (supplierId: string, params: TRestListParamsSuppliers) => {
  return getInstance()
    .get<TRestListData<TSupplierProduct>>(`${BASE_PATH}/${supplierId}/groups`, { params })
    .then((res) => res.data);
};

export const createSupplierProductsGroup = async (supplierId: string, data: Partial<TSupplierProduct>) => {
  return getInstance()
    .post<TSupplierProduct>(`${BASE_PATH}/${supplierId}/groups`, data)
    .then((res) => res.data);
};

export const getSupplierProductsGroupById = async (supplierId: string, groupId: string, includeComments = false) => {
  return getInstance()
    .get<TSupplierProduct>(`${BASE_PATH}/${supplierId}/groups/${groupId}`,{ params: { includeComments } })
    .then((res) => res.data);
};

export const deleteSupplierProductsGroupById = async (supplierId: string, groupId: string) => {
  return getInstance()
    .delete(`${BASE_PATH}/${supplierId}/groups/${groupId}`)
    .then((res) => res.data);
};

export const updateSupplierProductsGroupById = async (
  supplierId: string,
  groupId: string,
  data: Partial<TSupplierProduct>
) => {
  return getInstance()
    .put<TSupplierProduct>(`${BASE_PATH}/${supplierId}/groups/${groupId}`, data)
    .then((res) => res.data);
};

export const getSupplierProductsByGroupId = async (
  supplierId: string,
  groupId: string,
  params: TRestListParamsSuppliers
) => {
  return getInstance()
    .get<TRestListData<TSupplierProduct>>(`${BASE_PATH}/${supplierId}/groups/${groupId}/products`, { params })
    .then((res) => res.data);
};

export const createSupplierProductByGroupId = async (
  supplierId: string,
  groupId: string,
  data: Partial<TSupplierProduct>
) => {
  return getInstance()
    .post<TSupplierProduct>(`${BASE_PATH}/${supplierId}/groups/${groupId}/products`, data)
    .then((res) => res.data);
};

export const getSupplierProductByGroupIdAndProductId = async (
  supplierId: string,
  groupId: string,
  productId: string,
  includeComments = false
) => {
  return getInstance()
    .get<TSupplierProduct>(`${BASE_PATH}/${supplierId}/groups/${groupId}/products/${productId}`, { params: { includeComments } })
    .then((res) => res.data);
};

export const deleteSupplierProductByGroupIdAndProductId = async (
  supplierId: string,
  groupId: string,
  productId: string
) => {
  return getInstance()
    .delete<TSupplierProduct>(`${BASE_PATH}/${supplierId}/groups/${groupId}/products/${productId}`)
    .then((res) => res.data);
};

export const updateSupplierProductByGroupIdAndProductId = async (
  supplierId: string,
  groupId: string,
  productId: string,
  data: Partial<TSupplierProduct>
) => {
  return getInstance()
    .put<TSupplierProduct>(`${BASE_PATH}/${supplierId}/groups/${groupId}/products/${productId}`, data)
    .then((res) => res.data);
};

export const getAllSupplierProducts = async (params: TRestListParamsSuppliers) => {
  return getInstance()
    .get<TRestListData<TSupplierProduct>>(BASE_PATH, { params })
    .then((res) => res.data);
};

export const approveSupplierProduct = async (supplierId: string, productId: string, data: any) => {
  return getInstance()
    .post<TRestListData<TSupplierProduct>>(`${BASE_PATH}/${supplierId}/groups/${productId}/approve`, data)
    .then((res) => res.data);
};

export const approveSupplierVariant = async (supplierId: string, productId: string, variantId: string) => {
  return getInstance()
    .post<TRestListData<TSupplierProduct>>(`${BASE_PATH}/${supplierId}/groups/${productId}/products/${variantId}/approve`, {})
    .then((res) => res.data);
};

export const toogleSupplierProductVisibility = async (supplierId: string, productId: string) => {
  return getInstance()
    .post<TRestListData<TSupplierProduct>>(`${BASE_PATH}/${supplierId}/groups/${productId}/toggle-visibility`, {})
    .then((res) => res.data);
};

export const toogleSupplierVariantVisibility = async (supplierId: string, productId: string, variantId: string) => {
  return getInstance()
    .post<TRestListData<TSupplierProduct>>(`${BASE_PATH}/${supplierId}/groups/${productId}/products/${variantId}/toggle-visibility`, {})
    .then((res) => res.data);
};