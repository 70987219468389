import { useState } from "react";
import { TSupplierIntegration, getIntegration } from "@/http";
import useSWR from "swr";
import { Modal } from "@/components/shared/Modal/Modal";
import { IntegrationCredentialForm } from "./IntegrationCredentialForm";
import { TabItem, Tabs } from "@/components/clients/ui/Tab/Tab";
import { integrationTypes } from "./constants";
import { IntegrationCredentialSynchronizations } from "./IntegrationCredentialSynchronizations";
import { IntegrationParametersForm } from "./IntegrationParametersForm";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styles from "./styles.module.scss";

type IntegrationModalProps = {
  supplierIntegration: TSupplierIntegration;
};

export function IntegrationModal({
  supplierIntegration,
}: IntegrationModalProps) {
  const [open, setOpen] = useState(false);
  const [t] = useTranslation("global");
  const { data: integration, error } = useSWR(
    open ? supplierIntegration.id : null,
    getIntegration
  );

  const type = integrationTypes[supplierIntegration.type];

  return (
    <>
      <button className="primary" onClick={() => setOpen(true)}>
        {t("CONFIGURE")}
      </button>
      <Modal isOpen={open} onClose={() => setOpen(false)}>
        <div className={styles.integrationModalContent}>
          <h3 className="bolder">
            <div className="contentInline">
              <div>
                {supplierIntegration.imageUrl && (
                  <img
                    src={supplierIntegration.imageUrl}
                    alt={supplierIntegration.name}
                    className={styles.logo}
                  />
                )}
              </div>
              <div>
                <br />
                {supplierIntegration.credentialId ? (
                  <div className={clsx(styles.statusChip, styles.connected)}>
                    {t("INTEGRATIONS.CONNECTED")}
                  </div>
                ) : (
                  <div className={clsx(styles.statusChip, styles.disconnected)}>
                    {t("INTEGRATIONS.NO_CONNECTED")}
                  </div>
                )}
              </div>
            </div>
            <p>{supplierIntegration.name}</p>
          </h3>
          <br />
          <Tabs panelsClassName={styles.tabs}>
            <TabItem label={t("INTEGRATIONS.TAB_DESCRIPTION")}>
              <p>{supplierIntegration.description}</p>
              <br />
              <div className={styles.type}>
                {type.icon} {t(type.text)}
              </div>
            </TabItem>
            <TabItem label={t("INTEGRATIONS.TAB_CONNECTION")}>
              {error ? (
                t("INTEGRATIONS.FORM_ERROR")
              ) : !integration ? (
                t("INTEGRATIONS.LOADING_FIELDS")
              ) : (
                <IntegrationCredentialForm
                  integration={integration}
                  credentialId={supplierIntegration.credentialId}
                />
              )}
            </TabItem>
            {!!supplierIntegration.credentialId && integration && (
              <>
                {integration.parameters.length > 0 && (
                  <TabItem label={t("INTEGRATIONS.PARAMS")}>
                    <IntegrationParametersForm
                      credentialId={supplierIntegration.credentialId}
                      integration={integration}
                    />
                  </TabItem>
                )}

                <TabItem label={t("INTEGRATIONS.SYNC_TAB")}>
                  <IntegrationCredentialSynchronizations
                    credentialId={supplierIntegration.credentialId}
                  />
                </TabItem>
              </>
            )}
          </Tabs>
        </div>
      </Modal>
    </>
  );
}
