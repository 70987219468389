const charMap: { [key: string]: string } = {
  à: "a",
  á: "a",
  ä: "a",
  â: "a",
  è: "e",
  é: "e",
  ë: "e",
  ê: "e",
  ì: "i",
  í: "i",
  ï: "i",
  î: "i",
  ò: "o",
  ó: "o",
  ö: "o",
  ô: "o",
  ù: "u",
  ú: "u",
  ü: "u",
  û: "u",
  ñ: "n",
  ç: "c",
  "·": "-",
  "/": "-",
  _: "-",
  ",": "-",
  ":": "-",
};

export const generateSlug = (str: string) => {
  str = generateSimpleSlug(str);

  return str.length
    ? `${str}-${Math.floor(Math.random() * (999 - 100 + 1) + 100)}`
    : "";
};

export const generateSimpleSlug = (str: string) => {
  str = str
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, match => charMap[match] || "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");

  return str;
};

export const generateVariantName = (productName = "", options: string[]) => {
  return `${productName}${options
    .filter(option => option !== "newOptionToCreate")
    .map(option => (option ? ` ${option}` : ""))
    .toString()
    .replaceAll(",", "")}`;
};
