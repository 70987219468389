import { LockedFeaturePlaceholder } from "@/components/subscription/LockedFeaturePlaceholder";
import { TieredDiscountModal } from "@/components/tiered-discounts/TieredDiscountModal";
import { TieredDiscountsList } from "@/components/tiered-discounts/TieredDiscountsList";
import { FEAT_DISCOUNTS } from "@/constants/features";
import { useSubscription } from "@/hooks/useSubscription";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const TieredDiscountsPage = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");
  const { hasFeature } = useSubscription();

  useEffect(() => {
    setTitle(t("TIERED_DISCOUNTS.LIST.TITLE"));
  }, [t]);

  if (!hasFeature(FEAT_DISCOUNTS)) {
    return <LockedFeaturePlaceholder />;
  }

  return (
    <>
      <TieredDiscountModal />
      <TieredDiscountsList />
    </>
  );
};
