import { Modal } from "@/components/shared/Modal/Modal";
import { useSubscription } from "@/hooks/useSubscription";
import { Packages, TPeriod } from "@/lib/subscriptions/components/Packages";
import { IPackage } from "@/lib/subscriptions/types";
import { useAuth } from "@/store/auth";
import { useHeaderTitle } from "@/store/ui";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SubscriptionResult } from "@/components/subscription/SubscriptionResult";
import { PackageAddons } from "@/components/subscription/PackageAddons";
import { startSubscription } from "@/http/resources/subscription";
import { useCountries } from "@/hooks/useCountry";

export const SubscriptionPage = () => {
  const { setTitle } = useHeaderTitle(s => s);
  const [t] = useTranslation("global");
  const supplier = useAuth(s => s.user);
  const countries = useCountries();
  const country = countries?.find(
    c => c.prefix.toLocaleLowerCase() === supplier.countryCode
  );

  const { currentPackage, reloadSubscription } = useSubscription();
  const [addonsOpen, setAddonsOpen] = useState(false);
  const [chosenPack, setChosenPack] = useState<IPackage>();
  const [chosenPeriod, setChosenPeriod] = useState<TPeriod>("YEARLY");

  useEffect(() => {
    setTitle(t("SUBSCRIPTION.TITLE"));
  }, [t]);

  const preStartPlan = (pack: IPackage, period: TPeriod) => {
    if (pack.addonnable) {
      setChosenPeriod(period);
      setChosenPack(pack);
      setAddonsOpen(true);
    } else {
      startPlan(pack, period, {});
    }
  };

  const startPlan = async (
    pack: IPackage,
    period: TPeriod,
    addons: Record<string, number>
  ) => {
    const data = await startSubscription(supplier.id, {
      code: pack.code,
      period,
      addons,
    });

    if (data.url) {
      location.href = data.url;
    } else {
      reloadSubscription();
    }
  };

  return (
    <div>
      <Packages
        currentPackage={currentPackage?.code}
        initialPeriod={chosenPeriod}
        onChoose={preStartPlan}
        country={country?.prefix.toLowerCase() ?? "us"}
      />
      <Modal isOpen={addonsOpen} onClose={() => setAddonsOpen(false)}>
        {chosenPack && (
          <PackageAddons
            chosenPack={chosenPack}
            chosenPeriod={chosenPeriod}
            onDone={addons => {
              setAddonsOpen(false);
              startPlan(chosenPack, chosenPeriod, addons);
            }}
          />
        )}
      </Modal>
      <SubscriptionResult />
    </div>
  );
};
