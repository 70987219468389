import { getInstance } from "../../core/axios-gateway";
import { TRestListData, TRestListParams } from "../../types/rest";

const BASE_PATH = "/api/suppliers/payment";

export const getSupplierPayments = async (
  supplierId: string,
  params: TRestListParams
) => {
  return getInstance()
    .get<TRestListData<any>>(`${BASE_PATH}/list/${supplierId}`, { params })
    .then(res => res.data);
};

export const getSupplierPaymentById = async (paymentId: string) => {
  return getInstance()
    .get<any>(`${BASE_PATH}/${paymentId}`)
    .then(res => res.data);
};

export const downloadSupplierPaymentById = async (paymentId: string) => {
  return getInstance()
    .get<any>(`api/reports/supplier-payment/payment/${paymentId}`, {
      responseType: "blob",
    })
    .then(res => res.data);
};

export const getSupplierPendingTransactions = async (
  supplierId: string,
  params: TRestListParams
) => {
  return getInstance()
    .get<TRestListData<any>>(`api/suppliers/transaction/list/${supplierId}`, {
      params,
    })
    .then(res => res.data);
};
