import { getInstance } from "../../core/axios-gateway";
import { TRestListData, TRestListParams } from "../../types/rest";
import { TSupplierBox } from "../../types/supplier-box";

const BASE_PATH = "/api/suppliers";

export const getSupplierBoxes = async (supplierId: string, params: TRestListParams) => {
  return getInstance()
    .get<TRestListData<TSupplierBox>>(`${BASE_PATH}/${supplierId}/boxes`, { params })
    .then((res) => res.data);
};

export const getSupplierBoxById = async (supplierId: string, boxId: string) => {
  return getInstance()
    .get<TSupplierBox>(`${BASE_PATH}/${supplierId}/boxes/${boxId}`)
    .then((res) => res.data);
};

export const createSupplierBox = async (supplierId: string, data: Partial<TSupplierBox>) => {
  return getInstance()
    .post<TSupplierBox>(`${BASE_PATH}/${supplierId}/boxes`, data)
    .then((res) => res.data);
};

export const updateSupplierBox = async (supplierId: string, boxId: string, data: Partial<TSupplierBox>) => {
  return getInstance()
    .put<TSupplierBox>(`${BASE_PATH}/${supplierId}/boxes/${boxId}`, data)
    .then((res) => res.data);
};

export const deleteSupplierBox = async (supplierId: string, boxId: string) => {
  return getInstance()
    .delete(`${BASE_PATH}/${supplierId}/boxes/${boxId}`)
    .then((res) => res.data);
};
