import { InputV2 } from "@/components/shared/Input/InputV2";
import { Modal } from "@/components/shared/Modal/Modal";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { changeUserPassword } from "@/http";
import { useAuth } from "@/store/auth";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

type TFormChangePassword = { password: string; confirmPassword: string };
export const ChangePassword = () => {
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm<TFormChangePassword>();
  const passwordWatch = methods.watch("password");
  const { commercial, user, isCommercial } = useAuth();
  const userId = isCommercial ? commercial?.id : user.id;

  const handleSubmit = async ({ password }: TFormChangePassword) => {
    try {
      setIsLoading(true);
      await changeUserPassword(userId ?? "", password);
      showToastSuccess(t("PASSWORD_UPDATED"));
      setIsOpen(false);
    } catch (e) {
      showToastError({
        e,
        message: t("PASSWORD_UPDATE_FAIL"),
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <button
        className="primary"
        onClick={() => {
          setIsOpen(true);
          methods.reset();
        }}
      >
        {t("CHANGE_PASSWORD")}
      </button>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size={"sm"}>
        <p className="bolder">{t("CHANGE_PASSWORD")}</p>
        <div className="divider" />
        <FormProvider {...methods}>
          <InputV2
            name="password"
            label={t("NEW_PASS")}
            required
            type="password"
          />
          <InputV2
            name="confirmPassword"
            label={t("CONFIRM_PASS")}
            validate={(cp: string) => cp === passwordWatch}
            validMessage={t("NO_MATCH_PASS")}
            required
            type="password"
          />
        </FormProvider>

        <div className="rigthAlign">
          <button disabled={isLoading} onClick={() => setIsOpen(false)}>
            {t("CANCEL")}
          </button>
          <button
            disabled={isLoading}
            className="primary lg"
            onClick={methods.handleSubmit(handleSubmit)}
          >
            {t("CHANGE_PASSWORD")}
          </button>
        </div>
      </Modal>
    </div>
  );
};
