import { View, StyleSheet, Text } from "@react-pdf/renderer";
import { poweredLabel } from "./Styles";

const styles = StyleSheet.create(poweredLabel);

const PoweredLabel = () => (
  <View style={styles.container}>
    <Text>powered by Seeri</Text>
  </View>
);

export default PoweredLabel;
