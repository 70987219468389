import { FC, ReactNode } from "react";
import styles from "./Card.module.scss";

type Props = {
  borderTop?: boolean;
  contentBottom?: ReactNode | boolean;
  children?: ReactNode;
  className?: string;
  allowOverflow?: boolean;
};
export const Card: FC<Props> = ({
  borderTop,
  contentBottom,
  children,
  className = "",
  allowOverflow,
}) => {
  return (
    <div
      className={styles.card}
      style={allowOverflow ? { overflow: "visible" } : {}}
    >
      {borderTop && <div className={styles.borderTop} />}
      <div className={`${styles.content} ${className}`}>{children}</div>
      {contentBottom && (
        <div className={styles.contentBottom}>{contentBottom}</div>
      )}
    </div>
  );
};
