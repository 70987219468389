import { Modal } from "@/components/shared/Modal/Modal";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { deleteSupplierOrderById } from "@/http";
import { useAuth } from "@/store/auth";
import { hasPermission } from "@/utils/validations/permissions";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

export const DeleteDispatchOrder = ({
  button,
  orderNumber,
  orderId,
  onDone,
}: {
  button: ReactNode;
  orderNumber: string;
  orderId: string;
  onDone?: () => void;
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isCommercial, role } = useAuth();
  const [t] = useTranslation("orders-page");
  const [tGlobal] = useTranslation("global");

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await deleteSupplierOrderById(orderId);
      setModalOpen(false);
      onDone?.();
      showToastSuccess(t("ORDER_DELETED"));
    } catch (error) {
      showToastError({ message: tGlobal("ERROR_SOME_BAD"), error });
    } finally {
      setIsLoading(false);
    }
  };

  if (!hasPermission(isCommercial, role, ["action.orders.delete"])) {
    return null;
  }

  return (
    <>
      <span onClick={() => setModalOpen(true)}>{button}</span>
      <Modal isOpen={isModalOpen} size="sm" onClose={() => setModalOpen(false)}>
        <div className={styles.modal}>
          <h6 className="bold">{t("DELETE_ORDER")}</h6>
          <div className={"divider"} />
          <div className={styles.content}>
            <p>{`${t("SURE_DELETE_ORDER")} ${orderNumber}?`}</p>
            <div className={"spacer"} />
            <div className={"spacer"} />
          </div>
          <div className={"divider"} />
          <div className="rigthAlign">
            <button
              className="primary lg"
              disabled={isLoading}
              onClick={onSubmit}
            >
              {t("IM_SURE")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
