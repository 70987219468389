import { PurchaseOrderForm } from "@/components/purchases/PurchaseOrders/Form/PurchaseOrderForm";
import { LockedFeaturePlaceholder } from "@/components/subscription/LockedFeaturePlaceholder";
import { FEAT_SELL_IN } from "@/constants/features";
import { useSubscription } from "@/hooks/useSubscription";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const PurchasesOrderCreatePage = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");
  const { hasFeature } = useSubscription();

  useEffect(() => {
    setTitle(t("PURCHASES.ORDERS.TITLE"), "/purchases");
  }, [t]);

  if (!hasFeature(FEAT_SELL_IN)) {
    return <LockedFeaturePlaceholder />;
  }

  return (
    <>
      <PurchaseOrderForm />
    </>
  );
};
