import { TSellerAddress } from "@/http";
import clsx from "clsx";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineAddLocationAlt } from "react-icons/md";
import { Modal } from "../shared/Modal/Modal";
import { AddressForm } from "./AddressForm";
import styles from "./styles.module.scss";

type AddressModalProps = {
  clientId: string;
  address?: TSellerAddress;
  onDone?: (address: TSellerAddress) => void;
  iconButton?: boolean;
};

export const AddressModal: FC<AddressModalProps> = ({
  clientId,
  address,
  onDone,
  iconButton,
}) => {
  const [open, setOpen] = useState(false);
  const [t] = useTranslation("global");

  return (
    <>
      {iconButton ? (
        <span className="pointer circleButton" onClick={() => setOpen(true)}>
          <MdOutlineAddLocationAlt />
        </span>
      ) : (
        <button
          type="button"
          onClick={() => setOpen(true)}
          className={clsx("primary", styles.modalTriggerButton)}
        >
          {address ? t("EDIT") : t("CLIENTS.NEW_ADDRESS")}
        </button>
      )}
      <Modal isOpen={open} onClose={() => setOpen(false)}>
        {open && (
          <div className="leftAlign marginTop1">
            <h6 className="bolder">
              {t(address ? "CLIENTS.EDIT_ADDRESS" : "CLIENTS.NEW_ADDRESS")}
            </h6>
            <div className="divider" />
            <div className="spacer" />
            <AddressForm
              clientId={clientId}
              address={address}
              onDone={address => {
                onDone?.(address);
                setOpen(false);
              }}
            />
          </div>
        )}
      </Modal>
    </>
  );
};
