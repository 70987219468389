import clsx from "clsx";
import { ReactNode, useEffect } from "react";
import { createPortal } from "react-dom";
import styles from "./styles.module.scss";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: ReactNode;
  children: ReactNode;
};
export const ModalSide = ({ isOpen, children, onClose, title }: Props) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("noScroll");
    } else {
      document.body.classList.remove("noScroll");
    }

    return () => {
      document.body.classList.remove("noScroll");
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return createPortal(
    <>
      <div
        className={`${styles.sideModal} ${isOpen ? styles.open : ""}`}
        onClick={() => onClose()}
      >
        <div
          className={styles.content}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <div className={clsx(styles.title, "bold contentInline")}>
            {title}
            <span className="pointer" onClick={() => onClose()}>
              X
            </span>
          </div>
          <div>{children}</div>
        </div>
      </div>
    </>,
    document.body
  );
};
