import { useTranslation } from "react-i18next";
import { SUBSCRIPTIONS } from "./constants";
import { ISubscriptionResources } from "./types";
import useSWR from "swr";
import { API_URL } from "../constants";

export const useSubscriptionResources = (country = "co") => {
  const { i18n } = useTranslation(SUBSCRIPTIONS);
  return useSWR<ISubscriptionResources>(
    [`${API_URL}/api/subscriptions/packages`, country, i18n.resolvedLanguage],
    url =>
      fetch(url, {
        headers: {
          "x-user-country": country,
          "x-user-language": i18n.resolvedLanguage as string,
          "Accept-Language": i18n.resolvedLanguage as string,
        },
      }).then(async res => {
        if (!res.ok) throw new Error("Server error");
        return res.json();
      })
  );
};
