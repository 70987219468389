import ProductSKU from "@/components/order-details/ProductsSKU";
import { ProductName } from "@/components/shared/Details";
import { isUSA } from "@/constants/core";
import { DATE_HOUR_SLASH, USA_DATE_HOUR_SLASH } from "@/constants/date";
import {
  renderingPaymentReference,
  renderingPaymentType,
} from "@/constants/payments";
import { PaginationSelect } from "@/models/orders";
import { useAuth } from "@/store/auth";
import { usePayments } from "@/store/payments";
import { formatCurrency } from "@/utils/currency";
import { validateTimezoneDate } from "@/utils/date";
import { format } from "date-fns";
import { get } from "lodash-es";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Paginate from "react-paginate";
import styles from "./styles.module.scss";

export const PendingTransactionsList = () => {
  const { getPendingTransactions, pendingTransactios, isLoadingTransactions } =
    usePayments();
  const { user } = useAuth();
  const [t] = useTranslation("global");

  useEffect(() => {
    getPendingTransactions(user.id);
  }, []);

  if (!pendingTransactios.length && !isLoadingTransactions) {
    return <h6 className="bolder">{t("PAYMENTS.NO_TRANSACTIONS")}</h6>;
  }
  return (
    <>
      <div>
        <table className={styles.table}>
          <thead>
            <tr className={`bold ${styles.thead} ${styles.row}`}>
              <td>{t("PAYMENTS.CREATION")}</td>
              <td>{t("PAYMENTS.TYPE")}</td>
              <td>{t("PAYMENTS.REFERENCE")}</td>
              <td>{t("PAYMENTS.SKU")}</td>
              <td>{t("PAYMENTS.SELL_PRICE")}</td>
              <td>{t("PAYMENTS.SUPPLIER_PRICE")}</td>
              <td>{t("PAYMENTS.QUANTITY")}</td>
              <td>{t("PAYMENTS.SUBTOTAL")}</td>
            </tr>
          </thead>
          <tbody>
            {pendingTransactios.map((payment, index: number) => (
              <tr key={index} className={styles.row}>
                <td>
                  {payment?.createdAt
                    ? format(
                        new Date(validateTimezoneDate(payment.createdAt)),
                        isUSA ? USA_DATE_HOUR_SLASH : DATE_HOUR_SLASH
                      )
                    : "-"}
                </td>
                <td>{get(renderingPaymentType, payment.type, "")}</td>
                <td>
                  {payment?.type === "PRODUCT_SELL" ? (
                    <ProductName id={payment.reference} />
                  ) : (
                    get(
                      renderingPaymentReference,
                      payment.reference,
                      payment.reference
                    )
                  )}
                </td>
                <td>
                  {payment?.type === "PRODUCT_SELL" ? (
                    <ProductSKU
                      userId={payment.supplierId}
                      id={payment.reference}
                    />
                  ) : (
                    "-"
                  )}
                </td>
                <td>
                  {payment?.data?.sellPrice
                    ? formatCurrency(Number(payment?.data?.sellPrice))
                    : "-"}
                </td>
                <td>
                  {payment?.type === "PRODUCT_SELL"
                    ? formatCurrency(
                        Number(payment?.value / (payment?.data?.quantity ?? 1))
                      )
                    : "-"}
                </td>
                <td>{payment?.data?.quantity ?? "-"}</td>
                <td className={Number(payment?.value) < 0 ? "redText" : ""}>
                  {formatCurrency(Number(payment?.value))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <PendingTransactionsPagination />
    </>
  );
};

const PendingTransactionsPagination = () => {
  const {
    setCurrentPageTransaction,
    currentPageTransaction,
    totalPagesTransactions,
  } = usePayments();

  const handlePageChange = (selectedObject: PaginationSelect) => {
    setCurrentPageTransaction(selectedObject.selected);
  };

  if (totalPagesTransactions < 2) {
    return null;
  }

  return (
    <div className={styles.paginate}>
      <Paginate
        forcePage={currentPageTransaction}
        pageCount={totalPagesTransactions}
        previousLabel={"←"}
        nextLabel={"→"}
        marginPagesDisplayed={3}
        onPageChange={handlePageChange}
        containerClassName={styles.pagination}
        previousLinkClassName={styles.previous}
        nextLinkClassName={styles.next}
        disabledClassName={styles.disabled}
        activeClassName={styles.selected}
        breakClassName={styles.break}
      />
    </div>
  );
};
