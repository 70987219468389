import { TProduct, searchProducts } from "@/http";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/store/auth";
import ProductImage from "@/components/layout/ProductImage";
import { formatCurrency } from "@/utils/currency";
import { InputV2 } from "@/components/shared/Input/InputV2";
import styles from "./styles.module.scss";
import { useFormContext } from "react-hook-form";
import { ProductNameChip } from "./ProductNameChip";

import { BrandName } from "@/components/shared/Details";
import { getProductSupplierFromProduct } from "@/utils/products";

type TProductInput = {
  name: string;
  products: string[];
  setProducts: (products: string[]) => void;
  onDelete: (idx: number) => void;
  includeGift?: boolean;
};

export const ProductInput = ({
  name,
  products,
  setProducts,
  onDelete,
}: TProductInput) => {
  const [options, setOptions] = useState<TProduct[]>([]);
  const [showOptions, setShowOptions] = useState(false);
  const debounceRefProduct = useRef<any>();
  const optionsRef = useRef<HTMLDivElement>(null);
  const [t] = useTranslation("purchases");
  const { user } = useAuth();
  const { setValue } = useFormContext();

  const onProductNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (debounceRefProduct.current) {
      clearTimeout(debounceRefProduct.current);
    }

    debounceRefProduct.current = setTimeout(() => {
      getFilterProducts(event.target.value);
    }, 250);
  };

  const getFilterProducts = async (name: string) => {
    const { content } = (await searchProducts({
      data: { text: name, supplierId: user.id },
      params: {
        sort: "name,asc",
        page: 0,
        size: 10,
      },
    })) as any;
    setOptions(content ?? []);
    setShowOptions(true);
  };

  const setProduct = ({ id }: TProduct) => {
    setShowOptions(false);
    const parsedValuesSet = new Set([...products, id]);
    setProducts(Array.from(parsedValuesSet));
    setValue(name, "");
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target as Node)
      ) {
        setShowOptions(false);
        setValue(name, "");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className={styles.containerInput}>
        <InputV2
          name={`${name}`}
          label={""}
          type="text"
          placeholder={t("SELECT_PRODUCTS")}
          onChange={onProductNameChange}
        />
        {showOptions && (
          <div ref={optionsRef}>
            <ul>
              {options.map(option => {
                const productSupplier = getProductSupplierFromProduct(
                  option,
                  user.id
                );

                return (
                  <li
                    key={option.id}
                    onClick={() => {
                      if (productSupplier?.status) {
                        setProduct(option);
                      }
                    }}
                    className={
                      !productSupplier?.status ? styles.disabledProduct : ""
                    }
                  >
                    <ProductImage images={option.images} />
                    <div className={styles.description}>
                      {option.name}
                      <br />
                      {!productSupplier?.status && (
                        <span className={styles.sku}>
                          {t("PRODUCT_DISABLED")}
                          <br />
                        </span>
                      )}
                      {option?.suppliers?.[0]?.supplierSku && (
                        <span className={styles.sku}>
                          SKU: {option?.suppliers?.[0]?.supplierSku}
                          <br />
                        </span>
                      )}
                      {option?.brandId && (
                        <span className={styles.sku}>
                          {t("BRAND")} <BrandName id={option.brandId} />
                          <br />
                        </span>
                      )}
                    </div>
                    <div className="bold">
                      {formatCurrency(
                        option?.suppliers[0]?.wholesalePrice ?? 0
                      )}
                    </div>
                  </li>
                );
              })}
              {!options?.length && <li>{t("NO_OPTIONS")}</li>}
            </ul>
          </div>
        )}
      </div>
      {products?.length > 0 && (
        <div>
          {products.map((product, idx) => (
            <ProductNameChip
              key={product}
              id={product}
              onDelete={() => onDelete(idx)}
            />
          ))}
        </div>
      )}
    </>
  );
};
