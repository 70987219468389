import { CURRENT_LANG, ES } from "@/constants/core";

export const getFirebaseErrorText = (
  errorCode: string,
  errorMessage: string
) => {
  const logFirebaseErrorCodeType: { [key: string]: () => string } = {
    "auth/invalid-action-code": () =>
      CURRENT_LANG() === ES
        ? "Este link ya no es válido"
        : "This link is no longer valid",
    "auth/invalid-password": () =>
      CURRENT_LANG() === ES
        ? "Correo o contraseña invalidos"
        : "Invalid email or password",
    "auth/invalid-email": () =>
      CURRENT_LANG() === ES
        ? "El correo ingresado no coincide con el correo de link rápido."
        : "The email entered does not match the quick link email.",
    DEFAULT: () => errorMessage,
  };
  return (
    logFirebaseErrorCodeType[errorCode]?.() ||
    logFirebaseErrorCodeType["DEFAULT"]()
  );
};
