import { useState } from "react";
import { marked } from "marked";
import packageJson from "../../../package.json";
import changelog from "../../../CHANGELOG.md?raw";
import { Modal } from "../shared/Modal/Modal";
import { createPortal } from "react-dom";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

export const Version = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [t] = useTranslation("global");

  return (
    <>
      <div>
        <span className="purpleText bold">
          {`${t("VERSION")} ${packageJson.version}`}
        </span>
      </div>
      {createPortal(
        <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
          <div
            className={styles.changelog}
            dangerouslySetInnerHTML={{
              __html: marked(changelog, { headerIds: false }),
            }}
          />
        </Modal>,
        document.body
      )}
    </>
  );
};
