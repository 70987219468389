import { useIsProductBrandOwner } from "@/hooks/useProducts";
import { calculateTieredDiscounts, getProductById } from "@/http";
import { useAuth } from "@/store/auth";
import { TieredDiscountSupplierTable } from "./TieredDiscountSupplierTable";
import { NEGOTIATION_TYPE } from "@/constants/supplier";
import { CreateProductDiscount } from "./CreateProductDiscount";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import { FilterTabs } from "@/components/app/ButtonFilterTabs/ButtonFilterTabs";
import { useState } from "react";

type TProductTieredDiscounts = {
  productId: string;
  price: number;
};
export const ProductTieredDiscounts = ({
  productId,
  price,
}: TProductTieredDiscounts) => {
  const [t] = useTranslation("products-page");
  const { user } = useAuth();
  const [selectedSupplier, setSelectedSupplier] = useState(user.id);
  const { data: globalVariant = {} as any } = useSWR(
    productId ? [productId, "variantProduct"] : null,
    getProductById
  );

  const { data: calculatedDiscounts = {} as any, mutate } = useSWR(
    [
      [
        {
          supplierId: user.id,
          categoryId: globalVariant.categoryId,
          brandId: globalVariant.brandId,
          productId,
        },
      ],
      "variantProduct" + productId,
    ],
    calculateTieredDiscounts
  );

  const hasDiscount = !!calculatedDiscounts[0]?.sales?.length;
  const isProductGift = ["GIFT"].includes(globalVariant?.type ?? "");
  const isSupplierBrand = useIsProductBrandOwner(productId, user.id);
  const isPriceListUser = user.negotiationType === NEGOTIATION_TYPE.PRICE_LIST;
  const sortedSuppliers = isSupplierBrand
    ? globalVariant?.suppliers
        ?.sort((s: any) => (s?.supplierId === user.id ? -1 : 1))
        ?.filter((s: any) => s?.sales?.length > 0)
    : [
        globalVariant?.suppliers?.find((s: any) => s.supplierId === user.id) ??
          {},
      ];
  const optionSuppliers = globalVariant?.suppliers?.reduce(
    (dict: any, o: any) => {
      dict[o["supplierId"]] = o;
      return dict;
    },
    {}
  );

  return (
    <>
      {!isProductGift && !isPriceListUser && (
        <>
          <div className="contentInline">
            <span className="bold">{t("DISCOUNTS")}</span>
            <CreateProductDiscount
              action={mutate}
              productId={globalVariant.id}
            />
          </div>
          <div className="spacer" />

          <FilterTabs
            options={
              sortedSuppliers?.map((s: any) => ({
                text:
                  s?.supplierId === user.id
                    ? t("MY_DISCOUNTS")
                    : s.supplierName,
                value: s.supplierId,
              })) || []
            }
            currentFilter={selectedSupplier}
            action={setSelectedSupplier}
          />
          <div className="spacer" />

          {selectedSupplier === user.id ? (
            hasDiscount ? (
              <TieredDiscountSupplierTable
                sales={calculatedDiscounts[0]?.sales ?? []}
                retailPrice={price}
              />
            ) : (
              <p>{t("WHITHOUT_DISCOUNTS")}</p>
            )
          ) : (
            <TieredDiscountSupplierTable
              sales={optionSuppliers[selectedSupplier]?.sales ?? []}
              retailPrice={
                optionSuppliers[selectedSupplier]?.wholesalePrice ?? 0
              }
            />
          )}

          <div className="spacer" />
        </>
      )}
    </>
  );
};
