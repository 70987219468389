import { DetailOffer } from "@/components/offers/detail/DetailOffer";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const ShowOfferPage = (): JSX.Element => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");

  useEffect(() => setTitle(t("OFFERS.DETAIL_TITLE"), "/offers"), [t]);

  return (
    <div>
      <DetailOffer />
    </div>
  );
};
