import { useAuth } from "@/store/auth";
import { formatCurrency } from "@/utils/currency";
import { getSupplierReports } from "@/http";
import { TSupplierReport, TSupplierReportSales } from "@/http/types/reports";
import { ReactNode, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { DatesInputs } from "./DatesInputs";
import { Chart } from "react-google-charts";
import styles from "./styles.module.scss";
import { Spinner } from "../shared/Spinner/Spinner";
import { format, subMonths } from "date-fns";
import { DEFAULT_FORMAT } from "@/constants/date";
import { useTranslation } from "react-i18next";

export const DashboardGrid = () => {
  const [t] = useTranslation("global");
  const { user } = useAuth();
  const [report, setReport] = useState<TSupplierReport>({} as any);
  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm({
    defaultValues: {
      from: format(subMonths(new Date(), 1), DEFAULT_FORMAT),
      to: format(new Date(), DEFAULT_FORMAT),
    },
  });
  const fromWatch = methods.watch("from");
  const toWatch = methods.watch("to");

  useEffect(() => {
    if (fromWatch && toWatch) {
      getReport();
    }
  }, [fromWatch, toWatch]);

  const getReport = async (tries = 0) => {
    if (tries >= 3) {
      setReport({} as any);
      setIsLoading(false);
      return;
    }
    try {
      setReport({} as any);
      setIsLoading(true);
      const fromDate = new Date(fromWatch);
      const toDate = new Date(toWatch);
      fromDate.setHours(0, 0, 0, 0);
      toDate.setHours(23, 59, 59, 999);
      const report = await getSupplierReports({
        supplierId: user.id,
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
      });
      setReport(report);
      setIsLoading(false);
    } catch (e) {
      await new Promise(resolve => setTimeout(resolve, 2000));
      await getReport(tries + 1);
    }
  };

  const getProductsUnits = (units: TSupplierReportSales[]) => {
    const unitsSort = units.sort((a, b) => b.total - a.total);
    const unitsFirst = unitsSort
      .slice(0, 10)
      .map(unit => [unit.name, unit.total, null, unit.name]);
    const total = Math.round(
      units.reduce((acumulador, actual) => acumulador + actual.total, 0)
    );

    return { unitsFirst, total };
  };

  const getProductsDays = (units: TSupplierReportSales[]) => {
    const unitsSort = units.sort((a, b) => b.days - a.days);
    const unitsFirst = unitsSort
      .slice(0, 10)
      .map(unit => [unit.name, Math.ceil(unit.days), null, unit.name]);

    return unitsFirst;
  };

  return (
    <>
      <FormProvider {...methods}>
        <div className="flexGap center flexSmColumn">
          <div className="col-sm-12 noPadding">
            <DatesInputs />
          </div>
          <div>
            {!!fromWatch && !!toWatch && (
              <div className="textCenter">
                {t("DASHBOARD.SHOWING_RESULTS")}{" "}
                {format(new Date(fromWatch), DEFAULT_FORMAT)
                  .split("-")
                  .reverse()
                  .join("/")}{" "}
                {t("DASHBOARD.SHOWING_RESULTS_TO")}{" "}
                {format(new Date(toWatch), DEFAULT_FORMAT)
                  .split("-")
                  .reverse()
                  .join("/")}
              </div>
            )}
          </div>
        </div>
      </FormProvider>
      <div className="spacer" />
      <div className="spacer" />
      {isLoading && <Spinner />}

      {Object.keys(report).length > 0 && !isLoading ? (
        <>
          {(report?.totalSales || 0) > 0 && (report?.totalOrders || 0) > 0 ? (
            <>
              <div className={`row flexGap ${styles.row}`}>
                <Card
                  title={t("DASHBOARD.SALES_TOTAL")}
                  content={
                    <span className={styles.bigDesc}>
                      {formatCurrency(report?.totalSales || 0)}
                    </span>
                  }
                />
                <Card
                  title={t("DASHBOARD.ORDERS_TOTAL")}
                  content={
                    <span className={styles.bigDesc}>
                      {report?.totalOrders || 0}
                    </span>
                  }
                />
              </div>
              <div className="spacer" />
              <div className={`row flexGap ${styles.row}`}>
                <Card
                  title={t("DASHBOARD.AVG_ORDERS_VALUE")}
                  content={
                    <span className={styles.bigDesc}>
                      {formatCurrency(report?.averageOrderValue || 0)}
                    </span>
                  }
                />
                <Card
                  title={t("DASHBOARD.PERCENTAGE_ACCEPTED_ORDERS")}
                  content={
                    <>
                      <span className={styles.bigDesc}>
                        {(
                          ((report?.totalOrders || 0) *
                            (report?.fillRate || 0)) /
                          100
                        )?.toFixed(0)}
                      </span>
                      {!!report?.fillRate && (
                        <Chart
                          chartType="PieChart"
                          data={[
                            ["Sale", "Percentage"],
                            [
                              t("DASHBOARD.REJECTED_ORDERS"),
                              100 - report?.fillRate,
                            ],
                            [t("DASHBOARD.ACCEPTED_ORDERS"), report?.fillRate],
                          ]}
                          options={{
                            pieHole: 0.5,
                            chartArea: {
                              width: "95%",
                              height: "95%",
                            },
                            colors: ["#bdc4cc", "#9189FF"],
                          }}
                          width={"100%"}
                        />
                      )}
                    </>
                  }
                />
              </div>
              <div className="spacer" />
              <div className={`row flexGap ${styles.row}`}>
                <Card
                  title={t("DASHBOARD.TOP_PRODUCTS")}
                  content={
                    <>
                      <div>
                        <span className={styles.bigDesc}>
                          {
                            getProductsUnits(report?.productsBySoldUnits || [])
                              .total
                          }
                        </span>
                        <span>{` ${t("DASHBOARD.SALE_UNITS")}`}</span>
                      </div>
                      {report?.productsBySoldUnits?.length > 0 && (
                        <Chart
                          chartType="BarChart"
                          width="100%"
                          data={[
                            [
                              t("DASHBOARD.PRODUCT"),
                              t("DASHBOARD.QUANTITY"),
                              { role: "style" },
                              { role: "annotation" },
                            ],
                            ...getProductsUnits(report.productsBySoldUnits)
                              .unitsFirst,
                          ]}
                          options={{
                            chartArea: { width: "100%", top: 18, bottom: 0 },
                            colors: ["#9189FF"],
                            legend: "none",
                            height: 320,
                            bar: { groupWidth: "75%" },
                          }}
                        />
                      )}
                    </>
                  }
                />
                <Card
                  title={t("DASHBOARD.TOP_PRODUCTS")}
                  content={
                    <>
                      <div>
                        <span className={styles.bigDesc}>
                          {formatCurrency(
                            getProductsUnits(report?.productsBySales || [])
                              .total
                          )}
                        </span>
                        <span>{` ${t("DASHBOARD.SALE_PRODUCTS")}`}</span>
                      </div>
                      {report?.productsBySales?.length > 0 && (
                        <Chart
                          chartType="BarChart"
                          width="100%"
                          data={[
                            [
                              t("DASHBOARD.PRODUCT"),
                              t("DASHBOARD.QUANTITY"),
                              { role: "style" },
                              { role: "annotation" },
                            ],
                            ...getProductsUnits(report.productsBySales)
                              .unitsFirst,
                          ]}
                          options={{
                            chartArea: { width: "100%", top: 18, bottom: 0 },
                            colors: ["#9189FF"],
                            legend: "none",
                            height: 320,
                            bar: { groupWidth: "75%" },
                          }}
                        />
                      )}
                    </>
                  }
                />
              </div>
              <div className="spacer" />
              <div className={`row flexGap ${styles.row}`}>
                <Card
                  title={t("DASHBOARD.INVENTORY_ROTATION")}
                  content={
                    <>
                      {report?.productsStockRisk?.length > 0 && (
                        <Chart
                          chartType="BarChart"
                          width="100%"
                          data={[
                            [
                              t("DASHBOARD.PRODUCT"),
                              t("DASHBOARD.DAYS"),
                              { role: "style" },
                              { role: "annotation" },
                            ],
                            ...getProductsDays(report.productsStockRisk),
                          ]}
                          options={{
                            chartArea: { width: "100%", top: 0, bottom: 0 },
                            colors: ["#9189FF"],
                            legend: "none",
                            height: 320,
                            bar: { groupWidth: "75%" },
                          }}
                        />
                      )}
                    </>
                  }
                />

                <Card
                  title={t("DASHBOARD.OVERSTOCK")}
                  content={
                    <>
                      {report?.productsOverStock?.length > 0 && (
                        <Chart
                          chartType="BarChart"
                          width="100%"
                          data={[
                            [
                              t("DASHBOARD.PRODUCT"),
                              t("DASHBOARD.DAYS"),
                              { role: "style" },
                              { role: "annotation" },
                            ],
                            ...getProductsDays(report.productsOverStock),
                          ]}
                          options={{
                            chartArea: { width: "100%", top: 0, bottom: 0 },
                            colors: ["#9189FF"],
                            legend: "none",
                            height: 320,
                            bar: { groupWidth: "75%" },
                          }}
                        />
                      )}
                    </>
                  }
                />
              </div>
              <div className="spacer" />
              <div className={`row flexGap ${styles.row}`}>
                <Card
                  title={t("DASHBOARD.PERCENTAGE_RETURN")}
                  content={
                    <>
                      <span className={styles.bigDesc}>
                        {(
                          ((report?.totalOrders || 0) *
                            (100 - (report?.fillRate || 0))) /
                          100
                        )?.toFixed(0)}
                      </span>
                      {!!report?.fillRate && (
                        <Chart
                          chartType="PieChart"
                          data={[
                            ["Sale", "Percentage"],
                            [
                              t("DASHBOARD.REJECTED_ORDERS"),
                              100 - report?.fillRate,
                            ],
                            [t("DASHBOARD.ACCEPTED_ORDERS"), report?.fillRate],
                          ]}
                          options={{
                            pieHole: 0.5,
                            chartArea: {
                              width: "95%",
                              height: "95%",
                            },
                            colors: ["#FF833E", "#bdc4cc"],
                          }}
                          width={"100%"}
                        />
                      )}
                    </>
                  }
                />
                <Card
                  title={t("DASHBOARD.PERCENTAGE_CLIENT_ORDERS")}
                  content={
                    <>
                      <div>
                        <span className={styles.bigDesc}>
                          {(
                            (report.totalOrders *
                              report.returningCustomerRate) /
                            100
                          )?.toFixed(0)}
                        </span>
                        <br />
                        <span>{t("DASHBOARD.ORDERS_QUANTITY")}</span>
                      </div>
                      <div className="spacer" />
                      {!!report?.returningCustomerRate && (
                        <>
                          <div className={styles.linearBar}>
                            <div
                              className={styles.progress}
                              style={{
                                width: `${report.returningCustomerRate ?? 0}%`,
                              }}
                            >
                              {report.returningCustomerRate?.toFixed(0)}%
                            </div>
                            <div
                              style={{
                                width: `${
                                  100 - (report.returningCustomerRate ?? 0)
                                }%`,
                              }}
                            >
                              {(100 - report.returningCustomerRate)?.toFixed(0)}
                              %
                            </div>
                          </div>
                          <div className="spacer" />
                          <div className={styles.itemDesc}>
                            <span className={styles.principal} />{" "}
                            <span className={styles.textDesc}>
                              {t("DASHBOARD.MORE_ONE_ORDER")}
                            </span>
                            <span className={styles.secondary} />
                            <span className={styles.textDesc}>
                              {t("DASHBOARD.ONE_ORDER")}
                            </span>
                          </div>
                        </>
                      )}
                    </>
                  }
                />
              </div>
            </>
          ) : (
            <h6 className={styles.noData}>{t("DASHBOARD.NO_SALES")}</h6>
          )}
        </>
      ) : (
        <h6 className={styles.noData}>{t("DASHBOARD.NO_SALES")}</h6>
      )}
    </>
  );
};

type TCard = {
  title: ReactNode;
  content: ReactNode;
};
const Card = ({ title, content }: TCard) => {
  return (
    <div className={`col-6 col-sm-12 noPadding ${styles.card}`}>
      <div className={styles.purpleBorder} />
      <div className={styles.content}>
        <span className={styles.title}>{title}</span>
        <div className="spacer" />
        <div>{content}</div>
      </div>
    </div>
  );
};
