import { create } from "zustand";
import { getSupplierLimits } from "@/http";
import { TSupplierLimit } from "@/http/types/supplier-limit";

type TUseTieredDiscounts = {
  getTieredDiscounts: (...args: any) => void;
  tieredDiscounts: TSupplierLimit[];
  isLoading: boolean;
  totalPages: number;
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
};
export const useTieredDiscounts = create<TUseTieredDiscounts>((set, get) => ({
  getTieredDiscounts: async (userId: string) => {
    set(() => ({ isLoading: true }));
    try {
      const { currentPage } = get();
      if (userId) {
        const limits = await getSupplierLimits({
          search: `supplierId:${userId}`,
          page: currentPage,
          size: 10,
        });

        set(() => ({
          tieredDiscounts: limits.content,
          isLoading: false,
          totalPages: limits.totalPages,
        }));
      }
    } catch (error) {
      set(() => ({ tieredDiscounts: [], isLoading: false }));
    }
  },
  tieredDiscounts: [],
  isLoading: false,
  totalPages: 0,
  currentPage: 0,
  setCurrentPage: currentPage => set(() => ({ currentPage })),
}));
