import { useState } from "react";
import { InputV2 } from "@/components/shared/Input/InputV2";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { TSupplierVariantPP } from "@/models/order-details";
import { useAuth } from "@/store/auth";
import { validateOnlyNumber } from "@/utils/keyboard";
import {
  getProductById,
  TProductSupplier,
  TSupplier,
  TSupplierProduct,
  updateProductV2,
  updateSupplierVariantV2,
  updateSupplierWarehouseOverstock,
} from "@/http";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { useProducts } from "@/store/products";
import { ImagesVariant } from "./ImagesVariant";
import { DragAndDropFile } from "@/components/shared/DragAndDropFIle/DragAndDropFIle";
import { ImagesList } from "../ProductsForm/ProductImageList";
import { uploadImageProductObject } from "@/utils/image-upload";
import { RecordCustomAttributes } from "@/components/custom-fields/RecordCustomAttributes";
import { ProductTieredDiscounts } from "./TieredDiscounts";
import { VariantsPricesInputs } from "../ProductsForm/VariantPricesInputs";
import { ProductSegments } from "@/components/segments/ProductSegments";
import { NEGOTIATION_TYPE } from "@/constants/supplier";
import { ProductDetailsIntegrations } from "./ProductIntegrations";
import { CURRENT_COUNTRY, isUSA } from "@/constants/core";
import { VariantProcurementList } from "@/pages/Products/VariantProcurementList";
import { DeliveryTimes } from "../Variants/Show/DeliveryTimes";
import { ProductStockDetail } from "../ProductDetail/ProductStockDetail";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { capitalizeFirstLetter } from "@/utils/string";
import { Card } from "@/components/shared/Card/Card";
import { format } from "date-fns";
import { DEFAULT_FORMAT } from "@/constants/date";
import { validateTimezoneDate } from "@/utils/date";
import { TextArea } from "@/components/shared/Input/TextArea";
import { get } from "lodash-es";
import { UnitTypes } from "../ProductDetail/UnitTypes/UnitTypes";
import { Checkbox } from "@/components/shared/Input/Checkbox";

type TFormEditVariant = {
  onDone?: (variant: TSupplierVariantPP) => void;
  isSupplierBrand?: boolean;
  productGroup: TSupplierProduct;
};

export const FormEditVariant = ({
  onDone,
  isSupplierBrand = false,
  productGroup,
}: TFormEditVariant) => {
  const [t] = useTranslation("products-page");
  const [tGlobal] = useTranslation("global");
  const { user } = useAuth();
  const negotiationType = user.negotiationType;
  const minDate = new Date().toISOString().slice(0, 16);
  const { selectedVariantEdit, setOpenModal, setSelectedVariant } =
    useProducts();
  const [images, setImages] = useState<any[]>(selectedVariantEdit?.media ?? []);

  const [isLoading, setLoading] = useState(false);
  const [enableEditing, setEnableEditing] = useState(false);
  const { FIXED_MARGIN, VARIABLE_MARGIN, PRICE_LIST } = NEGOTIATION_TYPE;

  const supplierProduct: TProductSupplier =
    selectedVariantEdit?.suppliers?.find(
      ({ supplierId }: TSupplier) => supplierId === user.id
    ) || {};

  const defaultValues = {
    ...supplierProduct,
    name: supplierProduct.name ?? selectedVariantEdit?.name,
    description:
      supplierProduct.description ?? selectedVariantEdit?.description,
    barCode: supplierProduct.barCode ?? selectedVariantEdit.barCode,
    product: selectedVariantEdit,
    retailPrice: selectedVariantEdit?.retailPrice,
    isMarketplace: selectedVariantEdit?.isMarketplace,
    dueDate: supplierProduct?.dueDate
      ? format(
          new Date(validateTimezoneDate(supplierProduct?.dueDate)),
          DEFAULT_FORMAT
        )
      : "",
    overStock: supplierProduct?.warehouses?.reduce((acc, item) => {
      acc[item.id] = {
        overStock: !!item?.overStock,
        overStockAmount: item?.overStockAmount ?? 1000000,
      };
      return acc;
    }, {}),
  };

  const methods = useForm<any>({ defaultValues });

  const wholesalePriceWatch = methods.watch("wholesalePrice");

  const onSubmit = async (values: any) => {
    setLoading(true);

    try {
      const {
        supplierSku,
        disableInStockOut,
        securityStock,
        product: productForm,
        dueDate,
        unitPrice,
        purchasePrice,
        customAttributes,
        name,
        description,
        barCode,
        isMarketplace,
      } = values;

      const payload = {} as any;
      if ([FIXED_MARGIN, VARIABLE_MARGIN].includes(negotiationType)) {
        payload.wholesalePrice = values?.wholesalePrice;
        payload.supplierPrice = 0;
      }
      if ([PRICE_LIST].includes(negotiationType)) {
        payload.supplierPrice = values.supplierPrice;
        payload.wholesalePrice =
          values.supplierPrice /
          (1 - (user.priceListIncreasePercentage ?? 0) / 100);
      }
      payload.retailPrice = isSupplierBrand
        ? values.retailPrice
        : selectedVariantEdit?.retailPrice ?? 0;

      if (isSupplierBrand) {
        const {
          name,
          description,
          longDescription,
          height,
          width,
          length,
          weight,
          barCode,
          productOptions,
          newProductOptions,
        } = productForm ?? {};

        for (const key in productOptions) {
          if (productOptions[key] === "newOption") {
            productOptions[key] = newProductOptions[key];
          }
        }

        let dataImages = images;
        if (images?.length > 0) {
          await Promise.all(dataImages?.map(uploadImageProductObject)).then(
            images => {
              dataImages = images;
            }
          );
        }

        updateProductV2(selectedVariantEdit?.id, {
          productOptions,
          name,
          description,
          longDescription,
          customAttributes,
          height,
          width,
          length,
          weight,
          media: dataImages ?? [],
          images: dataImages ?? [],
          barCode,
          isMarketplace,
          ...payload,
        } as any);
      }

      if (dueDate) {
        const date = new Date(dueDate + "T23:59:59");
        payload.dueDate = date.toISOString();
      }

      const overStockIds = Object.keys(values?.overStock ?? {});
      for (const id of overStockIds) {
        try {
          const overStockValues = get(values, `overStock.${id}`, {
            overStock: false,
          });
          await updateSupplierWarehouseOverstock(id, overStockValues);
        } catch (_) {
          showToastError(tGlobal("OVERSTOCK_FAILED_UPDATE"));
        }
      }

      const newVariant = await updateSupplierVariantV2(supplierProduct.id, {
        ...payload,
        marginSeeri: user.marginSeeri,
        supplierSku,
        disableInStockOut,
        securityStock: securityStock || 0,
        unitPrice,
        purchasePrice,
        customAttributes,
        name,
        description,
        barCode,
      } as any);

      const product = await getProductById(selectedVariantEdit?.id);
      onDone?.({
        ...newVariant,
        productUpdated: product,
      } as any);
      setSelectedVariant(product);
      setEnableEditing(false);
      showToastSuccess(t("PRODUCT_UPDATED"));
      methods.reset({
        ...newVariant,
        product,
        retailPrice: product?.retailPrice,
        dueDate: newVariant?.dueDate
          ? format(
              new Date(validateTimezoneDate(newVariant?.dueDate)),
              DEFAULT_FORMAT
            )
          : "",
      });
      setOpenModal(false);
    } catch (error) {
      showToastError({ message: tGlobal("ERROR_SOME_BAD"), error });
    } finally {
      setLoading(false);
    }
  };

  const isProductGift = () => {
    return ["GIFT"].includes(selectedVariantEdit?.type);
  };

  const integrations = selectedVariantEdit?.suppliers?.[0]?.integrations;

  return (
    <Card borderTop>
      <div className="flexGap">
        <div className="col-12 noPadding">
          <FormProvider {...methods}>
            <form autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="col-12 noPadding">
                {(!enableEditing || !isSupplierBrand) && (
                  <ImagesVariant
                    enableEditing={enableEditing}
                    isSupplierBrand={isSupplierBrand}
                    setEnableEditing={setEnableEditing}
                  />
                )}
                <div
                  className={`${
                    !enableEditing || !isSupplierBrand ? "col-7" : "col-12"
                  } col-sm-12 ${styles.containerForm}`}
                >
                  {enableEditing && isSupplierBrand && (
                    <>
                      <div className="col-12 noPaddingH">
                        <InputV2
                          name="product.name"
                          label={t("PRODUCT.NAME")}
                          type="text"
                          disabled={!enableEditing}
                          required
                        />
                      </div>
                      <div className="col-12 noPaddingH">
                        <TextArea
                          name="product.description"
                          label={t("PRODUCT.DESCRIPTION")}
                          rows={4}
                          disabled={!enableEditing}
                        />
                      </div>
                      <div className="col-12 noPaddingH">
                        <Checkbox
                          name="isMarketplace"
                          label={t("PRODUCT.IS_MARKETPLACE")}
                        />
                      </div>
                      <div className="col-12 noPadding">
                        <div className="divider" />
                      </div>
                      <div className="col-12 noPadding">
                        <div className="col-12 noPaddingH">
                          <h1 className="bold">{"Media"}</h1>
                          <DragAndDropFile
                            accept="image/png,image/jpeg,video/mp4,video/mov,video/avi,video/webm,video/flv,video/wmv,video/mpeg,application/pdf"
                            files={images}
                            setFiles={setImages}
                          />
                        </div>
                        <div className="col-12">
                          <ImagesList images={images} setImages={setImages} />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="divider" />
                      </div>
                    </>
                  )}

                  <div className="col-12 noPaddingH">
                    <p className="bold">{t("PRODUCT.INVENTORY")}</p>
                  </div>
                  <div className="col-12 noPadding">
                    {!isUSA && (
                      <div className="col-6 col-sm-12 noPaddingH">
                        <InputV2
                          name="supplierSku"
                          label={t("PRODUCT.DETAIL.SKU")}
                          type="text"
                          disabled={!enableEditing}
                        />
                      </div>
                    )}
                    <div className="col-6 col-sm-12 noPaddingH">
                      <InputV2
                        name="product.barCode"
                        label={t("PRODUCT.BAR_CODE")}
                        type="text"
                        disabled={!enableEditing || !isSupplierBrand}
                      />
                    </div>
                  </div>
                  <div className="col-12 noPadding">
                    <div className="col-6 col-sm-12 noPaddingH">
                      <InputV2
                        name="securityStock"
                        label={t("PRODUCT.SECURITY_STOCK")}
                        type="text"
                        onKeyPress={validateOnlyNumber}
                        disabled={!enableEditing}
                      />
                    </div>
                    <div className="col-6 col-sm-12 noPaddingH">
                      <InputV2
                        name="dueDate"
                        label={
                          <div className="flexGap center textLine">
                            {t("PRODUCT.DUE_DATE")}
                            <span className="smFont">
                              ({isUSA ? "mm/dd/yyyy" : "dd/mm/aaaa"})
                            </span>
                          </div>
                        }
                        type="date"
                        min={minDate}
                        disabled={!enableEditing}
                      />
                    </div>
                  </div>
                  {Object.keys(selectedVariantEdit.productOptions ?? {}).map(
                    (go: string) => (
                      <div className="col-12 noPadding" key={go}>
                        <div className="col-6 col-sm-12 noPaddingH">
                          <SelectV2
                            disabled={!enableEditing || !isSupplierBrand}
                            name={`product.productOptions.${go}`}
                            label={capitalizeFirstLetter(go)}
                            choices={[
                              ...(productGroup?.options?.[go]?.map(
                                (o: string) => ({
                                  value: o + "",
                                  label: capitalizeFirstLetter(o + ""),
                                })
                              ) ?? []),
                              {
                                value:
                                  selectedVariantEdit?.productOptions?.[go] ??
                                  "-",
                                label:
                                  selectedVariantEdit?.productOptions?.[go] ??
                                  "-",
                              },
                              { value: "newOption", label: t("NEW_OPTION") },
                            ]}
                          />
                        </div>
                        {methods.watch(`product.productOptions.${go}`) ===
                          "newOption" && (
                          <div className="col-6 col-sm-12 noPaddingH">
                            <InputV2
                              disabled={!enableEditing || !isSupplierBrand}
                              name={`product.newProductOptions.${go}`}
                              label={`${t("NEW_OPTION")} (${go})`}
                              required
                            />
                          </div>
                        )}
                      </div>
                    )
                  )}

                  {!isProductGift() ? (
                    <VariantsPricesInputs
                      brandId={selectedVariantEdit?.brandId}
                      disabled={!enableEditing}
                    />
                  ) : null}
                  <div className="col-12 noPadding">
                    <div className="col-6 col-sm-12 noPaddingH">
                      <InputV2
                        name="departureStock"
                        label={t("DEPARTURE_STOCK")}
                        type="text"
                        disabled
                      />
                    </div>
                    <div className="col-6 col-sm-12 noPaddingH">
                      <InputV2
                        name="reservedStock"
                        label={t("RESERVED_STOCK")}
                        type="text"
                        disabled
                      />
                    </div>
                  </div>
                  {!isProductGift() && (
                    <ProductSegments
                      productSupplier={supplierProduct as TProductSupplier}
                    />
                  )}
                </div>
                {!enableEditing && (
                  <div className="col-12 noPadding">
                    <UnitTypes
                      unitTypes={selectedVariantEdit?.unitTypes ?? []}
                    />
                  </div>
                )}
                <div className="col-12 noPadding mt1">
                  <RecordCustomAttributes
                    appliesTo="PRODUCTS"
                    record={supplierProduct}
                    forForm={enableEditing}
                  />
                </div>
              </div>

              {supplierProduct?.warehouses?.length > 0 && (
                <>
                  <div className="col-12 noPadding">
                    <ProductStockDetail
                      suppliers={selectedVariantEdit?.suppliers || []}
                      isSupplierBrand={isSupplierBrand}
                      isEdit={enableEditing}
                    />
                  </div>
                </>
              )}

              {enableEditing ? (
                <div className="col-12 noPadding rigthAlign">
                  <div className="divider spacer" />
                  <button
                    disabled={isLoading}
                    onClick={() => {
                      methods.reset();
                      setEnableEditing(false);
                    }}
                  >
                    {t("CANCEL")}
                  </button>
                  <button className="primary lg" disabled={isLoading}>
                    {t("SAVE_CHANGES")}
                  </button>
                  <div className="spacer" />
                </div>
              ) : null}
            </form>
          </FormProvider>
          {!enableEditing && (
            <div>
              <div className="col-12 noPadding">
                <div className="spacer" />
                <div className="divider" />
                <div className="spacer" />
                <ProductTieredDiscounts
                  productId={selectedVariantEdit?.id}
                  price={wholesalePriceWatch}
                />
              </div>
              <div className="col-12 noPadding">
                <DeliveryTimes
                  deliveryTimes={supplierProduct?.deliveryTimes || []}
                />
              </div>
              {integrations?.length > 0 && CURRENT_COUNTRY === "co" && (
                <div className="col-12 noPadding">
                  <ProductDetailsIntegrations integrations={integrations} />
                </div>
              )}

              <div className="col-12 noPadding">
                <h6 className="bold">{t("MOVEMENTS")}</h6>
                <VariantProcurementList />
              </div>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};
