import { TableCell, Table } from "@/components/clients/ui/Table/Table";
import { TPurchase, TVoucher, getPurchaseVouchers } from "@/http";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { format } from "date-fns";
import { isUSA } from "@/constants/core";
import { USA_DATE_HOUR, DATE_HOUR } from "@/constants/date";
import { Link } from "react-router-dom";
import { AiOutlineLink } from "react-icons/ai";
import { CreateVoucherModal } from "./CreateVoucherModal";
import { usePurchase } from "@/store/purchase";

type TVoucherList = {
  purchase: TPurchase;
};
export const VoucherList = ({ purchase }: TVoucherList) => {
  const [t] = useTranslation("purchases");
  const { getPurchase } = usePurchase();

  const { data, mutate } = useSWR(purchase.id, getPurchaseVouchers);
  let pendingValue =
    purchase.pendingToPay - (purchase.partialMethodAmount ?? 0);
  if (data) {
    pendingValue = data.reduce(
      (currentValue: number, v2: TVoucher) => currentValue - v2.amount,
      pendingValue
    );
  }
  const showCreateButton =
    data &&
    ["PENDING"].includes(purchase.paymentStatus) &&
    purchase.paymentProvider != "SUPPLIER_CREDIT";
  return (
    <>
      {showCreateButton && (
        <CreateVoucherModal
          maxAmount={pendingValue}
          purchase={purchase}
          setDone={function (): void {
            mutate();
            getPurchase(purchase.id);
          }}
        />
      )}
      {data && (
        <Table records={data}>
          <TableCell
            label={t("CREATED_AT")}
            source="createdAt"
            render={(voucher: TVoucher) => {
              return format(
                new Date(voucher.createdAt),
                isUSA ? USA_DATE_HOUR : DATE_HOUR
              );
            }}
          />
          <TableCell label={t("AMOUNT")} source="amount" />
          <TableCell
            label={""}
            source="amount"
            render={(voucher: TVoucher) => (
              <Link to={voucher.fileUrl} target="_blank">
                <AiOutlineLink />
              </Link>
            )}
          />
        </Table>
      )}
    </>
  );
};
