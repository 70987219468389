import { CURRENT_LANG, ES } from "./core";

export const purchaseStatus = [
  { name: CURRENT_LANG() === ES ? "Pendiente" : "Pending", code: "PENDING" },
  {
    name: CURRENT_LANG() === ES ? "En proceso" : "In process",
    code: "IN_PROCESS",
  },
  {
    name: CURRENT_LANG() === ES ? "En camino" : "On the way",
    code: "IN_TRANSIT",
  },
  {
    name: CURRENT_LANG() === ES ? "Entregado" : "Delivered",
    code: "DELIVERED",
  },
  { name: CURRENT_LANG() === ES ? "Realizado" : "Done", code: "DONE" },
  { name: CURRENT_LANG() === ES ? "Cancelado" : "Canceled", code: "CANCELLED" },
  { name: CURRENT_LANG() === ES ? "Expirado" : "Expired", code: "EXPIRED" },
  { name: CURRENT_LANG() === ES ? "Rechazado" : "Rejected", code: "REJECTED" },
  { name: CURRENT_LANG() === ES ? "Fallido" : "Failed", code: "FAILED" },
  { name: CURRENT_LANG() === ES ? "Cotización" : "Quotation", code: "QUOTED" },
];
export const purchaseStatusRenderFilter = [
  { name: CURRENT_LANG() === ES ? "Pendiente" : "Pending", code: "PENDING" },
  {
    name: CURRENT_LANG() === ES ? "En camino" : "On the way",
    code: "IN_TRANSIT",
  },
  {
    name: CURRENT_LANG() === ES ? "Entregado" : "Delivered",
    code: "DELIVERED",
  },
  { name: CURRENT_LANG() === ES ? "Realizado" : "Done", code: "DONE" },
  { name: CURRENT_LANG() === ES ? "Rechazado" : "Rejected", code: "REJECTED" },
  { name: CURRENT_LANG() === ES ? "Cancelado" : "Canceled", code: "CANCELLED" },
  {
    name: CURRENT_LANG() === ES ? "En proceso" : "In process",
    code: "IN_PROCESS",
  },
];

export const quotesRenderFilter = [
  { text: CURRENT_LANG() === ES ? "Cotizaciones" : "Quotes", value: "QUOTED" },
];

export const supplierStatus = [
  { name: CURRENT_LANG() === ES ? "Pendiente" : "Pending", code: "PENDING" },
  { name: CURRENT_LANG() === ES ? "Aprobado" : "Approved", code: "APPROVED" },
  { name: CURRENT_LANG() === ES ? "Rechazado" : "Rejected", code: "REJECTED" },
  { name: CURRENT_LANG() === ES ? "Cancelado" : "Canceled", code: "CANCELLED" },
  {
    name: CURRENT_LANG() === ES ? "En proceso" : "In process",
    code: "IN_PROCESS",
  },
  {
    name: CURRENT_LANG() === ES ? "Remisión generada" : "Remission generated",
    code: "REMISSION_GENERATED",
  },
];

export const deliveryStatus = [
  { name: CURRENT_LANG() === ES ? "Pendiente" : "Pending", code: "PENDING" },
  {
    name: CURRENT_LANG() === ES ? "En camino" : "On the way",
    code: "IN_TRANSIT",
  },
  {
    name: CURRENT_LANG() === ES ? "En procesamiento" : "In processing",
    code: "IN_PROCESS",
  },
  {
    name: CURRENT_LANG() === ES ? "Entregado" : "Delivered",
    code: "DELIVERED",
  },
  { name: CURRENT_LANG() === ES ? "Rechazado" : "Rejected", code: "REJECTED" },
  { name: CURRENT_LANG() === ES ? "Cancelado" : "Canceled", code: "CANCELLED" },
];

export const deliveryStatusRendering = [
  { name: CURRENT_LANG() === ES ? "Pendiente" : "Pending", code: "PENDING" },
  { name: CURRENT_LANG() === ES ? "Fallido" : "Failed", code: "FAILED" },
  {
    name: CURRENT_LANG() === ES ? "En preparación" : "In preparation",
    code: "PREPARATION",
  },
  {
    name: CURRENT_LANG() === ES ? "En alistamiento" : "In enlistment",
    code: "PREPARING",
  },
  {
    name: CURRENT_LANG() === ES ? "En camino" : "On the way",
    code: "IN_TRANSIT",
  },
  {
    name: CURRENT_LANG() === ES ? "En procesamiento" : "In processing",
    code: "IN_PROCESS",
  },
  {
    name: CURRENT_LANG() === ES ? "Entregado" : "Delivered",
    code: "DELIVERED",
  },
  { name: CURRENT_LANG() === ES ? "Rechazado" : "Rejected", code: "REJECTED" },
  { name: CURRENT_LANG() === ES ? "Cancelado" : "Canceled", code: "CANCELLED" },
  {
    name: CURRENT_LANG() === ES ? "Fallo al enviar" : "Failed to send",
    code: "FAILED_TO_SEND",
  },
  {
    name:
      CURRENT_LANG() === ES
        ? "Enviado desde el proveedor"
        : "Sent from supplier",
    code: "PICKUP_FROM_SUPPLIER",
  },
  {
    name:
      CURRENT_LANG() === ES ? "Devuelta a proveedor" : "Returned to supplier",
    code: "DELIVERED_TO_SUPPLIER",
  },
  {
    name:
      CURRENT_LANG() === ES
        ? "Rechazado por proveedor"
        : "Rejected by supplier",
    code: "SUPPLIER_REJECTED",
  },
  {
    name: CURRENT_LANG() === ES ? "Pendiente" : "Pending",
    code: "SUPPLIER_PENDING",
  },
];

export const paymentStatus = [
  { name: CURRENT_LANG() === ES ? "Pendiente" : "Pending", code: "PENDING" },
  {
    name: CURRENT_LANG() === ES ? "Por revisar" : "To be reviewed",
    code: "REVIEW",
  },
  { name: CURRENT_LANG() === ES ? "Pagado" : "Paid", code: "PAID" },
  { name: CURRENT_LANG() === ES ? "Fallido" : "Failed", code: "FAILED" },
  { name: CURRENT_LANG() === ES ? "Expirado" : "Expired", code: "EXPIRED" },
  { name: CURRENT_LANG() === ES ? "Cancelado" : "Canceled", code: "CANCELLED" },
];

export const linkStatus = [
  { name: CURRENT_LANG() === ES ? "Pendiente" : "Pending", code: "PENDING" },
  { name: CURRENT_LANG() === ES ? "Compartido" : "Shared", code: "SHARED" },
  { name: CURRENT_LANG() === ES ? "Abierto" : "Open", code: "OPENED" },
  { name: CURRENT_LANG() === ES ? "Expirado" : "Expired", code: "EXPIRED" },
  { name: CURRENT_LANG() === ES ? "Sin enlace" : "No link", code: "NO_LINK" },
];

export const quoterUserType = [
  { name: CURRENT_LANG() === ES ? "Vendedor" : "Seller", code: "SELLER" },
  { name: CURRENT_LANG() === ES ? "Tienda" : "Store", code: "STORE" },
];

export const ticketRefundTypes = [
  { name: CURRENT_LANG() === ES ? "Manual" : "Manual", code: "MANUAL" },
  { name: CURRENT_LANG() === ES ? "SeeriCoins" : "SeeriCoins", code: "COINS" },
  { name: CURRENT_LANG() === ES ? "Ninguno" : "None", code: "NONE" },
  {
    name: CURRENT_LANG() === ES ? "Reponer Producto" : "Replenish Product",
    code: "REPLENISH_PRODUCTS",
  },
];

export const ticketStatuses = [
  { name: CURRENT_LANG() === ES ? "Pendiente" : "Pending", code: "PENDING" },
  { name: CURRENT_LANG() === ES ? "Rechazado" : "Rejected", code: "REJECTED" },
  { name: CURRENT_LANG() === ES ? "Aprobado" : "Approved", code: "APPROVED" },
];

export const ticketTypes = [
  { name: CURRENT_LANG() === ES ? "Reembolso" : "Refund", code: "REFUND" },
  { name: CURRENT_LANG() === ES ? "Devolución" : "Return", code: "RETURN" },
];

export const ticketReasons = [
  {
    name:
      CURRENT_LANG() === ES
        ? "Mi pedido no ha llegado"
        : "My order has not arrived",
    code: "ORDER_WITHOUT_ARRIVING",
  },
  {
    name:
      CURRENT_LANG() === ES
        ? "Mi pedido llegó incompleto"
        : "My order arrived incomplete",
    code: "INCOMPLETE_ORDER",
  },
  {
    name:
      CURRENT_LANG() === ES ? "Productos defectuosos" : "Defective products",
    code: "DEFECTIVE_PRODUCTS",
  },
  {
    name: CURRENT_LANG() === ES ? "Productos erróneos" : "Wrong products",
    code: "WRONG_PRODUCTS",
  },
];

export const invoiceTypes = [
  { name: CURRENT_LANG() === ES ? "Factura" : "Invoice", code: "INVOICE" },
  {
    name:
      CURRENT_LANG() === ES ? "Nota de Crédito Parcial" : "Partial Credit Note",
    code: "PARTIAL_CREDIT_NOTE",
  },
  {
    name: CURRENT_LANG() === ES ? "Nota de Crédito Total" : "Total Credit Note",
    code: "TOTAL_CREDIT_NOTE",
  },
];
