import ProductImage from "@/components/layout/ProductImage";
import { ImagesList } from "@/components/products/ProductsForm/ProductImageList";
import { DragAndDropFile } from "@/components/shared/DragAndDropFIle/DragAndDropFIle";
import { Checkbox } from "@/components/shared/Input/Checkbox";
import { NumericInput } from "@/components/shared/Input/NumericInput";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { TextArea } from "@/components/shared/Input/TextArea";
import { Modal } from "@/components/shared/Modal/Modal";
import { RadioGroupV2 } from "@/components/shared/RadioGroup/RadioGroupV2";
import { showToastError } from "@/hooks/useToast";
import { confirmSupplierDelivery, TPurchase } from "@/http";
import { useAuth } from "@/store/auth";
import { usePurchase } from "@/store/purchase";
import { addressToString } from "@/utils/address";
import { uploadImageTicketObject } from "@/utils/image-upload";
import { useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

type Props = {
  purchase: TPurchase;
};

type TForm = {
  products?: { [key: string]: { value: boolean; quantity?: any } };
  createTicket: string;
  successDelivery: string;
  files: any[];
  [key: string]: any;
};
export const ConfirmPurchaseDelivery = ({ purchase }: Props) => {
  const [t] = useTranslation("global");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { getPurchase } = usePurchase();
  const methods = useForm<TForm>();
  const files = methods.watch("files") || [];
  const {
    user: { addresses = [] },
  } = useAuth();

  const confirmDelivery = async (values: TForm) => {
    try {
      setIsLoading(true);
      const { createTicket = "", successDelivery = "", products = {} } = values;
      if (successDelivery === "DELIVERED") {
        await confirmSupplierDelivery(purchase.id!, {});
      } else {
        const filteredProducts = Object.fromEntries(
          Object.entries(products).filter(([_, { value }]) => !!value)
        );
        if (!Object.keys(filteredProducts).length) {
          showToastError({ message: t("CHOOSE_ONE_PRODUCT") });
          return;
        }
        const productQuantity: any = {};
        purchase?.products?.forEach(product => {
          productQuantity[product?.id] = filteredProducts[product.id]
            ? product.quantity - (filteredProducts[product.id]?.quantity || 0)
            : product.quantity;
        });
        const payload = {
          addressId: values?.addressId,
          createTicket: values.createTicket === "TICKET",
          productQuantity,
          ticket: {} as any,
        };

        if (createTicket === "TICKET") {
          payload.ticket = {
            evidenceLink: "",
            reason: values.reason,
            type: values.type,
          };

          if (files.length) {
            const image = await uploadImageTicketObject(files[0]);
            payload.ticket.evidenceLink = image?.src;
          }
        }

        await confirmSupplierDelivery(purchase.id!, payload);
      }

      await getPurchase(purchase.id!);
      methods.reset();
      setIsOpen(false);
    } catch (error) {
      showToastError({ message: t("ERROR_SOME_BAD"), error });
    } finally {
      setIsLoading(false);
    }
  };

  if (
    ["DELIVERED", "CANCELLED", "DONE"].includes(purchase?.status) ||
    purchase?.subPurchases?.length === 0 ||
    !["PENDING"].includes(purchase?.deliveryStatus)
  ) {
    return null;
  }

  return (
    <div>
      <button
        className="primary sm"
        disabled={isLoading}
        onClick={() => {
          methods.reset();
          setIsOpen(true);
        }}
      >
        {t("PACKAGE_RECEIVED_BUTTON")}
      </button>
      <Modal
        size="sm"
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        title={t("PACKAGE_CONFIRMATION_TITLE")}
      >
        <FormProvider {...methods}>
          <RadioGroupV2
            required
            name="successDelivery"
            color="purple"
            label={t("RECEIVED_ALL_PRODUCTS")}
            options={[
              { label: t("YES"), value: "DELIVERED" },
              { label: t("NO"), value: "TROUBLE" },
            ]}
          />
          {methods.watch("successDelivery") === "TROUBLE" && (
            <div>
              <div className="spacer" />
              <RadioGroupV2
                required
                name="createTicket"
                label={t("WANNA_CREATE_TICKET")}
                color="purple"
                options={[
                  { label: t("YES"), value: "TICKET" },
                  { label: t("NO"), value: "CLOSE" },
                ]}
              />
            </div>
          )}
          <div className="spacer" />
          {["TICKET"].includes(methods.watch("createTicket")) &&
            methods.watch("successDelivery") === "TROUBLE" && (
              <>
                <TextArea
                  name="reason"
                  label={t("TELL_US_WHAT_HAPPEN")}
                  rows={6}
                  required
                />
                <p>{t("EVIDENCE")}</p>
                {!files.length && (
                  <DragAndDropFile
                    minifiedStyle
                    accept="image/png,image/jpeg"
                    files={files}
                    setFiles={files => methods.setValue("files", files)}
                  />
                )}
                <ImagesList
                  images={files}
                  setImages={files => methods.setValue("files", files)}
                />
                <div className="spacer" />
                <RadioGroupV2
                  color="purple"
                  required
                  name={"type"}
                  label={t("TYPE_LABEL")}
                  inline
                  options={[
                    { label: t("RETURN"), value: "RETURN" },
                    { label: t("REFUND"), value: "REFUND" },
                  ]}
                />
                <div className="spacer" />
              </>
            )}
          {["TICKET", "CLOSE"].includes(methods.watch("createTicket")) &&
            methods.watch("successDelivery") === "TROUBLE" && (
              <>
                <TicketInputs purchase={purchase} />
                <SelectV2
                  label={t("SELECT_DESTINY_WAREHOUSE")}
                  name="addressId"
                  choices={addresses.map(a => ({
                    label: addressToString(a),
                    value: a.id ?? "",
                  }))}
                  required
                />
              </>
            )}
        </FormProvider>

        <div className="divider" />
        <div className="spacer" />
        <div className="rigthAlign">
          <button disabled={isLoading} onClick={() => setIsOpen(false)}>
            {t("CANCEL")}
          </button>
          <button
            className="primary lg"
            disabled={isLoading}
            onClick={methods.handleSubmit(confirmDelivery)}
          >
            {t("CONFIRM")}
          </button>
        </div>
        <div className="spacer" />
        <div className="spacer" />
      </Modal>
    </div>
  );
};

export const TicketInputs = ({ purchase }: Props) => {
  const [t] = useTranslation("global");
  const methods = useFormContext();
  return (
    <>
      <p className="mbmd">{t("SELECT_THE_RETURN_PRODUCTS")}</p>
      {purchase.products.map(p => (
        <div key={p.id}>
          <div
            className={
              methods.watch(`products.${p.id}.value`)
                ? styles.noMarginInput
                : ""
            }
          >
            <Checkbox
              name={`products.${p.id}.value`}
              label={
                <div className="flexGap center">
                  <ProductImage images={p?.images ?? []} />
                  {p.name}
                </div>
              }
            />
          </div>
          {methods.watch(`products.${p.id}.value`) && (
            <div className="ml2">
              <NumericInput
                name={`products.${p.id}.quantity`}
                label={
                  <span className="mdFont">
                    {t("QUANTITY_RETURNED") + ` (Max. ${p.quantity})`}
                  </span>
                }
                decimals
                required
                max={p.quantity}
                min={0.1}
              />
            </div>
          )}
        </div>
      ))}
    </>
  );
};
