import Header from "@/components/layout/Header/Header";
import Sidebar from "@/components/layout/Sidebar/Sidebar";
import { useAuth } from "@/store/auth";
import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import styles from "./styles.module.scss";
import { OnboardingProvider } from "@/components/onboarding/OnboardingProvider";
import { SHOULD_SHOW_ONBOARDING } from "@/components/onboarding/constants";
import { useOnboarding } from "@/components/onboarding/useOnboarding";
import { DocumentTitle } from "../DocumentTitle";

const InnerContent = () => {
  const [isLoading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    if (user?.id) {
      setLoading(false);
    }
  }, [user]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <DocumentTitle />
      <OnboardingProvider>
        <OnboardingTrigger />
        <div className={styles.innerContent}>
          <Sidebar />
          <div className={styles.contentContainer}>
            <div>
              <Header />
              <div className={styles.outlet}>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </OnboardingProvider>
    </>
  );
};

export default InnerContent;

const OnboardingTrigger = () => {
  const { startOnboarding } = useOnboarding();

  useEffect(() => {
    if (localStorage.getItem(SHOULD_SHOW_ONBOARDING)) {
      localStorage.removeItem(SHOULD_SHOW_ONBOARDING);
      startOnboarding();
    }
  }, []);

  return null;
};
