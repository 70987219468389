import { Fragment } from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { TItem } from "../invoiceData";
import { isUSA } from "@/constants/core";
import { formatCurrency } from "@/utils/currency";
import { buildCleanName } from "../utils";
import { invoiceItemsStyle, invoiceItemsStylePOS } from "./Styles";

type Props = {
  items: TItem[];
  hasDiscount: boolean;
  showPrices: boolean;
  forPOS?: boolean;
  showUnitTypes?: boolean;
};

const InvoiceTableRow = ({
  items,
  hasDiscount,
  showPrices,
  forPOS = false,
  showUnitTypes,
}: Props) => {
  const styles = StyleSheet.create(
    forPOS ? invoiceItemsStylePOS : invoiceItemsStyle
  );

  const showImages = localStorage.getItem("showImagesOnInvoice") === "true";

  const rows = items.map(item => {
    const productSkuKey = (!isUSA ? item?.sku : item?.upc) ?? " ";
    const cleanName = buildCleanName(
      item?.name,
      item?.properties,
      item?.customAttributes ?? []
    );

    return (
      <View style={styles.row} key={item?.id?.toString()}>
        {showImages && (
          <View style={styles.image}>
            <Image
              src={item.image ?? "/images/fallback.jpg"}
              style={{ width: "100%", aspectRatio: 1, objectFit: "cover" }}
            />
          </View>
        )}

        {!forPOS && <Text style={styles.key}>{productSkuKey ?? "-"}</Text>}

        <Text style={styles.description}>{cleanName}</Text>
        <Text style={styles.qty}>{item?.quantity}</Text>
        {showUnitTypes && (
          <Text style={styles.key}>{item?.unitType ?? "-"}</Text>
        )}
        {showPrices && (
          <Text style={styles.amount}>
            {formatCurrency(item?.unitPrice || 0)}
          </Text>
        )}
        {!forPOS && (
          <>
            {showPrices && hasDiscount && (
              <Text style={styles.amount}>
                {formatCurrency(item?.subTotal || 0)}
              </Text>
            )}
            {showPrices && hasDiscount && (
              <Text style={styles.amount}>
                {formatCurrency(item?.discount || 0)}
              </Text>
            )}
          </>
        )}
        {showPrices && (
          <Text style={styles.amount}>{formatCurrency(item?.total || 0)}</Text>
        )}
      </View>
    );
  });
  return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableRow;
