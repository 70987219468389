import { getPaymentProviders, TPaymentProvider } from "@/http";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import useSWR from "swr";
import { PaymentProviderForm } from "./PaymentProviderForm";
import clsx from "clsx";
import { PaymentProviderDelete } from "./PaymentProviderDelete";

export const PaymentProviders = () => {
  const [t] = useTranslation("global");

  const { data: supplierPaymentProviders = [], mutate } = useSWR(
    "supplierPaymentProviders",
    getPaymentProviders
  );

  return (
    <div className={styles.container}>
      <div className="contentInline">
        <span className={styles.title}>{t("PAYMENT_PROVIDERS.TITLE")}</span>
        <div className="rigthAlign">
          <PaymentProviderForm onDone={() => mutate()} />
        </div>
      </div>
      <div>
        <div>
          {supplierPaymentProviders.map((paymentProvider: TPaymentProvider) => (
            <div
              key={paymentProvider.id}
              className={clsx(styles.providersContainer, styles.providersCard)}
            >
              <div>
                <p className="bold">{paymentProvider.code}</p>
                <p>{paymentProvider.description}</p>
              </div>
              <div className={styles.actions}>
                <PaymentProviderForm
                  iconButton
                  paymentProvider={paymentProvider}
                  onDone={() => mutate()}
                />
                <PaymentProviderDelete
                  iconButton
                  paymentProvider={paymentProvider}
                  onDone={() => mutate()}
                />
              </div>

              <div className="rigthAlign"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
