import { getInstance } from "@/http/core/axios-gateway";
import { TRestListData, TRestListParams } from "@/http/types/rest";

const CREDITS_PATH = "/api/credits";

export type TCreditTransaction = {
  id: string;
  creditId: number;
  amount: number;
  paidAmount: number;
  reference: string;
  referenceId: string;
  referenceDetail: string;
  status: string;
  dueDays: number;
  interest: number;
  createdBy: string;
  createdAt: Date;
  updatedAt: Date;
};

export type TCredit = {
  id: number;
  supplierId: string;
  sellerId: string;
  amount: number;
  dueDays: number;
  interest: number;
  balance: number;
  isActive: boolean;
  createdAt: Date;
  createdBy: string;
  approvedAt: Date | null;
  approvedBy: string | null;
  updatedAt: Date | null;
};

export type TNewCredit = {
  sellerId: string;
  supplierId: string;
  amount: number;
  dueDays: number;
  interest: number;
  isActive: boolean;
};

export type TPayment = {
  id: string;
  amount: number;
  reference: string;
  referenceId: string;
  referenceDetail: string;
  referenceUrl: string;
  status: string;
  createdAt: Date;
};

export type TNewPayment = {
  amount: number;
  reference: string;
  referenceId: string;
  referenceDetail: string;
  referenceUrl: string;
  status: string;
};

export const getCredits = async (params?: any) => {
  return getInstance()
    .get<TRestListData<TCredit>>(`${CREDITS_PATH}`, { params })
    .then(res => res.data);
};

export const createCredit = async (payload: TNewCredit) => {
  return getInstance()
    .post<TCredit>(`${CREDITS_PATH}`, payload)
    .then(res => res.data);
};

export const updateCredit = async (
  id: number,
  payload: Partial<TNewCredit>
) => {
  return getInstance()
    .put<TCredit>(`${CREDITS_PATH}/${id}`, payload)
    .then(res => res.data);
};

export const getCreditTransactions = async (
  creditId: number,
  params: TRestListParams
) => {
  return getInstance()
    .get<TRestListData<TCreditTransaction>>(
      `${CREDITS_PATH}/${creditId}/transactions`,
      { params }
    )
    .then(res => res.data);
};

export const getCredit = async (id: number) => {
  return getInstance()
    .get<TCredit>(`${CREDITS_PATH}/${id}`)
    .then(res => res.data);
};

export const createReversion = async (
  creditId: number,
  transactionId: string
) => {
  return getInstance()
    .post<TRestListData<TCreditTransaction>>(
      `${CREDITS_PATH}/${creditId}/transactions/${transactionId}/reversion`
    )
    .then(res => res.data);
};

export const updateTransaction = async (
  creditId: number,
  transactionId: string,
  transaction: any
) => {
  return getInstance()
    .put<TRestListData<TCreditTransaction>>(
      `${CREDITS_PATH}/${creditId}/transactions/${transactionId}`,
      transaction
    )
    .then(res => res.data);
};

export const createPayment = async (creditId: number, body: TNewPayment) => {
  return getInstance()
    .post<TRestListData<TCreditTransaction>>(
      `${CREDITS_PATH}/${creditId}/payments`,
      body
    )
    .then(res => res.data);
};

export const getPaymentById = async (creditId: number, paymentId: any) => {
  return getInstance()
    .get<TPayment>(`${CREDITS_PATH}/${creditId}/payments/${paymentId}`)
    .then(res => res.data);
};
