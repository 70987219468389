import { Autocomplete } from "@/components/shared/Autocomplete/Autocomplete";
import { Checkbox } from "@/components/shared/Input/Checkbox";
import { InputV2 } from "@/components/shared/Input/InputV2";
import Tooltip from "@/components/shared/Tooltip/Tooltip";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BiTrash } from "react-icons/bi";

type TGroupOptionsForm = {
  index: number;
  options: any[];
};
export const GroupOptionsForm = ({ index, options }: TGroupOptionsForm) => {
  const methods = useFormContext();
  const optionWatch = methods.watch(`options[${index}]`);
  const optionsLength = (methods.watch("options") ?? []).length;
  const [t] = useTranslation("products-page");

  const deleteOption = () => {
    methods.setValue("options", [
      ...methods
        .watch("options")
        .filter((_: any, idx: number) => index !== idx),
    ]);
  };

  const aditionalValuesRequired = () => {
    const hasOptionsValues = optionWatch?.optionValues?.length > 0;
    const optionsChecked = Object.values(optionWatch.optionsSelected ?? {});
    const hasValuesChecked = optionsChecked.filter(
      value => value === true
    ).length;

    if (hasOptionsValues && hasValuesChecked) {
      return false;
    }

    return true;
  };

  return (
    <>
      <div className="flexGap flexSmColumn ">
        <div className="col-8 col-sm-12 noPadding flexGap flexSmColumn">
          <div className="col-6 noPadding">
            <Autocomplete
              allowNewValue
              inputName={`options[${index}].name`}
              inputLabel={
                <div>
                  <div className="flexGap">
                    <p>{t("OPTION_NAME")}</p>
                    {optionsLength > 1 && (
                      <Tooltip title={t("DELETE_OPTION")} position="Right">
                        <BiTrash className="pointer" onClick={deleteOption} />
                      </Tooltip>
                    )}
                  </div>
                  <p className="smFont">{t("FIELD_REQUIRED")}</p>
                </div>
              }
              inputPlaceholder={""}
              required
              choices={options}
              action={option => {
                if (option) {
                  methods.setValue(`options[${index}].optionsSelected`, {});
                  const parsedValuesSet = new Set(option.options);
                  methods.setValue(
                    `options[${index}].optionValues`,
                    Array.from(parsedValuesSet)
                  );
                  methods.setValue(`options[${index}].name`, option.name);
                } else {
                  methods.setValue(`options[${index}].optionValues`, []);
                }
              }}
            />
          </div>

          <div className="col-6 noPadding">
            {optionWatch?.optionValues?.length > 0 && (
              <>
                <div>
                  <p>{t("OPTION_VALUES")}</p>
                  <p className="smFont">{t("SELECT_OPTION_VALUES")}</p>
                  <div className="spacer" />
                </div>
                {optionWatch?.optionValues?.map(
                  (option: string, idx: number) => (
                    <Checkbox
                      key={idx}
                      name={`options[${index}].optionsSelected.${option}`}
                      label={option}
                    />
                  )
                )}
              </>
            )}
            <InputV2
              name={`options[${index}].aditionalOptionValues`}
              label={
                <div>
                  <p>
                    {t("VALUES")}{" "}
                    {optionWatch?.optionValues?.length > 0 ? "adicionales" : ""}{" "}
                    {t("FROM_OPTION")}
                  </p>
                  <p className="smFont">{t("VALUES_SPLIT")}</p>
                </div>
              }
              required={aditionalValuesRequired()}
            />
          </div>
        </div>
      </div>
      <div className="spacer" />
    </>
  );
};
