import { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { Modal } from "@/components/shared/Modal/Modal";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import styles from "./styles.module.scss";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { deleteProductUnitType, getProductById, TUnitType } from "@/http";

import { useTranslation } from "react-i18next";
import { useProducts } from "@/store/products";

type TDeleteUnitType = {
  unitType: TUnitType;
};
export const DeleteUnitType = ({ unitType }: TDeleteUnitType) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [t] = useTranslation("global");
  const { selectedVariantEdit, setSelectedVariant } = useProducts();

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      await deleteProductUnitType(selectedVariantEdit?.id, unitType?.id ?? "");
      const product = await getProductById(selectedVariantEdit.id);
      setSelectedVariant(product);
      setIsLoading(false);
      showToastSuccess(t("UNIT_TYPE_DELETED"));
      setModalOpen(false);
    } catch (error) {
      showToastError({ message: t("ERROR_SOME_BAD"), error });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ToolTip title={t("DELETE")} position="Left">
        <span className="pointer circleButton">
          <AiOutlineDelete onClick={() => setModalOpen(true)} />
        </span>
      </ToolTip>
      <Modal
        title={t("DELETE_UNIT_TYPE")}
        isOpen={isModalOpen}
        size="sm"
        onClose={() => setModalOpen(false)}
        footer={
          <button
            className="primary md"
            disabled={isLoading}
            onClick={onSubmit}
          >
            {t("IM_SURE")}
          </button>
        }
      >
        <div className={styles.content}>
          <p>{`${t("SURE_DELETE_UNIT_TYPE")} "${unitType.name}"?`}</p>
        </div>
      </Modal>
    </>
  );
};
