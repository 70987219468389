import { FC, ReactNode } from "react";

import styles from "./styles.module.scss";

type Props = {
  title: string | ReactNode;
  position?: "Bottom" | "Top" | "Left" | "Right";
  theme?: "dark" | "light" | "orange";
  containerClass?: string;
  children?: ReactNode;
  width?: string;
};
const ToolTip: FC<Props> = ({
  title,
  children,
  containerClass = "",
  position = "Bottom",
  theme = "light",
  width,
}) => {
  return (
    <div className={`${styles.tooltip} ${containerClass}`}>
      {children}
      <div
        className={`${styles.tooltiptext} ${theme ? styles[theme] : ""} ${
          styles[`tooltip${position}`]
        }`}
        style={width ? { width } : undefined}
      >
        {title}
      </div>
    </div>
  );
};

export default ToolTip;
