import { StepType } from "@reactour/tour";
import { onboardingSelectors } from "./selectors";
import i18next from "i18next";
import { NavigateFunction } from "react-router";

export const getSteps = (
  t: (typeof i18next)["t"],
  navigate: NavigateFunction
): StepType[] => [
  {
    selector: `[data-onboarding=${onboardingSelectors.sidebar("products")}]`,
    content: (
      <>
        <p>{t("SIDEBAR_PRODUCTS.FIRST_LINE")}</p>
        <br />
        <h3 className="bold">{t("OBJECTIVE")}:</h3>
        <p>{t("SIDEBAR_PRODUCTS.OBJECTIVE_CONTENT")}</p>
      </>
    ),
    mutationObservables: ["#sidebar"],
    action() {
      navigate("/products");
    },
    position: "right",
  },

  {
    selector: `[data-onboarding=${onboardingSelectors.addProduct}]`,
    mutationObservables: [
      `[data-onboarding=${onboardingSelectors.addProduct}]`,
    ],
    content: (
      <>
        <p>{t("ACTION_ADD_PRODUCT.FIRST_LINE")}</p>
        <br />
        <h3 className="bold">{t("OBJECTIVE")}:</h3>
        <p>{t("ACTION_ADD_PRODUCT.OBJECTIVE_CONTENT")}</p>
      </>
    ),
    action() {
      navigate("/products");
    },
    position: "bottom",
  },

  {
    selector: `[data-onboarding=${onboardingSelectors.sidebar(
      "product_bulks"
    )}]`,
    content: (
      <>
        <p>{t("ACTION_PRODUCT_BULKS.FIRST_LINE")}</p>
        <ol>
          <li>{t("ACTION_PRODUCT_BULKS.LIST.ONE")}</li>
          <li>{t("ACTION_PRODUCT_BULKS.LIST.TWO")}</li>
          <li>{t("ACTION_PRODUCT_BULKS.LIST.THREE")}</li>
          <li>{t("ACTION_PRODUCT_BULKS.LIST.FOUR")}</li>
        </ol>
      </>
    ),
    action() {
      navigate("/product-bulks");
      const parentSelector = onboardingSelectors.sidebar("products");
      document
        .querySelector<HTMLElement>(
          `[data-onboarding=${parentSelector}] [data-toggle=closed]`
        )
        ?.click();
    },
    position: "right",
  },

  {
    selector: `[data-onboarding=${onboardingSelectors.sidebar("clients")}]`,
    content: (
      <>
        <p>{t("SIDEBAR_CLIENTS.FIRST_LINE")}:</p>
        <h3 className="bold">{t("DESCRIPTION")}</h3>
        <p>{t("SIDEBAR_CLIENTS.DESCRIPTION_CONTENT")}</p>
        <br />
        <h3 className="bold">{t("OBJECTIVE")}:</h3>
        <p>{t("SIDEBAR_CLIENTS.OBJECTIVE_CONTENT")}</p>
      </>
    ),
    action() {
      navigate("/clients");
    },
    position: "right",
  },

  {
    selector: `[data-onboarding=${onboardingSelectors.clientsActions}]`,
    mutationObservables: [
      `[data-onboarding=${onboardingSelectors.clientsActions}]`,
    ],
    content: (
      <>
        <h3 className="bold">{t("DESCRIPTION")}:</h3>
        <p>{t("ACTION_ADD_CLIENTS.DESCRIPTION_CONTENT")}</p>
        <br />
        <h3 className="bold">{t("STEPS")}:</h3>
        <ol>
          <li>{t("ACTION_ADD_CLIENTS.STEPS.ONE")}</li>
          <li>{t("ACTION_ADD_CLIENTS.STEPS.TWO")}</li>
          <li>{t("ACTION_ADD_CLIENTS.STEPS.THREE")}</li>
        </ol>
      </>
    ),
    action() {
      navigate("/clients");
    },
    position: "bottom",
  },

  {
    selector: `[data-onboarding=${onboardingSelectors.sidebar(
      "acquisitions"
    )}]`,
    content: (
      <>
        <p>{t("SIDEBAR_ACQUISITIONS.DESCRIPTION_CONTENT")}</p>
        <br />
        <h3 className="bold">{t("STEPS")}:</h3>
        <ol>
          <li>{t("SIDEBAR_ACQUISITIONS.STEPS.ONE")}</li>
          <li>{t("SIDEBAR_ACQUISITIONS.STEPS.TWO")}</li>
          <li>{t("SIDEBAR_ACQUISITIONS.STEPS.THREE")}</li>
        </ol>
        <br />
        <p>{t("SIDEBAR_ACQUISITIONS.LAST_LINE")}</p>
      </>
    ),
    action() {
      navigate("/acquisitions");
      const parentSelector = onboardingSelectors.sidebar("purchases");
      document
        .querySelector<HTMLElement>(
          `[data-onboarding=${parentSelector}] [data-toggle=closed]`
        )
        ?.click();
    },
    position: "right",
  },

  {
    selector: `[data-onboarding=${onboardingSelectors.sidebar("quotes")}]`,
    content: (
      <>
        <h3 className="bold">{t("SIDEBAR_QUOTES.TITLE")}</h3>
        <p>{t("SIDEBAR_QUOTES.CONTENT")}</p>
      </>
    ),
    action() {
      navigate("/quotes");
      const parentSelector = onboardingSelectors.sidebar("purchases");
      document
        .querySelector<HTMLElement>(
          `[data-onboarding=${parentSelector}] [data-toggle=closed]`
        )
        ?.click();
    },
    position: "right",
  },

  {
    selector: `[data-onboarding=${onboardingSelectors.sidebar("purchases")}]`,
    content: (
      <>
        <p>{t("SIDEBAR_PURCHASES.CONTENT_1")}</p>
        <br />
        <p>{t("SIDEBAR_PURCHASES.CONTENT_2")}</p>
      </>
    ),
    action() {
      navigate("/purchases");
    },
    position: "right",
  },

  {
    selector: `[data-onboarding=${onboardingSelectors.createPurchase}]`,
    mutationObservables: [
      `[data-onboarding=${onboardingSelectors.createPurchase}]`,
    ],
    content: (
      <>
        <p>{t("ACTION_CREATE_PURCHASE.CONTENT")}</p>
      </>
    ),
    action() {
      navigate("/purchases");
    },
    position: "bottom",
  },

  {
    selector: `[data-onboarding=${onboardingSelectors.sidebar("commercials")}]`,
    content: (
      <>
        <p>{t("SIDEBAR_STAFF.CONTENT_1")}</p>
        <br />
        <p>{t("SIDEBAR_STAFF.CONTENT_2")}</p>
      </>
    ),
    action() {
      navigate("/commercials");
    },
    position: "right",
  },

  {
    selector: `[data-onboarding=${onboardingSelectors.sidebar("tickets")}]`,
    content: (
      <>
        <h3 className="bold">{t("DESCRIPTION")}:</h3>
        <p>{t("SIDEBAR_TICKETS.DESCRIPTION_CONTENT")}</p>
        <br />
        <p>{t("SIDEBAR_TICKETS.CONTENT_1")}</p>
        <br />
        <p>{t("SIDEBAR_TICKETS.CONTENT_2")}</p>
      </>
    ),
    action() {
      navigate("/tickets");
    },
    actionAfter() {
      document
        .querySelector<HTMLElement>(
          `[data-onboarding=${onboardingSelectors.accountMenu}]`
        )
        ?.click();
      navigate("/integrations");
    },
    position: "right",
  },

  {
    selector: `[data-onboarding=${onboardingSelectors.integrations}]`,
    highlightedSelectors: [
      `[data-onboarding=${onboardingSelectors.accountMenu}]`,
      `[data-onboarding=${onboardingSelectors.integrations}]`,
    ],
    mutationObservables: [
      `[data-onboarding=${onboardingSelectors.integrations}]`,
    ],
    content: (
      <>
        <h3 className="bold">{t("DESCRIPTION")}:</h3>
        <p>{t("SIDEBAR_INTEGRATIONS.DESCRIPTION_CONTENT")}</p>
        <br />
        <p>{t("SIDEBAR_INTEGRATIONS.CONTENT")}</p>
        <br />
        <h3 className="bold">{t("STEPS")}:</h3>
        <ol>
          <li>{t("SIDEBAR_INTEGRATIONS.STEPS.ONE")}</li>
          <li>{t("SIDEBAR_INTEGRATIONS.STEPS.TWO")}</li>
          <li>{t("SIDEBAR_INTEGRATIONS.STEPS.THREE")}</li>
        </ol>
        <br />
        <p>{t("SIDEBAR_INTEGRATIONS.LAST_LINE")}</p>
      </>
    ),
    action() {
      navigate("/integrations");
    },
    position(positionsProps) {
      return [320, positionsProps.windowHeight - positionsProps.height - 20];
    },
  },
];
