import { InputV2 } from "@/components/shared/Input/InputV2";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

export const ProductSearch = () => {
  const [t] = useTranslation("purchases");

  return (
    <>
      <div className={styles.container}>
        <div className={`row flexGap ${styles.containerInputs}`}>
          <div className={`col-12 col-sm-12 noPadding`}>
            <InputV2
              name={"searchProductName"}
              label=""
              type="text"
              placeholder={"🔍  " + t("ORDER_SELECT_PRODUCTS")}
              inputBG="white"
            />
          </div>
        </div>
      </div>
    </>
  );
};
