import { CURRENT_LANG, ES } from "./core";

export const productTypeChoices = [
  { id: "PRODUCT", name: CURRENT_LANG() === ES ? "Producto" : "Product" },
  { id: "KIT", name: "Kit" },
  { id: "GIFT", name: CURRENT_LANG() === ES ? "Regalo" : "Gift" },
];

export const productTypeChoicesRead: Readonly<Record<string, string>> = {
  PRODUCT: CURRENT_LANG() === ES ? "Producto" : "Product",
  KIT: "Kit",
  GIFT: CURRENT_LANG() === ES ? "Obsequio" : "Gift",
};

export enum ApprovedStatusProduct {
  PENDING = "PENDING",
  IN_REVIEW = "IN_REVIEW",
}

export const productsRenderFilter: Readonly<
  Array<{ name: string; code: string }>
> = [
  {
    name: CURRENT_LANG() === ES ? "Con inventario" : "With inventory",
    code: "availableStock>0",
  },
  {
    name: CURRENT_LANG() === ES ? "Sin inventario" : "No inventory",
    code: "availableStock<1",
  },
];

export const TAX_EXCLUDED = "Excluído";
