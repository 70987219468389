import { TCredit } from "@/http";
import styles from "./styles.module.scss";
import { formatCurrency } from "@/utils/currency";
import { useTranslation } from "react-i18next";

type TCreditResume = {
  credit?: TCredit;
};

export const CreditResume = ({ credit }: TCreditResume) => {
  const [t] = useTranslation("global");
  return (
    <div>
      <div>
        <span className="bold">{t("CREDITS.TABLE.AMOUNT")}</span>:{" "}
        {formatCurrency(credit?.amount || 0)}
      </div>
      <div>
        <span className="bold">{t("CREDITS.TABLE.BALANCE")}</span>:{" "}
        {formatCurrency(credit?.balance || 0)}
      </div>
      <div>
        <span className="bold">{t("CREDITS.TABLE.REMAINING")}</span>:{" "}
        {formatCurrency((credit?.amount || 0) - (credit?.balance || 0))}
      </div>
    </div>
  );
};
