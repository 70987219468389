import { useTranslation } from "react-i18next";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";

const NotFoundPage = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");

  useEffect(() => {
    setTitle("404");
  }, [t]);

  return (
    <div className="main-not-found">
      <div className="fof">
        <h1>{t("NOT_FOUND_MESSAGE")}</h1>
      </div>
    </div>
  );
};

export default NotFoundPage;
