import { Card } from "@/components/shared/Card/Card";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { getSuppliersByIds, TSupplier } from "@/http";
import { TPrepurchaseExtended } from "@/models/prepurchase";
import { useAuth } from "@/store/auth";
import { addressToString } from "@/utils/address";
import { debounce } from "lodash-es";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CreateSupplierModal } from "./CreateSupplierModal";

type Props = { prepurchase: TPrepurchaseExtended };
export const PurchaseDeliveryInfo = ({ prepurchase }: Props) => {
  const {
    user: { addresses = [], id: userId },
  } = useAuth();
  const { watch, setValue } = useFormContext();
  const [suppliers, setSuppliers] = useState<any[]>([]);
  const [t] = useTranslation("global");
  const supplierId = watch("supplierId");
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);

  const onCreateSupplier = async (supplier: TSupplier) => {
    await updateSuppliers();
    const setSupplier = debounce(
      () => setValue("supplierId", supplier.id),
      400
    );
    setSupplier();
  };

  const updateSuppliers = async () => {
    try {
      const suppliers = await getSuppliersByIds(userId);

      setSuppliers(suppliers ?? []);
    } catch (e) {
      setSuppliers([]);
      setValue("supplierId", null);
    }
  };

  const validateUseCustom = (e: any) => {
    const supplierId = e?.target?.value;
    const supplier = suppliers.find(s => s.id === supplierId);
    if (supplier?.parentSupplierId === userId) {
      setValue("parentSupplier", "parentSupplier");
    } else {
      setValue("parentSupplier", "");
    }
  };

  useEffect(() => setValue("quantities", []), [supplierId]);
  useEffect(() => {
    const choices = prepurchase?.paymentMethods
      ?.filter(p => p.isValid && p.code !== "PARTIAL_PAYMENT")
      ?.map(paymentMethod => ({
        label: t(`PAYMENT_METHOD.${paymentMethod.code}`),
        value: paymentMethod.code,
      }));
    if (choices?.length) {
      setPaymentMethods(choices);
    }
  }, [prepurchase]);

  useEffect(() => {
    updateSuppliers();
  }, []);

  return (
    <>
      <Card borderTop>
        <div className="flexGap flexSmColumn">
          <div className="col-4 col-sm-12 noPaddingH">
            <SelectV2
              name="warehouseIdentificator"
              label={
                <span className="bold">{t("PURCHASES.ORDERS.WAREHOUSE")}</span>
              }
              choices={addresses.map(a => ({
                label: addressToString(a, true),
                value: a.id ?? "",
              }))}
            />
          </div>
          <div className="col-4 col-sm-12 noPaddingH">
            <SelectV2
              label={
                <span className="bold">
                  {t("PURCHASES.ORDERS.SELECT_SUPPLIER")}
                </span>
              }
              name="supplierId"
              choices={(suppliers ?? []).map(s => ({
                value: s.id,
                label: s.name,
              }))}
              required
              onChange={validateUseCustom}
            />
            <div className="rigthAlign">
              <CreateSupplierModal onDone={onCreateSupplier} />
            </div>
          </div>
          <div className="col-4 col-sm-12 noPaddingH">
            <SelectV2
              label={
                <span className="bold">
                  {t("PURCHASES.ORDERS.SELECT_PAYMENT_METHOD")}
                </span>
              }
              name="paymentMethod"
              choices={paymentMethods}
              required
              disabled={paymentMethods?.length === 0}
            />
          </div>
        </div>
      </Card>
    </>
  );
};
