import { TProduct } from "@/http";
import { t } from "i18next";
import { BiGift } from "react-icons/bi";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

type TProductResume = {
  product: TProduct;
};

export const ProductResume = ({ product }: TProductResume) => {
  return (
    <>
      <Link to={`/products/${product.id}`} className="textUnderline">
        {product?.type === "GIFT" && (
          <>
            <BiGift className={styles.giftIcon} />{" "}
          </>
        )}
        {product.name}
      </Link>
      {product.brand?.name && (
        <div className={styles.description}>
          {`${t("BRAND")}: ${product.brand?.name}`}
        </div>
      )}
    </>
  );
};
