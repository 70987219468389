import { TSupplierAddress } from "@/http";

export const addressToString = (
  address: Partial<TSupplierAddress> & { name?: string },
  includeName?: boolean
) => {
  return `${address?.name && includeName ? address.name + ", " : ""}${
    address?.addressLine1 ?? ""
  } ${address?.addressLine2 ?? ""} - ${address?.city ?? ""}, ${
    address?.state ?? ""
  }, ${address?.country ?? ""}`;
};
