import { useAuth } from "@/store/auth";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TieredDiscountModal } from "./TieredDiscountModal";
import styles from "./styles.module.scss";
import { formatCurrency } from "@/utils/currency";
import { useTieredDiscounts } from "@/store/limits";
import { LIMIT_TYPES } from "@/constants/limits";
import { TSupplierLimit } from "@/http/types/supplier-limit";
import { BrandName, ProductName } from "../shared/Details";
import { PaginationSelect } from "@/models/orders";
import Paginate from "react-paginate";
import { Spinner } from "../shared/Spinner/Spinner";
import { DeleteTieredDiscount } from "./DeleteTieredDiscount";

export const TieredDiscountsList = () => {
  const [t] = useTranslation("tiered-discounts");
  const { user } = useAuth();
  const { getTieredDiscounts, isLoading, tieredDiscounts, currentPage } =
    useTieredDiscounts();

  const getTargetName = (limit: TSupplierLimit) => {
    switch (limit.applier) {
      case "SUPPLIER":
        return <>{user.name}</>;
      case "PRODUCT":
        return <ProductName id={limit.referenceId} />;
      case "BRAND":
        return <BrandName id={limit.referenceId} />;
    }
  };

  useEffect(() => {
    getTieredDiscounts(user.id);
  }, [currentPage]);

  if (isLoading) {
    return <Spinner />;
  }

  if (!tieredDiscounts?.length) {
    return <h6>{t("NO_TIERED_DISCOUNT")}</h6>;
  }

  return (
    <>
      <div className="tableScroll">
        <table className={styles.table}>
          <thead>
            <tr>
              <th>{t("DISCOUNT")}</th>
              <th>{t("NAME")}</th>
              <th>{t("AMOUNT")}</th>
              <th>{t("PERCENTAGE")}</th>
              <th>{t("ACTIONS")}</th>
            </tr>
          </thead>
          <tbody>
            {tieredDiscounts.map((sale, index) => (
              <tr key={index}>
                <td>
                  {LIMIT_TYPES.find(({ id }) => id === sale.applier)?.value ??
                    "-"}
                </td>
                <td>{getTargetName(sale)}</td>
                <td>
                  {sale.valueType === "AMOUNT"
                    ? formatCurrency(sale?.minValue || 0)
                    : sale?.minValue ?? 0}
                </td>
                <td>{sale.discountPercentage}%</td>
                <td className={styles.actions}>
                  <TieredDiscountModal isEdit discount={sale} index={index} />
                  <DeleteTieredDiscount index={index} discount={sale} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <TieredDiscountPagination />
    </>
  );
};

const TieredDiscountPagination = () => {
  const { setCurrentPage, currentPage, totalPages } = useTieredDiscounts();

  const handlePageChange = (selectedObject: PaginationSelect) => {
    setCurrentPage(selectedObject.selected);
  };

  if (totalPages < 2) {
    return null;
  }

  return (
    <div className={styles.paginate}>
      <Paginate
        forcePage={currentPage}
        pageCount={totalPages}
        previousLabel={"←"}
        nextLabel={"→"}
        marginPagesDisplayed={3}
        onPageChange={handlePageChange}
        containerClassName={styles.pagination}
        previousLinkClassName={styles.previous}
        nextLinkClassName={styles.next}
        disabledClassName={styles.disabled}
        activeClassName={styles.selected}
        breakClassName={styles.break}
      />
    </div>
  );
};
