import { ImagesList } from "@/components/products/Variants/Relate/ImagesList";
import { TableRelate } from "@/components/products/Variants/Relate/TableRelate";
import { BrandName, CategoryName } from "@/components/shared/Details";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { useAuth } from "@/store/auth";
import { useProductsCreation } from "@/store/products-creation";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

export const RelateProductPage = () => {
  const { getProductRelate, productRelate, loading } = useProductsCreation();
  const [t] = useTranslation("products-page");
  const { setTitle } = useHeaderTitle();
  const { refreshUserData } = useAuth();
  const { id } = useParams();

  useEffect(() => {
    setTitle(t("ADD_PRODUCT"), "/products/search");
  }, [t]);

  useEffect(() => {
    refreshUserData();
    getProductRelate(id!);
  }, []);

  if (!productRelate || loading) {
    return <Spinner />;
  }

  return (
    <div className="grayContainer">
      <div className="row">
        <div className="col-8 col-sm-12">
          <h6 className="bold">{t("PRODUCT_ORGANIZATION")}</h6>
          <div className="spacer" />
          <div className="flexGap mdFont">
            <div>
              <p className="bold">{t("PRODUCT_TYPE")}</p>
              <p>
                {productRelate.group.type === "GIFT"
                  ? t("GIFT")
                  : t("PRODUCT_NAME")}
              </p>
            </div>
            <div className="spacerVertical" />
            <div className="spacerVertical" />
            <div>
              <p className="bold">{t("CATEGORY")}</p>
              <p>
                <CategoryName
                  id={
                    productRelate.group?.categoryId ??
                    productRelate.group?.subcategoryId
                  }
                />
              </p>
            </div>
          </div>
          <div className="spacer" />
          <div className="flexGap mdFont">
            <div>
              <p className="bold">{t("BRAND")}</p>
              <p>
                <BrandName id={productRelate.group.brandId} />
              </p>
            </div>
          </div>
          <div className="spacer" />

          <div className="divider" />
          <p className="bold">{t("PRODUCT_NAME_DETAIL")}</p>
          <p>{productRelate?.group?.name ?? "-"}</p>
          <div className="spacer" />
          <p className="bold">{t("DESCRIPTION")}</p>
          <p>{productRelate?.group?.description ?? "-"}</p>
          <div className="divider" />
        </div>
        <div className="col-4 col-sm-12">
          <ImagesList />
        </div>
      </div>
      <TableRelate />
    </div>
  );
};
