import { usePurchase } from "@/store/purchase";
import { useTranslation } from "react-i18next";
import { MultipleMenu } from "@/components/shared/Menu/MultipleMenu";
import { GoSortAsc } from "react-icons/go";
import styles from "./styles.module.scss";
import { FaChevronDown } from "react-icons/fa";
import clsx from "clsx";

export const PurchasesListSort = () => {
  const { sortBy, sortDirection, setSorting } = usePurchase();
  const [t] = useTranslation("orders-page");

  const setSortBy = (sortBy: string | string[]) => {
    if (typeof sortBy === "string") {
      setSorting(sortBy, sortDirection);
    }
  };
  const setSortDirection = (sortDirection: string | string[]) =>
    setSorting(sortBy, sortDirection);

  const sortValues: Record<string, string> = {
    subPurchaseNumber: "ID",
    createdAt: "DATE",
  };

  return (
    <>
      <MultipleMenu
        className={styles.lineContent}
        label={
          <div className="col-12 noPadding">
            <p className="mdFont flexGap center">
              <GoSortAsc />
              {t("SORT.TITLE")}
            </p>
            <div className={clsx(styles.filterContainer, "contentInline")}>
              <span>
                {sortBy && t(`SORT.${sortValues[sortBy]}`)}
                {sortDirection && ` - ${t(`SORT.${sortDirection}`)}`}
              </span>
              <span>
                <FaChevronDown size={"0.7rem"} />
              </span>
            </div>
          </div>
        }
        values={[
          {
            onSelect: setSortBy,
            title: t("PROPERTY"),
            selectedValue: sortBy,
            options: [{ label: t("SORT.DATE"), value: "createdAt" }],
          },
          {
            onSelect: setSortDirection,
            title: t("ORDER"),
            selectedValue: sortDirection,
            options: [
              { label: t("SORT.ASC"), value: "ASC" },
              { label: t("SORT.DESC"), value: "DESC" },
            ],
          },
        ]}
      />
    </>
  );
};
