import styles from "./styles.module.scss";
import { ImageWithFallback } from "@/components/shared/ImageWithFallback";
import { useTranslation } from "react-i18next";

type Props = {
  integrations: string[];
};

export const ProductDetailsIntegrations = ({ integrations }: Props) => {
  const [t] = useTranslation("products-page");
  return (
    <>
      <p className="bold">{t("INTEGRATIONS")}</p>
      <div className="spacer" />
      <ProductIntegrations integrations={integrations} />
      <div className="spacer" />
      <div className="divider" />
      <div className="spacer" />
    </>
  );
};

export const ProductIntegrations = ({ integrations }: Props) => {
  return (
    <>
      {integrations?.length > 0 ? (
        <div className={styles.integrationsIcons}>
          {integrations.map((name: string) => (
            <ImageWithFallback
              key={name}
              src={`/images/integrations/${name}.png`}
              fallbackSrc={"/images/fallback.webp"}
              title={name}
              height={32}
            />
          ))}
        </div>
      ) : (
        <p>-</p>
      )}
    </>
  );
};
