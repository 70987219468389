import { PurchaseList } from "@/components/purchases/PurchaseList/PurchaseList";
import { PurchaseListActions } from "@/components/purchases/PurchaseList/PurchaseListActions/PurchaseListActions";
import { QUOTED } from "@/http";
import { usePurchase } from "@/store/purchase";
import { useHeaderTitle } from "@/store/ui";
import { debounce } from "lodash-es";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const PurchasesListPage = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");
  const { statusFilters, setStatusFilters } = usePurchase();
  const isQuotes = statusFilters["status"] === QUOTED;
  const { setIsProcurement, isProcurementFilter } = usePurchase();

  const debouncedEffectFunction = debounce(() => {
    setIsProcurement(false);
    if (isQuotes) {
      setStatusFilters({ key: "status", value: "PENDING" });
    }
  }, 500);

  useEffect(() => {
    setTitle(t("PURCHASES.LIST.TITLE"));
  }, [t]);

  useEffect(() => {
    debouncedEffectFunction();
  }, []);

  return (
    <>
      <PurchaseListActions />
      {!isQuotes && !isProcurementFilter && <PurchaseList />}
    </>
  );
};
