import { TRestListData, TRestListParams } from "../../types/rest";
import { getInstance } from "../../core/axios-gateway";
import { TPayloadCalculate, TSupplierLimit } from "../../types/supplier-limit";

const BASE_PATH = "/api/limits";

export const getSupplierLimits = async (params: TRestListParams) => {
  return getInstance()
    .get<TRestListData<TSupplierLimit>>(BASE_PATH, { params })
    .then(res => res.data);
};

export const createSupplierLimit = async (data: Partial<TSupplierLimit>) => {
  return getInstance()
    .post<TSupplierLimit>(BASE_PATH, data)
    .then(res => res.data);
};

export const updateSupplierLimit = async (
  id: string,
  data: Partial<TSupplierLimit>
) => {
  return getInstance()
    .put<TSupplierLimit>(`${BASE_PATH}/${id}`, data)
    .then(res => res.data);
};

export const calculateTieredDiscounts = async (data: TPayloadCalculate[]) => {
  return getInstance()
    .post<TSupplierLimit[]>(`${BASE_PATH}/tiered-discounts`, data)
    .then(res => res.data);
};

export const deleteSupplierLimit = async (id: string) => {
  return getInstance()
    .delete<TSupplierLimit>(`${BASE_PATH}/${id}`)
    .then(res => res.data);
};
