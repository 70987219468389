import { Modal } from "@/components/shared/Modal/Modal";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { TPurchase, updatePurchasePaymentStatus } from "@/http";
import { usePurchase } from "@/store/purchase";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MdOutlineEdit } from "react-icons/md";
import styles from "./styles.module.scss";
import { DragAndDropFile } from "@/components/shared/DragAndDropFIle/DragAndDropFIle";
import { IoMdRemoveCircle } from "react-icons/io";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { uploadImagePaymentObject } from "@/utils/image-upload";

type TPurchaseUpdatePaymentStatus = {
  purchase: TPurchase;
};
type TUpdatePaymentStatus = {
  paymentStatus: string;
};

export const PurchaseUpdatePaymentStatus = ({
  purchase,
}: TPurchaseUpdatePaymentStatus) => {
  const methods = useForm<TUpdatePaymentStatus>({});
  const [t] = useTranslation("purchases");
  const [globalT] = useTranslation("global");
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { getPurchase } = usePurchase();
  const [files, setFiles] = useState<any>([]);

  const choices = [{ label: t("PAID"), value: "PAID" }];

  const {
    reset,
    formState: { errors },
  } = useForm({ defaultValues: { paymentStatus: "PAID", voucherLink: "" } });

  const onSubmit = async (values: any) => {
    if (files && files.length) {
      values.voucherLink = (await uploadImagePaymentObject(files[0])).src;
    }

    try {
      setLoading(true);
      await updatePurchasePaymentStatus(purchase.id, values);
      showToastSuccess(t("PAYMENT_STATUS_UPDATED"));
      getPurchase(purchase.id);
      setLoading(false);
      setOpen(false);
      reset();
    } catch (error: any) {
      setLoading(false);
      setOpen(false);
      reset();
      showToastError({ message: globalT("ERROR_SOME_BAD"), error });
    }
  };

  const showVoucherLink = purchase?.paymentStatus == "PENDING";

  return (
    <>
      <ToolTip title={t("UPDATE_PAYMENT_STATUS")} position="Left">
        <span className="pointer circleButton" onClick={() => setOpen(true)}>
          <MdOutlineEdit />
        </span>
      </ToolTip>
      <Modal
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        size={"sm"}
        title={t("UPDATE_PAYMENT_STATUS_TITLE")}
        footer={
          <div className={styles.actions}>
            <button
              className="primary lg"
              disabled={isLoading}
              onClick={methods.handleSubmit(onSubmit)}
            >
              {t("UPDATE_PAYMENT_STATUS")}
            </button>
          </div>
        }
      >
        <FormProvider {...methods}>
          <label>
            <SelectV2
              name="paymentStatus"
              label={t("PAYMENT_STATUS")}
              required
              disabled={isLoading}
              choices={choices}
            />
            <div className="spacer" />
            {showVoucherLink && files.length == 0 && (
              <>
                <span>{globalT("VOUCHERS.ADD_VOUCHER")}</span>
                <DragAndDropFile
                  minifiedStyle
                  accept="image/png,image/jpeg"
                  files={files}
                  setFiles={setFiles}
                />
              </>
            )}
            {showVoucherLink && files.length > 0 && (
              <div>
                <ToolTip title={t("VOUCHERS.DELETE_IMAGE")}>
                  <IoMdRemoveCircle
                    className="redText"
                    cursor="pointer"
                    onClick={() => setFiles([])}
                  />
                </ToolTip>
                {files[0].name}
              </div>
            )}
          </label>
          {errors.paymentStatus && errors.paymentStatus.type === "required" && (
            <span className={styles.errorsLabel}>{t("FIELD_REQUIRED")}</span>
          )}
        </FormProvider>
      </Modal>
    </>
  );
};
