import { InputV2 } from "@/components/shared/Input/InputV2";
import { Modal } from "@/components/shared/Modal/Modal";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { createSupplierLimit } from "@/http";
import { useAuth } from "@/store/auth";
import { useState } from "react";
import { createPortal } from "react-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

type TCreateProductDiscount = { action: () => void; productId: string };

export const CreateProductDiscount = ({
  action,
  productId,
}: TCreateProductDiscount) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const [t] = useTranslation("products-page");
  const [tGlobal] = useTranslation("global");

  const methods = useForm();
  const { handleSubmit, reset } = methods;

  const onSubmit = async (values: any) => {
    setIsLoading(true);
    try {
      values.applier = "PRODUCT";
      values.referenceId = productId;
      values.supplierId = user.id;
      values.type = "DISCOUNT";
      values.valueType = "AMOUNT";

      await createSupplierLimit(values);

      action();
      setIsLoading(false);
      setIsOpen(false);
      reset();
      showToastSuccess(t("DISCOUNT_CREATED"));
    } catch (error) {
      setIsLoading(false);
      showToastError({ message: tGlobal("ERROR_SOME_BAD"), error });
    }
  };

  return (
    <div>
      <span className="pointer" onClick={() => setIsOpen(true)}>
        + {t("ADD_DISCOUNT")}
      </span>
      {createPortal(
        <Modal
          isOpen={isOpen}
          onClose={() => {
            action();
            reset();
            setIsOpen(false);
          }}
          size="sm"
        >
          <FormProvider {...methods}>
            <form
              autoComplete="false"
              className={styles.modalDiscountCreate}
              onSubmit={e => {
                e.stopPropagation();
                handleSubmit(onSubmit)(e);
              }}
            >
              <h1 className="bolder">{t("CREATE_DISCOUNT")}</h1>
              <div className="divider" />
              <div className={styles.content}>
                <InputV2
                  name="value"
                  label={t("MIN_VALUE")}
                  type="number"
                  placeholder={"Ej. 1000"}
                  min={0}
                  required
                />
                <InputV2
                  name="percentage"
                  label={t("PERCENTAGE_DISCOUNT")}
                  type="number"
                  placeholder={"Ej. 10"}
                  min={0}
                  max={100}
                  required
                />
              </div>
              <div className="divider" />
              <div className="rigthAlign">
                <button type="submit" className="primary" disabled={isLoading}>
                  {t("CREATE")}
                </button>
              </div>
            </form>
          </FormProvider>
        </Modal>,
        document.body
      )}
    </div>
  );
};
