import { getInstance } from "../../core/axios-gateway";
import { TSubPurchaseComment, TSubPurchase } from "../../types/purchase";
import { TRestListData } from "../../types/rest";

const BASE_PATH_API = "/api/sub-purchases";

export const updateTrackingCodeBySupurchaseId = async (
  subPurchaseId: string,
  trackingCode: string,
  deliveryProvider: string
) => {
  return getInstance()
    .put(`${BASE_PATH_API}/trackingCode/${subPurchaseId}/`, {
      trackingCode,
      deliveryProvider,
    })
    .then(res => res.data);
};

export const updateDeliveryStatusBySupurchaseId = async (
  subPurchaseId: string,
  deliveryStatus: string
) => {
  return getInstance()
    .put(`${BASE_PATH_API}/deliveryStatus/${subPurchaseId}/`, {
      deliveryStatus,
    })
    .then(res => res.data);
};

export const updatePaymentStatusBySupurchaseId = async (
  subPurchaseId: string,
  paymentStatus: string
) => {
  return getInstance()
    .put(`${BASE_PATH_API}/payment-status/${subPurchaseId}/`, {
      paymentStatus,
    })
    .then(res => res.data);
};

export const deleteSubpurchaseById = async (subPurchaseId: string) => {
  return getInstance().delete(`${BASE_PATH_API}/${subPurchaseId}`);
};

export const getSubpurchaseByTrackingCode = async (trackingCode: string) => {
  return getInstance()
    .get<TRestListData<TSubPurchase>>(`${BASE_PATH_API}`, {
      params: { search: `trackingCode:${trackingCode}` },
    })
    .then(response => response.data);
};

export const updateSubpurchaseDeliveryCost = async (
  subPurchaseId: string,
  deliveryCost: number
) => {
  return getInstance()
    .put(`${BASE_PATH_API}/delivery-cost/${subPurchaseId}`, { deliveryCost })
    .then(response => response.data);
};

export const getSubpurchasePaymentLink = async (subPurchaseId: string) => {
  return getInstance()
    .get(`${BASE_PATH_API}/${subPurchaseId}/payment-link`)
    .then(response => response.data);
};

export const addComment = async (
  subPurchaseId: string,
  data: Omit<TSubPurchaseComment, "createdAt">
) => {
  return getInstance()
    .post<TSubPurchaseComment>(
      `${BASE_PATH_API}/${subPurchaseId}/comment`,
      data,
      {
        headers: {
          "x-log": "omit",
        },
      }
    )
    .then(res => res.data);
};

export const getComments = async (subPurchaseId: string) => {
  return getInstance()
    .get<TSubPurchaseComment[]>(`${BASE_PATH_API}/${subPurchaseId}/comments`)
    .then(res => res.data);
};
