import { getInstance } from "../../core/axios-gateway";
import { TPaymentMethodV2 } from "../../types/payment-method-v2";

export const getPaymentMethods = async () => {
  return getInstance()
    .get<TPaymentMethodV2[]>("/api/payment-methods")
    .then((res) => res.data);
};

export const getSupplierPaymentMethods = async () => {
  return getInstance()
    .get<TPaymentMethodV2[]>("/api/payment-methods/supplier")
    .then((res) => res.data);
};


export const setSupplierPaymentMethods = async (body: any) => {
  return getInstance()
    .post<TPaymentMethodV2[]>("/api/payment-methods/supplier", body)
    .then((res) => res.data);
};

