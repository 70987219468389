import { showToastError } from "@/hooks/useToast";
import {
  sendOtpSignUp,
  sendOtpToPhone,
  verifyOtpCode,
  verifyOtpCodeSignUp,
} from "@/http/resources/sellers/otp";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PinInput } from "../shared/Input/PinInput";
import { Modal } from "../shared/Modal/Modal";

type TClientOTPModal = {
  isOTPMOdalOpen: boolean;
  isUserValidated: boolean;
  setOTPModalOpen: (isOTPMOdalOpen: boolean) => void;
  setUserValidated: (userValidated: boolean) => void;
  onSubmit: (...args: any) => void;
  otpOrigin?: string;
};
export const ClientOTPModal = ({
  isOTPMOdalOpen,
  setOTPModalOpen,
  setUserValidated,
  isUserValidated,
  onSubmit,
  otpOrigin = "sellers",
}: TClientOTPModal) => {
  const methods = useFormContext();
  const phone = methods.watch("phone");
  const country = methods.watch(
    otpOrigin === "sellers" ? "country" : "phonePrefix"
  );
  const initialTime = 2 * 60;
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const [isActiveTimer, setIsActiveTimer] = useState(false);
  const [isSentOTP, setSentOTP] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const [t] = useTranslation("global");

  const sendOTP = async () => {
    try {
      setLoading(true);
      if (otpOrigin === "sellers") {
        await sendOtpToPhone({
          phone,
          prefix: country,
        });
      } else {
        await sendOtpSignUp({
          phone: country + phone,
        });
      }
      setSentOTP(true);
    } catch (error) {
      showToastError({ message: t("ERROR_SOME_BAD"), error });
    } finally {
      setLoading(false);
    }
  };

  const validateOTP = async (code: string) => {
    try {
      setUserValidated(false);
      setLoading(true);

      const otpParams =
        otpOrigin === "sellers"
          ? { phone, prefix: country, code }
          : { phone: country + phone, otp: code };

      const verified =
        otpOrigin === "sellers"
          ? await verifyOtpCode(otpParams)
          : await verifyOtpCodeSignUp(otpParams);

      if (verified?.isValid || verified?.validated) {
        setUserValidated(true);
      }
    } catch (_) {
      showToastError({ message: t("CLIENTS.NO_VALID_CODE") });
    } finally {
      setLoading(false);
    }
  };

  const handleStartTimer = () => {
    setIsActiveTimer(true);
  };

  const handleResetTimer = () => {
    setTimeLeft(initialTime);
    setIsActiveTimer(true);
  };

  useEffect(() => {
    if (isUserValidated) {
      setOTPModalOpen(false);
      methods.handleSubmit(onSubmit)();
    }
  }, [isUserValidated]);

  useEffect(() => {
    setSentOTP(false);
  }, [phone, isOTPMOdalOpen]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isActiveTimer) {
      timer = setInterval(() => {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1);
        } else {
          clearInterval(timer);
          setIsActiveTimer(false);
        }
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isActiveTimer, timeLeft]);

  return (
    <>
      <Modal
        isOpen={isOTPMOdalOpen}
        onClose={() => setOTPModalOpen(false)}
        size="sm"
      >
        <div className="textCenter">
          <div className="spacer" />
          <img src="/logo_blue.svg" alt="Seeri logo" />
          <div className="spacer" />
          <h6>
            <b>{t("CLIENTS.PHONE")}</b> {phone}
          </h6>
          <div className="spacer" />
          {!isSentOTP && (
            <>
              <p>{t("CLIENTS.OTP_MESSAGE")}</p>
              <div className="spacer" />
              <div className="spacer" />
              <div className="spacer" />
              <button
                className="primary lg"
                onClick={() => {
                  sendOTP();
                  handleStartTimer();
                }}
                disabled={isLoading}
              >
                {t("CONTINUE")}
              </button>
            </>
          )}
          {isSentOTP && (
            <>
              <PinInput
                onComplete={validateOTP}
                numInputs={otpOrigin === "sellers" ? 4 : 6}
              />
              <span className="mdFont">
                {t("CLIENTS.LEFT_TIME")}: {minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </span>
              {!isActiveTimer && (
                <>
                  <div className="spacer" />
                  <div className="spacer" />
                  <button
                    className="primary lg"
                    disabled={isLoading}
                    onClick={() => {
                      sendOTP();
                      handleResetTimer();
                    }}
                  >
                    {t("RESEND")}
                  </button>
                </>
              )}
              <div className="spacer" />
              <div className="spacer" />
            </>
          )}
        </div>
      </Modal>
    </>
  );
};
