export const getVariantOptionsString = (
  index: number,
  currentVariants: any[],
  newVariants: any[]
) => {
  const newVariantsFiltered = (newVariants ?? []).filter(
    (_, idx) => idx !== index
  );
  const newVariantsOptionsString = newVariantsFiltered.map(({ variant }: any) =>
    JSON.stringify(
      Object.keys(variant ?? {})
        .sort()
        .reduce((obj: any, key) => {
          obj[key] = variant[key];
          return obj;
        }, {})
    ).toLowerCase()
  );
  const variantsOptionsString = currentVariants?.map(
    ({ productOptions }: any) =>
      JSON.stringify(
        Object.keys(productOptions ?? {})
          .sort()
          .reduce((obj: any, key) => {
            obj[key] = productOptions[key];
            return obj;
          }, {})
      ).toLowerCase()
  );

  return [...variantsOptionsString, ...newVariantsOptionsString];
};

export const getCurrentOptionString = (variant: any) => {
  const sortedKeys = Object.keys(variant).sort();
  const sortedObject: any = {};

  sortedKeys.forEach(key => {
    sortedObject[key] = variant[key];
  });

  return JSON.stringify(sortedObject).toLowerCase();
};

export const optionsToString = (options: any) => {
  const paresClaveValor = Object.entries(options);

  const optionsString = paresClaveValor
    .map(([_, value]) => `${value}`)
    .join(" ");

  return optionsString;
};

export const optionsToValueString = (options: any) => {
  const paresClaveValor = Object.entries(options);

  const optionsString = paresClaveValor
    .map(([key, value]) => `${key}: ${value}`)
    .join(",");

  return optionsString;
};
